import React, { useEffect, useState, CSSProperties } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";




import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import styles from "../style/Home.module.scss"
import { addJob } from "../middleware/postAction"
import { updateJobDetails } from "../middleware/putAction"

import { setCityId, setDurDays, setDurSpan, setStateId, setTimeFrom, setTimeTo } from "../../../../redux/Apislices/postJobSlice";
import clsx from "clsx"
import {
  getCityList,
  getCompanyList,
  getCountryList,
  getDeleteJob,
  getExperinceList,
  getJobTypeList,
  getRoleByJobType,
  getSalaryList,
  getStateList,
} from "../middleware/getAction";
import { setAuthorizationToken } from "../../../../utils/apiServices";
import { getCookie, setSession } from "../../../../utils/storage";
import Msg from "../../../../assets/lang/en.json"

function BasicDetails(props) {

  const {
    next,

    jobDetails,
    getUpdatedJobData,
  } = props;



  const dispatch = useDispatch()

  const { stateId, timeFrom, timeTo, cityId, durDays, durSpan, cityLatitude, cityLongitude } = useSelector((state) => (

    {
      timeFrom: state?.postJobSlice?.basicDetailsState?.timeFrom,
      timeTo: state?.postJobSlice?.basicDetailsState?.timeTo,
      stateId: state?.postJobSlice?.basicDetailsState?.stateId,

      cityId: state?.postJobSlice?.basicDetailsState?.cityId,
      durDays: state?.postJobSlice?.basicDetailsState?.durDays,
      durSpan: state?.postJobSlice?.basicDetailsState?.durSpan,
      cityLatitude: state?.postJobSlice?.basicDetailsState?.cityLatitude,
      cityLongitude: state?.postJobSlice?.basicDetailsState?.cityLongitude,

    }



  ))






  const initialData = {

    hrName: "",
    companyName: "",
    companyId: "",
    jobType: "",
    jobRole: "",
    jobTitle: "",
    jobCountry: "6391653eb1f4c4be8c1b37f7",
    jobState: "",
    jobCity: "",
    isAllowWFH: "",
    isNightShift: "",
    workingDays: [],
    workTimings: {},
    duration: "",
    experinced: "",
    experince: "",
    gender: "",
    salary: "",
    noOfOpening: "",
    isIncentives: "",
  };

  const router = useNavigate();

  const [basicPageOne, setBasicPageOne] = useState(initialData);

  const {
    hrName,

    companyName,
    companyId,
    jobType,
    jobRole,
    jobTitle,
    jobCountry,
    jobState,
    jobCity,
    isAllowWFH,
    isNightShift,
    workingDays,
    workTimings,
    experinced,
    experince,
    gender,
    salary,
    noOfOpening,
    isIncentives,
  } = basicPageOne;



  const [jobStateList, setJobStateList] = useState([]);

  const [jobCityList, setJobCityList] = useState([]);
  const [jobTypeList, setJobTypeList] = useState([]);
  const [getJobRole, setGetJobRole] = useState([]);

  const [openStateOption, setOpenStateOption] = useState(false);
  const [openCityOption, setOpenCityOption] = useState(false);

  const [timeSlotList, setTimeSlotList] = useState([]);
  const [toTimeSlotList, setToTimeSlotList] = useState([])

  const [salaryList, setSalaryListRange] = useState([]);
  const [experienceList, setExperinceList] = useState([]);
  const [jobTypeLoading, setJobTypeLoading] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [hrList, setHrList] = useState([])
  const [hrList2, setHrList2] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target;


    if (name === "companyName") {
      let findCompany = companyList?.filter((el) => {
        return el?._id === value
      })
      let findHr = findCompany[0]?.team?.filter((el) => {
        return el?.role === "admin"
      })
      setHrList(findHr)

      setBasicPageOne({
        ...basicPageOne,

        hrName: findHr[0]?.user?._id,
      });
    }

    if (name === "jobType") {
      setSession("jobTypeStatus", true)
    }

    if (e.target.type === "checkbox") {
      if (e.target.checked) {
        setBasicPageOne({
          ...basicPageOne,
          [name]: true,
        });
      } else {
        setBasicPageOne({
          ...basicPageOne,
          [name]: !value,
        });
      }
    } else {
      setBasicPageOne({
        ...basicPageOne,
        [name]: value,
      });
    }
  };

  // Handle Continue
  const handleContinue = async (e) => {
    e.preventDefault();

    let timeFromSplit = false;
    if (timeFrom !== "aN:aN undefined" || timeFrom === "") {
      timeFromSplit = timeFrom.replace(" ", ":").split(":");
    }


    let timeToSplit = false;
    if (timeTo !== "aN:aN undefined" || timeTo === "") {
      timeToSplit = timeTo.replace(" ", ":").split(":");
    }

    let data = {
      company: companyName,
      companyName,
      jobType,
      jobCategory: jobRole,
      jobCountry,
      city: jobCity,
      user: hrName,
      location: {
        type: "Point",
        coordinates: [Number(cityLatitude), Number(cityLongitude)],
      },
    };
    if (workingDays) {
      data = { ...data, workingDays }
    }
    if (jobState) {
      data = { ...data, jobState };
    }

    if (jobTitle) {
      data = { ...data, title: jobTitle };
    }

    if (isAllowWFH) {
      data = { ...data, wfh: isAllowWFH };
    }

    if (isNightShift) {
      data = { ...data, nightShift: isNightShift };
    }

    if (gender) {
      data = { ...data, gender: gender };
    }

    if (salary) {
      data = { ...data, salary: salary };
    }


    if (experinced === "") {
      toast.error(Msg?.reqExpError);
      return false
    }

    if (experinced === "Fresher" || experinced === "Experienced") {
      data = {
        ...data,
        experienceRequired: experinced,
      };
    }

    if (experinced === "Experienced") {
      if (experienceList?.length || experince) {
        const expId = experince || experienceList?.sort((a, b) => a.min - b.min)?.shift()?._id || null
        data = { ...data, experience: expId };
      }
    } else {

    }

    if (noOfOpening) {
      data = { ...data, openings: noOfOpening };
    }

    if (isIncentives) {
      data = { ...data, incentives: isIncentives };
    }

    let startTime;
    let endTime;
    let jobDuration;
    let workTimings;

    if (timeFromSplit?.length > 1) {
      startTime = {
        hours: Number(timeFromSplit[0]),
        mins: Number(timeFromSplit[1]),
        zone: timeFromSplit[2],
      };

      workTimings = { ...workTimings, startTime };
      data = { ...data, workTimings };
    } else {
      startTime = null
      workTimings = { ...workTimings, startTime };
      data = { ...data, workTimings };
    }

    if (timeToSplit?.length > 1) {
      endTime = {
        hours: Number(timeToSplit[0]),
        mins: Number(timeToSplit[1]),
        zone: timeToSplit[2],
      };

      workTimings = { ...workTimings, endTime };
      data = { ...data, workTimings };
    } else {
      endTime = null;
      workTimings = { ...workTimings, endTime };
      data = { ...data, workTimings };
    }

    if (durSpan === "unlimited") {
      jobDuration = durSpan.trim();

      data = { ...data, jobDuration };
    } else if (durSpan && durDays) {
      jobDuration = `${durDays} ${durSpan}`.trim();

      data = { ...data, jobDuration };
    }



    if (
      companyName === "" ||
      jobType === "" ||
      jobRole === "" ||
      jobTitle === "" ||
      jobCity === ""
    ) {

      toast.error(`Oops. we can't go further before you provide the required information`);




    } else if (!jobDetails) {

      let response = await addJob(data);
      if (response.success) {
        let data = { jobId: response.data.jobId };
        setSession("jobData", data);
        getUpdatedJobData();
        toast.success(response.message);
        router("/post-a-job/" + next);
      } else {
        toast.error(response.message);
      }
    } else if (jobDetails) {
      let response = await updateJobDetails(jobDetails?._id, data);
      if (response.success) {
        getUpdatedJobData();
        router("/post-a-job/" + next);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  };


  const findTimeSlotList = () => {
    let date = new Date(1998, 7, 26, 0, 0, 0);
    const intervalMinutes = 30;
    const dom = date.getDate();
    let times = [];

    do {

      times.push(
        {
          value: date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }),
          label: date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
        }
      );
      date = new Date(date.setMinutes(date.getMinutes() + intervalMinutes));
    } while (date.getDate() === dom);

    setTimeSlotList(times);


  };

  const toTimeSlotListFun = (value) => {
    const findIndex = timeSlotList.findIndex((item) => {
      return item.value === value
    });


    let newArr = timeSlotList?.slice(findIndex + 1)


    setToTimeSlotList(newArr)





  }


  useEffect(() => {

    setAuthorizationToken(getCookie("_jwt"));

    getCompanyList().then((response) => {
      if (response?.success && response?.data?.length) {
        let verifyCompanyList = response?.data?.filter((item) => {
          return item?.companyVerified === true;
        })
        setCompanyList(verifyCompanyList);

      }
    });


    getCountryList().then((response) => {
      if (response?.success && response?.data?.length) {

        setCountryList(response?.data);

      }
    });

    if (jobDetails) {

      setBasicPageOne({
        ...basicPageOne,
        companyName:
          companyName ||
          jobDetails?.company?._id,


        hrName: hrName || jobDetails?.user?._id,
        companyId: companyId ||
          jobDetails?.company?._id,
        jobType: jobType || jobDetails?.jobType?._id,
        jobRole: jobRole || jobDetails?.jobCategory?._id,
        jobTitle: jobDetails?.title || jobTitle,
        jobCountry: jobDetails?.jobCountry?._id || jobCountry,
        jobState: jobState || jobDetails?.jobState?._id,


        jobCity: jobDetails?.city?._id || jobCity,
        isAllowWFH: jobDetails?.wfh || isAllowWFH,
        gender: jobDetails?.gender || gender,
        isIncentives: jobDetails?.incentives || isIncentives,
        noOfOpening: jobDetails?.openings || noOfOpening,
        isNightShift: jobDetails?.nightShift || isNightShift,
        salary: jobDetails?.salary?._id || salary,
        experinced: jobDetails?.experienceRequired || experinced,
        workingDays: jobDetails?.workingDays || workingDays,
        experince: jobDetails?.experience?._id || experince,
        workTimings: workTimings,
      });


      if (jobDetails?.user) {
        let hrList = [jobDetails?.user]
        setHrList2(hrList)

      }


      if (jobDetails?.jobState) {

        dispatch(setStateId(
          stateId || jobDetails?.jobState?.translations[0]?.stateTranslation?.name
        ));
      }

      if (jobDetails?.city) {


        dispatch(setCityId(
          jobDetails?.city?.translations[0]?.cityTranslation?.name || cityId
        ));





      }


      if (jobDetails?.jobDuration) {
        if (jobDetails?.jobDuration === "unlimited") {
          dispatch(setDurSpan(jobDetails?.jobDuration));
        } else {
          dispatch(setDurDays(durDays || jobDetails?.jobDuration.split(" ")[0]));
          dispatch(setDurSpan(jobDetails?.jobDuration.split(" ")[1] || durSpan));
        }
      }

      dispatch(setTimeFrom(
        timeFrom ||
        ("0" + parseInt(jobDetails?.workTimings?.startTime?.hours)).substr(-2) +
        ":" +
        ("0" + parseInt(jobDetails?.workTimings?.startTime?.mins)).substr(
          -2
        ) +
        " " +
        jobDetails?.workTimings?.startTime?.zone
      ));

      dispatch(

        setTimeTo(
          timeTo ||
          ("0" + parseInt(jobDetails?.workTimings?.endTime?.hours)).substr(-2) +
          ":" +
          ("0" + parseInt(jobDetails?.workTimings?.endTime?.mins)).substr(-2) +
          " " +
          jobDetails?.workTimings?.endTime?.zone
        ));

      if (!toTimeSlotList?.length) {
        toTimeSlotListFun(
          ("0" + parseInt(jobDetails?.workTimings?.startTime?.hours)).substr(-2) +
          ":" +
          ("0" + parseInt(jobDetails?.workTimings?.startTime?.mins)).substr(
            -2
          ) +
          " " +
          jobDetails?.workTimings?.startTime?.zone)
      }
    }



    if (jobCountry) {

      let data = jobCountry;
      getStateList(data).then((response) => {
        if (response?.success) {
          setJobStateList(response.data);

        }
      });
    }

    if (jobState !== "") {
      getCityList(jobState).then((response) => {
        if (response?.success) {
          setJobCityList(response.data);
        }
      });
    }


    if (jobType) {
      setGetJobRole([]);
      setJobTypeLoading(true)
    }

    getJobTypeList().then((response) => {
      if (response?.success && response?.data?.length) {

        setJobTypeList(response.data);
        setJobTypeLoading(false)
      }
    });

    if (jobType) {
      getRoleByJobType(jobType).then((res) => {
        if (res.success) {
          setGetJobRole(res?.data);
        }
      });
    }

    findTimeSlotList();

    getSalaryList().then((response) => {
      if (response?.success && response?.data?.length) {
        setSalaryListRange(response.data);
      }
    });
    getExperinceList().then((response) => {
      if (response?.success && response?.data?.length) {
        setExperinceList(response.data);
      }
    });




    return () => {


    };
  }, [jobType, jobCountry, jobState, jobDetails]);




  return (
    <>
      <div
        className="col-xl-9 col-lg-8 col-md-12 col-12 addpadd1 "
        style={{ padding: "0px 15px" }}
      >





        <div className={styles.postMain}>
          <h3 className={styles.postTitle}>Basic Details</h3>
          <div className={"row " + styles.postForm}>
            <div className="col-lg-12 col-md-12 col-xl-12">
              <div className="row">
                <div className="col-lg-7 col-md-12">
                  <div>


                    <div className={styles.postBlock} id={"companyInput"}>
                      <select
                        value={companyName}
                        onChange={handleChange}
                        id="companyName"
                        name="companyName"
                        required
                        spellCheck="false"
                      >
                        <option value=""></option>
                        {companyList?.length
                          ? companyList?.sort((a, b) =>
                            a?.companyName.localeCompare(
                              b?.companyName
                            )
                          )
                            ?.map((items, i) => (
                              <option key={i} value={items?._id}>
                                {items?.companyName

                                  ? items?.companyName

                                  : ""}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className={styles.placeholder}>Company Name *</span>
                    </div>

                    <div className={styles.postBlock} id={"hrInput"}>
                      <select
                        value={hrName}
                        onChange={handleChange}
                        id="hrName"
                        name="hrName"
                        required
                        spellCheck="false"
                      >
                        <option value=""></option>
                        {hrList?.length
                          ? hrList
                            ?.sort((a, b) =>
                              a.user.fullName.localeCompare(
                                b.user.fullName
                              )
                            )
                            ?.map((items, i) => (
                              <option key={i} value={items?.user?._id}>
                                {items?.user?.fullName
                                  ? items?.user?.fullName
                                  : ""}
                              </option>
                            ))
                          : hrList2?.length ? (
                            hrList2?.map((items, i) => (
                              <option key={i} value={items?._id}>
                                {items?.fullName
                                  ? items?.fullName
                                  : ""}
                              </option>
                            ))

                          ) : null}
                      </select>
                      <span className={styles.placeholder}>HR Name *</span>
                    </div>

                    {/* Job Type */}
                    <div className={styles.postBlock} id={"jobTypeInput"}>
                      <select
                        value={jobType}
                        onChange={handleChange}
                        id="jobType"
                        name="jobType"
                        required
                        spellCheck="false"
                      >
                        <option value=""></option>
                        {jobTypeList?.length
                          ? jobTypeList
                            ?.sort((a, b) =>
                              a.translations[0].jobTypeTranslation.name.localeCompare(
                                b.translations[0].jobTypeTranslation.name
                              )
                            )
                            ?.map((items, i) => (
                              <option key={i} value={items?._id}>
                                {items?.translations[0]?.jobTypeTranslation
                                  ?.name
                                  ? items?.translations[0]?.jobTypeTranslation
                                    ?.name
                                  : ""}
                              </option>
                            ))
                          : null}
                      </select>
                      <span className={styles.placeholder}>Job Type *</span>
                    </div>

                    {/* Job Role */}
                    <div className={styles.postBlock} id={"jobRoleInput"}>
                      <select
                        value={jobRole}
                        onChange={handleChange}
                        id="jobRole"
                        name="jobRole"
                        required
                        spellCheck="false"
                      >
                        <option value=""></option>
                        {jobTypeLoading === true ?
                          (
                            <option value="">
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                            </option>

                          )
                          : getJobRole?.length ? (
                            getJobRole
                              ?.sort((a, b) =>
                                a.translations[0].jobCategoryTranslation.name.localeCompare(
                                  b.translations[0].jobCategoryTranslation.name
                                )
                              )
                              ?.map((items, i) => (
                                <option key={i} value={items?._id}>
                                  {items?.translations[0]?.jobCategoryTranslation
                                    ?.name
                                    ? items?.translations[0]
                                      ?.jobCategoryTranslation?.name
                                    : ""}
                                </option>
                              ))
                          ) : (
                            <option value="" disabled>
                              Select Job type first
                            </option>
                          )}
                      </select>
                      <span className={styles.placeholder}>Job Role *</span>
                    </div>

                    {/* Job Title */}
                    <div className={styles.postBlock} id={"jobTitleInput"}>
                      <input
                        type="text"
                        value={jobTitle}
                        onChange={handleChange}
                        name="jobTitle"
                        id="jobTitle"
                        required
                        spellCheck="false"
                      />
                      <span className={styles.placeholder}>Job Title *</span>
                    </div>

                    {/* Job Country */}
                    <div className={styles.postBlock} id={"jobCountryInput"}>
                      <select
                        value={jobCountry}
                        onChange={handleChange}
                        id="jobCountry"
                        name="jobCountry"
                        required
                        spellCheck="false"
                      >
                        <option value=""></option>
                        {countryList?.length
                          ? countryList
                            ?.sort((a, b) =>
                              a.translations[0].countryTranslation.name.localeCompare(
                                b.translations[0].countryTranslation.name
                              )
                            )
                            ?.map((items, i) => {
                              return (
                                <option key={i} value={items._id}>
                                  {items?.translations[0]?.countryTranslation
                                    ?.name
                                    ? items?.translations[0]
                                      ?.countryTranslation?.name
                                    : ""}
                                </option>
                              );
                            })
                          : null}
                      </select>
                      <span className={styles.placeholder}>Job Country</span>
                    </div>



                    {/* Job State * */}
                    <div
                      id="jobStateInput"
                      className={`${styles.postBlock} ${styles.typeahead}`}
                    >
                      <input
                        className={styles.typeaheadInput}
                        value={stateId}

                        onChange={(e) => dispatch(setStateId(e.target.value))}
                        onFocus={() => {
                          setOpenStateOption(true);
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            setOpenStateOption(false);
                          }, 300);
                        }}
                        id="jobState"
                        name="jobState"
                        required
                        autoComplete="off"
                        spellCheck="false"
                      />
                      <span className={styles.placeholder}>Job State / Governorate</span>
                      <div
                        className={styles.inputSearchBox}
                        style={{
                          display: openStateOption ? "block" : "none",
                        }}
                      >
                        {jobStateList?.length ? (
                          <div
                            style={{
                              background: "#f9f9f9",
                              cursor: "default",
                            }}
                            onClick={() => {
                              setBasicPageOne({
                                ...basicPageOne,
                                jobState: "",
                              });
                              dispatch(setStateId(""));
                            }}
                          >
                            Search State
                          </div>
                        ) : null}
                        {jobStateList?.length ? (
                          jobStateList
                            ?.sort((a, b) =>
                              a.translations[0].stateTranslation.name.localeCompare(
                                b.translations[0].stateTranslation.name
                              )
                            )
                            ?.filter((item) => {
                              return stateId ? item?.translations[0]?.stateTranslation?.name
                                .toLowerCase()
                                .includes(stateId.toLowerCase()) : item?.translations[0]?.stateTranslation?.name
                                  .toLowerCase();
                            })
                            ?.map((items, index) => (
                              <div
                                value={items?._id}
                                key={index}
                                onClick={() => {
                                  setBasicPageOne({
                                    ...basicPageOne,
                                    jobState: items?._id,
                                  });
                                  dispatch(setStateId(
                                    items?.translations[0]?.stateTranslation
                                      ?.name
                                  ));
                                }}
                              >
                                <div>
                                  {
                                    items?.translations[0]?.stateTranslation
                                      ?.name
                                  }
                                </div>
                              </div>
                            ))
                        ) : (
                          <div
                            style={{
                              background: "#f9f9f9",
                              cursor: "default",
                            }}
                          >
                            Please select country
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Job City * */}
                    <div
                      id="jobCityInput"
                      className={`${styles.postBlock} ${styles.typeahead}`}
                    >
                      <input
                        className={styles.typeaheadInput}
                        value={cityId}

                        onChange={(e) => dispatch(setCityId(e.target.value))}
                        onFocus={() => {
                          setOpenCityOption(true);
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            setOpenCityOption(false);
                          }, 300);
                        }}
                        id="jobCity"
                        name="jobCity"
                        required
                        autoComplete="off"
                        spellCheck="false"
                      />
                      <span className={styles.placeholder}>Job Location *</span>
                      <div
                        className={styles.inputSearchBox}
                        style={{
                          display: openCityOption ? "block" : "none",
                        }}
                      >
                        {jobCityList?.length
                          ? jobCityList
                            ?.sort((a, b) =>
                              a.translations[0]?.cityTranslation?.name?.localeCompare(
                                b?.translations[0]?.cityTranslation?.name
                              )
                            )
                            ?.filter((item) => {
                              return item?.translations[0]?.cityTranslation?.name
                                .toLowerCase()
                                .includes(cityId?.toLowerCase());
                            })
                            ?.map((items, index) => (
                              <div
                                value={items?._id}
                                key={index}
                                onClick={() => {
                                  setBasicPageOne({
                                    ...basicPageOne,
                                    jobCity: items?._id,
                                  });
                                  dispatch(setCityId(
                                    items?.translations[0]?.cityTranslation
                                      ?.name
                                  ));
                                }}
                              >
                                <div>
                                  {
                                    items?.translations[0]?.cityTranslation
                                      ?.name
                                  }
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>

                    {/* WFH */}
                    <div
                      className="formCheck"
                      style={{
                        padding: "10px 5px",
                        paddingBottom: "0px",
                        display: "flex",
                        justifyContent: "start",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="checkboxInput1"
                        onChange={handleChange}
                        checked={isAllowWFH}
                        value={isAllowWFH}
                        type="checkbox"
                        name="isAllowWFH"
                        id="isAllowWFH"
                        style={{
                          padding: "15px",
                          width: "24px",
                          height: "24px",
                          marginTop: "0px",
                        }}
                      />
                      <label
                        style={{
                          lineHeight: "normal",
                          textAlign: "left",
                          fontSize: "14px",
                          fontWeight: "550",
                          padding: "0px 15px",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >
                        This job allows work from home
                      </label>
                    </div>

                    {/* Night Shift */}
                    <div
                      style={{
                        padding: "15px 5px",
                        display: "flex",
                        justifyContent: "start",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="checkboxInput1"
                        checked={isNightShift}
                        value={isNightShift}
                        onChange={handleChange}
                        type="checkbox"
                        name="isNightShift"
                        id="isNightShift"
                        style={{
                          padding: "15px",
                          width: "24px",
                          height: "24px",
                          marginTop: "0px",
                        }}
                      />
                      <label
                        style={{
                          lineHeight: "normal",
                          textAlign: "left",
                          fontSize: "14px",
                          fontWeight: "550",
                          padding: "0px 15px",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >
                        This is a rotational shift job
                      </label>
                    </div>
                  </div>

                  {/* Working days */}
                  <div style={{ margin: "20px 0px" }}>
                    <h5 className={styles.postTime}>Working Days</h5>
                    <div
                      className={"d-flex " + styles.btnGroup}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >

                      {["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]?.map(
                        (items, i) => (
                          <button
                            onClick={(e) => {
                              if (workingDays?.includes(items)) {
                                let findActiveIndex =
                                  workingDays.indexOf(items);
                                workingDays.splice(findActiveIndex, 1);
                                setBasicPageOne({
                                  ...basicPageOne,
                                  workingDays: [...workingDays],
                                });
                              } else {
                                setBasicPageOne({
                                  ...basicPageOne,
                                  workingDays: [...workingDays, items],
                                });
                              }
                            }}
                            className={
                              workingDays?.includes(items)
                                ? styles.btn + " activebtnc"
                                : styles.btn
                            }
                            key={i}
                            style={{
                              borderLeft: "0px",
                              textTransform: "capitalize",
                            }}
                          >
                            {items?.toUpperCase()}
                          </button>
                        )
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12"></div>

                {/* Timing */}
                <div className="col-lg-5 col-md-12">
                  <div style={{ margin: "30px 0px" }}>
                    <h5 className={styles.postTime}>
                      Timings
                    </h5>
                    <div className="row">
                      <div
                        className={"col-md-6 " + styles.postBlock}
                        style={{
                          margin: "5px 0px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <select
                          onChange={(e) => {
                            dispatch(setTimeFrom(e.target.value))
                            if (e.target.value === "") {
                              dispatch(setTimeTo(""))

                            }
                            toTimeSlotListFun(e.target.value)
                          }}
                          value={timeFrom}
                          name="timeFrom"
                          id="timeFrom"
                          required
                          spellCheck="false"
                        >
                          <option value=""></option>
                          {timeSlotList?.length
                            ? timeSlotList?.slice(0, timeSlotList?.length - 1)?.map((items, i) => (
                              <option key={i} value={items?.value}>
                                {items?.label}
                              </option>
                            ))
                            : null}

                        </select>
                        <span
                          className={clsx(styles.placeholder)}
                          style={{ left: "10%" }}
                        >

                          From
                        </span>
                      </div>
                      <div
                        className={"col-md-6 " + styles.postBlock}
                        style={{
                          margin: "5px 0px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <select
                          value={timeTo}
                          onChange={(e) => {
                            dispatch(setTimeTo(e.target.value))
                          }}
                          name="timeTo"
                          id="timeTo"
                          required
                          spellCheck="false"
                          style={{}}
                          disabled={!timeFrom ? true : false}
                        >
                          <option value=""></option>

                          {toTimeSlotList?.length
                            ? toTimeSlotList?.map((items, i) => (
                              <option key={i} value={items?.value}>
                                {items?.label}
                              </option>
                            ))
                            : null}
                        </select>
                        <span
                          className={clsx(styles.placeholder)}
                          style={{ left: "10%" }}
                        >

                          To
                        </span>
                      </div>

                      <br />


                      <div className="mt-5">
                        <h5 className={styles.postTime}>
                          Duration of job

                        </h5>

                        <div
                          className={clsx("col-md-6 " + styles.postBlock)}
                          style={{
                            margin: "10px 0px",
                            padding: "0 10px",
                          }}



                        >
                          <input
                            value={durSpan === "unlimited" ? "" : durDays}
                            onChange={(e) => dispatch(setDurDays(e.target.value))}
                            disabled={durSpan === "unlimited" ? true : false}
                            name="durDays"
                            id="durDays"
                            required
                            spellCheck="false"
                            style={{}}
                            placeholder={"e.g. 4"}
                          />

                        </div>

                        <div
                          className={"col-md-6 " + styles.postBlock}
                          style={{
                            margin: "10px 0px",
                            padding: "0 10px",
                          }}
                        >
                          <select
                            value={durSpan}
                            onChange={(e) => dispatch(setDurSpan(e.target.value))}
                            name="durSpan"
                            id="durSpan"

                            required
                            spellCheck="false"
                            style={{}}
                          >
                            <option value="Months">

                              Months
                            </option>
                            <option value="Days">

                              Days
                            </option>
                            <option value="Years">
                              Years
                            </option>
                          </select>
                        </div>

                        <br />

                        <div className="mx-3"
                          style={{
                            padding: "10px 5px",
                            paddingBottom: "0px",
                            display: "flex",
                            justifyContent: "start",
                            flexDirection: "row",
                            alignItems: "center",
                          }} >

                          <input
                            className="checkboxInput1"

                            checked={durSpan === "unlimited" ? true : false}
                            onChange={(e) => {
                              dispatch(setDurSpan(e.target.checked ? "unlimited" : "Months"));

                            }}
                            value={durSpan}
                            type="checkbox"
                            name="durSpan"
                            style={{
                              padding: "15px",
                              width: "24px",
                              height: "24px",
                              marginTop: "0px",
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              textAlign: "left",
                              fontSize: "16px",
                              fontWeight: "550",
                              padding: "0px 10px",
                              color: "#000000",
                              marginBottom: "0px",
                            }}
                          >

                            This job has no fixed duration
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 col-md-12"></div>
                <div className="col-lg-4 col-md-12"></div>

                {/* Experience */}
                <div className="col-lg-6 col-md-12">
                  <h5 className={styles.postTime}>Experience Required <span className={styles.postTime} style={{ fontSize: "16px" }}>*</span> </h5>
                  <div className="d-flex" style={{ margin: "15px 0px" }}>
                    <div>
                      <input
                        className=""
                        checked={experinced === "Fresher" ? true : false}
                        type="radio"
                        onChange={handleChange}
                        name="experinced"
                        value={"Fresher"}
                      />
                      <label
                        style={{
                          lineHeight: "normal",
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "550",
                          padding: "0px 15px",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >
                        Fresher
                      </label>
                    </div>
                    <div>
                      <input
                        className=""
                        checked={experinced === "Experienced" ? true : false}
                        type="radio"
                        onChange={handleChange}
                        name="experinced"
                        value={"Experienced"}
                      />
                      <label
                        style={{
                          lineHeight: "normal",
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "550",
                          padding: "0px 15px",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >
                        Experienced
                      </label>
                    </div>
                  </div>
                </div>

                {/* Gender */}
                <div className={"col-lg-6 col-md-12 " + styles.basicGender}>
                  <h5 className={styles.postTime}>Gender</h5>
                  <div className="d-flex" style={{ margin: "15px 0px" }}>
                    <div>
                      <input
                        className=""
                        checked={gender === "Male" ? true : false}
                        onChange={handleChange}
                        value={"Male"}
                        type="radio"
                        name="gender"
                      />
                      <label
                        style={{
                          lineHeight: "normal",
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "550",
                          padding: "0px 15px",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >
                        Male
                      </label>
                    </div>
                    <div>
                      <input
                        className=""
                        checked={gender === "Female" ? true : false}
                        onChange={handleChange}
                        value={"Female"}
                        type="radio"
                        name="gender"
                      />
                      <label
                        style={{
                          lineHeight: "normal",
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "550",
                          padding: "0px 15px",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >
                        Female
                      </label>
                    </div>
                    <div>
                      <input
                        className=""
                        checked={gender === "Both" ? true : false}
                        onChange={handleChange}
                        value={"Both"}
                        type="radio"
                        name="gender"
                      />
                      <label
                        style={{
                          lineHeight: "normal",
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "550",
                          padding: "0px 15px",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >
                        Both
                      </label>
                    </div>
                  </div>
                </div>

                {/* Experience details */}
                {experinced === "Experienced" ? (
                  <div
                    className={"col-lg-5 col-md-12 " + styles.postBlock}
                    style={{
                      margin: "10px 0px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <div className="d-flex">
                      <select
                        onChange={handleChange}
                        value={experince}
                        id="experince"
                        name="experince"
                        required
                        spellCheck="false"
                      >
                        {/* <option value=""></option> */}
                        {experienceList?.length
                          ? experienceList?.sort((a, b) => a.min - b.min)?.map((items, i) => (
                            <option key={i} value={items?._id}>
                              {items?.min +
                                " - " +
                                items?.max +
                                (items?.max > 1 ? " Years" : " Year")}
                            </option>
                          ))
                          : null}
                      </select>
                      <span className={styles.placeholder}>Experience *</span>
                    </div>
                  </div>
                ) : null}

                <div
                  className="col-lg-12 col-md-12 "
                  style={{ padding: "5px" }}
                ></div>

                {/* Salary */}
                <div
                  className={"col-lg-5 col-12 " + styles.postBlock}
                  style={{ padding: "0px 10px" }}
                >
                  <select
                    onChange={handleChange}
                    value={salary}
                    name="salary"
                    id="salary"
                    required
                    spellCheck="false"
                    style={{}}
                  >
                    <option value=""></option>
                    {salaryList?.length
                      ? salaryList?.map((items, i) => (
                        <option key={i} value={items?._id}>
                          {items?.label ? items?.label : "0"}
                        </option>
                      ))
                      : null}
                  </select>
                  <span className={styles.placeholder} style={{ left: "5%" }}>
                    Monthly Salary
                  </span>
                </div>

                <div className="col-lg-1 col-md-12"></div>

                {/* No. of Openings */}
                <div className="col-lg-5 col-12 ">
                  <div
                    className={styles.postBlock}
                    style={{ margin: "10px 0px" }}
                  >
                    <input
                      type="number"
                      onChange={handleChange}
                      value={noOfOpening}
                      name="noOfOpening"
                      id="noOfOpening"
                      required
                      spellCheck="false"
                    />
                    <span className={styles.placeholder}>No. of openings</span>
                  </div>
                </div>

                {/* Incentives */}
                <div
                  className="col-lg-5 col-12 "
                  style={{ padding: "0px 10px" }}
                >
                  <div
                    className="formCheck"
                    style={{
                      padding: "5px 0px",
                      paddingBottom: "0px",
                      display: "flex",
                      justifyContent: "start",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <input
                      className="checkboxInput1"
                      type="checkbox"
                      onChange={handleChange}
                      checked={isIncentives}
                      value={isIncentives}
                      name="isIncentives"
                      id="isIncentives"
                      style={{
                        padding: "15px",
                        width: "24px",
                        height: "24px",
                        marginTop: "0px",
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "550",
                        padding: "0px 15px",
                        color: "#000000",
                        marginBottom: "0px",
                      }}
                    >
                      This includes incentives
                    </label>
                  </div>
                </div>
              </div>

              {/* Continue */}
              <div>
                <div className={styles.basicBtn}>
                  <button
                    className={styles.btn}
                    onClick={(e) => {
                      handleContinue(e);
                    }}
                    type="button"
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicDetails;