/* eslint-disable */

import React, { useEffect, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import clsx from "clsx";
import { toast } from "react-toastify";

import { asyncUpdateStatusUser } from "../../redux/Action/postListAction";
import { asyncgetAllCommentList, asyncUpdateStatusComment } from "../../redux/Action/commentListAction";
import styles from "../../style/feed.module.scss";
import { getScreenWidth } from "../../utils/Utils";
import DotPagination from "../../components/pagination/DotPagination.j";
import { setIsOpen, setParentId } from "../../redux/Apislices/commentListSlice";
import ChildModal from "./ChildModal";

const CommentList = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { error, loading, success, commentList, total } = useSelector((state) => state?.commentListSlice);

  const [tablesm, updateTableSm] = useState(true);
  const [onSearch, setonSearch] = useState(false);
  const [onSearchText, setSearchText] = useState("");
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());

  const [currentPage, setCurrentPage] = useState(searchParams.get("p") ? parseInt(searchParams.get("p")) : 1);
  const [itemPerPage, setItemPerPage] = useState(10);

  const handleBlockComment = async (id) => {
    let data = {
      status: false,
    };
    let res = await dispatch(asyncUpdateStatusComment(id, data));

    if (res?.success) {
      await dispatch(asyncgetAllCommentList(currentPage, itemPerPage));
    } else {
      await dispatch(asyncgetAllCommentList(currentPage, itemPerPage));
    }
  };

  const handleUnBlockComment = async (id) => {
    let data = {
      status: true,
    };
    let res = await dispatch(asyncUpdateStatusComment(id, data));

    if (res?.success) {
      await dispatch(asyncgetAllCommentList(currentPage, itemPerPage));
    } else {
      await dispatch(asyncgetAllCommentList(currentPage, itemPerPage));
    }
  };

  const handleBlockUser = async (id) => {
    let data = {
      status: true,
    };
    let res = await dispatch(asyncUpdateStatusUser(id, data));

    if (res?.success) {
      await dispatch(asyncgetAllCommentList(currentPage, itemPerPage));
    } else {
      await dispatch(asyncgetAllCommentList(currentPage, itemPerPage));
    }
  };

  const handleUnBlockUser = async (id) => {
    let data = {
      status: false,
    };
    let res = await dispatch(asyncUpdateStatusUser(id, data));

    if (res?.success) {
      await dispatch(asyncgetAllCommentList(currentPage, itemPerPage));
    } else {
      await dispatch(asyncgetAllCommentList(currentPage, itemPerPage));
    }
  };

  const inactiveCommentAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete the comment?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleBlockComment(id);
        Swal.fire("Deleted!", "Your comment has been deleted", "success");
      }
    });
  };

  const activeCommentAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to Recover the comment?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUnBlockComment(id);
        Swal.fire("Recovered!", "Your comment has been recovered.", "success");
      }
    });
  };

  const blockAlertUser = (id) => {
    Swal.fire({
      title: "Are you sure you want to block the user?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleBlockUser(id);
        Swal.fire("Blocked!", "Your user has been blocked.", "success");
      }
    });
  };

  const unBlockAlertUser = (id) => {
    Swal.fire({
      title: "Are you sure you want to unblock the user?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUnBlockUser(id);
        Swal.fire("Unblocked!", "Your user has been unblocked.", "success");
      }
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setView({ edit: false, add: false });
  };

  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };
  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const toggle = () => setonSearch(!onSearch);

  const searchComment = async (e) => {
    let res = await dispatch(asyncgetAllCommentList(currentPage, itemPerPage, onSearchText));

    if (!res?.success) {
      await dispatch(asyncgetAllCommentList(currentPage, itemPerPage));
    }
  };

  useEffect(() => {
    dispatch(asyncgetAllCommentList(currentPage, itemPerPage));

    return () => {};
  }, [dispatch, currentPage, itemPerPage]);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(getScreenWidth());
    }

    const resizeListener = () => handleResize();

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="Comment List"></Head>
      <Content>
        <Breadcrumb className="breadcrumb-arrow">
          <BreadcrumbItem>
            <Link to={"/feed"}>Feed</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={"/moderate-world"}>Moderate World</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a>Comment List</a>
          </BreadcrumbItem>
        </Breadcrumb>

        <Block style={{ marginTop: "15px" }}>
          <Card className="card-bordered">
            <div className="card-inner-group">
              <div
                className="card-inner p-0"
                style={{
                  width: "100%",
                }}
              >
                <div className="card-inner position-relative card-tools-toggle">
                  <div className="card-title-group">
                    <div className="card-tools mr-n1">
                      <ul className="btn-toolbar gx-1">
                        <li>
                          <a
                            href="#search"
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggle();
                            }}
                            className="btn btn-icon search-toggle toggle-search"
                          >
                            <Icon name="search"></Icon>
                          </a>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <div className="toggle-wrap">
                            <Button
                              className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                              onClick={() => updateTableSm(true)}
                            >
                              <Icon name="menu-right"></Icon>
                            </Button>
                            <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                              <ul className="btn-toolbar gx-1">
                                <li className="toggle-close">
                                  <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                    <Icon name="arrow-left"></Icon>
                                  </Button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                    <div className="card-body">
                      <div className="search-content">
                        <Button
                          className="search-back btn-icon toggle-search active"
                          onClick={() => {
                            setSearchText("");
                            toggle();
                          }}
                        >
                          <Icon name="arrow-left"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="border-transparent form-focus-none form-control"
                          placeholder="Enter Post Id "
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                        />
                        <Button
                          className="search-submit btn-icon"
                          onClick={(e) => {
                            searchComment(e);
                          }}
                        >
                          <Icon name="search"></Icon>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                {loading ? (
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <Spinner type="grow" color="dark" />
                  </div>
                ) : error ? (
                  "something went wrong..."
                ) : success ? (
                  <DataTableBody>
                    <DataTableHead>
                      <DataTableRow size="xx">
                        <span>Post Id</span>
                      </DataTableRow>

                      <DataTableRow size="md">
                        <span>{"Date"}</span>
                      </DataTableRow>

                      <DataTableRow size="md">
                        <span>Creator Name</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools" size="md">
                        <span>Text</span>
                      </DataTableRow>

                      <DataTableRow className="nk-tb-col-tools" size="md">
                        <span>Replies Count</span>
                      </DataTableRow>

                      <DataTableRow className={clsx("nk-tb-col-tools", styles.tableLastHead)} size="md">
                        <span>Status</span>
                      </DataTableRow>
                    </DataTableHead>
                    <>
                      {commentList?.length ? (
                        commentList
                          ?.slice()
                          .sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
                          ?.map((item, index) => {
                            return (
                              <DataTableItem key={item._id}>
                                <DataTableRow size="xx">
                                  <Link to={`/comment-list/${item?._id}?p=${currentPage}`} className="tb-product">
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        color: "#8094AE",
                                      }}
                                      className="title"
                                    >
                                      {item?.post ? item?.post : ""}
                                    </span>
                                  </Link>
                                </DataTableRow>

                                <DataTableRow size="md">
                                  <span style={{ fontSize: "10px" }}>
                                    {item?.createdAt ? moment(item.createdAt)?.utc()?.format("DD MMM YY h:mm A") : ""}
                                  </span>
                                </DataTableRow>

                                <DataTableRow size="md">
                                  <span className="tb-sub">{item?.user?.fullName ? item?.user?.fullName : ""}</span>
                                </DataTableRow>

                                <DataTableRow size="md">
                                  <span className="tb-sub">{item?.text ? item?.text : ""}</span>
                                </DataTableRow>

                                <DataTableRow size="md">
                                  <span
                                    className={clsx(
                                      "tb-sub",
                                      item?.replies?.length ? styles.replyBtnGreen : styles.replyBtnRed
                                    )}
                                    onClick={(e) => {
                                      if (item?.replies?.length) {
                                        dispatch(setIsOpen(true));
                                        dispatch(setParentId(item?._id));
                                      }
                                    }}
                                  >
                                    {item?.replies?.length ? item?.replies?.length : "0"}
                                  </span>
                                </DataTableRow>

                                <DataTableRow size="md">
                                  <span className={clsx("tb-sub", item?.isActive ? "active-color" : "inactive-color")}>
                                    {" "}
                                    {item?.isActive ? "Active " : "Inactive"}
                                  </span>
                                </DataTableRow>

                                <DataTableRow className="nk-tb-col-tools">
                                  <ul className="nk-tb-actions gx-1 my-n1">
                                    <li className="mr-n1">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          tag="a"
                                          href="#more"
                                          onClick={(ev) => ev.preventDefault()}
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                        >
                                          <Icon name="more-h"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href={`/comment-list/${item?._id}?p=${currentPage}`}
                                              >
                                                <Icon name="article"></Icon>
                                                <span>Preview</span>
                                              </DropdownItem>
                                            </li>
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#remove"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  if (item?.isActive) {
                                                    inactiveCommentAlert(item._id);
                                                  } else {
                                                    activeCommentAlert(item._id);
                                                  }
                                                }}
                                              >
                                                <Icon name={item?.isActive ? "unlink-alt" : "link-alt"}></Icon>
                                                <span>{item?.isActive ? "Delete Comment" : "Recover Comment"}</span>
                                              </DropdownItem>
                                            </li>

                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#remove"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  if (!item?.user?.blockedByAdmin) {
                                                    blockAlertUser(item?.user?._id);
                                                  } else {
                                                    unBlockAlertUser(item?.user?._id);
                                                  }
                                                }}
                                              >
                                                <Icon
                                                  name={!item?.user?.blockedByAdmin ? "user-cross" : "user-add-fill"}
                                                ></Icon>
                                                <span>
                                                  {!item?.user?.blockedByAdmin ? "Block User " : "Unblock User"}
                                                </span>
                                              </DropdownItem>
                                            </li>

                                            <li
                                              className={clsx(
                                                item?.replies?.length ? styles.replyBtnGreen : styles.replyBtnRed
                                              )}
                                            >
                                              <DropdownItem
                                                tag="a"
                                                href="#remove"
                                                onClick={(e) => {
                                                  if (item?.replies?.length) {
                                                    dispatch(setIsOpen(true));
                                                    dispatch(setParentId(item?._id));
                                                  } else {
                                                    toast.warning("No Child Comment List Available");
                                                  }
                                                }}
                                              >
                                                <Icon name={"list"}></Icon>
                                                <span>{"Child Comment List"}</span>
                                              </DropdownItem>
                                            </li>
                                          </ul>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </li>
                                  </ul>
                                </DataTableRow>
                              </DataTableItem>
                            );
                          })
                      ) : (
                        <div className="text-center d-flex justify-content-center flex-column">
                          <span className="text-silent">No Comment List found</span>
                          <span
                            onClick={() => {
                              dispatch(asyncgetAllCommentList(currentPage, itemPerPage));
                            }}
                            style={{ color: "dodgerblue", cursor: "pointer" }}
                          >
                            Reset It
                          </span>
                        </div>
                      )}
                    </>
                  </DataTableBody>
                ) : null}
                <div className="card-inner">
                  {commentList?.length > 0 ? (
                    <DotPagination
                      itemPerPage={itemPerPage}
                      totalItems={total}
                      paginate={paginate}
                      currentPage={currentPage}
                      siblingCount={screenWidth < 575 ? 0 : 2}
                      screenWidth={screenWidth}
                    />
                  ) : (
                    <DotPagination
                      itemPerPage={itemPerPage}
                      totalItems={total}
                      paginate={paginate}
                      currentPage={currentPage}
                      siblingCount={screenWidth < 575 ? 0 : 2}
                      screenWidth={screenWidth}
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Block>
      </Content>

      {/* child list modal */}
      <ChildModal currentPage={currentPage} itemPerPage={itemPerPage} />
    </React.Fragment>
  );
};

export default CommentList;
