import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  company: null,
  total: 0,
  companyId: null,

};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    isCompanyLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isCompanySuccess: (state, action) => {
      state.loading = false;
      state.company = action.payload.data;
      state.total = action.payload.total;

      state.success = true;
      state.error = false;
    },
    isCompanyByIdSuccess: (state, action) => {
      state.loading = false;
      state.companyId = action.payload;
      state.success = true;
      state.error = false;
    },
    isSuccess: (state, action) => {
      state.loading = false;

      state.success = true;
      state.error = false;
    },
    isCompanyError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export default companySlice.reducer;

export const { isSuccess, isCompanyByIdSuccess, isCompanyError, isCompanyLoading, isCompanySuccess } = companySlice.actions;
