/* eslint-disable */

import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  RSelect,
  Row,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

import { toast } from "react-toastify";
import {
  asyncAddHashtag,
  asyncDeleteHashtag,
  asyncEditHashtag,
  asyncgetAllHashtags,
  asyncgetAllJobTypes,
  asyncGetHashtagById,
} from "../../redux/Action/hashtagAction";
import { EnglishMessage } from "../../lang/english";
import clsx from "clsx";
import styles from "../../style/feed.module.scss";
import moment from "moment";

const isActiveSelectTag = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const HashtagList = () => {
  const dispatch = useDispatch();
  const { error, loading, success, hashtagList, hashtagId, total, jobTypeList } = useSelector(
    (state) => state?.hashtagSlice
  );
  const [englishLanguageId, setEnglishLanguageId] = useState("62e7f3b038629a3473e07310");
  const [arabicLanguageId, setArabicLanguageId] = useState("62e7f4bd38629a3473e07313");
  const [jobTypeOption, setJobTypeOption] = useState([]);
  const [sm, updateSm] = useState(false);

  const [view, setView] = useState({
    edit: false,
    add: false,
  });

  const [hashtagData, setHashtagData] = useState({
    nameEn: "",
    nameAr: "",
    isActive: "",
    jobType: "",
    jobTypeId: "",
  });

  const [idValue, setId] = useState("");
  const { nameEn, nameAr, isActive, jobType, jobTypeName } = hashtagData;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  // category change
  const onAddSkillChange = (e) => {
    const { name, value } = e.target;
    setHashtagData({
      ...hashtagData,
      [name]: value,
    });
  };

  const onCategoryChange = (value) => {
    setHashtagData({ ...hashtagData, isActive: value?.value });
  };

  const onCategoryJobTypeChange = (value) => {
    setHashtagData({ ...hashtagData, jobType: value?.value });
  };

  const handleAddHashtag = async (e) => {
    e.preventDefault();

    let data = {
      hashtags: [
        {
          name: nameEn,
          language: englishLanguageId,
        },
        {
          name: nameAr,
          language: arabicLanguageId,
        },
      ],
      isactive: isActive,
      jobType: jobType ? jobType : null,
    };

    if (nameAr && nameEn) {
      let res = await dispatch(asyncAddHashtag(data));
      if (res?.success) {
        toast.success(res?.message);

        await dispatch(asyncgetAllHashtags(currentPage, itemPerPage));
        onFormCancel();
        setHashtagData({ ...hashtagData, nameEn: "", nameAr: "", isActive: "", jobType: "" });
      } else {
        toast.error(res?.message);
        onFormCancel();
        await dispatch(asyncgetAllHashtags(currentPage, itemPerPage));
        setHashtagData({ ...hashtagData, nameEn: "", nameAr: "", isActive: "", jobType: "" });
      }
    }
  };

  const editIntialValue = (id, hashtagObj) => {
    setHashtagData({
      ...hashtagData,
      nameEn: hashtagObj?.translations[0]?.hashtagTranslation?.name,
      nameAr: hashtagObj?.translations[1]?.hashtagTranslation?.name,
      isActive: hashtagObj?.isactive,
      jobType: hashtagObj?.jobType?._id,
      jobTypeName: hashtagObj?.jobType?.translations[0]?.jobTypeTranslation?.name,
    });
  };

  const handleEditHashtag = async (e) => {
    e.preventDefault();

    let englishId = hashtagId?.translations[0]?.hashtagTranslation?._id;
    let arLanguageId = hashtagId?.translations[1]?.hashtagTranslation?._id;

    let data = {
      englishId: englishId,
      arabicId: arLanguageId,
      englishName: nameEn,
      arabicName: nameAr,
      isactive: isActive,
      jobType: jobType ? jobType : null,
    };

    if (nameEn && nameAr) {
      let res = await dispatch(asyncEditHashtag(idValue, data));
      if (res?.success) {
        await dispatch(asyncgetAllHashtags(currentPage, itemPerPage));
        onFormCancel();
        setHashtagData({ ...hashtagData, nameEn: "", nameAr: "", isActive: "", jobType: "" });
      } else {
        toast.error(res?.message);
      }
    } else {
      toast.error("Please fill all fields");
    }
  };

  // function to delete a skill
  const handleDeleteHashtag = async (id) => {
    let res = await dispatch(asyncDeleteHashtag(id));

    if (res?.success) {
      await dispatch(asyncgetAllHashtags(currentPage, itemPerPage));
    }
  };

  // Delete Alert
  const deleteAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to deactivate the hashtag?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteHashtag(id);
        Swal.fire("Deactivated!", "The hashtag has been deactivated.", "success");
      }
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setView({ edit: false, add: false });
  };

  // toggle function to view product details
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
    });
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { errors, register } = useForm();

  useEffect(() => {
    dispatch(asyncgetAllHashtags(currentPage, itemPerPage));

    return () => {};
  }, [dispatch, currentPage, itemPerPage]);

  useEffect(() => {
    if (!jobTypeList?.length) {
      dispatch(asyncgetAllJobTypes());
    }

    return () => {};
  }, [dispatch, jobTypeList]);

  useEffect(() => {
    if (jobTypeList?.length) {
      let mappedOptions = jobTypeList.map((item) => ({
        value: item._id,
        label: item?.translations[0]?.jobTypeTranslation?.name,
      }));
      let addEmptyState = [
        {
          value: "",
          label: "Select",
        },
      ];

      let finalList = [...addEmptyState, ...mappedOptions];

      setJobTypeOption(finalList);
    }
  }, [jobTypeList]);

  useEffect(() => {
    if (idValue) {
      dispatch(asyncGetHashtagById(idValue));
      dispatch(asyncgetAllHashtags(currentPage, itemPerPage));
    }
    return () => {};
  }, [idValue, dispatch]);

  const copyHashtagId = (hashId) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(hashId)
        .then(() => {
          toast.success("Copied!");
        })
        .catch((error) => {
          // Handle any errors that may occur while copying
          toast.error("Failed to copy:", error);
        });
    } else {
      // Fallback method for browsers that do not support the Clipboard API

      const tempInputElement = document.createElement("textarea");
      tempInputElement.value = hashId;
      document.body.appendChild(tempInputElement);
      tempInputElement.select();
      document.execCommand("copy");
      document.body.removeChild(tempInputElement);

      toast.success("Copied!");
    }
  };

  return (
    <React.Fragment>
      <Head title="Hashtags List"></Head>
      <Content>
        <Breadcrumb className="breadcrumb-arrow">
          <BreadcrumbItem>
            <Link to={"/"}>Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={"/feed"}>Feed</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a>Hashtags</a>
          </BreadcrumbItem>
        </Breadcrumb>
        <BlockHead size="sm">
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle d-flex justify-content-end">
              <a
                href="#more"
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                onClick={(ev) => {
                  ev.preventDefault();
                  updateSm(!sm);
                }}
              >
                <Icon name="more-v"></Icon>
              </a>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li className="nk-block-tools-opt">
                    <Button
                      className="toggle btn-icon d-md-none"
                      color="primary"
                      onClick={() => {
                        toggle("add");
                      }}
                    >
                      <Icon name="plus"></Icon>
                    </Button>
                    <Button
                      className="toggle d-none d-md-inline-flex"
                      color="primary"
                      onClick={() => {
                        toggle("add");
                      }}
                    >
                      <Icon name="plus"></Icon>
                      <span>Add Hashtag</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-inner-group">
              <div
                className="card-inner p-0"
                style={{
                  width: "100%",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <Spinner type="grow" color="dark" />
                  </div>
                ) : error ? (
                  "something went wrong..."
                ) : success ? (
                  <DataTableBody>
                    <DataTableHead>
                      <DataTableRow size="xx">
                        <span>Id</span>
                      </DataTableRow>

                      <DataTableRow size="md">
                        <span>Job Type</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>Hashtag English</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools" size="md">
                        <span>Hashtag Arabic</span>
                      </DataTableRow>
                      <DataTableRow className={clsx("nk-tb-col-tools", styles.tableLastHead)} size="md">
                        <span>Status</span>
                      </DataTableRow>
                    </DataTableHead>
                    <>
                      {hashtagList?.length > 0 ? (
                        hashtagList
                          ?.slice()
                          .sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
                          ?.map((item, index) => {
                            return (
                              <DataTableItem key={item._id}>
                                <DataTableRow size="xx">
                                  <button
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                      fontSize: "10px",
                                      color: "#8094AE",
                                    }}
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      copyHashtagId(item._id);
                                    }}
                                  >
                                    <span className="title">{item?._id ? item?._id : ""}</span>
                                  </button>
                                </DataTableRow>

                                <DataTableRow size="md">
                                  <span className="tb-sub">
                                    {item?.jobType?.translations[0]?.jobTypeTranslation?.name
                                      ? item?.jobType?.translations[0]?.jobTypeTranslation?.name
                                      : ""}
                                  </span>
                                </DataTableRow>
                                <DataTableRow size="md">
                                  <span className="tb-sub">
                                    {item?.translations[0]?.hashtagTranslation?.name
                                      ? item?.translations[0]?.hashtagTranslation?.name
                                      : ""}
                                  </span>
                                </DataTableRow>

                                <DataTableRow size="md">
                                  <span className="tb-sub">
                                    {item?.translations[1]?.hashtagTranslation?.name
                                      ? item?.translations[1]?.hashtagTranslation?.name
                                      : ""}
                                  </span>
                                </DataTableRow>
                                <DataTableRow size="md">
                                  <span className={clsx("tb-sub", item?.isactive ? "active-color" : "inactive-color")}>
                                    {item?.isactive ? "Active" : "InActive"}
                                  </span>
                                </DataTableRow>

                                <DataTableRow className="nk-tb-col-tools">
                                  <ul className="nk-tb-actions gx-1 my-n1">
                                    <li className="mr-n1">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          tag="a"
                                          href="#more"
                                          onClick={(ev) => ev.preventDefault()}
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                        >
                                          <Icon name="more-h"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#remove"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  setId(item._id);

                                                  editIntialValue(item._id, item);
                                                  toggle("edit", item._id);
                                                }}
                                              >
                                                <Icon name="edit"></Icon>
                                                <span>Update</span>
                                              </DropdownItem>
                                            </li>
                                            {item?.isactive ? (
                                              <li>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#remove"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    if (item?.isactive) {
                                                      deleteAlert(item._id);
                                                    } else {
                                                      toast.warning(EnglishMessage?.HashtagInactive);
                                                    }
                                                  }}
                                                >
                                                  <Icon name="unlink-alt"></Icon>
                                                  <span>Inactive</span>
                                                </DropdownItem>
                                              </li>
                                            ) : null}
                                          </ul>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </li>
                                  </ul>
                                </DataTableRow>
                              </DataTableItem>
                            );
                          })
                      ) : (
                        <div className="text-center">
                          <span className="text-silent">No Hashtag found</span>
                        </div>
                      )}
                    </>
                  </DataTableBody>
                ) : null}
                <div className="card-inner">
                  {hashtagList.length > 0 ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={total}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={total}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Block>

        {view.add && <div className="toggle-overlay" onClick={toggle}></div>}

        {/* Add Hashtag  */}

        <Modal
          isOpen={view.add || view?.edit}
          toggle={() => onFormCancel()}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <BlockHead>
              <BlockHeadContent>
                <BlockTitle tag="h5">
                  <p style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span>{view?.add ? "Add" : view?.edit ? "Edit" : "Add"} Hashtag</span>
                    <RxCross2
                      onClick={(e) => {
                        e.stopPropagation();
                        onFormCancel();
                        setHashtagData({
                          ...hashtagData,
                          nameEn: "",
                          nameAr: "",
                          isActive: "",
                          jobType: "",
                          jobTypeName: "",
                        });
                      }}
                      style={{ width: "20px", height: "20px", color: "gray", cursor: "pointer" }}
                    />
                  </p>
                </BlockTitle>
                <BlockDes>
                  <p>
                    {view?.add
                      ? "Add information of hashtag."
                      : view?.edit
                      ? "Edit information of hashtag."
                      : "Add information of hashtag."}
                  </p>
                </BlockDes>
              </BlockHeadContent>
            </BlockHead>
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Spinner type="grow" color="dark" />
              </div>
            ) : success ? (
              <Block>
                <form
                  onSubmit={(e) => {
                    if (view?.add) {
                      handleAddHashtag(e);
                    } else {
                      handleEditHashtag(e);
                    }
                  }}
                >
                  <Row className="g-3">
                    {/* Job Type */}
                    <Col size="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="title">
                          Job Type
                        </label>
                        <div className="form-control-wrap">
                          <RSelect
                            name="jobType"
                            options={jobTypeOption}
                            onChange={onCategoryJobTypeChange}
                            defaultValue={
                              view?.edit
                                ? {
                                    label: jobTypeName ? jobTypeName : "",
                                    value: jobType ? jobType : "",
                                  }
                                : {
                                    label: "Select",
                                    value: "",
                                  }
                            }
                          />
                        </div>
                      </div>
                    </Col>

                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Row className="g-3">
                        {/* English Hashtag */}
                        <Col size="12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="product-title">
                              English Hashtag
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="nameEn"
                                value={nameEn}
                                onChange={onAddSkillChange}
                                placeholder="Enter hashtag in english"
                                required
                                ref={register({
                                  required: "This field is required",
                                })}
                              />
                              {errors.title && <span className="invalid">{errors.title.message}</span>}
                            </div>
                          </div>
                        </Col>

                        {/*Arabic Hashtag  */}
                        <Col size="12">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="product-title"
                              style={{
                                width: "100%",
                                textAlign: "start",
                              }}
                            >
                              Arabic Hashtag
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control"
                                name="nameAr"
                                value={nameAr}
                                onChange={onAddSkillChange}
                                dir="rtl"
                                placeholder="Enter hashtag in arabic"
                                required
                                ref={register({
                                  required: "This field is required",
                                })}
                              />
                            </div>
                          </div>
                        </Col>

                        {/* isActive */}
                        <Col size="12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="category">
                              isActive
                            </label>
                            <div className="form-control-wrap">
                              <RSelect
                                name="isActive"
                                options={isActiveSelectTag}
                                onChange={onCategoryChange}
                                defaultValue={
                                  view?.edit
                                    ? {
                                        label: isActive ? "Yes" : "No",
                                        value: isActive ? true : false,
                                      }
                                    : {
                                        label: "Select",
                                        value: "",
                                      }
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <Col size="12">
                      <div style={{ display: "flex", flexDirection: "row-reverse", gap: "15px" }}>
                        <div>
                          <Button
                            color="danger"
                            type="button"
                            onClick={(e) => {
                              onFormCancel();
                              setHashtagData({
                                ...hashtagData,
                                nameEn: "",
                                nameAr: "",
                                isActive: "",
                                jobType: "",
                                jobTypeName: "",
                              });
                            }}
                          >
                            <span>Cancel</span>
                          </Button>
                        </div>

                        <div>
                          <Button color="primary" type="submit">
                            <span>{view?.add ? "Add Hashtag" : view?.edit ? "Edit Hashtag" : "Add Hashtag"}</span>
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              </Block>
            ) : null}
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default HashtagList;
