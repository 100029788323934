import country_splitter from "country-code-splitter";
import { Country } from "country-state-city";
import { phone } from "phone";
import React, { useEffect, useRef, useState } from "react";
import { MdOutlinePlaylistAddCheck } from "react-icons/md";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
import CountdownTimer from "./CountdownTimer";
import { getCookie } from "../helpers/storage";
import { validateEmail } from "../helpers/validation"
import Msg from "../../../../assets/lang/en.json"
import { anotherRecOtpSend, anotherRecOtpVerify } from "../middleware/postAction";
import { getCountryCode } from "../middleware/getAction";
import { updateJobDetails } from "../middleware/putAction";
import styles from "../style/Home.module.scss";
import { setAuthorizationToken } from "../utils/apiServices";

import clsx from "clsx"
import { useNavigate } from "react-router-dom";

function Interview(props) {
  const {
    back,
    next,
    profile,
    jobDetails,
    getUpdatedJobData,
    arabicView
  } = props;
  const router = useNavigate();


  const [jobHeading, setJobHeading] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [status, setStatus] = useState("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [cCodeList, setCCodeList] = useState([]);

  const recNumRef = useRef(null);
  const recNameRef = useRef(null);
  const [mobVerify, setMobVerify] = useState(false);
  const [mobOtpShow, setMobOtpShow] = useState(false);
  const [mobOtpFoucs, setMobOtpFoucs] = useState(false);
  const [mobileOtp, setMobileOtp] = useState("");
  const [mobileOtpData, setMobileOtpData] = useState({});
  const [isnumVerified, setIsnumVerified] = useState(false);

  const [emailVerify, setEmailVerify] = useState(false);
  const [emailOtpShow, setEmailOtpShow] = useState(false);
  const [emailOtpFocus, setEmailOtpFocus] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const [emailOtpData, setEmailOtpData] = useState({});
  const [isemailVerified, setIsemailVerified] = useState(false);

  const [interviewer, setInterviewer] = useState("Myself");

  const [anotherRecruterName, setAnotherRecruterName] = useState("");
  const [anotherRecruterNumber, setAnotherRecruterNumber] = useState("+20");
  const [anotherRecruterEmail, setAnotherRecruterEmail] = useState("");




  const [enableChatSystem, setEnableChatSystem] = useState(false);

  const handleThirdStepTrigger = async (e) => {
    e.preventDefault();

    let data = {
      enableChat: enableChatSystem,
    };

    if (interviewer === "Myself") {
      data = {
        ...data,
        Interviewer: interviewer,
        RecruiterName: "",
        HrWhatsAppNumber: "",
        email: "",
      };
    } else if (interviewer === "Another recuiter") {
      data = {
        ...data,
        Interviewer: interviewer,
        RecruiterName: anotherRecruterName,
        HrWhatsAppNumber: anotherRecruterNumber,
        email: anotherRecruterEmail,

      };
    }

    if (interviewer === "") {
      toast.error("Oops. we can't go further before you provide the required")

    } else if (interviewer === "Another recuiter") {
      if (anotherRecruterName === "" || (!isnumVerified && !isemailVerified)) {
        toast.error("Oops. we can't go further before you provide the required")

      } else if (
        anotherRecruterName !== "" &&
        (isnumVerified || isemailVerified)
      ) {

        let response = await updateJobDetails(jobDetails?._id, data);
        if (response.success) {
          getUpdatedJobData();
          router("/post-a-job/" + next);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      } else {
        toast.warn("Please verify number and email");
      }
    } else {
      let response = await updateJobDetails(jobDetails?._id, data);
      if (response.success) {
        getUpdatedJobData();
        router("/post-a-job/" + next);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }

    }
  };

  const getCountryCodeList = () => {
    try {
      let res = Country.getAllCountries();
      setCCodeList(res);
    } catch (error) {

    }
  };



  const handleValidation = async (value) => {


    if (value?.includes("@")) {
      let checkValue = validateEmail(value);
      if (checkValue) {
        return { check: value, provider: "email" };
      } else {
        toast.error(Msg.enterValidEmail);
        return 0;
      }
    } else if (value?.includes("+")) {
      let checkValue = phone(value);
      let data = country_splitter?.getNumber(value);
      let cCode = data?.code;
      let number = data?.number;



      if (checkValue?.isValid) {

        if (data?.number?.length < 10 || data?.number?.length > 10) {

          toast.error(Msg.enterValidMobile)
          return 0;
        }

        return { check: number, countryCode: cCode, provider: "mobile" };
      } else {


        toast.error(Msg.invalidCountryCode)
        return 0;
      }
    } else {
      if (+value) {


        toast.error(Msg.invalidCountryCode);
        return 0;
      }
      toast.error(Msg.invalidFormat);
    }
  };

  const sendMobileOtp = async () => {
    const validData = await handleValidation(anotherRecruterNumber);

    if (validData) {
      let data = { check: validData.check, countryCode: validData.countryCode, provider: validData.provider };


      let res = await anotherRecOtpSend(data);
      if (res?.success) {
        setMobileOtpData({
          check: data?.check,
          countryCode: data?.countryCode,
          ...res,
        });
        setMobOtpShow(true);
        setMobOtpFoucs(true);
        setMobVerify(false);
        setMobileOtp("");
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    }
  };


  const handleVerifyNumber = async (e) => {
    e.preventDefault();

    if (mobileOtpData) {
      let data = {
        verificationKey: mobileOtpData?.data,
        check: mobileOtpData?.check,
        otp: mobileOtp,
        ProviderValue: mobileOtpData?.ProviderValue,
        countryCode: mobileOtpData?.countryCode
      };



      const res = await anotherRecOtpVerify(data);
      if (res.success) {
        toast.success(res.message);
        let response = await updateJobDetails(jobDetails?._id, {
          Interviewer: interviewer,
          HrWhatsAppNumber: data?.countryCode + data?.check,

        });
        if (res.success) {
          toast.success(response.message);
          getUpdatedJobData();
          setMobVerify(false);
          setMobOtpShow(false);
          setIsnumVerified(true);
        } else {
          toast.error(response.message);
        }
      } else {
        toast.error(res.message);
      }
    } else {
      toast.error("Resend Otp");
    }
  };

  const sendEmailOtp = async () => {
    const validData = await handleValidation(anotherRecruterEmail);

    if (validData) {
      let data = { check: validData.check, provider: validData.provider };
      let res = await anotherRecOtpSend(data);
      if (res?.success) {
        setEmailOtpData({
          check: data?.check,
          ...res,
        });
        toast.success(res.message);
        setEmailOtpShow(true);
        setEmailOtpFocus(true);
        setEmailVerify(false);
        setEmailOtp("");
      } else {
        toast.error(res.message);
      }
    }
  };

  const handleVerifyEmail = async (e) => {
    e.preventDefault();

    if (emailOtpData) {
      let data = {
        verificationKey: emailOtpData?.data,
        check: emailOtpData?.check,
        otp: emailOtp,
        ProviderValue: emailOtpData?.ProviderValue,
      };

      const res = await anotherRecOtpVerify(data);
      if (res.success) {
        toast.success(res.message);
        let response = await updateJobDetails(jobDetails?._id, {
          Interviewer: interviewer,
          email: data?.check,
        });
        if (res.success) {
          toast.success(response.message);
          getUpdatedJobData();
          setEmailVerify(false);
          setEmailOtpShow(false);
          setIsemailVerified(true);
        } else {
          toast.error(response.message);
        }
      } else {
        toast.error(res.message);
      }
    } else {
      toast.error("Resend Otp");
    }
  };

  useEffect(() => {
    setAuthorizationToken(getCookie("_jwt"));

    if (jobDetails) {

      if (arabicView === "ar") {
        setJobHeading(
          jobDetails?.jobCategory?.translations[1]?.jobCategoryTranslation?.name
        );

        setJobLocation(jobDetails?.city?.translations[1]?.cityTranslation?.name);

      } else {
        setJobHeading(
          jobDetails?.jobCategory?.translations[0]?.jobCategoryTranslation?.name
        );

        setJobLocation(jobDetails?.city?.translations[0]?.cityTranslation?.name);
      }


      setStatus(jobDetails?.status || status);

      setInterviewer(jobDetails?.Interviewer || interviewer);

      setAnotherRecruterNumber(
        jobDetails?.HrWhatsAppNumber || anotherRecruterNumber
      );


      if (jobDetails?.HrWhatsAppNumber) {
        setIsnumVerified(true);
      }


      setAnotherRecruterName(jobDetails?.RecruiterName || anotherRecruterName);

      setAnotherRecruterEmail(jobDetails?.email || anotherRecruterEmail);

      if (jobDetails?.email) {
        setIsemailVerified(true);
      }

      setEnableChatSystem(jobDetails?.enableChat || enableChatSystem);


    }


    if (interviewer === "Another recuiter" && countryCode) {
      recNumRef.current.focus();
    }

    getCountryCode().then((res) => {
      if (res.success) {
        setCountryCodeList(res.data);
      }
    });

    getCountryCodeList();

    return () => { };
  }, [countryCode, jobDetails]);


  return (
    <div
      className={
        "col-xl-9 col-lg-8 col-md-12 col-12 addpadd1 " + styles.candCol9
      }
    >
      <div className={styles.Content}>
        <div className={styles.candTitle}>
          {jobHeading ? <h3>{jobHeading}</h3> : null}

          {jobLocation ? <p>Location: {jobLocation}</p> : null}
        </div>
        {status === "draft" ? (
          <div className={styles.candSave} style={{ color: "#00C353" }}>
            <MdOutlinePlaylistAddCheck
              style={{ color: "#00C353", width: "25px", height: "25px" }}
            />
            &nbsp;


            SAVED AS DRAFT

          </div>
        ) : (
          <div
            className={styles.candSave}
            onClick={(e) => setStatus("draft")}
            style={{ cursor: "pointer" }}
          >
            <img
              src="/assets/images/icons/Product-Icons.svg"
              alt=""
              className="img-fluid"
              style={{ width: "auto", marginRight: "12px", marginTop: "-5px" }}
            />


            SAVE AS DRAFT

          </div>
        )}
      </div>
      <div className={styles.postMain}>
        <h3 className={styles.postTitle}>


          Interview Information

        </h3>
        <div
          className={"row " + styles.postForm}
          style={{ paddingTop: "10px" }}
        >
          <div className="col-xl-9 col-lg-9 col-md-9 col-12">
            <div className="row">
              <div>
                <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                  <div>
                    <h4 className={styles.candidateTitle}>


                      Interviewer Details *

                    </h4>
                    <p className={styles.candidateDesc}>


                      Who will connect with candidates and manage the
                      interviews?

                    </p>
                  </div>
                  <div className="d-flex">
                    <div>
                      <input
                        onChange={(e) => {
                          setInterviewer("Myself");
                        }}
                        value={interviewer === "Myself" ? true : false}
                        checked={interviewer === "Myself" ? true : false}
                        className=""
                        type="radio"
                        name="newsletter"
                      />
                      <label
                        style={{
                          lineHeight: "normal",
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "550",
                          padding: "0px 15px",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >


                        Myself

                      </label>
                    </div>
                    <div>
                      <input
                        onChange={(e) => {
                          setInterviewer("Another recuiter");
                        }}
                        value={
                          interviewer === "Another recuiter" ? true : false
                        }
                        checked={
                          interviewer === "Another recuiter" ? true : false
                        }
                        className=""
                        type="radio"
                        name="newsletter"
                      />
                      <label
                        style={{
                          lineHeight: "normal",
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "550",
                          padding: "0px 15px",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >


                        Another recruiter

                      </label>
                    </div>
                  </div>
                </div>

                {interviewer === "Another recuiter" ? (
                  <div className={clsx("row", arabicView === "ar" ? "basicDetails" : "")} style={{ paddingTop: "15px" }}>
                    <div className={"col-md-6 " + styles.Col1}>
                      <div
                        className={styles.postBlock}
                        style={{ margin: "10px 0px" }}
                      >
                        <input
                          value={anotherRecruterName}
                          ref={recNameRef}
                          onChange={(e) =>
                            setAnotherRecruterName(e.target.value)
                          }
                          type="text"
                          name="username"
                          id="username"
                          required
                          spellCheck="false"
                        />
                        <span className={styles.placeholder}>


                          Recruiter's Name *

                        </span>
                      </div>
                    </div>
                    <div className={"col-md-6 " + styles.Col2}></div>
                    <div
                      className={clsx("col-md-6 " + styles.Col1, arabicView === "ar" ? "englishView" : "")}

                    >

                      <div className={styles.postBlock}>


                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="EG"
                          placeholder={`Mobile Number *`}
                          className={clsx("mob-input mob-input2 d-flex", arabicView === "ar" ? "arabicLtr" : "")}
                          value={anotherRecruterNumber}
                          onChange={(e) => {
                            setAnotherRecruterNumber(e);
                            if (
                              e &&
                              e.length >= 8 &&
                              !e.includes(profile?.mobile)
                            ) {

                              setMobVerify(true);
                              setMobOtpShow(false);
                            } else {
                              setMobVerify(false);
                            }
                          }}
                          name="phone"
                          id="phone"

                          spellCheck="false"
                        />
                      </div>
                      <div className={`col-md-12 ${styles.RecOtpVerify}`}>
                        {mobVerify ? (
                          <div className={`${styles.RecVerifyBtn}`}>
                            <button
                              onClick={sendMobileOtp}
                              className={styles.btn}
                              type="submit"
                            >


                              Send OTP

                            </button>
                          </div>
                        ) : null}
                        {mobOtpShow ? (
                          <>
                            <form
                              onSubmit={handleVerifyNumber}
                              className={`${styles.RecVerifyInput}`}
                            >
                              <OtpInput
                                value={mobileOtp}
                                onChange={(e) => setMobileOtp(e)}
                                numInputs={4}
                                name="mobileOtp"
                                separator={<span>&nbsp;&nbsp;</span>}
                                inputStyle="inputStyle1 input-style1"
                                containerStyle="containerStyle1"
                                shouldAutoFocus={mobOtpFoucs ? true : false}
                              />
                              <div className={`${styles.RecVerifyBtn}`}>
                                <button

                                  className={styles.btn}
                                  type="submit"
                                >


                                  Verify

                                </button>
                                <CountdownTimer
                                  minSecs={{ minutes: 0, seconds: 180 }}
                                  handleSubmit={sendMobileOtp}
                                  label={"Resend OTP"}
                                />
                              </div>
                            </form>
                          </>
                        ) : null}
                      </div>
                    </div>

                    {/* Another Email */}
                    <div className={"col-md-6 " + styles.Col2}>
                      <div
                        className={styles.postBlock}
                        style={{ margin: "15px 0px" }}
                      >
                        <input
                          value={anotherRecruterEmail}
                          onChange={(e) => {
                            let value = e.target.value;
                            setAnotherRecruterEmail(value);
                            if (value) {
                              setEmailVerify(true);
                            } else {
                              setEmailVerify(false);
                            }
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              sendEmailOtp();
                            }
                          }}
                          type="email"
                          name="email"
                          id="email"
                          required
                          spellCheck="false"
                        />
                        <span className={styles.placeholder}>


                          Contact Email *

                        </span>
                      </div>
                      <div className={`col-md-12 ${styles.RecOtpVerify}`}>
                        {emailVerify ? (
                          <div className={`${styles.RecVerifyBtn}`}>
                            <button
                              onClick={sendEmailOtp}
                              className={styles.btn}
                              type="submit"
                            >


                              Send OTP

                            </button>
                          </div>
                        ) : null}

                        {emailOtpShow ? (
                          <>
                            <div className={`${styles.RecVerifyInput}`}>
                              <OtpInput
                                value={emailOtp}
                                onChange={(e) => setEmailOtp(e)}
                                numInputs={4}
                                name="otp"
                                separator={<span>&nbsp;&nbsp;</span>}
                                inputStyle="inputStyle1 input-style1"
                                containerStyle="containerStyle1 englishView"
                                shouldAutoFocus={emailOtpFocus ? true : false}
                              />
                            </div>
                            <div className={`${styles.RecVerifyBtn}`}>
                              <button
                                onClick={handleVerifyEmail}
                                className={styles.btn}
                                type="submit"
                              >


                                Verify

                              </button>
                              <CountdownTimer
                                minSecs={{ minutes: 0, seconds: 180 }}
                                handleSubmit={sendEmailOtp}
                                label={"Resend OTP"}
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <span
                      className={styles.textareaLine}
                      style={{ margin: "10px 0 0 0" }}
                    >


                      NOTE: Your contact details are safe. We are not sharing
                      them with the candidates. All communications go through
                      Skatch built-in channels.

                    </span>
                  </div>
                ) : null}

                <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                  <div>
                    <h4 className={styles.candidateTitle}>


                      Enable in-app chat with candidates?

                    </h4>
                  </div>
                  <div className="d-flex">
                    <div>
                      <input
                        value={enableChatSystem ? true : false}
                        checked={enableChatSystem ? true : false}
                        onChange={(e) => setEnableChatSystem(true)}
                        className=""
                        type="radio"
                        name="newsletter1"
                      />
                      <label
                        style={{
                          lineHeight: "normal",
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "550",
                          padding: "0px 15px",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >


                        Yes

                      </label>
                    </div>
                    <div>
                      <input
                        value={!enableChatSystem ? true : false}
                        checked={!enableChatSystem ? true : false}
                        onChange={(e) => setEnableChatSystem(false)}
                        className=""
                        type="radio"
                        name="newsletter1"
                      />
                      <label
                        style={{
                          lineHeight: "normal",
                          textAlign: "left",
                          fontSize: "16px",
                          fontWeight: "550",
                          padding: "0px 15px",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >


                        No

                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles.Buttons}>
                  <div className={styles.candBtn}>
                    <button
                      onClick={(e) => {
                        router("/post-a-job/" + back);
                      }}
                      className={styles.backBtn}
                      type="submit"
                    >


                      BACK

                    </button>
                  </div>
                  <div className={styles.candBtn}>
                    <button
                      onClick={(e) => {
                        handleThirdStepTrigger(e);
                      }}
                      className={styles.btn}
                      type="submit"
                    >


                      CONTINUE

                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Interview;