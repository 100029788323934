import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
   country: [],
   countryId:"",
   total: 0,
 
};

export const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    isCountryLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.country = [];
    },
    countryReducer: (state, action) => {
      state.loading = false;
      state.country = action.payload.data;
      state.total = action.payload.total;
      state.success = true;
      state.error = false;
    },
    countryById: (state, action) => {
      state.loading = false;
      state.countryId = action.payload;
      // state.total = action.payload.total;
      state.success = true;
      state.error = false;
    },
    addCountryReducer: (state, action) => {
      state.loading = false;
    
      state.success = true;
      state.error = false;
    },
    removeCountry: (state) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isCountrySuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isCountryError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.contry = [];
    },
  },
});

export default countrySlice.reducer;

export const { isCountryLoading, countryReducer, countryById, addCountryReducer, removeCountry, isCountrySuccess, isCountryError } =
  countrySlice.actions;
