import { toast } from "react-toastify";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";
import { getCookie } from "../../utils/storage";
import { addSkills, isSkillsError, isSkillsLoading, isSkillsSuccess, skillById, skills } from "../Apislices/skillSlice";

export const getAllSkills =
  (type = "", page, limit) =>
    async (dispatch) => {
      try {
        dispatch(isSkillsLoading());
        setAuthorizationToken(getCookie("_jwt"));
        const response = await getMethod(`/admin/skills/list?type=${type}&page=${page}&limit=${limit}`);
        if (response?.success) {
          dispatch(isSkillsSuccess());
          dispatch(
            skills({
              data: response?.data,
              total: response?.total,
            })
          );
        } else {
          dispatch(isSkillsError(response?.message));
        }
      } catch (error) {
        dispatch(isSkillsError(error?.message));
      }
    };

export const getSkillById = (id) => async (dispatch) => {
  try {
    dispatch(isSkillsLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/skill/${id}`);
    if (response?.success) {
      dispatch(isSkillsSuccess());
      dispatch(skillById(response?.data));
    } else {
      dispatch(isSkillsError(response?.message));
    }
  } catch (error) {
    dispatch(isSkillsError(error?.message));
  }
};

export const addSkillsToList = (data) => async (dispatch) => {
  try {
    let response = await postMethod(`/admin/addSkills`, data);
    if (response?.success) {
      dispatch(isSkillsSuccess());
    } else {
      dispatch(isSkillsError(response?.message));
    }
  } catch (error) {
    dispatch(isSkillsError(error?.message));
  }
};

export const deleteSkillsFromList = (id) => async (dispatch) => {
  try {
    let response = await deleteMethod(`/admin/remove/${id}/skill`);
    if (response?.success) {
      dispatch(isSkillsSuccess());
      toast.success(response?.message);
    } else {
      dispatch(isSkillsError(response?.message));
    }
  } catch (error) {
    dispatch(isSkillsError(error?.message));
  }
};

export const editSkillsFromList = (id, data) => async (dispatch) => {
  try {
    let response = await putMethod(`/admin/skill/${id}/edit`, data);
    if (response?.success) {
      dispatch(isSkillsSuccess());
      toast.success(response?.message);
    } else {
      dispatch(isSkillsError(response?.message));
    }
  } catch (error) {
    dispatch(isSkillsError(error?.message));
  }
};
