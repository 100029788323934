import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { MdOutlinePlaylistAddCheck } from "react-icons/md";
import { RiErrorWarningFill } from "react-icons/ri";
import JobPreviewSVG from "./JobPreviewSVG";
import styles from "../style/Home.module.scss"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateJobDetails, updateJobStatus } from "../middleware/putAction";
import { setCityId, setDurDays, setDurSpan, setJobHeading, setJobLocation, setStateId, setTimeFrom, setTimeTo } from "../../../../redux/Apislices/postJobSlice";
import { getCookie, getLocal, removeSession } from "../helpers/storage";
import JobPreviewComponent from "./JobPreviewComponent";
import { setAuthorizationToken } from "../utils/apiServices";

function JobPreview(props) {
  const {
    back,
    jobDetails,
  } = props;
  const router = useNavigate();
  const dispatch = useDispatch()

  console.log("job details ==> ", jobDetails)

  const { jobHeading, jobLocation } = useSelector((state) => ({
    jobHeading: state?.postJobSlice?.candidateState?.jobHeading,
    jobLocation: state?.postJobSlice?.candidateState?.jobLocation,
  }))


  const [status, setStatus] = useState("live");
  const [basicFormData, setBasicFormData] = useState({});


  const [trigger, setTrigger] = useState(false);

  const [isTerms, setIsTerms] = useState(false);




  const handleFinalStep = async (e) => {
    e.preventDefault();

    let postData = basicFormData;

    if (isTerms === false) {
      toast.error("Oops. we can't go further before you provide the required information")

    } else {
      postData = Object.assign(postData, {
        termsAndConditions: isTerms,
      });


      postData = Object.assign(postData, {
        status: jobDetails?.status,
      });

      if (postData) {
        await updateJobDetails(jobDetails?._id, postData).then((response) => {
          if (response.success) {
            toast.success(response.message);
            dispatch(setTimeFrom(""))
            dispatch(setTimeTo(""))
            dispatch(setStateId(""))
            dispatch(setCityId(""))
            dispatch(setDurDays(""))
            dispatch(setDurSpan(""))
            removeSession("jobData")

            router("/jobs")
          } else {
            toast.error(response.message);
          }
        });
      }
    }
  };



  useEffect(() => {
    setAuthorizationToken(getCookie("_jwt"));
    if (jobDetails) {
      dispatch(setJobHeading(
        jobDetails?.jobCategory?.translations[0]?.jobCategoryTranslation?.name
      ));

      dispatch(setJobLocation(jobDetails?.city?.translations[0]?.cityTranslation?.name));

      setStatus(jobDetails?.status || status);

      setIsTerms(jobDetails?.termsAndConditions || isTerms);


    }



    return () => { };
  }, [jobDetails]);

  return (
    <>
      <div
        className={
          "col-xl-9 col-lg-8 col-md-12 col-12 addpadd1 " + styles.candCol9
        }
      >
        <div className={styles.Content}>
          <div className={styles.candTitle}>
            {jobHeading ? <h3>{jobHeading}</h3> : null}

            {jobLocation ? <p>Location: {jobLocation}</p> : null}
          </div>
          {status === "draft" ? (
            <div className={styles.candSave} style={{ color: "#00C353" }}>
              <MdOutlinePlaylistAddCheck
                style={{ color: "#00C353", width: "25px", height: "25px" }}
              />
              &nbsp;SAVED AS DRAFT
            </div>
          ) : (
            <div
              className={styles.candSave}
              onClick={(e) => setStatus("draft")}
              style={{ cursor: "pointer" }}
            >
              <img
                src="/assets/images/icons/Product-Icons.svg"
                alt=""
                className="img-fluid"
                style={{
                  width: "auto",
                  marginRight: "12px",
                  marginTop: "-5px",
                }}
              />
              SAVE AS DRAFT
            </div>
          )}
        </div>
        <div className={styles.postMain} style={{ paddingBottom: "50px" }}>
          <h3 className={styles.postTitle}>Job Preview</h3>
          <div className="row" style={{ paddingTop: "30px" }}>
            <div
              className="col-xl-4 col-lg-5 col-md-4 col-12 mobileBorderNone"
              style={{ borderRight: "1px solid #989898" }}
            >
              {jobDetails ? (
                <JobPreviewComponent details={jobDetails} />
              ) : (
                <JobPreviewSVG />
              )}
            </div>
            <div className="col-xl-8 col-lg-7 col-md-8 col-12">
              <div style={{ paddingLeft: "20px" }}>
                <h4 className={styles.candidateTitle}>IMPORTANT</h4>
                <ul className={styles.jobList}>
                  <li>
                    <img
                      src="/assets/images/icons/circle-tick.svg"
                      alt=""
                      className="img-fluid"
                      style={{
                        width: "auto",
                        marginRight: "12px",
                        marginTop: "-5px",
                      }}
                    />
                    Applications are subject to jobs attractiveness and
                    competition
                  </li>
                  <li>
                    <img
                      src="/assets/images/icons/circle-tick.svg"
                      alt=""
                      className="img-fluid"
                      style={{
                        width: "auto",
                        marginRight: "12px",
                        marginTop: "-5px",
                      }}
                    />
                    Renew job once target applications are reached, to get more
                    applications
                  </li>
                  <li>
                    <img
                      src="/assets/images/icons/circle-tick.svg"
                      alt=""
                      className="img-fluid"
                      style={{
                        width: "auto",
                        marginRight: "12px",
                        marginTop: "-5px",
                      }}
                    />
                    Quick response & correct job information will give much
                    better results
                  </li>
                  <li>
                    <img
                      src="/assets/images/icons/circle-tick.svg"
                      alt=""
                      className="img-fluid"
                      style={{
                        width: "auto",
                        marginRight: "12px",
                        marginTop: "-5px",
                      }}
                    />
                    De-activate your job once you have filled your position
                  </li>
                  <li>
                    <img
                      src="/assets/images/icons/circle-tick.svg"
                      alt=""
                      className="img-fluid"
                      style={{
                        width: "auto",
                        marginRight: "12px",
                        marginTop: "-5px",
                      }}
                    />
                    Any unethical practices will be immediately reported to the
                    authorities with your KYC details
                  </li>
                </ul>
                <div>
                  <div
                    className="formCheck"
                    style={{
                      padding: "10px 0px",
                      paddingBottom: "0px",
                      display: "flex",
                      justifyContent: "start",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <input
                      checked={isTerms}
                      onChange={(e) => setIsTerms(e.target.checked)}
                      className="checkboxInput1"
                      type="checkbox"
                      name="newsletter"
                      style={{
                        padding: "15px",
                        width: "20px",
                        height: "20px",
                        marginTop: "0px",
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        textAlign: "left",
                        fontSize: "14px",
                        fontWeight: "550",
                        padding: "0px 15px",
                        color: "#000000",
                        marginBottom: "0px",
                      }}
                    >
                      I agree to the Skatch's Terms of Services and Code of
                      Conduct *
                    </label>
                  </div>
                  <br />
                  <div
                    className={styles.Buttons}
                    style={{
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      className={styles.candBtn}
                      style={{
                        paddingTop: "10px",
                        margin: "0px",
                        width: "100%",
                      }}
                    >
                      <button
                        onClick={(e) => {
                          router("/post-a-job/" + back);
                        }}
                        className={styles.backBtn}
                        style={{ width: "100%" }}
                        type="submit"
                      >
                        BACK
                      </button>
                    </div>
                    <div
                      className={styles.candBtn}
                      style={{
                        paddingTop: "10px",
                        margin: "0px",
                        width: "100%",
                      }}
                    >
                      <button
                        onClick={(e) => {
                          handleFinalStep(e);
                        }}
                        className={styles.btn}
                        type="submit"
                        style={{ width: "100%" }}
                      >
                        CONTINUE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobPreview;