

import { createSlice } from "@reduxjs/toolkit";
// import toast from "react-hot-toast";
import { getCountryList, getJobDetailsfetch, getProfile } from "../../pages/jobs/PostJob/middleware/getAction";
import { addJob } from "../../pages/jobs/PostJob/middleware/postAction";
import { updateJobDetails } from "../../pages/jobs/PostJob/middleware/putAction";




const postJobObj = {
    profileData: false,
    countryList: [],
    jobUpdateLoading: false,
    jobUpdateSuccess: false,
    jobUpdateError: false,
    isError: false,
    path: "basic-details",

    postJobPaths: [
        "basic-details",
        "candidates-requirements",
        "interview-information",
        "job-preview",
    ],
    editJobsIdActive: false,
    jobDetails: false,
    postLoading: false,


};

const postJobRoutingObj = {
    error: false,
    updateTrigger: false
}

const basicDetailsObj = {
    basicPageOne: {
        companyName: "",
        companyId: "",
        jobType: "",
        jobRole: "",
        jobTitle: "",
        jobCountry: "6391653eb1f4c4be8c1b37f7",
        jobState: "",
        jobCity: "",
        isAllowWFH: "",
        isNightShift: "",
        workingDays: [],
        workTimings: {},
        duration: "",
        experinced: "",
        experince: "",
        gender: "",
        salary: "",
        noOfOpening: "",
        isIncentives: "",
    },
    timeFrom: "",
    timeTo: "",
    cityId: "",
    stateId: "",
    durDays: "",
    durSpan: "Months",
    // cityLatitude: "-33.918861",
    // cityLongitude: "18.4233",
    cityLatitude: "",
    cityLongitude: "",
    jobStateList: [],
    jobCityList: [],
    jobTypeList: [],
    getJobRole: [],
    openStateOption: false,
    openCityOption: false,
    timeSlotList: [],
    salaryList: [],
    experienceList: [],
    jobTypeLoading: false,
    toTimeSlotList: [],
}

const candidateObj = {
    show: false,
    searchMoreSkillsCheckbox: false,
    isCheckboxDisabledValue: false,
    descriptionTemplate: [],
    jobHeading: "",
    jobLocation: "",
    educationList: [],
    skillList: [],
    requiredQuallification: [],
    benefitList: [],
    skillsSearch: "",
    jobDescription: "",
    minEducation: false,
    mandatorySkills: [],
    mandId: [],
    jobRequirements: [],
    otherRequredId: {
        id: "638de7478bf8c089c9bf0029",
        status: false
    },
    sumReqQualification: 0,
    otherRequirmentInp: "",
    otherRequirment: [],
    benfits: [],
    otherBenefitsInput: "",
    otherBenefits: [],
    isVideoQuestions: false,
    questionsList: [""],
    status: "draft",
    openSkillOption: false,
    trigger: false,


}

const jobPreviewObj = {
    currentLatitude: "-33.918861",
    currentLongitude: "18.4233"
}

const postJobSlice = createSlice({
    name: "postJobSlice",
    initialState: {
        postJobState: postJobObj,
        postJobRoutingState: postJobRoutingObj,
        basicDetailsState: basicDetailsObj,
        candidateState: candidateObj,
        jobPreviewState: jobPreviewObj
    },
    reducers: {

        isJobUpdateLoading: (state) => {
            state.postJobState.jobUpdateLoading = true;
            state.postJobState.jobUpdateSuccess = false;
            state.postJobState.jobUpdateError = false;
        },
        isJobUpdateSuccess: (state, action) => {
            state.postJobState.jobUpdateLoading = false;
            state.postJobState.jobUpdateSuccess = true;
            state.postJobState.jobUpdateError = false;
        },

        isJobUpdateError: (state) => {
            state.postJobState.jobUpdateLoading = false;
            state.postJobState.jobUpdateSuccess = false;
            state.postJobState.jobUpdateError = true;
        },

        setIsError: (state, action) => {
            state.postJobState.isError = action.payload;

        }, setPath: (state, action) => {
            state.postJobState.path = action.payload;

        }, setProfileData: (state, action) => {
            state.postJobState.profileData = action.payload;

        }, setCountryListData: (state, action) => {
            state.postJobState.countryList = action.payload;

        }, setEditJobsActiveId: (state, action) => {
            state.postJobState.editJobsIdActive = action.payload;

        }, setJobDetails: (state, action) => {
            state.postJobState.jobDetails = action.payload;

        }, setPostLoading: (state, action) => {
            state.postJobState.postLoading = action.payload;

        },
        setError: (state, action) => {
            state.postJobRoutingState.error = action.payload;

        },
        setBasicPageOne: (state, action) => {
            state.basicDetailsState.basicPageOne = action.payload;

        },
        setTimeFrom: (state, action) => {
            state.basicDetailsState.timeFrom = action.payload;

        },
        setTimeTo: (state, action) => {
            state.basicDetailsState.timeTo = action.payload;

        }, setCityId: (state, action) => {
            state.basicDetailsState.cityId = action.payload;

        }, setStateId: (state, action) => {
            state.basicDetailsState.stateId = action.payload;

        }, setDurDays: (state, action) => {
            state.basicDetailsState.durDays = action.payload;

        }, setDurSpan: (state, action) => {
            state.basicDetailsState.durSpan = action.payload;

        }, setCityLatitude: (state, action) => {
            let cairoData = {
                cityLatitude: 30.0444196,
                cityLongitude: 31.2357116
            }
            if (action?.payload?.length) {
                let cityLatitude = action?.payload[0]?.latitude
                state.basicDetailsState.cityLatitude = cityLatitude;

            } else {
                state.basicDetailsState.cityLatitude = cairoData?.cityLatitude;
            }


        }, setCityLongitude: (state, action) => {

            let cairoData = {
                cityLatitude: 30.0444196,
                cityLongitude: 31.2357116
            }

            if (action?.payload?.length) {
                let cityLongitude = action?.payload[0]?.longitude
                state.basicDetailsState.cityLongitude = cityLongitude;

            } else {
                state.basicDetailsState.cityLongitude = cairoData?.cityLongitude;
            }



        }, setJobStateList: (state, action) => {
            state.basicDetailsState.jobStateList = action.payload;

        },
        setToTimeSlotList: (state, action) => {
            state.basicDetailsState.toTimeSlotList = action.payload;

        }

        , setShow: (state, action) => {
            state.candidateState.show = action.payload;

        }, setSearchMoreSkillsCheckbox: (state, action) => {
            state.candidateState.searchMoreSkillsCheckbox = action.payload;

        }, setIsCheckboxDisabledValue: (state, action) => {
            state.candidateState.isCheckboxDisabledValue = action.payload;

        }, setDescriptionTemplate: (state, action) => {
            state.candidateState.descriptionTemplate = action.payload;

        }, setJobHeading: (state, action) => {
            state.candidateState.jobHeading = action.payload;

        }, setJobLocation: (state, action) => {
            state.candidateState.jobLocation = action.payload;

        }, setEducationList: (state, action) => {
            state.candidateState.educationList = action.payload;

        }, setSkillList: (state, action) => {
            state.candidateState.skillList = action.payload;

        }, setRequiredQualification: (state, action) => {
            state.candidateState.requiredQuallification = action.payload;

        }, setBenifitList: (state, action) => {
            state.candidateState.benefitList = action.payload;

        }, setSkillsSearch: (state, action) => {
            state.candidateState.skillsSearch = action.payload;

        }, setJobDescription: (state, action) => {
            state.candidateState.jobDescription = action.payload;

        }, setMinEducation: (state, action) => {
            state.candidateState.minEducation = action.payload;

        }, setMandatorySkills: (state, action) => {
            state.candidateState.mandatorySkills = action.payload;

        }, setMandId: (state, action) => {
            state.candidateState.mandId = action.payload;

        }, setJobRequirements: (state, action) => {
            state.candidateState.jobRequirements = action.payload;

        }, setOtherRequredId: (state, action) => {
            state.candidateState.otherRequredId = action.payload;

        }, setSumReqQualification: (state, action) => {
            state.candidateState.sumReqQualification = action.payload;

        }, setOtherRequirmentInp: (state, action) => {
            state.candidateState.otherRequirmentInp = action.payload;

        }, setOtherRequirment: (state, action) => {
            state.candidateState.otherRequirment = action.payload;

        }, setBenifits: (state, action) => {
            state.candidateState.benfits = action.payload;

        }, setOtherBenefitsInput: (state, action) => {
            state.candidateState.otherBenefitsInput = action.payload;

        }, setOtherBenefits: (state, action) => {
            state.candidateState.otherBenefits = action.payload;

        }, setVideoQuestions: (state, action) => {
            state.candidateState.isVideoQuestions = action.payload;

        }, setQuestionsList: (state, action) => {
            state.candidateState.questionsList = action.payload;

        }, setStatus: (state, action) => {
            state.candidateState.status = action.payload;

        }, setOpenSkillOption: (state, action) => {
            state.candidateState.openSkillOption = action.payload;

        },




    },



});

export const {
    setToTimeSlotList,
    setCountryListData,
    setShow, setSearchMoreSkillsCheckbox, setIsCheckboxDisabledValue, setDescriptionTemplate,
    setJobHeading, setJobLocation, setEducationList, setSkillList, setRequiredQualification,
    setBenifitList, setSkillsSearch, setJobDescription, setMinEducation, setMandatorySkills,
    setMandId, setJobRequirements, setOtherRequredId, setSumReqQualification, setOtherRequirmentInp,
    setOtherRequirment, setBenifits, setOtherBenefitsInput, setOtherBenefits, setVideoQuestions,
    setQuestionsList, setStatus, setOpenSkillOption,
    setBasicPageOne, setTimeFrom, setTimeTo, setCityId, setStateId, setDurDays, setDurSpan, setCityLatitude, setCityLongitude, setJobStateList, setJobCityList, setJobTypeList, setGetJobRole, setOpenStateOption, setOpenCityOption, setTimeSlotList, setSalaryListRange, setExperinceList, setJobTypeLoading,
    setError, isJobUpdateError, isJobUpdateSuccess, isJobUpdateLoading, setIsError, setPath, setProfileData, setEditJobsActiveId, setJobDetails, setPostLoading
} = postJobSlice.actions;
export default postJobSlice.reducer;

export const asyncGetUpdatedJobData = (jobId) => async (dispatch) => {

    if (jobId) {
        dispatch(setPostLoading(true));
        dispatch(isJobUpdateLoading());

        let res = await dispatch(getJobDetailsfetch(jobId))

        if (res?.success) {
            dispatch(setJobDetails(res?.jobDetails));
            dispatch(setPostLoading(false));
            dispatch(isJobUpdateSuccess());

        } else {
            dispatch(isJobUpdateError());

            // toast.error(res.message);
            dispatch(setPostLoading());
        }
        return res

    }





};

export const asyncGetJobDetailsfetch = (jobId) => async (dispatch) => {


    if (jobId) {
        let res = await getJobDetailsfetch(jobId)


        return res

    }

}




export const asyncAddJob = (data) => async (dispatch) => {



    let res = await addJob(data)


    return res

}



export const asyncUpdateJobDetails = (jobDetailsId, data) => async (dispatch) => {



    let res = await updateJobDetails(jobDetailsId, data)


    return res

}







export const asyncGetHrProfilePostJob = () => async (dispatch) => {

    let response = await getProfile();
    if (response?.success) {
        dispatch(setProfileData(response.data));
    }
    return response

};



export const asyncGetCountryList = () => async (dispatch) => {

    let response = await getCountryList();
    if (response?.success) {
        dispatch(setCountryListData(response.data));
    }
    return response

};
