import Parser from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  addQuestionsToSkill,
  deleteQuestion,
  editQuestion,
  getQuestionById,
  showQuestions,
} from "../../redux/Action/skillQuestionAction";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  RSelect,
  Row,
} from "../Component";

import RichTextEditor from "./TextEditor";

const languageOptions = [
  {
    value: "english",
    label: "English",
  },
  {
    value: "arabic",
    label: "لقب",
  },
];

const SkillQuestions = ({ id }) => {
  const dispatch = useDispatch();
  const { loading, success, error, questions, question, total } = useSelector((state) => state?.skillQuestions);

  const [view, setView] = useState({
    addQuestions: false,
    editQuestion: false,
  });

  const [questionLanguage, setQuestionLanguage] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionEn, setQuestionEn] = useState("");
  const [questionAr, setQuestionAr] = useState("");

  const [questionId, setQuestionId] = useState("");

  const [option1En, setOption1En] = useState("");
  const [option2En, setOption2En] = useState("");
  const [option3En, setOption3En] = useState("");
  const [option4En, setOption4En] = useState("");
  const [option1Ar, setOption1Ar] = useState("");
  const [option2Ar, setOption2Ar] = useState("");
  const [option3Ar, setOption3Ar] = useState("");
  const [option4Ar, setOption4Ar] = useState("");
  const [isAnswer1, setIsAnswer1] = useState(false);
  const [isAnswer2, setIsAnswer2] = useState(false);
  const [isAnswer3, setIsAnswer3] = useState(false);
  const [isAnswer4, setIsAnswer4] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(showQuestions(id));
    }

    return () => {};
  }, [id, dispatch]);

  useEffect(() => {
    if (question) {
      setQuestionEn(question?.translations[0]?.questionTranslation?.title || questionEn);
      setQuestionAr(question?.translations[1]?.questionTranslation?.title || questionAr);
      setOption1En(question?.translations[0]?.questionTranslation?.options[0]?.label || option1En);
      setOption2En(question?.translations[0]?.questionTranslation?.options[1]?.label || option2En);
      setOption3En(question?.translations[0]?.questionTranslation?.options[2]?.label || option3En);
      setOption4En(question?.translations[0]?.questionTranslation?.options[3]?.label || option4En);
      setOption1Ar(question?.translations[1]?.questionTranslation?.options[0]?.label || option1Ar);
      setOption2Ar(question?.translations[1]?.questionTranslation?.options[1]?.label || option2Ar);
      setOption3Ar(question?.translations[1]?.questionTranslation?.options[2]?.label || option3Ar);
      setOption4Ar(question?.translations[1]?.questionTranslation?.options[3]?.label || option4Ar);
      setIsAnswer1(question?.translations[0]?.questionTranslation?.options[0]?.isAnswer || isAnswer1);
      setIsAnswer2(question?.translations[0]?.questionTranslation?.options[1]?.isAnswer || isAnswer2);
      setIsAnswer3(question?.translations[0]?.questionTranslation?.options[2]?.isAnswer || isAnswer3);
      setIsAnswer4(question?.translations[0]?.questionTranslation?.options[3]?.isAnswer || isAnswer4);
    }

    return () => {};
  }, [question]);

  const handleQuestionAdd = async (e) => {
    e.preventDefault();

    let count = [isAnswer1, isAnswer2, isAnswer3, isAnswer4]?.filter((li) => li);

    let data = {
      questionList: [
        {
          options: [
            {
              label: option1En,
              isAnswer: isAnswer1,
            },
            {
              label: option2En,
              isAnswer: isAnswer2,
            },
            {
              label: option3En,
              isAnswer: isAnswer3,
            },
            {
              label: option4En,
              isAnswer: isAnswer4,
            },
          ],
          language: "62e7f3b038629a3473e07310",
          title: questionEn,
        },
        {
          options: [
            {
              label: option1Ar,
              isAnswer: isAnswer1,
            },
            {
              label: option2Ar,
              isAnswer: isAnswer2,
            },
            {
              label: option3Ar,
              isAnswer: isAnswer3,
            },
            {
              label: option4Ar,
              isAnswer: isAnswer4,
            },
          ],
          language: "62e7f4bd38629a3473e07313",
          title: questionAr,
        },
      ],
      skill: id,
      questionType: questionType === "single" ? "singleChoice" : "multipleChoice",
      active: true,
    };

    if (
      questionEn &&
      questionAr &&
      option1En &&
      option2En &&
      option3En &&
      option4En &&
      option1Ar &&
      option2Ar &&
      option3Ar &&
      option4Ar
    ) {
      if (count?.length === 0) {
        toast.error("Atleast select 1 option");
      } else if (count?.length > 1 && questionType === "single") {
        toast.error("Please select question type multiple choice or select one option", { autoClose: 5000 });
      } else {
        await dispatch(addQuestionsToSkill(data));
        setQuestionEn("");
        setQuestionAr("");
        setOption1En("");
        setOption2En("");
        setOption3En("");
        setOption4En("");
        setOption1Ar("");
        setOption2Ar("");
        setOption3Ar("");
        setOption4Ar("");
        setIsAnswer1(false);
        setIsAnswer2(false);
        setIsAnswer3(false);
        setIsAnswer4(false);
        onFormCancel();
        await dispatch(showQuestions(id));
      }
    } else {
      toast.error("Please fill all fields in both languages");
    }
  };

  const handleQuestionEdit = async (e) => {
    e.preventDefault();

    let count = [isAnswer1, isAnswer2, isAnswer3, isAnswer4]?.filter((li) => li);

    let data = {
      englishId: question?.translations[0]?.questionTranslation?._id,
      englishTitle: questionEn,
      englishOptions: [
        {
          label: option1En,
          isAnswer: isAnswer1,
        },
        {
          label: option2En,
          isAnswer: isAnswer2,
        },
        {
          label: option3En,
          isAnswer: isAnswer3,
        },
        {
          label: option4En,
          isAnswer: isAnswer4,
        },
      ],
      arabicId: question?.translations[1]?.questionTranslation?._id,
      arabicTitle: questionAr,
      arabicOptions: [
        {
          label: option1Ar,
          isAnswer: isAnswer1,
        },
        {
          label: option2Ar,
          isAnswer: isAnswer2,
        },
        {
          label: option3Ar,
          isAnswer: isAnswer3,
        },
        {
          label: option4Ar,
          isAnswer: isAnswer4,
        },
      ],
      questionType: questionType === "single" ? "singleChoice" : "multipleChoice",
    };

    if (
      questionEn &&
      questionAr &&
      option1En &&
      option2En &&
      option3En &&
      option4En &&
      option1Ar &&
      option2Ar &&
      option3Ar &&
      option4Ar
    ) {
      if (count?.length === 0) {
        toast.error("Atleast select 1 option");
      } else if (count?.length > 1 && questionType === "single") {
        toast.error("Please select question type multiple choice or select one option", { autoClose: 5000 });
      } else {
        await dispatch(editQuestion(questionId, data));
        setQuestionEn("");
        setQuestionAr("");
        setOption1En("");
        setOption2En("");
        setOption3En("");
        setOption4En("");
        setOption1Ar("");
        setOption2Ar("");
        setOption3Ar("");
        setOption4Ar("");
        setIsAnswer1(false);
        setIsAnswer2(false);
        setIsAnswer3(false);
        setIsAnswer4(false);
        onFormCancel();
        await dispatch(showQuestions(id));
      }
    } else {
      toast.error("Please fill all fields in both languages");
    }
  };

  const handleDelete = async (id) => {
    await dispatch(deleteQuestion(id));
    await dispatch(showQuestions(id));
  };

  // Delete Alert
  const deleteAlert = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
        Swal.fire("Deleted!", "Question has been deleted.", "success");
      }
    });
  };

  const onFormCancel = () => {
    setView({
      addQuestions: false,
      editQuestion: false,
    });
    setQuestionId("");
    setIsAnswer1(false);
    setIsAnswer2(false);
    setIsAnswer3(false);
    setIsAnswer4(false);
  };

  const toggleModal = (type) => {
    setView({
      addQuestions: type === "addQuestions" ? true : false,
      editQuestion: type === "editQuestion" ? true : false,
    });
  };

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            className="nk-modal-head"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h4 className="nk-modal-title title">Questions </h4>
            <span
              className=""
              style={{
                fontSize: "1rem",
                textAlign: "center",
                alignSelf: "center",
                margin: "-22px 0 0 8px",
              }}
            >
              ({total})
            </span>
          </div>
          <div
            style={{
              marginTop: "-22px",
              marginRight: "15px",
            }}
          >
            <Button
              className="toggle btn-icon d-md-none"
              color="secondary"
              onClick={() => {
                // toggle("addQuestions");
              }}
            >
              <Icon name="plus"></Icon>
            </Button>
            <Button
              className="toggle d-none d-md-inline-flex"
              color="secondary"
              onClick={() => {
                toggleModal("addQuestions");
              }}
            >
              <Icon name="plus"></Icon>
              <span>Add Questions</span>
            </Button>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "grid",
              placeItems: "center",
            }}
          >
            <Spinner type="grow" color="dark" />
          </div>
        ) : error ? (
          <div>something is wrong</div>
        ) : success ? (
          <>
            <div className="nk-tnx-details mt-sm-3">
              <Row className="gy-3">
                {questions?.length > 0 ? (
                  questions?.map((li, i) => (
                    <Col lg={12} key={i}>
                      <div
                        style={{
                          // border: "1px solid #d0d0d0",
                          border: "1px solid #000",
                          padding: "10px",
                          borderRadius: "10px",
                          position: "relative",
                        }}
                      >
                        <span className="question__more">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              href="#more"
                              onClick={(ev) => ev.preventDefault()}
                              className="dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-v" className={"more_icon"}></Icon>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#remove"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      toggleModal("editQuestion");
                                      setQuestionId(li?._id);
                                      dispatch(getQuestionById(li?._id));
                                    }}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit Question</span>
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#remove"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      deleteAlert(li._id);
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Delete Question</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </span>
                        {li?.translations?.map((ques, ind) => (
                          <div key={ind}>
                            {ques?.language === "62e7f4bd38629a3473e07313" ? (
                              <hr className="preview-hr" style={{ margin: "1rem 0 0 0" }} />
                            ) : null}

                            <div
                              style={{
                                direction: ques?.language === "62e7f4bd38629a3473e07313" ? "rtl" : "",
                                paddingTop: ques?.language === "62e7f4bd38629a3473e07313" ? "10px" : "",
                                textAlign: "start",
                              }}
                            >
                              <p
                                className="caption-text"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <span>{i + 1}</span>
                                <span
                                  style={{
                                    margin: "0 10px",
                                    marginRight: ques?.language === "62e7f4bd38629a3473e07313" ? "10px" : "",
                                    fontWeight: "500",
                                    width: "95%",
                                  }}
                                >
                                  {Parser(ques?.questionTranslation?.title)}
                                </span>
                              </p>
                              {ques?.questionTranslation?.options?.map((opt, index) => (
                                <p
                                  key={index}
                                  className="sub-text horizontal-scroll"
                                  style={{
                                    color: opt?.isAnswer ? "green" : "#666c74",
                                    fontWeight: opt?.isAnswer ? "600" : "",
                                    // display: "flex",
                                    // flexWrap: "wrap",
                                    overflow: "auto",
                                    margin: "0 5px",
                                  }}
                                >
                                  <span>{index + 1}.</span>
                                  <span
                                    style={{
                                      marginLeft: "5px",
                                      marginRight: ques?.language === "62e7f4bd38629a3473e07313" ? "5px" : "",
                                      textAlign: ques?.language === "62e7f4bd38629a3473e07313" ? "start" : "",
                                    }}
                                  >
                                    {opt?.label}
                                  </span>
                                </p>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  ))
                ) : error ? (
                  <div className="text-center">
                    <span className="text-silent">Something went wrong,</span>
                    <Button>try again!</Button>
                  </div>
                ) : (
                  ""
                  // <div className="text-center">
                  //   <span className="text-silent">No Questions found</span>
                  // </div>
                )}
              </Row>
            </div>
          </>
        ) : null}
      </div>

      {/* Questions add modal */}
      <Modal
        isOpen={view.addQuestions || view?.editQuestion}
        toggle={() => onFormCancel()}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h5">
                {view?.addQuestions ? "Add" : view?.editQuestion ? "Edit" : "Add"} Question
              </BlockTitle>
              <BlockDes>
                <p>
                  {view?.addQuestions
                    ? "Add questions to skill."
                    : view?.editQuestion
                    ? "Edit Question of this skill"
                    : "Add questions to skill."}
                </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          {loading ? (
            <div
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <Spinner type="grow" color="dark" />
            </div>
          ) : success ? (
            <Block>
              <form
                onSubmit={(e) => {
                  if (view?.addQuestions) {
                    handleQuestionAdd(e);
                  } else {
                    handleQuestionEdit(e);
                  }
                }}
              >
                <label className="form-label" htmlFor="title">
                  Question Type
                </label>
                <Row className="g-3">
                  <Col sm="6">
                    <div className="form-group">
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro checked">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="questionType"
                          value={"single"}
                          defaultChecked
                          defaultValue={"single"}
                          onChange={(e) => {
                            setQuestionType(e.target.value);
                          }}
                          id="btnRadioControl1"
                        />
                        <label className="custom-control-label" htmlFor="btnRadioControl1">
                          Single Choice
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="custom-control custom-control-sm custom-radio custom-control-pro checked">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="questionType"
                        value={"multiple"}
                        onChange={(e) => {
                          setQuestionType(e.target.value);
                        }}
                        id="btnRadioControl2"
                      />
                      <label className="custom-control-label" htmlFor="btnRadioControl2">
                        Multiple Choice
                      </label>
                    </div>
                  </Col>

                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="title">
                        Language
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="category"
                          options={languageOptions}
                          onChange={(value) => {
                            setQuestionLanguage(value?.value);
                          }}
                          defaultValue={questionLanguage}
                        />
                      </div>
                    </div>
                  </Col>

                  {questionLanguage === "english" ? (
                    <>
                      {/* Questions */}
                      <Col size="12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="title">
                            Question
                          </label>
                          <div className="form-control-wrap">
                            <RichTextEditor
                              initialValue={questionEn || ""}
                              getValue={(value) => setQuestionEn(value)}
                              arValue={false}
                            />
                          </div>
                        </div>
                      </Col>

                      {/* Options */}
                      <Col size="12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="category">
                            Options
                          </label>
                          <div className="form-control-wrap">
                            {/* Option 1 */}

                            <div
                              className="input-group"
                              style={{
                                margin: "0 0 15px 0",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`Option 1`}
                                value={option1En}
                                onChange={(e) => {
                                  setOption1En(e.target.value);
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  <input
                                    type={"checkbox"}
                                    // className="custom-control-input"
                                    name="option"
                                    checked={isAnswer1 ? true : false}
                                    onChange={(e) => {
                                      if (e.target.type === "checkbox") {
                                        if (e.target.checked) {
                                          setIsAnswer1(true);
                                        } else {
                                          setIsAnswer1(false);
                                        }
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>

                            {/* Option 2 */}

                            <div
                              className="input-group"
                              style={{
                                margin: "0 0 15px 0",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`Option 2`}
                                value={option2En}
                                onChange={(e) => {
                                  setOption2En(e.target.value);
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  <input
                                    type={"checkbox"}
                                    // className="custom-control-input"
                                    name="option"
                                    checked={isAnswer2 ? true : false}
                                    onChange={(e) => {
                                      if (e.target.type === "checkbox") {
                                        if (e.target.checked) {
                                          setIsAnswer2(true);
                                        } else {
                                          setIsAnswer2(false);
                                        }
                                      }
                                    }}
                                    // id="btnRadioControl4"
                                  />
                                </span>
                              </div>
                            </div>

                            {/* option 3 */}

                            <div
                              className="input-group"
                              style={{
                                margin: "0 0 15px 0",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`Option 3`}
                                value={option3En}
                                onChange={(e) => {
                                  setOption3En(e.target.value);
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  <input
                                    type={"checkbox"}
                                    // className="custom-control-input"
                                    name="option"
                                    // value={"multiple"}
                                    checked={isAnswer3 ? true : false}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setIsAnswer3(true);
                                      } else {
                                        setIsAnswer3(false);
                                      }
                                    }}
                                    // id="btnRadioControl4"
                                  />
                                </span>
                              </div>
                            </div>

                            {/* Option 4 */}

                            <div
                              className="input-group"
                              style={{
                                margin: "0 0 15px 0",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`Option 4`}
                                value={option4En}
                                onChange={(e) => {
                                  setOption4En(e.target.value);
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  <input
                                    type={"checkbox"}
                                    // className="custom-control-input"
                                    name="option"
                                    // value={"multiple"}
                                    checked={isAnswer4 ? true : false}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setIsAnswer4(true);
                                      } else {
                                        setIsAnswer4(false);
                                      }
                                    }}
                                    // id="btnRadioControl4"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : questionLanguage === "arabic" ? (
                    <>
                      {/* Questions */}
                      <Col size="12">
                        <div className="form-group">
                          <div
                            style={{
                              textAlign: "end",
                            }}
                          >
                            <label className="form-label" htmlFor="title">
                              سؤال
                            </label>
                          </div>
                          <div className="form-control-wrap">
                            <RichTextEditor
                              initialValue={questionAr || ""}
                              getValue={(value) => setQuestionAr(value)}
                              arValue={true}
                            />
                          </div>
                        </div>
                      </Col>

                      {/* Options */}
                      <Col size="12">
                        <div className="form-group" style={{ textAlign: "end" }}>
                          <label className="form-label" htmlFor="category">
                            اخْتِيار
                          </label>
                          <div className="form-control-wrap">
                            {/* Option 1 */}

                            <div
                              className="input-group"
                              style={{
                                margin: "0 0 15px 0",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`الخيار 1`}
                                value={option1Ar}
                                onChange={(e) => {
                                  setOption1Ar(e.target.value);
                                }}
                                dir="rtl"
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  <input
                                    type={"checkbox"}
                                    checked={isAnswer1}
                                    name="optionAr"
                                    onChange={(e) => {
                                      if (e.target.type === "checkbox") {
                                        if (e.target.checked) {
                                          setIsAnswer1(true);
                                        } else {
                                          setIsAnswer1(false);
                                        }
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>

                            {/* Option 2 */}

                            <div
                              className="input-group"
                              style={{
                                margin: "0 0 15px 0",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`الخيار 2`}
                                value={option2Ar}
                                onChange={(e) => {
                                  setOption2Ar(e.target.value);
                                }}
                                dir="rtl"
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  <input
                                    type={"checkbox"}
                                    name="optionAr"
                                    checked={isAnswer2}
                                    onChange={(e) => {
                                      if (e.target.type === "checkbox") {
                                        if (e.target.checked) {
                                          setIsAnswer2(true);
                                        } else {
                                          setIsAnswer2(false);
                                        }
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>

                            {/* option 3 */}

                            <div
                              className="input-group"
                              style={{
                                margin: "0 0 15px 0",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`الخيار 3`}
                                value={option3Ar}
                                onChange={(e) => {
                                  setOption3Ar(e.target.value);
                                }}
                                dir="rtl"
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  <input
                                    type={"checkbox"}
                                    name="optionAr"
                                    checked={isAnswer3}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setIsAnswer3(true);
                                      } else {
                                        setIsAnswer3(false);
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>

                            {/* Option 4 */}

                            <div
                              className="input-group"
                              style={{
                                margin: "0 0 15px 0",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`الخيار 4`}
                                value={option4Ar}
                                onChange={(e) => {
                                  setOption4Ar(e.target.value);
                                }}
                                dir="rtl"
                              />
                              <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon2">
                                  <input
                                    type={"checkbox"}
                                    name="optionAr"
                                    checked={isAnswer4}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setIsAnswer4(true);
                                      } else {
                                        setIsAnswer4(false);
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : null}
                  <Col size="12">
                    <Button color="primary" type="submit">
                      <Icon className="plus"></Icon>
                      <span>
                        {view?.addQuestions ? "Add Question" : view?.editQuestion ? "Edit Question" : "Add Question"}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </form>
            </Block>
          ) : null}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SkillQuestions;
