import React from 'react';
const validateEmail=(value)=>{
    let testCase = /\S+@\S+\.\S+/
    return testCase.test(value);
}
const validatePhone=(value)=>{
    return value.match(/^\d+$/)
}

function slugify(string) {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }
export {validateEmail, validatePhone, slugify};