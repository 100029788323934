import { deleteMethod, getMethod, setAuthorizationToken } from "../../utils/apiServices";
import { getCookie } from "../../utils/storage";
import {
  isUserByIdJobsSuccess,
  isUserByIdSuccess,
  isUserError,
  isUserLoading,
  isUserSuccess,
} from "../Apislices/userSlice";

export const getAllUsers = (query) => async (dispatch) => {
  try {
    dispatch(isUserLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/users/list`, query);

    if (response?.success) {
      dispatch(isUserSuccess({ data: response?.data, total: response?.total }));
    } else {
      dispatch(isUserError(response?.message));
    }
  } catch (error) {
    dispatch(isUserError(error?.message));
  }
};

export const getUserById = (id) => async (dispatch) => {
  try {
    dispatch(isUserLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/user/${id}/profile`);
    if (response?.success) {
      dispatch(isUserByIdSuccess(response?.data));
      dispatch(isUserByIdJobsSuccess(response?.userJobs));
    } else {
      dispatch(isUserError(response?.message));
    }
  } catch (error) {
    dispatch(isUserError(error?.message));
  }
};



export const deleteUser = (id, successCB, errorCB) => async (dispatch) => {
  try {
    setAuthorizationToken(getCookie("_jwt"));
    let response = await deleteMethod(`/admin/user/${id}/remove`);
    if (response?.success) {
      successCB(response);
    } else {
      errorCB(response);
    }
  } catch (error) {
    errorCB(error);
  }
};


