/* eslint-disable */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  Button,
  Col,
  Icon,
  PaginationComponent,
  RSelect,
  Row,
  TooltipComponent,
  UserAvatar,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

import { toast } from "react-toastify";

import {
  asyncgetAllPostList,
  asyncInactiveAllPostByUser,
  asyncUpdateStatusPost,
  asyncUpdateStatusUser,
} from "../../redux/Action/postListAction";
import clsx from "clsx";
import styles from "../../style/feed.module.scss";
import DotPagination from "../../components/pagination/DotPagination.j";
import { getScreenHeight, getScreenWidth } from "../../utils/Utils";
import { filterRole, filterStatus, userData } from "../pre-built/user-manage/UserData";

const PostList = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams()
  const { error, loading, success, postList, total } = useSelector((state) => state?.postListSlice);
  const [currentPage, setCurrentPage] = useState(searchParams.get("p") ? parseInt(searchParams.get("p")) : 1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [screenHeight, setScreenHeight] = useState(getScreenHeight());
  const [tablesm, updateTableSm] = useState(false);
  const [sort, setSortState] = useState("");
  const [onSearch, setonSearch] = useState(true);
  const [statusFilterObj, setStatusFilterObj] = useState({
    active: "",
    createrName: "",
    hashtagName: "",
    postText: "",
    filterStatus: false,
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const statusFilter = [
    { id: 1, value: "", label: "" },
    { id: 2, value: true, label: "Active" },
    { id: 3, value: false, label: "Inactive" },
  ];
  const [selectedOption, setSelectedOption] = useState(0);

  const copyPostId = (postId) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(postId)
        .then(() => {
          toast.success("Copied!");
        })
        .catch((error) => {
          // Handle any errors that may occur while copying
          toast.error("Failed to copy:", error);
          console.error("Failed to copy:", error);
        });
    } else {
      // Fallback method for browsers that do not support the Clipboard API

      const tempInputElement = document.createElement("textarea");
      tempInputElement.value = postId;
      document.body.appendChild(tempInputElement);
      tempInputElement.select();
      document.execCommand("copy");
      document.body.removeChild(tempInputElement);

      toast.success("Copied!");
    }
  };

  const handleBlockPost = async (id) => {
    let data = {
      status: false,
    };
    let res = await dispatch(asyncUpdateStatusPost(id, data));

    if (res?.success) {
      await dispatch(asyncgetAllPostList(currentPage, itemPerPage));
    } else {
      await dispatch(asyncgetAllPostList(currentPage, itemPerPage));
    }
  };

  const handleUnBlockPost = async (id) => {
    let data = {
      status: true,
    };
    let res = await dispatch(asyncUpdateStatusPost(id, data));

    if (res?.success) {
      await dispatch(asyncgetAllPostList(currentPage, itemPerPage));
    } else {
      await dispatch(asyncgetAllPostList(currentPage, itemPerPage));
    }
  };

  const handleBlockUser = async (id) => {
    let data = {
      status: true,
    };
    let res = await dispatch(asyncUpdateStatusUser(id, data));

    if (res?.success) {
      await dispatch(asyncgetAllPostList(currentPage, itemPerPage));
    } else {
      await dispatch(asyncgetAllPostList(currentPage, itemPerPage));
    }
  };

  const handleUnBlockUser = async (id) => {
    let data = {
      status: false,
    };
    let res = await dispatch(asyncUpdateStatusUser(id, data));

    if (res?.success) {
      await dispatch(asyncgetAllPostList(currentPage, itemPerPage));
    } else {
      await dispatch(asyncgetAllPostList(currentPage, itemPerPage));
    }
  };

  const inActiveAllPostByUser = async (id) => {
    let data = {
      userId: id,
    };
    let res = await dispatch(asyncInactiveAllPostByUser(data));

    if (res?.success) {
      await dispatch(asyncgetAllPostList(currentPage, itemPerPage));
    } else {
      await dispatch(asyncgetAllPostList(currentPage, itemPerPage));
    }
  };

  const inactivePostAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete the post?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleBlockPost(id);
        Swal.fire("Deleted!", "Your post has been deleted", "success");
      }
    });
  };

  const activePostAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to Recover the post?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUnBlockPost(id);
        Swal.fire("Recovered!", "Your post has been recovered", "success");
      }
    });
  };

  const blockAlertUser = (id) => {
    Swal.fire({
      title: "Are you sure you want to block the user?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleBlockUser(id);
        Swal.fire("Blocked!", "Your user has been blocked.", "success");
      }
    });
  };

  const unBlockAlertUser = (id) => {
    Swal.fire({
      title: "Are you sure you want to unblock the user?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUnBlockUser(id);
        Swal.fire("Unblocked!", "Your user has been unblocked.", "success");
      }
    });
  };

  const inActiveAllPostByUserAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to deactivate all posts of this user?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        inActiveAllPostByUser(id);
        Swal.fire("Deactivated!", "Your posts have been set to inactive successfully.", "success");
      }
    });
  };

  // function to close the form modal

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    let filterObj = {
      active: statusFilterObj?.active,
      createrName: statusFilterObj?.createrName,
      hashtagName: statusFilterObj.hashtagName,
      postText: statusFilterObj.postText,
    };

    dispatch(asyncgetAllPostList(currentPage, itemPerPage, filterObj));

    return () => { };
  }, [dispatch, currentPage, itemPerPage, statusFilterObj?.filterStatus]);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(getScreenWidth());
      setScreenHeight(getScreenHeight());
    }

    const resizeListener = () => handleResize();

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="Post List"></Head>

      <Content>
        <Breadcrumb className="breadcrumb-arrow">
          <BreadcrumbItem>
            <Link to={"/feed"}>Feed</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={"/moderate-world"}>Moderate World</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a>Post List</a>
          </BreadcrumbItem>
        </Breadcrumb>

        <Block style={{ marginTop: "15px" }}>
          {/* filter  */}
          <div className="card-inner position-relative card-tools-toggle" style={{ zIndex: 10 }}>
            <div className="card-title-group">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <div className="toggle-wrap">
                      <Button
                        className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                        onClick={() => updateTableSm(true)}
                      >
                        <Icon name="menu-right"></Icon>
                      </Button>
                      <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                        <ul className="btn-toolbar gx-1">
                          <li className="toggle-close">
                            <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                              <Icon name="arrow-left"></Icon>
                            </Button>
                          </li>
                          <li>
                            <Dropdown
                              className=""
                              isOpen={dropdownOpen}
                              toggle={() => {
                                setDropdownOpen(!dropdownOpen);
                              }}
                            >
                              <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                <div className="dot dot-primary"></div>
                                <Icon name="filter-alt"></Icon>
                              </DropdownToggle>
                              <DropdownMenu
                                right
                                className={clsx(
                                  "filter-wg dropdown-menu-xl",
                                  screenHeight < 695 ? styles.transform : ""
                                )}
                                style={{ overflow: "visible" }}
                              >
                                <div className="dropdown-head">
                                  <span className="sub-title dropdown-title">Filter Post List</span>
                                  <div className="dropdown">
                                    <Button
                                      color=""
                                      onClick={() => {
                                        setDropdownOpen(false);
                                      }}
                                    >
                                      <Icon name="cross"></Icon>
                                    </Button>
                                  </div>
                                </div>
                                <div className="dropdown-body dropdown-body-rg">
                                  <Row className="gx-6 gy-3">
                                    <Col size="12">
                                      <FormGroup>
                                        <label className="overline-title overline-title-alt">Creator Name</label>
                                        <input
                                          className="form-control form-control-lg"
                                          placeholder="Enter Creator Name"
                                          value={statusFilterObj?.createrName}
                                          onChange={(e) => {
                                            setStatusFilterObj({
                                              ...statusFilterObj,
                                              createrName: e.target.value,
                                            });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>

                                    <Col size="12">
                                      <FormGroup>
                                        <label className="overline-title overline-title-alt">Post Text</label>
                                        <input
                                          className="form-control form-control-lg"
                                          placeholder="Enter post text and deep link"
                                          value={statusFilterObj?.postText}
                                          onChange={(e) => {
                                            setStatusFilterObj({
                                              ...statusFilterObj,
                                              postText: e.target.value,
                                            });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>

                                    <Col size="12">
                                      <FormGroup>
                                        <label className="overline-title overline-title-alt">Hashtag</label>
                                        <input
                                          className="form-control form-control-lg"
                                          placeholder="e.g.	# سكاتشورلد🌍"
                                          value={statusFilterObj?.hashtagName}
                                          onChange={(e) => {
                                            setStatusFilterObj({
                                              ...statusFilterObj,
                                              hashtagName: e.target.value,
                                            });
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col size="6">
                                      <FormGroup>
                                        <label className="overline-title overline-title-alt">Status</label>
                                        <RSelect
                                          options={statusFilter}
                                          placeholder="Any Status"
                                          value={selectedOption}
                                          onChange={(e) => {
                                            setStatusFilterObj({
                                              ...statusFilterObj,
                                              active: e.value,
                                            });

                                            setSelectedOption(e);
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col size="12">
                                      <FormGroup className="form-group">
                                        <Button
                                          color="secondary"
                                          onClick={() => {
                                            setCurrentPage(1);
                                            setStatusFilterObj({
                                              ...statusFilterObj,
                                              filterStatus: !statusFilterObj?.filterStatus,
                                            });
                                            // dispatch(asyncgetAllPostList(currentPage, itemPerPage, filterObj));
                                            setDropdownOpen(false);
                                          }}
                                        >
                                          Apply
                                        </Button>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="dropdown-foot between">
                                  <a
                                    href="#reset"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setDropdownOpen(false);
                                      setCurrentPage(1);

                                      setStatusFilterObj({
                                        ...statusFilterObj,
                                        active: "",
                                        createrName: "",
                                        hashtagName: "",
                                        postText: "",
                                        filterStatus: !statusFilterObj?.filterStatus,
                                      });
                                      setSelectedOption(statusFilter[0]);
                                    }}
                                    className="clickable"
                                  >
                                    Reset Filter
                                  </a>
                                  <FormGroup className="form-group">
                                    <Button
                                      color={"danger"}
                                      onClick={() => {
                                        setDropdownOpen(false);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </FormGroup>
                                </div>
                              </DropdownMenu>
                            </Dropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*search bar */}

          <Card className="card-bordered">
            <div className="card-inner-group">
              <div
                className="card-inner p-0"
                style={{
                  width: "100%",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <Spinner type="grow" color="dark" />
                  </div>
                ) : error ? (
                  "something went wrong..."
                ) : success ? (
                  <>
                    {/* res table */}
                    <Table responsive>
                      <thead>
                        <tr className={clsx(styles.postTableHeading)}>
                          <th>
                            {" "}
                            <span>Post Id</span>
                          </th>
                          <th className="mdTable">
                            {" "}
                            <span>{"Date"}</span>
                          </th>
                          <th className="mdTable">
                            {" "}
                            <span>Post Type</span>
                          </th>
                          <th className="mdTable">
                            {" "}
                            <span>Creator Name</span>
                          </th>
                          <th className="mdTable">
                            <span>Text</span>
                          </th>
                          <th className="mdTable">
                            {" "}
                            <span>HashTag</span>
                          </th>
                          <th className="mdTable">
                            <span>Status</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {postList?.length ? (
                            postList
                              ?.slice()
                              .sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
                              ?.map((item) => {
                                return (
                                  <tr key={item._id} className={clsx(styles.postTableHeading)}>
                                    <th scope="row">
                                      <button
                                        style={{
                                          border: "none",
                                          background: "transparent",
                                          fontSize: "10px",
                                          color: "#8094AE",
                                        }}
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          copyPostId(item._id);
                                        }}
                                      >
                                        <span className={clsx("title", styles.fontSize10)}>
                                          {item?._id ? item?._id : ""}
                                        </span>
                                      </button>
                                    </th>
                                    <td className="mdTable">
                                      <span className={styles.fontSize10}>
                                        {item?.createdAt
                                          ? moment(item.createdAt)?.utc()?.format("DD MMM YY h:mm A")
                                          : ""}
                                      </span>
                                    </td>
                                    <td className="mdTable">
                                      <span className="tb-sub"> {item?.postType ? item?.postType : ""}</span>
                                    </td>
                                    <td className="mdTable">
                                      <span className="tb-sub">{`${item?.user?.fullName ? item?.user?.fullName : ""} ${item?.user?.lastName ? item?.user?.lastName : ""
                                        }`}</span>
                                    </td>
                                    <td className={clsx(styles.fixedWidthCell, "mdTable")}>
                                      <span className="tb-sub">{item?.post ? item?.post : ""}</span>
                                    </td>
                                    <td className="mdTable">
                                      <span className="tb-sub">
                                        {item?.hashtag?.translations[1]?.hashtagTranslation?.name
                                          ? item?.hashtag?.translations[1]?.hashtagTranslation?.name
                                          : item?.hashtag?.translations[0]?.hashtagTranslation?.name
                                            ? item?.hashtag?.translations[0]?.hashtagTranslation?.name
                                            : ""}
                                      </span>
                                    </td>
                                    <td className="mdTable">
                                      <span
                                        className={clsx("tb-sub", item?.active ? "active-color" : "inactive-color")}
                                      >
                                        {" "}
                                        {item?.active ? "Active" : "InActive"}
                                      </span>
                                    </td>

                                    <td>
                                      <ul className="nk-tb-actions gx-1 my-n1">
                                        <li className="mr-n1">
                                          <UncontrolledDropdown>
                                            <DropdownToggle
                                              tag="a"
                                              href="#more"
                                              onClick={(ev) => ev.preventDefault()}
                                              className="dropdown-toggle btn btn-icon btn-trigger"
                                            >
                                              <Icon name="more-h"></Icon>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                              <ul className="link-list-opt no-bdr">
                                                <li>
                                                  <DropdownItem tag="a" href={`/post-list/${item?._id}?p=${currentPage}`}>
                                                    <Icon name="article"></Icon>
                                                    <span>Preview</span>
                                                  </DropdownItem>
                                                </li>

                                                <li>
                                                  <DropdownItem
                                                    tag="a"
                                                    href="#remove"
                                                    onClick={(ev) => {
                                                      ev.preventDefault();
                                                      copyPostId(item._id);
                                                    }}
                                                  >
                                                    <Icon name="copy"></Icon>
                                                    <span>Copy PostId </span>
                                                  </DropdownItem>
                                                </li>
                                                <li>
                                                  <DropdownItem
                                                    tag="a"
                                                    href="#remove"
                                                    onClick={(ev) => {
                                                      ev.preventDefault();
                                                      if (item?.active) {
                                                        inactivePostAlert(item._id);
                                                      } else {
                                                        activePostAlert(item._id);
                                                      }
                                                    }}
                                                  >
                                                    <Icon name={item?.active ? "unlink-alt" : "link-alt"}></Icon>
                                                    <span>{item?.active ? "Delete Post" : "Recover Post"}</span>
                                                  </DropdownItem>
                                                </li>

                                                <li>
                                                  <DropdownItem
                                                    tag="a"
                                                    href="#remove"
                                                    onClick={(ev) => {
                                                      ev.preventDefault();
                                                      if (!item?.user?.blockedByAdmin) {
                                                        blockAlertUser(item?.user?._id);
                                                      } else {
                                                        unBlockAlertUser(item?.user?._id);
                                                      }
                                                    }}
                                                  >
                                                    <Icon
                                                      name={
                                                        !item?.user?.blockedByAdmin ? "user-cross" : "user-add-fill"
                                                      }
                                                    ></Icon>
                                                    <span>
                                                      {!item?.user?.blockedByAdmin ? "Block User " : "Unblock User"}
                                                    </span>
                                                  </DropdownItem>
                                                </li>

                                                <li>
                                                  <DropdownItem
                                                    tag="a"
                                                    href="#remove"
                                                    style={{ color: item?.active ? "#526484" : "#dddddd" }}
                                                    onClick={(ev) => {
                                                      ev.preventDefault();

                                                      if (item?.active) {
                                                        inActiveAllPostByUserAlert(item?.user?._id);
                                                      }
                                                    }}
                                                  >
                                                    <Icon name={"na"}></Icon>
                                                    <span>Inactive All Post</span>
                                                  </DropdownItem>
                                                </li>
                                              </ul>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })
                          ) : (
                            <tr>
                              <td>
                                <div className="text-center d-flex flex-column">
                                  <span className="text-silent">No PostList found</span>
                                  <span
                                    onClick={() => {
                                      setCurrentPage(1);

                                      setStatusFilterObj({
                                        ...statusFilterObj,
                                        active: "",
                                        createrName: "",
                                        hashtagName: "",
                                        postText: "",
                                        filterStatus: !statusFilterObj?.filterStatus,
                                      });

                                      setSelectedOption(statusFilter[0]);
                                    }}
                                    style={{ color: "dodgerblue", cursor: "pointer" }}
                                  >
                                    Reset It
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      </tbody>
                    </Table>
                  </>
                ) : null}
                <div className="card-inner">
                  {postList.length > 0 ? (
                    <DotPagination
                      itemPerPage={itemPerPage}
                      totalItems={total}
                      paginate={paginate}
                      currentPage={currentPage}
                      siblingCount={screenWidth < 575 ? 0 : 2}
                      screenWidth={screenWidth}
                    />
                  ) : (
                    <DotPagination
                      itemPerPage={itemPerPage}
                      totalItems={total}
                      paginate={paginate}
                      currentPage={currentPage}
                      siblingCount={screenWidth < 575 ? 0 : 2}
                      screenWidth={screenWidth}
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default PostList;
