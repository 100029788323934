import classnames from "classnames";
import parse from "html-react-parser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Modal, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { editCompany, getCompanyById, removeCompanyDocumentApi, verifyCompany } from "../../redux/Action/companyAction";
import DocumentUploadModal from "./DocumentUploadModal";
import { useSearchParams } from "react-router-dom/dist";
import { findRightPageBasedOnUrl } from "../../utils/numberToArabicNumber";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";
import CompanyCreditModal from "../../components/companyCreditModal/companyCreditModal";
import { toast } from "react-toastify";

const CompanyById = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, companyId } = useSelector((state) => state?.company);
  const [uploadModal, setUploadModal] = useState(false);
  const [activeIconTab, setActiveIconTab] = useState("1");
  const [searchParams] = useSearchParams();
  let pageNo = 0;

  if (searchParams.get("p")) {
    pageNo = searchParams.get("p");
  }

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  const toggleUploadModal = () => {
    setUploadModal(!uploadModal);
  };

  const VerifyAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to verify?",
      // text: "Do ",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, Verify it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await dispatch(verifyCompany(id));
        if (res?.success) {
          Swal.fire("Success!", res?.message, "success");
          dispatch(getCompanyById(id));
        } else {
          Swal.fire("Error!", res?.message, "success");
          dispatch(getCompanyById(id));
        }
      }
    });
  };

  const handleVerify = async (id) => {
    if (companyId.companyName && companyId.companyName != "" &&
      companyId.registrationNumber && companyId.registrationNumber.trim() != "" &&
      companyId?.address[0]?.address && companyId?.address[0]?.address.trim() != "" &&
      companyId?.address[0]?.country?.translations[0]?.countryTranslation?.name &&
      companyId?.address[0]?.country?.translations[0]?.countryTranslation?.name.trim() != "" &&
      companyId?.address[0]?.state?.translations[0]?.stateTranslation?.name &&
      companyId?.address[0]?.state?.translations[0]?.stateTranslation?.name.trim() != "" &&
      companyId?.address[0]?.city?.translations[0]?.cityTranslation?.name &&
      companyId?.address[0]?.city?.translations[0]?.cityTranslation?.name.trim() != "" &&
      companyId?.address[0]?.zipcode && companyId?.address[0]?.zipcode.trim() != "" &&
      companyId?.files.length > 0) {
      VerifyAlert(id);
    } else {
      // if (!companyId.companyName || companyId.companyName == "") {
      //   toast.error("Cannot Verify. Company Name missing")
      // }
      // if (!companyId.registrationNumber || companyId.registrationNumber == "") {
      //   toast.error("Cannot Verify. Registration Number missing")
      // }
      // if (!companyId?.address[0]?.address || companyId?.address[0]?.address == "") {
      //   toast.error("Cannot Verify. Company Address missing")
      // }
      // if (!companyId?.address[0]?.country?.translations[0]?.countryTranslation?.name ||
      //   companyId?.address[0]?.country?.translations[0]?.countryTranslation?.name == "") {
      //   toast.error("Cannot Verify. Company Country missing")
      // }
      // if (!companyId?.address[0]?.state?.translations[0]?.stateTranslation?.name ||
      //   companyId?.address[0]?.state?.translations[0]?.stateTranslation?.name == "") {
      //   toast.error("Cannot Verify. Company State missing")
      // }
      // if (!companyId?.address[0]?.city?.translations[0]?.cityTranslation?.name ||
      //   companyId?.address[0]?.city?.translations[0]?.cityTranslation?.name == "") {
      //   toast.error("Cannot Verify. Company City missing")
      // }
      // if (!companyId?.address[0]?.zipcode || companyId?.address[0]?.zipcode == "") {
      //   toast.error("Cannot Verify. Company Zip code missing")
      // }
      // if (!companyId?.team[0].user?.mobile || companyId?.team[0].user?.mobile == "" || !companyId?.team[0].user?.mobileVerified) {
      //   toast.error("Cannot Verify. HR Mobile Number Missing")
      // }

      let errorString = ``;
      if (!companyId.companyName || companyId.companyName == "") {
        errorString = errorString + `Company Name,`
      }
      if (!companyId.registrationNumber || companyId.registrationNumber == "") {
        errorString = errorString + ` Registrantion Number,`
      }
      if (!companyId?.address[0]?.address || companyId?.address[0]?.address == "") {
        errorString = errorString + ` Company Address,`
      }
      if (!companyId?.address[0]?.country?.translations[0]?.countryTranslation?.name ||
        companyId?.address[0]?.country?.translations[0]?.countryTranslation?.name == "") {
        errorString = errorString + ` Commpany Country,`
      }
      if (!companyId?.address[0]?.state?.translations[0]?.stateTranslation?.name ||
        companyId?.address[0]?.state?.translations[0]?.stateTranslation?.name == "") {
        errorString = errorString + ` Company State,`
      }
      if (!companyId?.address[0]?.city?.translations[0]?.cityTranslation?.name ||
        companyId?.address[0]?.city?.translations[0]?.cityTranslation?.name == "") {
        errorString = errorString + ` Commpany City,`
      }
      if (!companyId?.address[0]?.zipcode || companyId?.address[0]?.zipcode == "") {
        errorString = errorString + ` Company Zipcode,`
      }
      if (!companyId?.files || companyId?.files.length < 1) {
        errorString = errorString + ` Company Documnents `
      }

      toast.error(`Company Information incomplete. ${errorString} is needed for company verification.`)
    }
  }

  const companyDocumentDeleteAlert = (companyId, formData) => {
    Swal.fire({
      title: "Delete Document",
      text: "Are you sure you want to delete this document? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
      cancelButtonText: "Cancel",
      cancelButtonColor: "#d33",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await dispatch(removeCompanyDocumentApi(companyId, formData));
        if (res?.success) {
          Swal.fire("Deleted!", res?.message, "success");
          await dispatch(getCompanyById(companyId));
          document.getElementById("document").scrollIntoView({ behavior: "smooth" });
        } else {
          Swal.fire("Error!", res?.message, "error");
          dispatch(getCompanyById(companyId));
        }
      }
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(getCompanyById(id));
    }

    return () => { };
  }, [id]);

  const [showCreditModal, setShowCreditModal] = useState(false)


  // Get the pathname from the URL
  return (
    <React.Fragment>
      <Head title="Company Detail"></Head>
      {loading ? (
        <div
          style={{
            height: "200px",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spinner type="grow" color="dark" />
        </div>
      ) : companyId ? (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Company / <strong className="text-primary small">{companyId?.companyName}</strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      Job created at:{" "}
                      <span className="text-base">{moment(companyId?.createdAt).format("YYYY-MM-DD")}</span>
                    </li>
                    <li>
                      Company Verified:{" "}
                      <span className="text-base">{companyId?.companyVerified ? "Verified" : "Not verified"}</span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={(ev) => {
                    ev.preventDefault();
                    if (findRightPageBasedOnUrl("action-by-hr-company-details")) {
                      navigate(pageNo === 0 ? -1 : `/action-by-hr?p=${pageNo}`);
                    } else {
                      navigate(pageNo === 0 ? -1 : `/company?p=${pageNo}`);
                    }
                  }}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    if (findRightPageBasedOnUrl("action-by-hr-company-details")) {
                      navigate(pageNo === 0 ? -1 : `/action-by-hr?p=${pageNo}`);
                    } else {
                      navigate(pageNo === 0 ? -1 : `/company?p=${pageNo}`);
                    }
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <PreviewCard>
                    <Nav
                      tabs
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          margin: "0 15px",
                          display: "flex",
                        }}
                      >
                        <NavItem>
                          <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({ active: activeIconTab === "1" })}
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggleIconTab("1");
                            }}
                          >
                            <Icon name="file-docs"></Icon>
                            <span>Details</span>
                          </NavLink>
                        </NavItem>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          margin: "0 15px",
                        }}
                      >
                        <NavItem>
                          {companyId?.companyVerified ? (
                            ""
                          ) : (
                            <NavLink tag="div" href="#tab">
                              <Button
                                className="toggle btn-icon d-md-none"
                                color="info"
                                onClick={() => {
                                  VerifyAlert(companyId?._id);
                                }}
                              >
                                <Icon name="check-circle-cut"></Icon>
                              </Button>
                              <Button
                                className="toggle d-none d-md-inline-flex"
                                color="success"
                                outline
                                onClick={() => {
                                  // VerifyAlert(companyId?._id);
                                  handleVerify(companyId?._id);
                                }}
                              >
                                <Icon name="check-circle-cut"></Icon>
                                <span>Verify</span>
                              </Button>
                            </NavLink>
                          )}
                        </NavItem>

                        <NavItem>
                          <NavLink tag="div" href="#tab">
                            <Button
                              className="toggle btn-icon d-md-none"
                              color="primary"
                              onClick={() => {
                                toggleUploadModal();
                              }}
                            >
                              <Icon name="upload-cloud"></Icon>
                            </Button>
                            <Button
                              className="toggle d-none d-md-inline-flex"
                              color="primary"
                              outline
                              onClick={() => {
                                toggleUploadModal();
                              }}
                            >
                              <Icon name="upload-cloud"></Icon> <span>Upload</span>
                            </Button>
                          </NavLink>
                        </NavItem>
                      </div>
                    </Nav>

                    <TabContent activeTab={activeIconTab}>
                      <TabPane tabId="1">
                        <div className="card-inner">
                          <Block>
                            <BlockHead>
                              <BlockTitle tag="h5">Company Details</BlockTitle>
                            </BlockHead>
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Company Name</span>
                                  <span className="profile-ud-value">{companyId?.companyName}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Registration Number</span>
                                  <span className="profile-ud-value">{companyId?.registrationNumber}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Company Domain</span>
                                  <span className="profile-ud-value">{companyId?.domain}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Address</span>
                                  <span className="profile-ud-value">{companyId?.address[0]?.address}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Country</span>
                                  <span className="profile-ud-value">
                                    {companyId?.address[0]?.country?.translations[0]?.countryTranslation?.name}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Province</span>
                                  <span className="profile-ud-value">
                                    {companyId?.address[0]?.state?.translations[0]?.stateTranslation?.name}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">City</span>
                                  <span className="profile-ud-value">
                                    {companyId?.address[0]?.city?.translations[0]?.cityTranslation?.name}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Zip code</span>
                                  <span className="profile-ud-value">{companyId?.address[0]?.zipcode}</span>
                                </div>
                              </div>
                            </div>
                          </Block>

                          <Block>
                            <BlockHead className="nk-block-head-line">
                              <BlockTitle tag="h6">Team</BlockTitle>
                            </BlockHead>

                            <table className="table table-orders">
                              <thead className="tb-odr-head">
                                <tr className="tb-odr-item">
                                  <th className="tb-odr-amount">
                                    <span className="tb-odr-total">Name</span>
                                  </th>
                                  <th className="tb-odr-amount">
                                    <span className="tb-odr-total">Role</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="tb-odr-body">
                                {companyId?.team?.slice(0, 1)?.map((item) => {
                                  return (
                                    <tr className="tb-odr-item" key={item._id}>
                                      <td className="tb-odr-amount">
                                        <span className="tb-odr-total">
                                          <Link to={`/hr/${item?.user?._id}`} className="amount text-primary">
                                            {item?.user?.fullName}
                                          </Link>
                                        </span>
                                      </td>
                                      <td className="tb-odr-amount">
                                        <span className="tb-odr-total">
                                          <span className="amount">{item.role}</span>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Block>

                          <Block>
                            <BlockHead className="nk-block-head-line">
                              <BlockTitle tag="h6">Documents</BlockTitle>
                            </BlockHead>

                            <table className="table table-orders">
                              <thead className="tb-odr-head">
                                <tr className="tb-odr-item">
                                  <th className="tb-odr-amount">
                                    <span className="tb-odr-total">File Name</span>
                                  </th>
                                  <th className="tb-odr-action">&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody id="document" className="tb-odr-body">
                                {companyId?.files?.map((item) => {
                                  return (
                                    <tr className="tb-odr-item" key={item._id}>
                                      <td className="tb-odr-amount">
                                        <span className="tb-odr-total">
                                          <span className="amount">{item.title}</span>
                                        </span>
                                      </td>
                                      <td className="tb-odr-action">
                                        <div className="tb-odr-btns d-none  d-flex" style={{ columnGap: "10px" }}>
                                          <div className="nk-upload-action">
                                            <a
                                              href="#delete"
                                              onClick={async (ev) => {
                                                ev.preventDefault();
                                                let formData = {
                                                  removeId: item?._id,
                                                  url: item?.url,
                                                };
                                                companyDocumentDeleteAlert(companyId?._id, formData);
                                              }}
                                              className="btn btn-icon btn-trigger"
                                            >
                                              <Icon name="trash"></Icon>
                                            </a>
                                          </div>

                                          <Button
                                            color="primary"
                                            className="btn-sm"
                                            onClick={() => {
                                              window?.open(`${process.env.REACT_APP_IMG_URL}${item?.url}`, "_blank");
                                            }}
                                          >
                                            View
                                          </Button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Block>
                          <Block>
                            {/* <BlockHead className="nk-block-head-line">
                              <BlockTitle tag="h6">Credits</BlockTitle>
                            </BlockHead> */}



                            <div className="tb-odr-head d-flex justify-content-between align-items-center px-1 py-2">
                              <div>Credits</div>
                              <div>Wallet: {companyId?.order?.wallet_credits ? companyId?.order?.wallet_credits : 0} credits</div>
                            </div>

                            <div className={`d-flex w-100 justify-content-end align-items-center my-2`}>
                              <button className={`btn-primary px-2 py-1 fs-3`} style={{ borderRadius: "15px" }} onClick={() => { setShowCreditModal(true) }}>Add Credits</button>
                            </div>


                            {/* <table className="table table-orders">
                              <thead className="tb-odr-head">
                                <tr className="tb-odr-item">
                                  <th className="tb-odr-amount">
                                    <span className="tb-odr-total">Credits</span>
                                  </th>
                                  <th className="tb-odr-action">&nbsp;</th>
                                  <th className="tb-odr-action">Wallet: 120 Credits</th>
                                </tr>
                              </thead>
                              <tbody id="document" className="tb-odr-body">
                                {companyId?.files?.map((item) => {
                                  return (
                                    <tr className="tb-odr-item" key={item._id}>
                                      <td className="tb-odr-amount">
                                        <span className="tb-odr-total">
                                          <span className="amount">{item.title}</span>
                                        </span>
                                      </td>
                                      <td className="tb-odr-action">
                                        <div className="tb-odr-btns d-none  d-flex" style={{ columnGap: "10px" }}>
                                          <div className="nk-upload-action">
                                            <a
                                              href="#delete"
                                              onClick={async (ev) => {
                                                ev.preventDefault();
                                                let formData = {
                                                  removeId: item?._id,
                                                  url: item?.url,
                                                };
                                                companyDocumentDeleteAlert(companyId?._id, formData);
                                              }}
                                              className="btn btn-icon btn-trigger"
                                            >
                                              <Icon name="trash"></Icon>
                                            </a>
                                          </div>

                                          <Button
                                            color="primary"
                                            className="btn-sm"
                                            onClick={() => {
                                              window?.open(`${process.env.REACT_APP_IMG_URL}${item?.url}`, "_blank");
                                            }}
                                          >
                                            View
                                          </Button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table> */}

                            <table className="table table-orders">
                              <thead className="">
                                <tr className="">
                                  <th className="">Date</th>
                                  <th className="">Transaction</th>
                                  <th className="">Admin ID</th>
                                </tr>
                              </thead>
                              <tbody id="document" className="tb-odr-body">
                                {companyId?.order?.OrderBy?.map((item) => {
                                  return (
                                    <tr className="tb-odr-item" key={item._id}>
                                      <td className="tb-odr-amount">
                                        <span className="tb-odr-total">
                                          <span className="amount">{moment(item?.date.split("T")[0]).format('DD MMMM YYYY')}</span>
                                        </span>
                                      </td>
                                      <td className="">
                                        <div className=" d-none  d-flex" style={{ columnGap: "10px" }}>
                                          <div className="">
                                            <span className={``} style={{ color: item?.type === 'credit' ? `green` : `red` }}>
                                              {item?.type === 'credit' ? `+ ${item?.creditaddedbyAdmin}` : `- ${item?.creditdeductedbyUser}`}
                                            </span>&nbsp;
                                            {item?.type === 'credit' ? `credits added to the wallet for ${item?.currency} ${item?.amount}` : `credits withdrawn from the wallet for ${item?.for?.title}`}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="">
                                        <div className=" d-none  d-flex" style={{ columnGap: "10px" }}>
                                          <div className="">
                                            {item?.adminId?.email}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Block>
                        </div>
                      </TabPane>
                    </TabContent>
                  </PreviewCard>
                </div>
              </div>
            </Card>
          </Block>
        </Content>
      ) : null}

      <Modal isOpen={uploadModal} size="md" toggle={toggleUploadModal}>
        <DocumentUploadModal toggle={toggleUploadModal} companyId={companyId} />
      </Modal>
      <CompanyCreditModal
        show={showCreditModal}
        onHide={() => setShowCreditModal(false)}
        companyId={id}
        hrName={companyId?.team[0]?.user?.fullName}
        hrEmail={companyId?.team[0]?.user?.email}
        hrId={companyId?.team[0]?.user?._id}
        companyName={companyId?.companyName}
      />
    </React.Fragment>
  );
};

export default CompanyById;
