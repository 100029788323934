import phone from "phone";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineChevronRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import country_splitter from "country-code-splitter";
/* eslint-disable */

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Form, FormGroup, Spinner } from "reactstrap";
import Logo from "../../assets/images/company/skatch.svg";
import Msg from "../../assets/lang/en.json";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import PageContainer from "../../layout/page-container/PageContainer";
import { sendOtp } from "../../redux/Action/authAction";
import { otpSend } from "../../redux/Apislices/authSlice";
import { getCookie } from "../../utils/storage";
import { validateEmail } from "../../utils/validation";
import AuthFooter from "./AuthFooter";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, success, error, user, token, isOtpSend } = useSelector((state) => state?.auth);

  // const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");

  const handleValidation = async (value) => {
    if (value?.includes("@")) {
      let checkValue = validateEmail(value);
      if (checkValue) {
        return { check: value, provider: "email" };
      } else {
        toast.error("Please enter valid email");
        return 0;
      }
    } else if (value?.includes("+")) {
      let checkValue = phone(value);
      let data = country_splitter?.getNumber(value);
      let cCode = data?.code;
      let number = data?.number;
      if (checkValue?.isValid) {


        if (data?.number.length < 10 || data?.number.length > 10) {

          toast.error("Please enter a phone number with this format: +20xxxxxxxxxx");
          return 0;
        }

        return { check: number, countryCode: cCode, provider: "mobile" };
        // return { check: value, provider: "mobile" };
      } else {
        toast.error("Please enter a phone number with this format: +20xxxxxxxxxx");

        // toast.error(Msg.enterValidMobile)
        return 0;
      }
    } else {
      if (+value) {
        toast.error("Invalid country code");
        return 0;
      }
      toast.error("Invalid format");
    }
  };

  const onFormSubmit = async (formData) => {
    let username = formData?.name?.split(" ")?.join("");

    const validData = await handleValidation(username);
    if (validData) {
      dispatch(sendOtp(validData));
    }
  };

  const { errors, register, handleSubmit } = useForm();

  useEffect(() => {
    if (isOtpSend) {
      navigate("/verify-otp");
    }
    dispatch(otpSend(false));
    return () => { };
  }, [isOtpSend, dispatch]);

  useEffect(() => {
    if (getCookie("_jwt")) {
      navigate("/");
    }
    return () => { };
  }, []);

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Sign-In</BlockTitle>

              </BlockContent>
            </BlockHead>
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Phone Number / Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="name"
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your Phone Number or Email"
                    className="form-control-lg form-control"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </FormGroup>

              <FormGroup>
                <Button size="lg" className="btn-block" type="submit" style={{ background: "#d56c33", color: "#fff" }}>
                  {loading ? (
                    <Spinner size="sm" color="light" />
                  ) : (
                    <>
                      Send Otp{" "}
                      <MdOutlineChevronRight
                        style={{
                          fontSize: "25px",
                          marginTop: "-2px",
                        }}
                      />
                    </>
                  )}
                </Button>
              </FormGroup>
            </Form>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
