import { toast } from "react-toastify";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";
import { getCookie } from "../../utils/storage";
import { isJobByIdStatusSuccess, isJobByIdSuccess, isJobError, isJobLoading, isJobSuccess } from "../Apislices/jobSlice";

export const getAllJobs = (query) => async (dispatch) => {
  try {
    dispatch(isJobLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/job/list`, query);
    if (response?.success) {
      dispatch(isJobSuccess({ data: response?.data, total: response?.total }));
    } else {
      dispatch(isJobError(response?.message));
    }
  } catch (error) {
    dispatch(isJobError(error?.message));
  }
};

export const getJobById = (id) => async (dispatch) => {
  try {
    dispatch(isJobLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/get/${id}/job`);
    if (response?.success) {
      dispatch(isJobByIdSuccess(response?.data));
    } else {
      dispatch(isJobError(response?.message));
    }
  } catch (error) {
    dispatch(isJobError(error?.message));
  }
};

export const deleteJob = (id, successCB, errorCB) => async (dispatch) => {
  try {
    setAuthorizationToken(getCookie("_jwt"));
    const response = await deleteMethod(`/admin/remove/${id}/job`);
    if (response?.success) {
      successCB(response);
    } else {
      errorCB(response);
    }
  } catch (error) { }
};

export const editJob = (id, data, successCB, errorCB) => async (dispatch) => {
  try {
    dispatch(isJobLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await putMethod(`/admin/update/${id}/job`, data);
    if (response?.success) {
      dispatch(isJobByIdSuccess(response?.data));
      successCB(response);
    } else {
      errorCB(response);
    }
  } catch (error) { }
};

export const editJobStatus = (id, data, successCB, errorCB) => async (dispatch) => {
  try {
    dispatch(isJobLoading());
    setAuthorizationToken(getCookie("_jwt"));

    const response = await putMethod(`/admin/update-job-status/${id}`, data);
    if (response?.success) {
      dispatch(isJobByIdStatusSuccess());
      successCB(response);
    } else {
      errorCB(response);
    }
  } catch (error) { }
};


export const editTitleAndDescriptionApi = (id, data, successCB, errorCB) => async (dispatch) => {


  try {
    dispatch(isJobLoading());
    setAuthorizationToken(getCookie("_jwt"));

    const response = await putMethod(`/admin/job/${id}/edit`, data);
    if (response?.success) {
      dispatch(isJobByIdSuccess(response?.data));
      successCB(response);
    } else {
      errorCB(response);
    }
  } catch (error) { }
};
