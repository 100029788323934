import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  job: null,
  total: 0,
  jobId: null,
};

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    isJobLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isJobSuccess: (state, action) => {
      state.loading = false;
      state.job = action.payload.data;
      state.total = action.payload.total;
      state.success = true;
      state.error = false;
    },
    isJobByIdSuccess: (state, action) => {
      state.loading = false;
      state.jobId = action.payload;
      state.success = true;
      state.error = false;
    },
    isJobByIdStatusSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isJobError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export default jobSlice.reducer;

export const { isJobByIdStatusSuccess, isJobByIdSuccess, isJobError, isJobLoading, isJobSuccess } = jobSlice.actions;
