import classNames from "classnames";
import React from "react";
import Logo from "../../assets/images/company/skatch.svg";

import Toggle from "../sidebar/Toggle";

import User from "./dropdown/user/User";

const Header = ({ fixed, theme, className, setVisibility, ...props }) => {
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });
  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none ml-n1"
              icon="menu"
              click={props.sidebarToggle}
            />
          </div>
          <div className="nk-header-brand d-xl-none">
            <img src={Logo} alt="skatch_logo" width={125} />
            {/* <Logo /> */}
          </div>
          <div className="nk-header-news d-none d-xl-block">
            <img src={Logo} alt="skatch_logo" width={125} />
            {/* <News /> */}
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="user-dropdown" onClick={() => setVisibility(false)}>
                <User />
              </li>
              {/* <li className="notification-dropdown mr-n1" onClick={() => setVisibility(false)}>
                <Notification />
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
