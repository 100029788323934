import { toast } from "react-toastify";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";
import { getCookie } from "../../utils/storage";
import { isHashtagError, isHashtagLoading, isHashtagSuccess, getHashtagById, getHashtagList, getJobTypeList } from "../Apislices/hashtagSlice";


export const asyncgetAllJobTypes = () => async (dispatch) => {
    try {
        dispatch(isHashtagLoading());
        setAuthorizationToken(getCookie("_jwt"));
        const response = await getMethod(`/job-types/list`);
        if (response?.success) {
            dispatch(isHashtagSuccess());
            dispatch(
                getJobTypeList({
                    data: response?.data,

                })
            );

        } else {
            dispatch(isHashtagError(response?.message));

        }
    } catch (error) {
        dispatch(isHashtagError(error?.message));
    }
};
export const asyncgetAllHashtags = (page, limit) => async (dispatch) => {
    try {
        dispatch(isHashtagLoading());
        setAuthorizationToken(getCookie("_jwt"));
        const response = await getMethod(`/admin/get/list/hashtag?page=${page}&limit=${limit}`);
        if (response?.success) {
            dispatch(isHashtagSuccess());
            dispatch(
                getHashtagList({
                    data: response?.data,
                    total: response?.total,
                })
            );
        } else {
            dispatch(isHashtagError(response?.message));
        }
    } catch (error) {
        dispatch(isHashtagError(error?.message));
    }
};


export const asyncGetHashtagById = (id) => async (dispatch) => {
    try {
        dispatch(isHashtagLoading());
        setAuthorizationToken(getCookie("_jwt"));
        const response = await getMethod(`/admin/get/${id}/hashtag`);
        if (response?.success) {
            dispatch(isHashtagSuccess());
            dispatch(getHashtagById(response?.data));
        } else {
            dispatch(isHashtagError(response?.message));
        }
    } catch (error) {
        dispatch(isHashtagError(error?.message));
    }
};

export const asyncAddHashtag = (data) => async (dispatch) => {

    let response = await postMethod(`/admin/create/hashtag`, data);
    if (response?.success) {
        dispatch(isHashtagSuccess());
    } else {
        dispatch(isHashtagError(response?.message));
    }
    return response

};

export const asyncDeleteHashtag = (id) => async (dispatch) => {
    try {
        let response = await deleteMethod(`/admin/delete/${id}/hashtag`);
        if (response?.success) {
            dispatch(isHashtagSuccess());
            toast.success(response?.message);
        } else {
            dispatch(isHashtagError(response?.message));
            toast.error(response?.message);
        }

        return response
    } catch (error) {
        dispatch(isHashtagError(error?.message));
        toast.error(error?.message);
    }
};

export const asyncEditHashtag = (id, data) => async (dispatch) => {
    try {
        let response = await putMethod(`/admin/update/${id}/hashtag`, data);
        if (response?.success) {
            dispatch(isHashtagSuccess());
            toast.success(response?.message);
        } else {
            dispatch(isHashtagError(response?.message));
        }
        return response
    } catch (error) {
        dispatch(isHashtagError(error?.message));
    }
};
