import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  user: null,
  token: null,
  isOtpSend: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },

    isSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    otpSend: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.isOtpSend = action.payload;
    },

    login: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.token = action.payload;
    },

    getUser: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.user = action.payload;
    },

    logout: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.token = null;
      state.user = null;
    },
    isError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = true;
    },
  },
});

export default authSlice.reducer;

export const { isLoading, isSuccess, isError, otpSend, login, getUser, logout } = authSlice.actions;
