import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import styles from "./style/feed.module.scss";
import { Link } from "react-router-dom";

const AddPosts = () => {
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(`/${path}`);
  };

  const handleDownload = () => {
    const downloadUrl = "/csv-template/post.xlsx";
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "template.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <React.Fragment>
      <Head title="Add Posts"></Head>
      <Content>
        <Breadcrumb className="breadcrumb-arrow">
          <BreadcrumbItem>
            <Link to={"/feed"}>Feed</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link>Add Posts</Link>
          </BreadcrumbItem>
        </Breadcrumb>
        <section className="mt-5 px-5 py-5 bg-white rounded border border-1">
          <div>
            <ol type="1" style={{ fontSize: "16px", listStyle: "block" }}>
              <li>Get links for all your images/videos/files/audios by uploading them</li>
              <li>
                Upload XLS in the following template
                <button
                  onClick={handleDownload}
                  className={clsx(styles.btn)}
                  style={{ color: "green", fontWeight: "bold", border: "none", background: "transparent" }}
                >
                  Download Template{"  >>"}
                </button>
              </li>
            </ol>
          </div>

          <div className="row " style={{ margin: "100px 0px" }}>
            <div className="col-sm-4">
              <div className="d-flex ">
                <Button
                  outline
                  color="primary"
                  onClick={(e) => {
                    handleClick("get-links");
                  }}
                  className={clsx(styles.feedBtn, "mt-sm-0 mt-3 py-3 d-flex justify-content-center ")}
                >
                  Get Links
                </Button>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="d-flex ">
                <Button
                  outline
                  color="primary"
                  onClick={(e) => {
                    handleClick("upload-xls");
                  }}
                  className={clsx(styles.feedBtn, "mt-sm-0 mt-3 py-3 d-flex justify-content-center ")}
                >
                  Upload XLS
                </Button>
              </div>
            </div>
          </div>
        </section>
      </Content>
    </React.Fragment>
  );
};

export default AddPosts;
