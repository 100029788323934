import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Breadcrumb, BreadcrumbItem, Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";

import styles from "../../style/feed.module.scss";

const ModrateWorld = () => {
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(`/${path}`);
  };
  return (
    <>
      <React.Fragment>
        <Head title="Skatch Moderator Panel"></Head>
        <Content>
          <Breadcrumb className="breadcrumb-arrow">
            <BreadcrumbItem>
              <Link to={"/feed"}>Feed</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a>Moderate World</a>
            </BreadcrumbItem>
          </Breadcrumb>

          <div
            className="row m-0 bg-white py-5 rounded border border-1 justify-content-center"
            style={{ height: "80vh" }}
          >
            <div className="col-sm-4  col-10  ">
              <div style={{ height: "100%" }} className=" d-flex justify-content-center align-items-center">
                <Button
                  outline
                  color="primary"
                  onClick={(e) => {
                    handleClick("post-list");
                  }}
                  className={clsx(styles.feedBtn, "w-100 mt-sm-0 mt-3 py-3 d-flex justify-content-center")}
                >
                  Post List
                </Button>
              </div>
            </div>
            <div className="col-sm-4  col-10  ">
              <div style={{ height: "100%" }} className="d-flex justify-content-center align-items-center">
                <Button
                  outline
                  color="primary"
                  onClick={(e) => {
                    handleClick("comment-list");
                  }}
                  className={clsx(styles.feedBtn, "w-100 mt-sm-0 mt-3 py-3 d-flex justify-content-center")}
                >
                  Comment List
                </Button>
              </div>
            </div>
          </div>
        </Content>
      </React.Fragment>
    </>
  );
};

export default ModrateWorld;
