import clsx from 'clsx'
import React from 'react'

const IconPause = (props) => {
  const { className, ...rest } = props

  return (
    <svg className={clsx('svgIcon', className)} xmlns="http://www.w3.org/2000/svg" width="9.988" height="10.003" viewBox="0 0 9.988 10.003" {...rest}>
      <path d="M5.919,14.923a.949.949,0,0,0,.285.7,1.025,1.025,0,0,0,.713.3h2a.975.975,0,0,0,.713-.3.9.9,0,0,0,.285-.7v-8A1,1,0,0,0,9.628,6.2a.893.893,0,0,0-.713-.285h-2A1,1,0,0,0,6.2,6.2a.893.893,0,0,0-.285.713Zm5.992,0a.924.924,0,0,0,.3.7,1.048,1.048,0,0,0,.7.3h2a.975.975,0,0,0,.713-.3.9.9,0,0,0,.285-.7v-8A1,1,0,0,0,15.62,6.2a.893.893,0,0,0-.713-.285h-2a.949.949,0,0,0-.7.285,1.025,1.025,0,0,0-.3.713Z" transform="translate(-5.918 -5.918)" fill="currentColor" />
    </svg>

  )
}

export default IconPause