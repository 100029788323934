import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    success: false,
    error: false,
    postList: [],
    postListId: {},
    total: 0,
};

export const postListSlice = createSlice({
    name: "postListSlice",
    initialState,
    reducers: {

        getPostList: (state, action) => {
            state.loading = false;
            state.postList = action.payload.data;
            state.total = action.payload.total;
            state.success = true;
            state.error = false;
        },
        getPostListById: (state, action) => {
            state.loading = false;
            state.postListId = action.payload;
            state.total = action.payload.total;
            state.success = true;
            state.error = false;
        },

        blockUser: (state) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },

        removePost: (state) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },
        isPostListLoading: (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
            state.postList = [];
        },
        isPostListSuccess: (state) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },
        isPostListError: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
            state.postList = [];
        },
    },
});

export default postListSlice.reducer;

export const { getPostList, getPostListById, blockUser, removePost, isPostListLoading, removeHashtag, isPostListSuccess, isPostListError } =
    postListSlice.actions;
