export const validateEmail = (value) => {
  let testCase = /\S+@\S+\.\S+/;
  return testCase.test(value);
};
export const validatePhone = (value) => {
  return value.match(/^\d+$/);
};

export const slugify = (string) => {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
};
