import React, { useEffect, useState } from 'react'
import styles from './companyCreditModal.module.scss'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ImCross } from "react-icons/im";
import { addCreditsToCompany, sendOtpForAddingCredits, verifyOtpForAddingCredits, uploadTransactionInvoice, deleteInvoiceFile, } from '../../redux/Action/companyAction';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import OtpInput from "react-otp-input";
import { getSession } from '../../utils/storage';
import { FormGroup } from "reactstrap";
import { anotherRecOtpSend } from '../../pages/jobs/PostJob/middleware/postAction';
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from 'react-redux';
import { uploadData } from '../../utils/awsServices';
import { IoClose } from "react-icons/io5";

function CompanyCreditModal(props) {
    const [screen, setScreen] = useState(1)
    const [status, setStatus] = useState("unset")
    const [resAmount, setResAmount] = useState(0)
    const [showOTPScreen, setShowOTPScreen] = useState(false)
    const [otp, setOtp] = useState("")
    const [otpData, setOtpData] = useState(getSession("_init"))
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state?.auth);
    const [creditsData, setCreditsData] = useState({
        companyId: props?.companyId,
        credit: 0,
        currency: "EGP",
        amount: 0,
        transactionId: "",
        invoiceURL: ""
    })
    useEffect(() => {
        setScreen(1)
        setStatus("unset")
        setCreditsData({
            companyId: props?.companyId,
            companyName: props?.companyName,
            hrId: props?.hrId,
            credit: 0,
            currency: "EGP",
            amount: 0,
            transactionId: "",
            invoiceURL: ""
        })
    }, [props?.show])
    const handleChange = (e, type) => {
        setCreditsData(prev => {
            return { ...prev, [type]: e.target.value }
        })
    }

    const [submitLoading, setSubmitLoading] = useState(false)
    const [otpResponse, setOtpResponse] = useState({})

    const handleAddCredits = async (data) => {
        setSubmitLoading(true)
        if (creditsData.amount < 0) {
            toast.error("Please enter valid amount")
            setSubmitLoading(false)
        } else if (creditsData.credit < 0) {
            toast.error("Please enter valid credits")
            setSubmitLoading(false)
        } else if (creditsData.transactionId == '') {
            toast.error("Please enter valid Transaction ID")
            setSubmitLoading(false)
        } else if (creditsData.invoiceURL == '') {
            toast.error("Please Upload Invoice")
            setSubmitLoading(false)
        } else {
            const otpData = { check: user?.email, provider: "email" }
            const otpRes = await dispatch(sendOtpForAddingCredits(otpData))
            if (otpRes.success) {
                setOtpResponse(otpRes)
                toast.success("OTP Send Successfully")
                setShowOTPScreen(true)
            }
            setSubmitLoading(false)
        }
    }

    const [loading, setLoading] = useState(false)

    const onUploadInvoice = async (e) => {

        setLoading(true)
        let fileGenerate = await dispatch(uploadTransactionInvoice({
            mimeType: e.target.files[0].type,
            name: e.target.files[0].name.replace(/\.[^/.]+$/, ""),
        }))

        if (fileGenerate?.success && fileGenerate?.data) {
            let preUrl = fileGenerate?.data;

            const formData = new Blob([e.target.files[0]], { type: e.target.files[0]?.type });

            // const toastId = toast.loading(`${locale === "ar" ? "...برجاء الإنتظار" : "Please wait..."}`);
            let fileUploadAws = await uploadData(preUrl, formData, e.target.files[0]?.type);
            if (fileUploadAws) {
                let localPath = fileGenerate?.data?.split("?")[0].substring(
                    fileGenerate?.data?.split("?")[0]?.lastIndexOf(".com") + 5)
                setCreditsData(prev => {
                    return { ...prev, invoiceURL: localPath }
                })
            }
        }
        setLoading(false)
    }

    const deleteFile = async (url) => {
        let del = await dispatch(deleteInvoiceFile({ url: url }))
    }

    useEffect(() => {

        if (props?.show == false) {
            if (creditsData?.invoiceURL != '' && screen != 2) {
                deleteFile(creditsData?.invoiceURL)
            }
        }

    }, [props?.show])

    const onFormSubmit = async (e) => {
        e.preventDefault();
        if (!otp) {
            toast.warn("Enter Valid OTP");
            return 0;
        }
        if (otp.length != 4) {
            toast.warn("Enter Valid OTP");
            return 0;
        }
        // let result = await anotherRecOtpSend({ check: user?.email, provider: "email" });
        var data = {
            verificationKey: otpResponse?.data,
            check: user?.email,
            otp: otp,
            ProviderValue: "email",
        };
        const res = await dispatch(verifyOtpForAddingCredits(data));
        if (res.success) {
            const resp = await dispatch(addCreditsToCompany(creditsData))
            if (resp.success) {
                toast.success("Credits Added")
                setScreen(2)
                setStatus("success")
                setShowOTPScreen(false)
                setResAmount(resp?.data?.OrderBy[resp?.data?.OrderBy.length - 1]?.creditaddedbyAdmin)
            } else {
                setStatus("failed")
            }
        }
    }
    const handleRefresh = () => {
        window.location.reload()
    }
    const handleReset = () => {
        const input = document.getElementById('invoiceInput');
        input.value = ''
        setCreditsData(prev => {
            return { ...prev, invoiceURL: "" }
        })
        if (creditsData?.invoiceURL != '') {
            deleteFile(creditsData?.invoiceURL)
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className='w-100 d-flex flex-column p-0'>
                <div className={`w-100 d-flex justify-content-end h-auto`}>
                    <ImCross onClick={props.onHide} style={{ cursor: "pointer" }} />
                </div>
                <Modal.Title id="contained-modal-title-vcenter" className={` d-flex w-100 justify-content-center`}>
                    Add credits for {props?.companyName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={`d-flex flex-column justify-content-start align-items-start ${styles.modalBody}`}>
                {showOTPScreen === false && <>
                    <p>HR : {props?.hrName}</p>
                    <p>HR Email: {props?.hrEmail}</p>
                </>}
                {
                    showOTPScreen === true ? (
                        <>
                            <p>OTP Sent to: {user?.email}</p>
                            <form className="is-alter" onSubmit={onFormSubmit}>
                                <FormGroup>
                                    <div className="form-label-group">
                                        <label className="form-label" htmlFor="default-01">
                                            Enter OTP
                                        </label>
                                    </div>
                                    <div className="form-control-wrap">
                                        <OtpInput
                                            value={otp}
                                            onChange={(e) => setOtp(e)}
                                            numInputs={4}
                                            BlockHead
                                            name="otp"
                                            separator={<span>&nbsp;&nbsp;</span>}
                                            inputStyle="otpIntputStyle"
                                            containerStyle="containerStyle1"
                                            shouldAutoFocus
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Button size="lg" className="btn-block" type="submit" style={{ background: "#d56c33", color: "#fff" }}>
                                        Verify OTP
                                    </Button>
                                </FormGroup>
                            </form>
                        </>
                    ) : (
                        <>
                            {
                                screen === 1 ? (<>
                                    <div className={`d-flex justify-content center align-items-center`}>
                                        <label>Number of Credits</label>
                                        <input type='number' value={creditsData.credit} onChange={(e) => { handleChange(e, "credit") }}></input>
                                    </div>
                                    <div className={`d-flex justify-content center align-items-center`}>
                                        <label>Amount (Cost)</label>
                                        <select style={{ minHeight: "30px", marginRight: "15px", borderRadius: "10px" }} value={creditsData.currency} onChange={(e) => { handleChange(e, "currency") }}>
                                            <option value="EGP">EGP</option>
                                            <option value="INR">INR</option>
                                            <option value="USD">USD</option>
                                            <option value="EUR">EUR</option>
                                            <option value="GBP">GBP</option>
                                        </select>
                                        <input value={creditsData.amount} style={{ maxWidth: "110px" }} onChange={(e) => { handleChange(e, "amount") }}></input>
                                    </div>
                                    <div className={`d-flex justify-content center align-items-center`}>
                                        <label>Enter Transaction ID</label>
                                        <input value={creditsData.transactionId} onChange={(e) => { handleChange(e, "transactionId") }}></input>
                                    </div>
                                    <div className={`d-flex justify-content center align-items-center`}>
                                        <label>Invoice</label>
                                        <input id='invoiceInput' className='btn btn-primary border w-auto' placeholder='upload' type='file' onChange={(e) => { onUploadInvoice(e) }}></input>
                                        {creditsData?.invoiceURL != '' && loading == false && < span style={{ cursor: "pointer", marginLeft: "8px" }} onClick={() => { handleReset() }} className='text-danger pointer fw-bold ms-2'>
                                            <IoClose color='red' />
                                        </span>}
                                        {
                                            loading && <span style={{ marginLeft: "8px", fontWeight: "bold" }} className={``}>Uploading</span>
                                        }
                                    </div>
                                </>) : screen === 2 ? (<>
                                    {
                                        status === 'success' ? (<div className='w-100 d-flex justify-content-center align-items-center fs-3 fw-bold'>
                                            <FaCheckCircle style={{ color: "green", height: "30px", width: "30px" }} />&nbsp; {resAmount} Credits added Successfully
                                        </div>) : status === 'failed' ? (<div className='w-100 d-flex justify-content-center align-items-center fs-3 fw-bold'>
                                            <MdCancel style={{ color: "red", height: "30px", width: "30px" }} />&nbsp; Unable to add credits. Please try later.
                                        </div>) : null
                                    }
                                    {
                                        status === 'success' && <div className='d-flex w-100 justify-content-center align-items-center'>
                                            <Button className='btn btn-primary rounded' onClick={handleRefresh}>Reload to update</Button>
                                        </div>
                                    }
                                </>) : null
                            }
                        </>
                    )
                }
            </Modal.Body>
            {
                status !== 'success' ? (
                    <>
                        {
                            screen === 1 && showOTPScreen === false ? (
                                <Modal.Footer>
                                    <Button disabled={submitLoading} className='btn btn-success px-5' onClick={handleAddCredits}>Add Credits</Button>
                                </Modal.Footer>
                            ) : screen === 2 ? (
                                <Modal.Footer>
                                    <Button className='btn btn-primary rounded' onClick={props.onHide}><IoIosArrowBack /> Back to the Company</Button>
                                </Modal.Footer>
                            ) : null
                        }
                    </>
                ) : null
            }
        </Modal >
    )
}

export default CompanyCreditModal