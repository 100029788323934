import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  user: null,
  total: 0,
  userId: null,
  userJobs: null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    isUserLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isUserSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload.data;
      state.total = action.payload.total;
      state.success = true;
      state.error = false;
    },
    isUserByIdSuccess: (state, action) => {
      state.loading = false;
      state.userId = action.payload;
      state.success = true;
      state.error = false;
    },
    isUserByIdJobsSuccess: (state, action) => {
      state.loading = false;
      state.userJobs = action.payload;
      state.success = true;
      state.error = false;

    },
    isUserError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export default userSlice.reducer;

export const { isUserByIdJobsSuccess, isUserByIdSuccess, isUserError, isUserLoading, isUserSuccess } = userSlice.actions;
