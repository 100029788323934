import clsx from "clsx";
import React, { useRef, useState } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Button } from "reactstrap";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import styles from "./style/feed.module.scss";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../components/Component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { asyncUploadCSV } from "../../redux/Action/getLinksAction";
import { resetCsvBackButton } from "../../redux/Apislices/getLinksSlice";
import OtpCsvModal from "./OtpCsvModal";
import { sendOtp, verifyOtp } from "../../redux/Action/authAction";
import { getSession } from "../../utils/storage";
import { toast } from "react-toastify";
import Msg from "../../assets/lang/en.json";

const UploadCsv = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  const [otpData, setOtpData] = useState({});

  const { loading, success } = useSelector((state) => state?.getLinksSlice);
  const [csvFile, setCsvFile] = useState("");
  const [csvFilePost, setCsvFilePost] = useState("");
  const [onCsvOtpModal, setOnCsvOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(`/${path}`);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files[0];
    setCsvFilePost(files);
    setCsvFile(files?.name);
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleAddedCsv = async (csvFilePost) => {
    let res = await dispatch(asyncUploadCSV(csvFilePost));
    if (res?.success) {
      setOnCsvOtpModal(false);
      toast.success("Your post has been Added");
    } else {
      toast.error(res?.message);
    }
  };

  const inactivePostAlert = () => {
    Swal.fire({
      title: "Are you sure the template is correct?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Check Again",
      confirmButtonText: "Confirm",
      customClass: {
        actions: "swal2-actions", // Apply the custom class to the actions container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (user?.email) {
          const validData = { check: user?.email, provider: "email" };
          let res = await dispatch(sendOtp(validData));
          let data = getSession("_init");
          setOtpData(data);
          if (res?.success) {
            setOnCsvOtpModal(true);
          }
        } else {
          const validData = { check: user?.mobile, countryCode: user?.countryCode, provider: "mobile" };
          let res = await dispatch(sendOtp(validData));
          let data = getSession("_init");
          setOtpData(data);
          if (res?.success) {
            setOnCsvOtpModal(true);
          }
        }
      } else {
        setCsvFile("");
      }
    });
  };

  const handleCsvOtpFlow = async (e) => {
    e.preventDefault();

    if (!otp) {
      toast.warn(Msg.enterValidOtp);
      return 0;
    }
    if (otp.length !== 4) {
      toast.warn(Msg.enterValidOtp);
      return 0;
    }
    var data = {
      verificationKey: otpData?.key,
      check: otpData?.label,
      otp: otp,
      countryCode: otpData?.countryCode,
      ProviderValue: otpData?.provider,
    };
    let res = await dispatch(verifyOtp(data));
    if (res?.success) {
      handleAddedCsv(csvFilePost);
    }
  };

  const onFormCancel = () => {
    setOnCsvOtpModal(!onCsvOtpModal);
  };

  return (
    <React.Fragment>
      <Head title="Get Links"></Head>
      <Content>
        <Breadcrumb className="breadcrumb-arrow">
          <BreadcrumbItem>
            <Link to={"/feed"}>Feed</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={"/add-posts"}>Add Posts</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a>Upload XLS</a>
          </BreadcrumbItem>
        </Breadcrumb>
        <section className="  py-5 bg-white rounded border border-1">
          <div className="row justify-content-center" style={{ margin: "0px 0px" }}>
            {!success ? (
              <div className="col-sm-4 col-12">
                <div className="d-flex  justify-content-center">
                  <Button
                    outline
                    onClick={(e) => {
                      if (!csvFile) {
                        openFileInput(e);
                      } else {
                        inactivePostAlert(csvFile);
                      }
                    }}
                    color={csvFile.length > 0 ? "success" : "danger"}
                    style={{ fontSize: "16px", columnGap: "10px" }}
                    className={clsx(styles.feedBtn, "mt-sm-0 mt-3 py-3 w-100 d-flex justify-content-center")}
                  >
                    {csvFile ? <Icon name="property-add"></Icon> : <BsFillCloudUploadFill />}

                    {csvFile ? "Add Posts" : "Upload XLS"}
                  </Button>
                  {!csvFile ? (
                    <input type="file" ref={fileInputRef} onChange={handleFileUpload} style={{ display: "none" }} />
                  ) : null}
                </div>
              </div>
            ) : null}

            {success ? (
              <div className="col-sm-4 col-12">
                <div className="d-flex justify-content-center ">
                  <Button
                    outline
                    color={"danger"}
                    onClick={(e) => {
                      setCsvFile("");
                      dispatch(resetCsvBackButton());
                      handleClick("add-posts");
                    }}
                    style={{ fontSize: "16px", columnGap: "10px" }}
                    className={clsx(styles.feedBtn, "mt-sm-0 mt-3 py-3  d-flex justify-content-center")}
                  >
                    {success ? <Icon name="chevron-left"></Icon> : ""}
                    {success > 0 ? "Back To Add Posts " : ""}
                  </Button>
                </div>
              </div>
            ) : null}

            <div className="col-12">
              <p style={{ textAlign: "center", fontWeight: "bold" }} className="mt-3">
                {csvFile && !success
                  ? csvFile
                  : !csvFile && !success
                  ? "Please ensure right template is used here"
                  : success
                  ? "Post Added!"
                  : null}
              </p>
            </div>
          </div>
        </section>

        {/* csv otp modal */}
        <OtpCsvModal
          onCsvOtpModal={onCsvOtpModal}
          onFormCancel={onFormCancel}
          handleCsvOtpFlow={handleCsvOtpFlow}
          loading={loading}
          otp={otp}
          setOtp={setOtp}
          setCsvFile={setCsvFile}
        />
      </Content>
    </React.Fragment>
  );
};

export default UploadCsv;
