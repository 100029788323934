import React, { useEffect, useState } from "react";
import AddCountry from "../../components/CountryComponent/AddCountry";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,

  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  OverlineTitle,
  PreviewCard,
  Row,
  RSelect,
  Sidebar,
  UserAvatar,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  PaginationComponent,
} from "../../components/Component";
import Swal from "sweetalert2";
import Content from "../../layout/content/Content";

import { useDispatch } from "react-redux";
import styles from "./Style/CountryList.module.scss";

import clsx from "clsx";
import { deleteCountryFromList, getAllCountry, getCountryById } from "../../redux/Action/countryAction";
import { useSelector } from "react-redux";


const CountryList = () => {
  const { error, loading, success, country, countryId, total } = useSelector((state) => state?.country);


  const dispatch = useDispatch();

  const [itemPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const [idValue, setId] = useState("");
  const [editValue, setEditValue] = useState({
    code: "",
    countryAr: "",
    countryEn: "",
    shortSymbol: "",
  })


  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  ;
  const [type, setType] = useState("");
  const [view, setView] = useState({
    addCountry: false,
    editCountry: false,
  });

  const toggleModal = async (type) => {
    setView({
      addCountry: type === "addCountry" ? true : false,
      editCountry: type === "editCountry" ? true : false,
    });


  };

  // Delete Alert
  const deleteAlert = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {

        dispatch(deleteCountryFromList(id));
        Swal.fire("Deleted!", "Country has been deleted.", "success");
        dispatch(getAllCountry(type, currentPage, itemPerPage));

      }
    });
  };

  const editIntialValue = (id, countryObj) => {


    setEditValue({
      ...editValue,
      code: countryObj?.code,
      countryAr: countryObj?.translations[0]?.countryTranslation?.name,
      countryEn: countryObj?.translations[1]?.countryTranslation?.name,
      shortSymbol: countryObj?.shortSymbol
    })

  }

  useEffect(() => {
    dispatch(getAllCountry(type, currentPage, itemPerPage));
  }, [currentPage, itemPerPage, type, dispatch]);

  useEffect(() => {

    if (idValue) {
      dispatch(getCountryById(idValue));
      dispatch(getAllCountry(type, currentPage, itemPerPage));
    }

    return () => { };
  }, [idValue, dispatch]);



  return (
    <>
      <div className={clsx(styles.countrySection)}>
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  <strong
                    className="text-primary small"
                    style={{
                      fontSize: "2rem",
                      fontWeight: "700",
                    }}
                  >

                    Country
                  </strong>
                </BlockTitle>
                <BlockDes className="text-soft"></BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>


                <Button
                  className="toggle btn-icon d-md-none"
                  color="info"
                  onClick={() => {
                    toggleModal("addCountry");
                  }}
                >
                  <Icon name="edit"></Icon>
                </Button>
                <Button
                  className="toggle d-none d-md-inline-flex"
                  color="info"
                  onClick={() => {
                    toggleModal("addCountry");
                  }}
                >
                  <Icon name="edit"></Icon>
                  <span>Add Country</span>
                </Button>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          {/* country list  */}
          <Block>
            <Card className="card-bordered">
              <div className="card-inner-group">
                <div
                  className="card-inner p-0"
                  style={{
                    width: "100%",
                  }}
                >
                  {loading ? (
                    <div
                      style={{
                        height: "200px",
                        width: "100%",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >

                      <Spinner type="grow" color="dark" />
                    </div>
                  ) : error ? (
                    "something went wrong..."
                  ) : success ? (
                    <DataTableBody>
                      <DataTableHead>
                        <DataTableRow className="nk-tb-col-check">{/* <span>#</span> */}</DataTableRow>
                        <DataTableRow size="">
                          <span>Name</span>
                        </DataTableRow>

                        <DataTableRow size="md">
                          <span>Country Code</span>
                        </DataTableRow>

                        <DataTableRow size="md">
                          <span>Alpha Code </span>
                        </DataTableRow>

                        <DataTableRow size="md">

                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                      </DataTableHead>
                      <>
                        {country.length > 0 ? (
                          country.map((item, index) => {
                            return (
                              <DataTableItem key={item._id}>
                                <DataTableRow className="nk-tb-col-check"></DataTableRow>
                                <DataTableRow size="">
                                  <Link to={`/country/${item._id}`} className="tb-product">
                                    <span className="title">

                                      {`${item?.name} {${item?.translations[1].countryTranslation.name}}`}
                                    </span>
                                  </Link>
                                </DataTableRow>

                                <DataTableRow size="md">
                                  <span className="tb-sub">{item?.code}</span>
                                </DataTableRow>

                                <DataTableRow size="md">
                                  <span className="tb-sub">{item?.shortSymbol}</span>
                                </DataTableRow>

                                <DataTableRow className="nk-tb-col-tools">
                                  <ul className="nk-tb-actions gx-1 my-n1">
                                    <li className="mr-n1">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          tag="a"
                                          href="#more"
                                          onClick={(ev) => {
                                            ev.preventDefault();

                                          }}
                                          className="dropdown-toggle btn btn-icon btn-trigger"
                                        >
                                          <Icon name="more-h"></Icon>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                          <ul className="link-list-opt no-bdr">
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#remove"
                                                onClick={(ev) => {
                                                  ev.preventDefault();

                                                  setId(item._id);
                                                  editIntialValue(item?._id, item)
                                                  toggleModal("editCountry");
                                                }}
                                              >
                                                <Icon name="edit"></Icon>
                                                <span>Edit </span>
                                              </DropdownItem>
                                            </li>
                                            <li>
                                              <DropdownItem
                                                tag="a"
                                                href="#remove"
                                                onClick={(ev) => {
                                                  ev.preventDefault();

                                                  deleteAlert(item._id);
                                                }}
                                              >
                                                <Icon name="trash"></Icon>
                                                <span>Remove </span>
                                              </DropdownItem>
                                            </li>
                                          </ul>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </li>
                                  </ul>
                                </DataTableRow>
                              </DataTableItem>
                            );
                          })
                        ) : (
                          <div className="text-center">
                            <span className="text-silent">No Skills found</span>
                          </div>
                        )}
                      </>
                    </DataTableBody>
                  ) : null}
                  <div className="card-inner">
                    {country.length > 0 ? (
                      <PaginationComponent
                        itemPerPage={itemPerPage}
                        totalItems={total}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    ) : null}
                  </div>


                </div>
              </div>
            </Card>
          </Block>
          {/* add country modal  */}
          {view.addCountry ? (
            <AddCountry
              toggle={toggleModal}
              modal={view.addCountry}
              showName={"addCountry"}
              idValue={idValue}
              currentPage={currentPage}
              itemPerPage={itemPerPage}
              countryId={countryId}
              editValue={editValue}
            />
          ) : null}

          {/* update modal */}
          {view.editCountry ? (
            <AddCountry
              toggle={toggleModal}
              modal={view.editCountry}
              showName={"editCountry"}
              idValue={idValue}
              currentPage={currentPage}
              itemPerPage={itemPerPage}
              countryId={countryId}
              editValue={editValue}
            />
          ) : null}
        </Content>
      </div>
    </>
  );
};

export default CountryList;
