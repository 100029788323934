import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    success: false,
    error: false,
    actionByHrList: [],
    total: 0,
};

export const actionByHrSlice = createSlice({
    name: "actionByHrSlice",
    initialState,
    reducers: {

        getActionByHrList: (state, action) => {
            state.loading = false;
            state.actionByHrList = action.payload.data;
            state.total = action.payload.total;
            state.success = true;
            state.error = false;
        },

        isActionByHrListLoading: (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
            state.actionByHrList = [];
        },
        isActionByHrListSuccess: (state) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },
        isActionByHrListError: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
            state.actionByHrList = [];
        },
    },
});

export default actionByHrSlice.reducer;

export const { getActionByHrList, isActionByHrListLoading, isActionByHrListSuccess, isActionByHrListError } =
    actionByHrSlice.actions;
