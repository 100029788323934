import React from "react";
import { BlockBetween, BlockHead, BlockHeadContent, BlockTitle } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import styles from "./style/feed.module.scss";
import { useSelector } from "react-redux";
import { postMethod } from "../../utils/apiServices";
import { toast } from "react-toastify";

const Feed = () => {
  const { user } = useSelector((state) => state?.auth);

  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(`/${path}`);
  };


  // const uploadSheet = async (event) => {
  //   try {
  //     const file = event.target.files[0];
  //     const formData = new FormData();
  //     formData.append('file', file);

  //     let response = await postMethod(`admin/upload/excel/profile/update`, formData);

  //     if (response?.success) {

  //       toast.success("Uploaded Successfully")
  //     } else {
  //       toast.error("There is an error");

  //     }

  //   } catch (error) {

  //   }
  // }
  return (
    <>
      <React.Fragment>
        <Head title="Skatch Moderator Panel"></Head>
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Welcome {user?.fullName}
                </BlockTitle>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <div className="row m-0 bg-white py-5 rounded border border-1 justify-content-center">
            <div className="col-sm-4  col-10  ">
              <div className="d-flex justify-content-center">
                <Button
                  outline
                  color="primary"
                  onClick={(e) => {
                    handleClick("add-posts");
                  }}
                  className={clsx(styles.feedBtn, "mt-sm-0 mt-3 py-3 d-flex justify-content-center ")}
                >
                  Add Posts{" "}
                </Button>
              </div>
            </div>
            <div className="col-sm-4  col-10  ">
              <div className="d-flex justify-content-center">
                <Button
                  outline
                  color="primary"
                  onClick={(e) => {
                    handleClick("moderate-world");
                  }}
                  className={clsx(styles.feedBtn, "mt-sm-0 mt-3 py-3 d-flex justify-content-center")}
                >
                  Moderate World
                </Button>
              </div>
            </div>
            <div className="col-sm-4  col-10  ">
              <div className="d-flex justify-content-center">
                <Button
                  outline
                  color="primary"
                  onClick={(e) => {
                    handleClick("hashtags");
                  }}
                  className={clsx(styles.feedBtn, "mt-sm-0 mt-3 py-3 d-flex justify-content-center")}
                >
                  Update Hashtags
                </Button>
              </div>
            </div>
            {/* <div className="col-12">
              <div
                className="d-flex align-items-center justify-content-start py-3 border border-primary my-2 px-2"
                style={{
                  width: "fit-content"
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginRight: "15px",
                    color: "#6576ff"
                  }}
                >
                  Upload Sheet for Profile Update:
                </span>
                <input
                  type="file"
                  onChange={uploadSheet}
                  className={clsx(styles.feedBtn, " w-auto btn btn-primary")}
                  placeholder="Upload Profile Update Sheet"
                />
              </div>
            </div> */}
          </div>
        </Content>
      </React.Fragment>
    </>
  );
};

export default Feed;
