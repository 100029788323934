import clsx from "clsx";
import React, { useRef, useState } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Button } from "reactstrap";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import styles from "./style/feed.module.scss";
import { AiFillCopy } from "react-icons/ai";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../components/Component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { asyncUpdateStatusPost } from "../../redux/Action/getLinksAction";
import { resetFileBackButton } from "../../redux/Apislices/getLinksSlice";
import { toast } from "react-toastify";

const GetLinks = () => {
  const dispatch = useDispatch();
  const { fileLinkList, fileTotal } = useSelector((state) => state?.getLinksSlice);
  const [fileSizeBig, setFileSizeBig] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [animate, setAnimate] = useState(false);

  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(`/${path}`);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    setTotalFiles(files?.length);
    let bigFile = [];
    let smallFile = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSize = file.size; // File size in bytes

      // Convert the file size to megabytes
      const fileSizeInMB = fileSize / (1024 * 1024);
      if (fileSizeInMB > 20) {
        bigFile.push(file);
      } else {
        smallFile.push(file);
      }
    }

    await dispatch(asyncUpdateStatusPost(smallFile));
    setFileSizeBig(bigFile);
  };

  const handleCopyAll = () => {
    const links = fileLinkList.map((file) => {
      // Generate link for each selected file
      return file;
    });

    copyToClipboard(links.join("\n"));

    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 1000);
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          toast.success("Copied All!");
        })
        .catch((error) => {
          toast.error("Failed to copy:", error);
        });
    } else {
      const tempInputElement = document.createElement("textarea");
      tempInputElement.value = text;
      document.body.appendChild(tempInputElement);
      tempInputElement.select();
      document.execCommand("copy");
      document.body.removeChild(tempInputElement);

      toast.success("Copied All!");
    }
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <React.Fragment>
      <Head title="Get Links"></Head>
      <Content>
        <Breadcrumb className="breadcrumb-arrow">
          <BreadcrumbItem>
            <Link to={"/feed"}>Feed</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={"/add-posts"}>Add Posts</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <a>Get Links</a>
          </BreadcrumbItem>
        </Breadcrumb>
        <section className="  py-5 bg-white rounded border border-1">
          <div className="row justify-content-center" style={{ margin: "0px 0px" }}>
            <div className="col-sm-4 col-12">
              <div className="d-flex  justify-content-center">
                <Button
                  outline
                  onClick={(e) => {
                    if (fileLinkList.length) {
                      handleCopyAll(e);
                    } else openFileInput(e);
                  }}
                  color={fileLinkList.length > 0 ? "success" : "danger"}
                  style={{ fontSize: "16px", columnGap: "10px" }}
                  className={clsx(styles.feedBtn, "mt-sm-0 mt-3 py-3 w-100 d-flex justify-content-center")}
                >
                  {fileLinkList.length > 0 ? (
                    <AiFillCopy
                      className={clsx({
                        [styles.animate]: animate,
                      })}
                    />
                  ) : (
                    <BsFillCloudUploadFill />
                  )}
                  {fileLinkList.length > 0 ? "Copy All" : "Upload Files"}
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*, text/plain, video/*, audio/*,application/pdf"
                  onChange={handleFileUpload}
                  multiple
                  style={{ display: "none" }}
                />
              </div>
            </div>

            {fileLinkList?.length ? (
              <div className="col-sm-4 col-12">
                <div className="d-flex justify-content-center ">
                  <Button
                    outline
                    color={"danger"}
                    onClick={(e) => {
                      dispatch(resetFileBackButton());

                      handleClick("add-posts");
                    }}
                    style={{ fontSize: "16px", columnGap: "10px" }}
                    className={clsx(styles.feedBtn, "mt-sm-0 mt-3 py-3  d-flex justify-content-center")}
                  >
                    {fileLinkList.length > 0 ? <Icon name="chevron-left"></Icon> : ""}
                    {fileLinkList.length > 0 ? "Back To Add Posts " : ""}
                  </Button>
                </div>
              </div>
            ) : null}

            <div className="col-12">
              <div
                className="d-flex flex-sm-row flex-column align-items-sm-start  justify-content-center align-items-center mt-3 "
                style={{ fontSize: "20px", columnGap: "15px" }}
              >
                <p>
                  <span>{totalFiles > 1 ? "Total Links" : "Total  Link"} </span>
                  <span style={{ color: "green", fontWeight: "bold" }}>{totalFiles ? totalFiles : "0"}</span>
                </p>

                <p>
                  <span>{fileTotal > 1 ? "Uploaded Links" : "Uploaded  Link"} </span>
                  <span style={{ color: "green", fontWeight: "bold" }}>{fileTotal ? fileTotal : "0"}</span>
                </p>

                <p>
                  <span>{fileSizeBig?.length > 1 ? "Unuploaded Links" : "Unuploaded Link"} </span>
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {fileSizeBig?.length ? fileSizeBig?.length : "0"}
                  </span>
                </p>
              </div>
            </div>
            <div className="col-sm-10 col-12  mt-2 ">
              {fileLinkList
                ? fileLinkList?.map((el, index) => {
                    return (
                      <div className="" key={index}>
                        <p
                          style={{
                            fontSize: "16px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                            textDecoration: "underline",
                          }}
                          title={el}
                          className={clsx("")}
                        >
                          {el}
                        </p>
                      </div>
                    );
                  })
                : null}
            </div>

            <div className="col-sm-10 col-12  mt-2 ">
              {fileSizeBig
                ? fileSizeBig?.map((el, index) => {
                    return (
                      <div className="" key={index}>
                        <p
                          style={{
                            fontSize: "16px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                            textDecoration: "underline",
                            color: "red",
                          }}
                          title={`${el?.name} file could not be uploaded. Max size allowed 20Mb`}
                          className={clsx("")}
                        >
                          {el?.name} file could not be uploaded. Max size allowed 20Mb
                        </p>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </section>
      </Content>
    </React.Fragment>
  );
};

export default GetLinks;
