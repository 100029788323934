import React from "react";
import { Navigate, Outlet, redirect, Route } from "react-router-dom";
import Layout from "../layout/Index";
import { getCookie } from "../utils/storage";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = getCookie("_jwt");

  return isAuthenticated ? (
    <>
      <Layout>
        <Outlet />
      </Layout>
    </>
  ) : (
    <Navigate to="/auth-login" />
  );
};

export default PrivateRoute;
