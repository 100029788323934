import React from 'react'

const CountryDetails = () => {



  return (
    <React.Fragment>
      CountryDetails
    </React.Fragment>
  )
}

export default CountryDetails
