import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  RSelect,
  UserAvatar,
} from "../../components/Component";
import DotPagination from "../../components/pagination/DotPagination.j";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { getAllHrs } from "../../redux/Action/hrAction";
import { deleteUser, getAllUsers } from "../../redux/Action/userAction";
import { findUpper, getScreenWidth } from "../../utils/Utils";

import clsx from "clsx";
import { filterStatus, userData } from "../pre-built/user-manage/UserData";
import { useSearchParams } from "react-router-dom/dist";

const Hr = () => {
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const [searchParams] = useSearchParams()

  const dispatch = useDispatch();
  const { loading, hr, total, error } = useSelector((state) => state?.hr);
  const [sm, updateSm] = useState(false);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    balance: "",
    phone: "",
    status: "Active",
  });

  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(searchParams.get("p") ? parseInt(searchParams.get("p")) : 1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  useEffect(() => {
    dispatch(getAllHrs({ page: currentPage, limit: itemPerPage }));
    return () => { };
  }, [currentPage, itemPerPage]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const { errors, register, handleSubmit } = useForm();

  const deleteAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this user?",
      text: "Changes cannot be reverted.",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.showLoading();
        dispatch(
          deleteUser(
            id,
            (res) => {
              dispatch(getAllHrs({ page: currentPage, limit: itemPerPage }));
              Swal.fire("Deleted!", res?.message, "success");
            },
            (res) => {
              Swal.fire("Error!", res?.message, "error");
            }
          )
        );
      }
    });
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(getScreenWidth());
    }

    const resizeListener = () => handleResize();

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="Hr List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                HR Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {loading ? 0 : total} users.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3"></ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group"></div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by user or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Spinner type="grow" color="dark" />
              </div>
            ) : hr?.length ? (
              <DataTableBody compact>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text">User</span>
                  </DataTableRow>
                  <DataTableRow size="sm">
                    <span className="sub-text">Email</span>
                  </DataTableRow>
                  <DataTableRow size="md">
                    <span className="sub-text">Phone</span>
                  </DataTableRow>
                  <DataTableRow size="lg">
                    <span className="sub-text">Company</span>
                  </DataTableRow>
                  <DataTableRow size="lg">
                    <span className="sub-text">Company Verification</span>
                  </DataTableRow>
                  <DataTableRow size="lg">
                    <span className="sub-text">Location</span>
                  </DataTableRow>
                  <DataTableRow size="lg">
                    <span className="sub-text">Profile Verified</span>
                  </DataTableRow>

                  <DataTableRow size="lg">
                    <span className="sub-text">Created</span>
                  </DataTableRow>

                  <DataTableRow className="nk-tb-col-tools text-right">
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                    </UncontrolledDropdown>
                  </DataTableRow>
                </DataTableHead>
                {/*Head*/}
                {hr?.map((item) => {
                  return (
                    <DataTableItem key={item._id}>
                      <DataTableRow>
                        <Link to={`/hr/${item?._id}?p=${currentPage}`}>
                          <div className="user-card">
                            {item?.profilePhoto?.url ? (
                              <UserAvatar
                                className="xs"
                                text={findUpper(item?.fullName)}
                                image={`${process.env.REACT_APP_IMG_URL}${item?.profilePhoto?.url}`}
                                imageAlt={findUpper(item?.fullName)}
                              ></UserAvatar>
                            ) : (
                              <UserAvatar theme="lighter" className="xs" icon={"user-alt"}></UserAvatar>
                            )}
                            <div className="user-info">
                              <span className="tb-lead text-primary">{item?.fullName} </span>
                            </div>
                          </div>
                        </Link>
                      </DataTableRow>
                      <DataTableRow size="sm" className={clsx("fixedWidthCell")}>
                        <span>{item?.email}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{item?.countryCode ? item?.countryCode + item?.mobile : item?.mobile}</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <Link to={`/company/${item?.company?.company?._id}`} className="text-primary">
                          {item?.company?.company?.companyName}
                        </Link>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span
                          className={`tb-status text-${item?.company?.company?.companyVerified ? "success" : "danger"}`}
                        >
                          {item?.company?.company?.companyVerified ? "Verified" : "Pending"}
                        </span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span>
                          {item?.company?.company?.address?.length ? (
                            <>
                              {item?.company?.company?.address[0]?.city?.translations[0]?.cityTranslation?.name},{" "}
                              {item?.company?.company?.address[0]?.country?.translations[0]?.countryTranslation?.name}
                            </>
                          ) : null}
                        </span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <ul className="list-status">
                          <li>
                            {/* {item?.emailVerified ? (
                              <> */}
                            <span>
                              {item?.emailVerified && item?.mobileVerified ? (
                                <>
                                  <Icon className={`text-success`} name={`check-circle`}></Icon> Mobile, Email
                                </>
                              ) : item?.emailVerified ? (
                                <>
                                  <Icon className={`text-success`} name={`check-circle`}></Icon> Email
                                </>
                              ) : item?.mobileVerified ? (
                                <>
                                  <Icon className={`text-success`} name={`check-circle`}></Icon> Mobile
                                </>
                              ) : null}
                            </span>
                          </li>
                        </ul>
                      </DataTableRow>

                      <DataTableRow size="lg">
                        <span>{item?.createdAt ? moment(item.createdAt)?.utc()?.format("DD MMM YY h:mm A") : ""}</span>
                      </DataTableRow>

                      <DataTableRow className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem
                                      tag="div"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        deleteAlert(item?._id);
                                      }}
                                    >
                                      <Icon name="user-cross-fill"></Icon>
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })}
              </DataTableBody>
            ) : error ? (
              <div
                style={{
                  height: "200px",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <span>Something went wrong...</span>
                  <span onClick={() => window.location.reload()} style={{ color: "dodgerblue", cursor: "pointer" }}>
                    try again
                  </span>
                </p>
              </div>
            ) : null}
            <div className="card-inner">
              <DotPagination
                itemPerPage={itemPerPage}
                totalItems={total}
                paginate={paginate}
                currentPage={currentPage}
                siblingCount={screenWidth < 575 ? 0 : 2}
                screenWidth={screenWidth}
              />
            </div>
          </DataTable>
        </Block>
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add User</h5>
              <div className="mt-4">
                <Form
                  className="row gy-4"
                // onSubmit={handleSubmit(onFormSubmit)}
                >
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Balance</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        defaultValue={formData.balance}
                        placeholder="Balance"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.balance && <span className="invalid">{errors.balance.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        defaultValue={formData.phone}
                        ref={register({
                          required: "This field is required",
                        })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          defaultValue={{ value: "active", label: "Active" }}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Add User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update User</h5>
              <div className="mt-4">
                <Form
                  className="row gy-4"
                // onSubmit={handleSubmit(onEditSubmit)}
                >
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Balance</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        disabled
                        defaultValue={parseFloat(formData.balance.replace(/,/g, ""))}
                        placeholder="Balance"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.balance && <span className="invalid">{errors.balance.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        defaultValue={Number(formData.phone)}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          defaultValue={{
                            value: formData.status,
                            label: formData.status,
                          }}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default Hr;
