import { toast } from "react-toastify";
import { getMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";
import { getCookie } from "../../utils/storage";
import { getCommentList, getCommentListById, isCommentListError, isCommentListLoading, isCommentListSuccess } from "../Apislices/commentListSlice";


export const asyncgetAllCommentList = (page, limit, postId = "") => async (dispatch) => {

    dispatch(isCommentListLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/comments/list?postId=${postId}&page=${page}&limit=${limit}`);
    if (response?.success) {
        dispatch(isCommentListSuccess());
        dispatch(
            getCommentList({
                data: response?.data,
                total: response?.total,
            })
        );
    } else {
        dispatch(isCommentListError(response?.message));
    }
    return response
};


export const asyncGetCommentListById = (id) => async (dispatch) => {

    dispatch(isCommentListLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/comment/${id}`);
    if (response?.success) {
        dispatch(isCommentListSuccess());
        dispatch(getCommentListById(response?.data));
    } else {
        dispatch(isCommentListError(response?.message));
    }
    return response

};



export const asyncUpdateStatusComment = (id, data) => async (dispatch) => {

    dispatch(isCommentListLoading());
    let response = await putMethod(`admin/active/${id}/comment`, data);
    if (response?.success) {
        dispatch(isCommentListSuccess());
    } else {
        dispatch(isCommentListError(response?.message));
        toast.error(response?.message);

    }

    return response

};


export const asyncUpdateChildCommentStatus = (id, data) => async (dispatch) => {

    dispatch(isCommentListLoading());
    let response = await putMethod(`admin/comments/${id}/replies/status`, data);
    return response

};


