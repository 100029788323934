import classnames from "classnames";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Card, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { asyncGetCommentListById, asyncUpdateStatusComment } from "../../redux/Action/commentListAction";
import { asyncUpdateStatusUser } from "../../redux/Action/postListAction";

const CommentListById = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, commentListId } = useSelector((state) => state?.commentListSlice);

  const [activeIconTab, setActiveIconTab] = useState("1");

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  let pageNo = 0;

  if (searchParams.get("p")) {
    pageNo = searchParams.get("p");
  }

  const handleBlockComment = async (id) => {
    let data = {
      status: false,
    };
    let res = await dispatch(asyncUpdateStatusComment(id, data));

    if (res?.success) {
      await dispatch(asyncGetCommentListById(id));
    } else {
      await dispatch(asyncGetCommentListById(id));
    }
  };

  const handleUnBlockComment = async (id) => {
    let data = {
      status: true,
    };
    let res = await dispatch(asyncUpdateStatusComment(id, data));

    if (res?.success) {
      await dispatch(asyncGetCommentListById(id));
    } else {
      await dispatch(asyncGetCommentListById(id));
    }
  };

  const handleBlockUser = async (id) => {
    let data = {
      status: true,
    };
    let res = await dispatch(asyncUpdateStatusUser(id, data));

    if (res?.success) {
      await dispatch(asyncGetCommentListById(commentListId?._id));
    } else {
      await dispatch(asyncGetCommentListById(commentListId?._id));
    }
  };

  const handleUnBlockUser = async (id) => {
    let data = {
      status: false,
    };
    let res = await dispatch(asyncUpdateStatusUser(id, data));

    if (res?.success) {
      await dispatch(asyncGetCommentListById(commentListId?._id));
    } else {
      await dispatch(asyncGetCommentListById(commentListId?._id));
    }
  };

  const inactiveCommentAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete the comment?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleBlockComment(id);
        Swal.fire("Deleted!", "Your comment has been deleted", "success");
      }
    });
  };

  const activeCommentAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to Recover the comment?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUnBlockComment(id);
        Swal.fire("Recovered!", "Your comment has been recovered.", "success");
      }
    });
  };
  const blockAlertUser = (id) => {
    Swal.fire({
      title: "Are you sure you want to block the user?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleBlockUser(id);
        Swal.fire("Blocked!", "Your user has been blocked.", "success");
      }
    });
  };

  const unBlockAlertUser = (id) => {
    Swal.fire({
      title: "Are you sure you want to unblock the user?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUnBlockUser(id);
        Swal.fire("Unblocked!", "Your user has been unblocked.", "success");
      }
    });
  };
  useEffect(() => {
    if (id) {
      dispatch(asyncGetCommentListById(id));
    }

    return () => {};
  }, [id]);

  return (
    <React.Fragment>
      <Head title="Comment Detail"></Head>
      {loading ? (
        <div
          style={{
            height: "200px",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spinner type="grow" color="dark" />
        </div>
      ) : commentListId ? (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <Breadcrumb className="breadcrumb-arrow">
                  <BreadcrumbItem>
                    <Link to={"/feed"}>Feed</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={"/moderate-world"}>Moderate World</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={"/comment-list"}>Comment List</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a>Comment Details</a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={() => navigate(pageNo === 0 ? -1 : `/comment-list?p=${pageNo}`)}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(pageNo === 0 ? -1 : `/comment-list?p=${pageNo}`);
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          {commentListId ? (
            <Block>
              <Card className="card-bordered">
                <div className="card-aside-wrap" id="user-detail-block">
                  <div className="card-content">
                    <PreviewCard>
                      <Nav
                        tabs
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            margin: "0 15px",
                            display: "flex",
                          }}
                        >
                          <NavItem>
                            <NavLink
                              tag="a"
                              href="#tab"
                              className={classnames({ active: activeIconTab === "1" })}
                              onClick={(ev) => {
                                ev.preventDefault();
                                toggleIconTab("1");
                              }}
                            >
                              <Icon name="file-docs"></Icon>
                              <span>Details</span>
                            </NavLink>
                          </NavItem>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            margin: "0 15px",
                          }}
                        >
                          <NavItem>
                            {commentListId ? (
                              <NavLink tag="div" href="#tab">
                                <Button
                                  className="toggle btn-icon d-md-none"
                                  color={commentListId?.isActive ? "danger" : "success"}
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    if (commentListId?.isActive) {
                                      inactiveCommentAlert(commentListId?._id);
                                    } else {
                                      activeCommentAlert(commentListId?._id);
                                    }
                                  }}
                                >
                                  <Icon name={commentListId?.isActive ? "unlink-alt" : "link-alt"}></Icon>
                                </Button>
                                <Button
                                  className="toggle d-none d-md-inline-flex"
                                  color={commentListId?.isActive ? "danger" : "success"}
                                  outline
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    if (commentListId?.isActive) {
                                      inactiveCommentAlert(commentListId?._id);
                                    } else {
                                      activeCommentAlert(commentListId?._id);
                                    }
                                  }}
                                >
                                  <Icon name={commentListId?.isActive ? "unlink-alt" : "link-alt"}></Icon>
                                  <span>{commentListId?.isActive ? "Delete Comment" : "Recover Comment"} </span>
                                </Button>
                              </NavLink>
                            ) : null}
                          </NavItem>

                          <NavItem>
                            {true ? (
                              <NavLink tag="div" href="#tab">
                                <Button
                                  className="toggle btn-icon d-md-none"
                                  color={!commentListId?.user?.blockedByAdmin ? "danger" : "success"}
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    if (!commentListId?.user?.blockedByAdmin) {
                                      blockAlertUser(commentListId?.user?._id);
                                    } else {
                                      unBlockAlertUser(commentListId?.user?._id);
                                    }
                                  }}
                                >
                                  <Icon
                                    name={!commentListId?.user?.blockedByAdmin ? "user-cross" : "user-add-fill"}
                                  ></Icon>{" "}
                                </Button>
                                <Button
                                  className="toggle d-none d-md-inline-flex"
                                  color={!commentListId?.user?.blockedByAdmin ? "danger" : "success"}
                                  outline
                                  onClick={() => {
                                    if (!commentListId?.user?.blockedByAdmin) {
                                      blockAlertUser(commentListId?.user?._id);
                                    } else {
                                      unBlockAlertUser(commentListId?.user?._id);
                                    }
                                  }}
                                >
                                  <Icon
                                    name={!commentListId?.user?.blockedByAdmin ? "user-cross" : "user-add-fill"}
                                  ></Icon>
                                  <span>{!commentListId?.user?.blockedByAdmin ? "Block User " : "Unblock User"}</span>{" "}
                                </Button>
                              </NavLink>
                            ) : null}
                          </NavItem>
                        </div>
                      </Nav>

                      <TabContent activeTab={activeIconTab}>
                        <TabPane tabId="1">
                          <div className="card-inner">
                            <Block>
                              <BlockHead>
                                <BlockTitle tag="h5">Comment Details</BlockTitle>
                              </BlockHead>
                              <div className="profile-ud-list">
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Post Id</span>
                                    <span className="profile-ud-value">
                                      {commentListId?.post ? commentListId?.post : ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">{"Date & Time"}</span>
                                    <span className="profile-ud-value">
                                      {commentListId?.createdAt
                                        ? moment(commentListId.createdAt)?.utc()?.format("DD MMM YY h:mm A")
                                        : ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Creator Name</span>
                                    <span className="profile-ud-value">
                                      {commentListId?.user?.fullName ? commentListId?.user?.fullName : ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Text</span>
                                    <span className="profile-ud-value">
                                      {commentListId?.text ? commentListId?.text : ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Comment Type</span>
                                    <span className="profile-ud-value">
                                      {!commentListId?.replies?.length ? "Parent" : "Child"}
                                    </span>
                                  </div>
                                </div>

                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Comment Status</span>
                                    <span
                                      className={clsx(
                                        "profile-ud-value",
                                        commentListId?.isActive ? "active-color" : "inactive-color"
                                      )}
                                    >
                                      {commentListId?.isActive ? "Active" : "InActive"}
                                    </span>
                                  </div>
                                </div>

                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">User Status</span>
                                    <span
                                      className={clsx(
                                        "profile-ud-value",
                                        !commentListId?.user?.blockedByAdmin ? "active-color" : "inactive-color"
                                      )}
                                    >
                                      {commentListId?.user?.blockedByAdmin ? "Block" : "Not Blocked"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Block>
                          </div>
                        </TabPane>
                      </TabContent>
                    </PreviewCard>
                  </div>
                </div>
              </Card>
            </Block>
          ) : null}
        </Content>
      ) : null}
    </React.Fragment>
  );
};

export default CommentListById;
