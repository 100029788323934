import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Icon, UserAvatar } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import { findUpper } from "../../../utils/Utils";
import UserProfileActivityPage from "./UserProfileActivity";
import UserProfileNotificationPage from "./UserProfileNotification";
import UserProfileRegularPage from "./UserProfileRegular";
import UserProfileSettingPage from "./UserProfileSetting";

const UserProfileLayout = () => {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state?.auth);

  const [path, setPath] = useState("/user-profile");

  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [profileName, setProfileName] = useState("Abu Bin Ishtiak");

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  // useEffect(() => {
  //   if (pathname) {
  //     setPath(pathname);
  //   }
  //   return () => {};
  // }, [pathname]);

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    {user?.profilePhoto ? (
                      <UserAvatar image={`${process.env.REACT_APP_IMG_URL}${user?.profilePhoto?.url}`} />
                    ) : (
                      <UserAvatar text={findUpper(user?.fullName)} theme="primary" />
                    )}
                    <div className="user-info">
                      <span className="lead-text">{user?.fullName}</span>
                      <span className="sub-text">{user?.email}</span>
                    </div>

                    <div className="user-action">
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-icon btn-trigger mr-n2">
                          <Icon name="more-v"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="camera-fill"></Icon>
                                <span>Change Photo</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="edit-fill"></Icon>
                                <span>Update Profile</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>

                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`#`}
                        className={path === "/user-profile" ? "active" : ""}
                        onClick={() => setPath("/user-profile")}
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>Personal Information</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`#`}
                        className={path === "/user-profile-notification" ? "active" : ""}
                        onClick={() => setPath("/user-profile-notification")}
                      >
                        <Icon name="bell-fill"></Icon>
                        <span>Notification</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`#`}
                        className={path === "/user-profile-activity" ? "active" : ""}
                        onClick={() => setPath("/user-profile-activity")}
                      >
                        <Icon name="activity-round-fill"></Icon>
                        <span>Account Activity</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`#`}
                        className={path === "/user-profile-setting" ? "active" : ""}
                        onClick={() => setPath("/user-profile-setting")}
                      >
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Security Setting</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              {path === "/user-profile" ? (
                <UserProfileRegularPage updateSm={updateSm} sm={sm} setProfileName={setProfileName} />
              ) : path === "/user-profile-notification" ? (
                <UserProfileNotificationPage updateSm={updateSm} sm={sm} />
              ) : path === "/user-profile-setting" ? (
                <UserProfileSettingPage updateSm={updateSm} sm={sm} />
              ) : path === "/user-profile-activity" ? (
                <UserProfileActivityPage updateSm={updateSm} sm={sm} />
              ) : null}
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default UserProfileLayout;
