import { toast } from "react-toastify";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";
import { getCookie } from "../../utils/storage";
import { isCompanyByIdSuccess, isCompanyError, isCompanyLoading, isCompanySuccess, isSuccess } from "../Apislices/companySlice";

export const getAllCompanies = (query) => async (dispatch) => {
  try {
    dispatch(isCompanyLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/company/list`, query);
    if (response?.success) {
      dispatch(isCompanySuccess({ data: response?.data, total: response?.total }));
    } else {
      dispatch(isCompanyError(response?.message));
    }
  } catch (error) {
    dispatch(isCompanyError(error?.message));
  }
};

export const getCompanyById = (id) => async (dispatch) => {
  try {
    dispatch(isCompanyLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/company/${id}/check`);

    if (response?.success) {
      dispatch(isCompanyByIdSuccess(response?.data));
    } else {
      dispatch(isCompanyError(response?.message));
    }
  } catch (error) {
    dispatch(isCompanyError(error?.message));
  }
};

export const deleteCompany = (id, successCB, errorCB) => async (dispatch) => {
  try {
    setAuthorizationToken(getCookie("_jwt"));
    const response = await deleteMethod(`/admin/company/${id}/remove`);
    if (response?.success) {
      successCB(response);
    } else {
      errorCB(response);
    }
  } catch (error) { }
};

export const verifyCompany = (id) => async (dispatch) => {

  dispatch(isCompanyLoading());
  setAuthorizationToken(getCookie("_jwt"));
  const response = await putMethod(`/admin/company/${id}/verified`);
  if (response?.success) {
    dispatch(isSuccess());
  } else {
    dispatch(isCompanyError(response?.message));
  }
  return response
};

export const editCompany = (id, data, successCB, errorCB) => async (dispatch) => {
  try {
    dispatch(isCompanyLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await putMethod(`/admin/company/${id}/update`, data);
    if (response?.success) {
      dispatch(isCompanyByIdSuccess(response?.data));
      successCB(response);
    } else {
      errorCB(response);
    }
  } catch (error) { }
};


export const filePresignedUrl = async (data) => {
  let response = await postMethod("admin/file/presingend", data);
  return response;
};


export const uploadCompanyDocumentApi = (formData) => async (dispatch) => {

  dispatch(isCompanyLoading());




  let response = await postMethod(`admin/file/create`, formData);

  if (response?.success) {

    dispatch(isSuccess());
  } else {
    dispatch(isCompanyError(response?.message));

  }

  return response

};

export const updateDataInAwsApi = (formData) => async (dispatch) => {

  dispatch(isCompanyLoading());


  let response = await putMethod(`/admin/company/edit/${formData?.companyId}/document`, formData);
  if (response?.success) {

    dispatch(isSuccess());
  } else {
    dispatch(isCompanyError(response?.message));
    toast.error(response?.message);

  }

  return response

};



export const removeCompanyDocumentApi = (companyId, formData) => async (dispatch) => {
  dispatch(isCompanyLoading());




  let response = await postMethod(`admin/removeCompany/${companyId}`, formData);

  if (response?.success) {

    dispatch(isSuccess());
  } else {
    dispatch(isCompanyError(response?.message));
    toast.error(response?.message);

  }

  return response

};

export const addCreditsToCompany = (data) => async (dispatch) => {
  dispatch(isCompanyLoading())
  let response = await postMethod('/admin/orders/company/add-credits', data)
  if (response.success) {
    dispatch(isSuccess())
  } else {
    dispatch(isCompanyError(response?.message));
    toast.error(response?.message);
  }
  return response;
};

export const sendOtpForAddingCredits = (data) => async (dispatch) => {
  dispatch(isCompanyLoading())
  let response = await postMethod(`/admin/send/verification/otp`, data)
  if (response.success) {
    dispatch(isSuccess())
  } else {
    dispatch(isCompanyError(response?.message));
    toast.error(response?.message);
  }
  return response;
}

export const verifyOtpForAddingCredits = data => async (dispatch) => {
  dispatch(isCompanyLoading())
  let response = await postMethod(`/admin/verified/verification/otp`, data);
  if (response.success) {
    dispatch(isSuccess())
  } else {
    dispatch(isCompanyError(response?.message));
    toast.error(response?.message);
  }
  return response
}

export const uploadTransactionInvoice = data => async (dispatch) => {
  let response = await postMethod('admin/file/presingend', data)
  return response
}

export const deleteInvoiceFile = data => async (dispatch) => {
  let response = await postMethod('/admin/file/presigned/delete', data)
}