import classnames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  OverlineTitle,
  PreviewCard,
  Row,
  Sidebar,
  UserAvatar,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { getUserById } from "../../redux/Action/userAction";
import { findUpper } from "../../utils/Utils";
import clsx from "clsx";
import { useSearchParams } from "react-router-dom/dist";

const UserId = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { loading, userId, userJobs } = useSelector((state) => state?.user);

  const [activeIconTab, setActiveIconTab] = useState("1");

  const [sideBar, setSidebar] = useState(false);

  let pageNo = 0;

  if (searchParams.get("p")) {
    pageNo = searchParams.get("p")
  }

  // function to toggle sidebar
  const toggle = () => {
    setSidebar(!sideBar);
  };

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  useEffect(() => {
    if (slug) {
      dispatch(getUserById(slug));
    }

    return () => { };
  }, [slug]);

  return (
    <React.Fragment>
      <Head title="HR Details"></Head>
      {loading ? (
        <div
          style={{
            height: "200px",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spinner type="grow" color="dark" />
        </div>
      ) : userId || userJobs ? (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  User / <strong className="text-primary small">{userId?.fullName}</strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    {userId?.createdAt ? (
                      <li>
                        Account created at:{" "}
                        <span className="text-base">
                          {moment(userId?.createdAt)?.utc()?.format("DD MMM YY h:mm A")}
                        </span>
                      </li>
                    ) : null}
                    <li>
                      Status:{" "}
                      <span className={userId?.active ? " text-success" : " text-danger"}>
                        ({userId?.active ? "Active" : "Inactive"})
                      </span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button color="light" outline className="bg-white d-none d-sm-inline-flex" onClick={() => navigate(pageNo === 0 ? -1 : `/user?p=${pageNo}`)}>
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(pageNo === 0 ? -1 : `/user?p=${pageNo}`);
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <PreviewCard>
                    <Nav
                      tabs
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          margin: "0 15px",
                          display: "flex",
                        }}
                      >
                        <NavItem>
                          <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({ active: activeIconTab === "1" })}
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggleIconTab("1");
                            }}
                          >
                            <Icon name="user" /> <span>Personal</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({ active: activeIconTab === "2" })}
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggleIconTab("2");
                            }}
                          >
                            <Icon name="search" /> <span>Job</span>
                          </NavLink>
                        </NavItem>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          margin: "0 15px",
                        }}
                      ></div>
                    </Nav>

                    <TabContent activeTab={activeIconTab}>
                      <TabPane tabId="1">
                        <div className="card-inner">
                          <Block>
                            <BlockHead>
                              <BlockTitle tag="h5">Personal Information</BlockTitle>
                            </BlockHead>
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Full Name</span>
                                  <span className="profile-ud-value">{userId?.fullName}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Surname</span>
                                  <span className="profile-ud-value">{userId?.lastName ? userId?.lastName : ""}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Gender</span>
                                  <span className="profile-ud-value">{userId?.gender}</span>
                                </div>
                              </div>
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Nationality</span>
                                  <span className="profile-ud-value">
                                    {userId?.nationality?.translations[0]?.countryTranslation?.name}
                                  </span>
                                </div>
                              </div>
                              {userId?.mobile ? (
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Mobile Number</span>
                                    <span className="profile-ud-value">{`${userId?.countryCode} ${userId?.mobile}`}</span>
                                  </div>
                                </div>
                              ) : null}
                              {userId?.email ? (
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Email Address</span>
                                    <span className="profile-ud-value">{userId?.email}</span>
                                  </div>
                                </div>
                              ) : null}

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Complete Profile</span>
                                  <span
                                    className={clsx(
                                      "profile-ud-value",
                                      userId?.completeProfile ? "text-success" : "text-danger"
                                    )}
                                  >
                                    {userId?.completeProfile ? "Yes" : "No"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Block>

                          <Block>
                            <BlockHead className="nk-block-head-line">
                              <BlockTitle tag="h6" className="overline-title text-base">
                                Education
                              </BlockTitle>
                            </BlockHead>
                            <div className="profile-ud-list">
                              {userId?.qualifications?.length
                                ? userId?.qualifications?.map((el, index) => {
                                  return (
                                    <React.Fragment key={el?._id + Math.floor()}>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            {userId?.qualifications?.length > 1 ? (
                                              <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                                                {index + 1}
                                              </span>
                                            ) : null}{" "}
                                            University
                                          </span>
                                          <span className="profile-ud-value">{el?.school}</span>
                                        </div>
                                      </div>

                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">Course</span>
                                          <span className="profile-ud-value">{el?.degree}</span>
                                        </div>
                                      </div>

                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">Degree</span>
                                          <span className="profile-ud-value">
                                            {" "}
                                            {el?.education?.translations[0]?.educationTranslation?.label}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">Passout Year</span>
                                          <span className="profile-ud-value"> {el?.yearOfGraduation} year</span>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  );
                                })
                                : ""}
                            </div>
                          </Block>

                          <Block>
                            <BlockHead className="nk-block-head-line">
                              <BlockTitle tag="h6" className="overline-title text-base">
                                Work Experience
                              </BlockTitle>
                            </BlockHead>
                            <div className="profile-ud-list">
                              {userId?.workExperience?.length
                                ? userId?.workExperience?.map((item, index) => {
                                  return (
                                    <React.Fragment key={item?._id + Math.floor()}>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            {userId?.workExperience?.length > 1 ? (
                                              <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                                                {index + 1}
                                              </span>
                                            ) : null}{" "}
                                            Position
                                          </span>
                                          <span className="profile-ud-value">{item?.title}</span>
                                        </div>
                                      </div>

                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">Company Name </span>
                                          <span className="profile-ud-value">{item?.company}</span>
                                        </div>
                                      </div>

                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">Join Date </span>
                                          <span className="profile-ud-value">
                                            {" "}
                                            {item?.startDate
                                              ? `
                                            ${item?.startDate}
                                           
                                            `
                                              : ""}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">End Date</span>
                                          <span className="profile-ud-value">
                                            {" "}
                                            {item?.endDate
                                              ? `
                                                ${item?.endDate}
                                               `
                                              : ""}
                                          </span>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  );
                                })
                                : ""}
                            </div>
                          </Block>

                          <Block>
                            <BlockHead className="nk-block-head-line">
                              <BlockTitle tag="h6" className="overline-title text-base">
                                Additional Information
                              </BlockTitle>
                            </BlockHead>
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Created At</span>
                                  <span className="profile-ud-value">
                                    {userId?.createdAt
                                      ? moment(userId?.createdAt)?.utc()?.format("YYYY-MM-DD hh:mm a")
                                      : null}
                                  </span>
                                </div>
                              </div>
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Verified</span>
                                  <span className="profile-ud-value">
                                    {userId?.emailVerified && userId?.mobileVerified
                                      ? "Mobile, Email"
                                      : userId?.emailVerified
                                        ? "Email"
                                        : userId?.mobileVerified
                                          ? "Mobile"
                                          : "NA"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Block>
                        </div>
                      </TabPane>
                      {userJobs?.length ? (
                        <TabPane tabId="2">
                          <div className="card-inner">
                            <Block>
                              <BlockHead>
                                <BlockTitle tag="h5">Information</BlockTitle>
                              </BlockHead>
                              <div className="profile-ud-list">
                                {userJobs?.map((el, index) => {
                                  return (
                                    <React.Fragment key={el?._id + Math.floor()}>
                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">
                                            {userJobs?.length > 1 ? (
                                              <span style={{ fontWeight: "bold", marginRight: "5px" }}>
                                                {index + 1}
                                              </span>
                                            ) : null}
                                            Applied Job Date
                                          </span>
                                          <span className="profile-ud-value">
                                            {moment(el?.createdAt).format("YYYY-MM-DD")}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">Applied Job Title</span>
                                          <span className="profile-ud-value">{el?.job?.title}</span>
                                        </div>
                                      </div>

                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label">Applied Job Status</span>
                                          <span className="profile-ud-value">{el?.status}</span>
                                        </div>
                                      </div>

                                      <div className="profile-ud-item">
                                        <div className="profile-ud wider">
                                          <span className="profile-ud-label"></span>
                                          <span className="profile-ud-value"></span>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            </Block>
                          </div>
                        </TabPane>
                      ) : null}
                    </TabContent>
                  </PreviewCard>
                </div>

                <Sidebar toggleState={sideBar}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2 mt-5 mt-xxl-0">
                      <UserAvatar className="lg" theme="primary" text={findUpper(userId?.name)} />
                      <div className="user-info">
                        <div className="badge badge-outline-light badge-pill ucap">{userId?.role}</div>
                        <h5>{userId?.name}</h5>
                        <span className="sub-text">{userId?.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner card-inner-sm">
                    <ul className="btn-toolbar justify-center gx-1">
                      <li>
                        <Button
                          href="#tool"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="shield-off"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#mail"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="mail"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#download"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="download-cloud"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#bookmark"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="bookmark"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon text-danger"
                        >
                          <Icon name="na"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                  <div className="card-inner">
                    <div className="overline-title-alt mb-2">In Account</div>
                    <div className="profile-balance">
                      <div className="profile-balance-group gx-4">
                        <div className="profile-balance-sub">
                          <div className="profile-balance-amount">
                            <div className="number">
                              2,500.00 <small className="currency currency-usd">USD</small>
                            </div>
                          </div>
                          <div className="profile-balance-subtitle">Invested Amount</div>
                        </div>
                        <div className="profile-balance-sub">
                          <span className="profile-balance-plus text-soft">
                            <Icon className="ni-plus"></Icon>
                          </span>
                          <div className="profile-balance-amount">
                            <div className="number">1,643.76</div>
                          </div>
                          <div className="profile-balance-subtitle">Profit Earned</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <Row className="text-center">
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{userId?.tasks}</span>
                          <span className="sub-text">Total Order</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{userId?.projects}</span>
                          <span className="sub-text">Complete</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{userId?.performed}</span>
                          <span className="sub-text">Progress</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">Additional</h6>
                    <Row className="g-3">
                      <Col size="6">
                        <span className="sub-text">User ID:</span>
                        <span>UD003054</span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Last Login:</span>
                        <span>{userId?.lastLogin} 01:02 PM</span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">KYC Status:</span>
                        <span
                          className={`lead-text text-${userId?.kycStatus === "success"
                            ? "success"
                            : userId?.kycStatus === "pending"
                              ? "info"
                              : userId?.kycStatus === "warning"
                                ? "warning"
                                : "secondary"
                            }`}
                        >
                          {/* {userId?.kycStatus.toUpperCase()} */}
                        </span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Register At:</span>
                        <span>Nov 24, 2019</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <OverlineTitle tag="h6" className="mb-3">
                      Groups
                    </OverlineTitle>
                    <ul className="g-1">
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          investor
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          support
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          another tag
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </Sidebar>

                {sideBar && <div className="toggle-overlay" onClick={() => toggle()}></div>}
              </div>
            </Card>
          </Block>
        </Content>
      ) : null}
    </React.Fragment>
  );
};
export default UserId;
