import { toast } from "react-toastify";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";
import { getCookie } from "../../utils/storage";
import { isHrByIdSuccess, isHrError, isHrLoading, isHrSuccess } from "../Apislices/hrSlice";

export const getAllHrs = (query) => async (dispatch) => {
  try {
    dispatch(isHrLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/hr/list`, query);

    if (response?.success) {
      dispatch(isHrSuccess({ data: response?.data, total: response?.total }));
    } else {
      dispatch(isHrError(response?.message));
    }
  } catch (error) {
    dispatch(isHrError(error?.message));
  }
};

export const getHrById = (id) => async (dispatch) => {
  try {
    dispatch(isHrLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/hr/${id}/profile`);
    if (response?.success) {
      dispatch(isHrByIdSuccess(response?.data));
    } else {
      dispatch(isHrError(response?.message));
    }
  } catch (error) {
    dispatch(isHrError(error?.message));
  }
};

export const verifyAccount = (id, succssCB, errorCB) => async (dispatch) => {
  try {
    setAuthorizationToken(getCookie("_jwt"));
    const response = await putMethod(`/admin/hr/${id}/company/verified`);
    if (response?.success) {
      succssCB(response);
    } else {
      errorCB(response);
    }
  } catch (error) { }
};
