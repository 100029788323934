//here create storage type cookies, localstorage, sessionstorage
import React from "react";
const getSession = (key) => {
  let data = sessionStorage.getItem(key) ? JSON.parse(decodeURIComponent(sessionStorage.getItem(key))) : false;
  return data;
};
const setSession = (key, value) => {
  sessionStorage.setItem(key, encodeURIComponent(JSON.stringify(value)));
};
const removeSession = (key) => {
  sessionStorage.removeItem(key);
};

const getLocal = (key) => {
  let data = localStorage.getItem(key) ? JSON.parse(decodeURIComponent(localStorage.getItem(key))) : false;
  return data;
};
const setLocal = (key, value) => {
  localStorage.setItem(key, encodeURIComponent(JSON.stringify(value)));
};
const removeLocal = (key) => {
  localStorage.removeItem(key);
};

const getCookie = (key) => {
  var returnFlag = "";
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (key === cookiePair[0].trim()) {
      returnFlag = decodeURIComponent(cookiePair[1]);
    }
  }
  return returnFlag;
};
const fetchCookie = (key, headers) => {
  var returnFlag = "";
  var cookieArr = headers?.cookie?.split(";");
  for (var i = 0; i < cookieArr?.length; i++) {
    var cookiePair = cookieArr[i]?.split("=");
    if (key === cookiePair[0].trim()) {
      returnFlag = decodeURIComponent(cookiePair[1]);
    }
  }
  return returnFlag;
};

const setCookie = (key, value) => {
  var days = 7;
  if (value === "") {
    days = 0;
  }
  var date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  var expires = "expires=" + days === 0 ? new Date() : date.toGMTString();
  if (process.env.REACT_APP_MODE === "development") {
    document.cookie = key + "=" + encodeURIComponent(value) + ";" + expires + ";path=/;";
  } else {
    document.cookie = key + "=" + encodeURIComponent(value) + ";" + expires + ";path=/;SameSite=Strict;Secure=true";
  }
};

const fethcAllStorageClear = () => {
  sessionStorage.clear();
  localStorage.clear();

  var value = "";
  var days = 7;
  if (value === "") {
    days = 0;
  }
  var date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  var expires = "expires=" + days === 0 ? new Date() : date.toGMTString();

  var cookieArr = document?.cookie?.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i]?.split("=");
    cookiePair[0].trim();
    if (process.env.REACT_APP_MODE === "development") {
      document.cookie = cookiePair[0]?.trim() + "=" + encodeURIComponent(value) + ";" + expires + ";path=/;";
    } else {
      document.cookie =
        cookiePair[0]?.trim() + "=" + encodeURIComponent(value) + ";" + expires + ";path=/;SameSite=Strict;Secure=true";
    }
  }
};

export {
  getSession,
  fetchCookie,
  fethcAllStorageClear,
  setSession,
  removeSession,
  getLocal,
  getCookie,
  setLocal,
  setCookie,
  removeLocal,
};
