
import React from 'react';
import Axios from 'axios';
const AxiosInstance = Axios.create({
    timeout: 30000000,
    headers: {},
});


async function uploadData(presignedUrl, data, type) {
    return new Promise(resolve => {
        var config = {
            method: 'PUT',
            url: presignedUrl,
            data: data,
            headers: { 'Content-Type': type }
        };

        AxiosInstance(config).then(response => {
            resolve(response);
        }, error => {
            resolve({ success: false, message: error.message });
        })
    });
};

export { uploadData };