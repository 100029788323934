/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Form, FormGroup, Spinner } from "reactstrap";
import Logo from "../../assets/images/company/skatch.svg";
import Msg from "../../assets/lang/en.json";
import { Block, BlockContent, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import Head from "../../layout/head/Head";
import PageContainer from "../../layout/page-container/PageContainer";
import { verifyOtp } from "../../redux/Action/authAction";
import { getCookie, getSession } from "../../utils/storage";
import AuthFooter from "./AuthFooter";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, success, error, user, token, isOtpSend } = useSelector((state) => state?.auth);

  const [otp, setOtp] = useState("");
  const [otpData, setOtpData] = useState({});

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (!otp) {
      toast.warn(Msg.enterValidOtp);
      return 0;
    }
    if (otp.length != 4) {
      toast.warn(Msg.enterValidOtp);
      return 0;
    }
    var data = {
      verificationKey: otpData?.key,
      check: otpData?.label,
      otp: otp,
      countryCode: otpData?.countryCode,
      ProviderValue: otpData?.provider,
    };
    dispatch(verifyOtp(data));
  };

  const { handleSubmit } = useForm();

  useEffect(() => {
    if (getSession("_init")) {
      let data = getSession("_init");
      setOtpData(data);
    }
  }, []);

  useEffect(() => {
    if (success) {
      // toast.success(success);
      navigate("/");
    }
  }, [success]);

  useEffect(() => {
    if (getCookie("_jwt")) {
      navigate("/");
    }
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Verify OTP</BlockTitle>
              </BlockContent>
            </BlockHead>
            <form className="is-alter" onSubmit={onFormSubmit}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Enter OTP
                  </label>
                </div>
                <div className="form-control-wrap">
                  <OtpInput
                    value={otp}
                    onChange={(e) => setOtp(e)}
                    numInputs={4}
                    BlockHead
                    name="otp"
                    separator={<span>&nbsp;&nbsp;</span>}
                    inputStyle="otpIntputStyle"
                    containerStyle="containerStyle1"
                    shouldAutoFocus
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Button size="lg" className="btn-block" type="submit" style={{ background: "#d56c33", color: "#fff" }}>
                  {loading ? <Spinner size="sm" color="light" /> : <>Verify OTP </>}
                </Button>
              </FormGroup>
            </form>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};

export default VerifyOtp;
