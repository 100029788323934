/* eslint-disable */

import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Modal, ModalBody, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  PreviewCard,
  RSelect,
  Row,
} from "../../components/Component";
import SkillQuestions from "../../components/skillComponent/SkillQuestions";
import RichTextEditor from "../../components/skillComponent/TextEditor";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { deleteSkillsFromList, editSkillsFromList, getSkillById } from "../../redux/Action/skillsAction";

import { toast } from "react-toastify";
import { slugify } from "../../utils/validation";
import { useSearchParams } from "react-router-dom/dist";

const categoryOptionsEnglish = [
  {
    value: "reference",
    label: "Reference",
  },
  {
    value: "tech",
    label: "Tech",
  },
  {
    value: "open",
    label: "Open",
  },
];
const languageOptions = [
  {
    value: "english",
    label: "English",
  },
  {
    value: "arabic",
    label: "لقب",
  },
];

const SkillDetails = ({ match }) => {
  const { id } = useParams();
  const [questionLanguage, setQuestionLanguage] = useState("");
  const navigate = useNavigate();
  const { errors, register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams()
  let pageNo = 0;

  if (searchParams.get("p")) {
    pageNo = searchParams.get("p")
  }

  const { error, loading, success, skill } = useSelector((state) => state?.skills);
  const [activeTab, setActiveTab] = useState("1");

  const [noOfQuestion, setNoOfQuestion] = useState(skill?.noOfquestionsToDisplay ? skill?.noOfquestionsToDisplay : 5);

  const [showInput, setShowInput] = useState(false);
  const [skillsData, setSkillsData] = useState({
    nameEn: "",
    nameAr: "",
    skillType: "",
    retestDuration: "",
    timePerQuestion: 1,
  });
  const [passPercentage, setPassPercentage] = useState("");
  const [minRefCount, setMinRefCount] = useState("");
  const [instEn, setInstEn] = useState(
    "You can take the test only once every xx months If you leave the test before the end, you will not be able to resume.  The questions are selected randomly. You and other users can have different questions for the same skill test. Once you submit an answer, you can't come back to amend a previous answer. Read the questions and all answers before selecting your answer In some questions, you are required only one option. In others, you are asked to select all correct options. Read the question very well."
  );
  const [instAr, setInstAr] = useState(
    "يمكنك إجراء الاختبار مرة واحدة فقط كل 3 شهرًا إذا سبت الاختبار قبل النهاية ، فلن تتمكن من الرجوع اليه و اكماله. الأسئلة تتغير مع كل اختبار.ما فيش اثنين عندهم نفس الاسئلة بالظبط . اقرأ الأسئلة وكل الاقتراحات قبل اختيار الإجابة  الرجوع الى الوراء غير متاح لتعديل الإجابة على سؤال سابق. -في بعض الأسئلة ، مطلوب منك خيار واحد فقط. في أسئلةأخرى ، يُطلب منك تحديد جميع الخيارات الصحيحة. اقرأ السؤال جيدًا."
  );

  const { nameEn, nameAr, skillType, retestDuration, timePerQuestion } = skillsData;

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [view, setView] = useState({
    editSkill: false,
    deleteSkill: false,
  });

  const onAddSkillChange = (e) => {
    const { name, value } = e.target;
    setSkillsData({
      ...skillsData,
      [name]: value,
    });
  };

  const onCategoryChange = (value) => {
    setSkillsData({ ...skillsData, skillType: value?.value });
  };

  const onFormCancel = () => {
    setView({
      editSkill: false,
      deleteSkill: false,
    });
  };

  const toggleModal = (type) => {
    setView({
      editSkill: type === "editSkill" ? true : false,
      deleteSkill: type === "deleteSkill" ? true : false,
    });
  };

  const handleEditSkill = async (e) => {
    e.preventDefault();

    let englishId = skill.translations[0].skillTranslation._id;
    let arLanguageId = skill.translations[1].skillTranslation._id;

    let data = {
      englishId: englishId,
      arabicId: arLanguageId,
      englishName: nameEn,
      englishInstructions: instEn,
      skillType,
      arabicName: nameAr,
      arabicInstructions: instAr,
      slug: slugify(nameEn),
    };

    if (skillType === "tech") {
      data = { ...data, passPercent: +passPercentage };
      data = { ...data, noOfquestionsToDisplay: +noOfQuestion };
      data = { ...data, retestDuration: +retestDuration };
      data = { ...data, timePerQuestion: +timePerQuestion };
    } else if (skillType === "reference") {
      data = { ...data, minRefCount: +minRefCount };
    }

    if (
      skillType === "tech" &&
      nameEn &&
      nameAr &&
      passPercentage &&
      noOfQuestion &&
      retestDuration &&
      timePerQuestion
    ) {
      dispatch(editSkillsFromList(id, data));
      dispatch(getSkillById(id));
      onFormCancel();
    } else if (skillType === "reference" && minRefCount && nameEn && nameAr && noOfQuestion) {
      dispatch(editSkillsFromList(id, data));
      dispatch(getSkillById(id));
      onFormCancel();
    } else {
      toast.error("Please fill all fields in both languages");
    }
  };

  const handleEditSkillNoOfQuestion = async (e) => {
    e.preventDefault();

    let data = {};

    if (skillType === "tech") {
      data = { ...data, noOfquestionsToDisplay: +noOfQuestion };
    }

    dispatch(editSkillsFromList(id, data));
    dispatch(getSkillById(id));
  };

  // Delete Alert
  const deleteAlert = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSkillsFromList(id));
        Swal.fire("Deleted!", "Question has been deleted.", "success");
        navigate(-1);
      }
    });
  };

  useEffect(async () => {
    await dispatch(getSkillById(id));

    return () => { };
  }, [id, dispatch]);

  useEffect(() => {
    if (skill) {
      setSkillsData({
        ...skillsData,
        nameEn: skill?.translations[0]?.skillTranslation?.name,
        nameAr: skill?.translations[1]?.skillTranslation?.name,
        skillType: skill?.skillType,
      });

      if (skill?.skillType === "tech") {
        setPassPercentage(skill?.passPercent);
        setSkillsData({
          ...skillsData,
          retestDuration: skill?.retestDuration,
          timePerQuestion: skill?.timePerQuestion,
        });
      } else if (skill?.skillType === "reference") {
        setMinRefCount(skill?.minRefCount);
      }
    }

    return () => { };
  }, [skill]);

  useEffect(() => {
    setNoOfQuestion(skill?.noOfquestionsToDisplay ? skill?.noOfquestionsToDisplay : 5);
  }, [skill?.noOfquestionsToDisplay]);

  return (
    <React.Fragment>
      <Head
        title={skill && success ? `${skill?.translations[0]?.skillTranslation?.name} Details` : "Skill Details"}
      ></Head>
      ;
      {loading ? (
        <div
          style={{
            height: "200px",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spinner type="grow" color="dark" />
        </div>
      ) : skill && success ? (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  <strong
                    className="text-primary small"
                    style={{
                      fontSize: "2rem",
                      fontWeight: "700",
                    }}
                  >
                    {skill?.translations[0]?.skillTranslation?.name}
                  </strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      Type: <span className="text-base">{skill?.skillType}</span>
                    </li>
                    {skill?.skillType === "tech" ? (
                      <li>
                        Pass Percent: <span className="text-base">{skill?.passPercent}</span>
                      </li>
                    ) : skill?.skillType === "reference" ? (
                      <li>
                        Min. Reference: <span className="text-base">{skill?.minRefCount}</span>
                      </li>
                    ) : null}
                    <li>
                      {showInput === false ? (
                        <>
                          No Of Question &nbsp;&nbsp;
                          <span className="text-base">{noOfQuestion}</span>
                        </>
                      ) : null}

                      {showInput ? (
                        <input
                          type="number"
                          style={{ width: "20%", border: "1px solid #dbdfea" }}
                          value={noOfQuestion}
                          onChange={(e) => {
                            setNoOfQuestion(e.target.value);
                          }}
                        />
                      ) : null}

                      {showInput === false ? (
                        <>
                          <Button
                            className="toggle btn-icon d-md-none"
                            color="info"
                            style={{ padding: "5px", margin: "0px 0px 0px 16px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowInput(true);
                            }}
                          >
                            <Icon name="edit"></Icon>
                          </Button>
                          <Button
                            className="toggle d-none d-md-inline-flex"
                            color="info"
                            style={{ padding: "5px", margin: "0px 0px 0px 16px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowInput(true);
                            }}
                          >
                            <Icon name="edit"></Icon>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            className="toggle btn-icon d-md-none"
                            color="info"
                            style={{ padding: "5px", margin: "0px 0px 0px 16px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditSkillNoOfQuestion(e);
                              setShowInput(false);
                            }}
                          >
                            <Icon name="plus"></Icon>
                          </Button>
                          <Button
                            className="toggle d-none d-md-inline-flex"
                            color="info"
                            style={{ padding: "5px", margin: "0px 0px 0px 16px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditSkillNoOfQuestion(e);
                              setShowInput(false);
                            }}
                          >
                            <Icon name="plus"></Icon>
                          </Button>
                        </>
                      )}
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button color="light" outline className="bg-white d-none d-sm-inline-flex" onClick={() => navigate(pageNo === 0 ? -1 : `/skills?p=${pageNo}`)}>
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(pageNo === 0 ? -1 : `/skills?p=${pageNo}`);
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered">
              <div
                className="card-aside-wrap"
                id="user-detail-block"
                style={{
                  display: "inline-block",
                }}
              >
                <PreviewCard>
                  <Nav
                    tabs
                    className="mt-n3"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        margin: "0 15px",
                        display: "flex",
                      }}
                    >
                      {skill?.skillType === "tech" ? (
                        <NavItem>
                          <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({ active: activeTab === "1" })}
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggle("1");
                            }}
                          >
                            Questions
                          </NavLink>
                        </NavItem>
                      ) : null}
                      <NavItem>
                        <NavLink
                          tag="a"
                          href="#tab"
                          className={classnames({ active: activeTab === "2" })}
                          onClick={(ev) => {
                            ev.preventDefault();
                            toggle("2");
                          }}
                        >
                          Details
                        </NavLink>
                      </NavItem>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        margin: "0 15px",
                      }}
                    >
                      <NavItem>
                        <NavLink tag="div" href="#tab">
                          <Button
                            className="toggle btn-icon d-md-none"
                            color="info"
                            onClick={() => {
                              toggleModal("editSkill");
                            }}
                          >
                            <Icon name="edit"></Icon>
                          </Button>
                          <Button
                            className="toggle d-none d-md-inline-flex"
                            color="info"
                            onClick={() => {
                              toggleModal("editSkill");
                            }}
                          >
                            <Icon name="edit"></Icon>
                            <span>Edit</span>
                          </Button>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink tag="div" href="#tab">
                          <Button
                            className="toggle btn-icon d-md-none"
                            color="danger"
                            onClick={() => {
                              toggleModal("editSkill");
                              deleteAlert(id);
                            }}
                          >
                            <Icon name="delete"></Icon>
                          </Button>
                          <Button
                            className="toggle d-none d-md-inline-flex"
                            color="danger"
                            onClick={() => {
                              deleteAlert(id);
                            }}
                          >
                            <Icon name="trash"></Icon>
                            <span>Delete</span>
                          </Button>
                        </NavLink>
                      </NavItem>
                    </div>
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">{skill?.skillType === "tech" ? <SkillQuestions id={id} /> : null}</TabPane>
                    <TabPane tabId="2">
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus laudantium deleniti rerum
                        fugit et alias voluptatibus ea dolor a esse?
                      </p>
                    </TabPane>
                  </TabContent>
                </PreviewCard>
              </div>
            </Card>
          </Block>
        </Content>
      ) : null}
      {/* edit skill2 */}
      <Modal isOpen={view?.editSkill} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h5">
                <p style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span>Edit Skill</span>
                  <RxCross2
                    onClick={(e) => {
                      e.stopPropagation();
                      onFormCancel();
                    }}
                    style={{ width: "20px", height: "20px", color: "gray" }}
                  />
                </p>
              </BlockTitle>
              <BlockDes>
                <p>{view?.editSkill ? "Edit information of skill." : null}</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          {loading ? (
            <div
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <Spinner type="grow" color="dark" />
            </div>
          ) : success ? (
            <Block>
              <form
                onSubmit={(e) => {
                  handleEditSkill(e);
                }}
              >
                <Row className="g-3">
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="title">
                        Language
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="category"
                          options={languageOptions}
                          onChange={(value) => {
                            setQuestionLanguage(value?.value);
                          }}
                          defaultValue={questionLanguage}
                        />
                      </div>
                    </div>
                  </Col>

                  {questionLanguage === "english" ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <Row className="g-3">
                          {/* Title */}
                          <Col size="12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="product-title">
                                Title *
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="nameEn"
                                  value={nameEn}
                                  onChange={onAddSkillChange}
                                  required
                                  ref={register({
                                    required: "This field is required",
                                  })}
                                  placeholder="title"
                                />
                                {errors.title && <span className="invalid">{errors.title.message}</span>}
                              </div>
                            </div>
                          </Col>
                          {/* Instruction */}
                          <Col size="12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="product-title">
                                Instructions
                              </label>
                              <div className="form-control-wrap">
                                <RichTextEditor
                                  initialValue={instEn || ""}
                                  getValue={(value) => {
                                    setInstEn(value);
                                  }}
                                  arValue={false}
                                />
                              </div>
                            </div>
                          </Col>

                          {/*No of question  */}
                          <Col size="12">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="product-title"
                                style={{
                                  width: "100%",
                                  textAlign: "start",
                                }}
                              >
                                No Of Question
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="instAr"
                                  value={noOfQuestion}
                                  onChange={(e) => setNoOfQuestion(e.target.value)}
                                  dir="ltr"
                                  placeholder="Enter number of question in number"
                                />
                              </div>
                            </div>
                          </Col>

                          {/* Type */}
                          <Col size="12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="category">
                                Type *
                              </label>
                              <div className="form-control-wrap">
                                <RSelect
                                  name="category"
                                  options={categoryOptionsEnglish}
                                  onChange={onCategoryChange}
                                  //ref={register({ required: "This is required" })}
                                  defaultValue={skillType}
                                />
                                {errors.category && <span className="invalid">{errors.category.message}</span>}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div style={{ marginTop: "1rem" }}>
                        <Row className={"g-3"}>
                          {skillType === "tech" ? (
                            <>
                              <Col size={"6"}>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="product-title">
                                    Pass Percentage
                                  </label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="passPercentage"
                                      value={passPercentage}
                                      onChange={(e) => setPassPercentage(e.target.value)}
                                      placeholder="Enter pass percentage in number"
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col size={"6"}>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="product-title">
                                    Retest Duration
                                  </label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="retestDuration"
                                      value={retestDuration}
                                      required
                                      onChange={onAddSkillChange}
                                      placeholder="Enter retest duration in months"
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col size={"6"}>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="product-title">
                                    Time Per Question
                                  </label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="timePerQuestion"
                                      value={timePerQuestion}
                                      required
                                      onChange={onAddSkillChange}
                                      placeholder="Enter time per question in minutes"
                                    />
                                  </div>
                                </div>
                              </Col>
                            </>
                          ) : skillType === "reference" ? (
                            <Col size={"12"}>
                              <div className="form-group">
                                <label className="form-label" htmlFor="product-title">
                                  Minimum Reference Count
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="minRefCount"
                                    value={minRefCount}
                                    onChange={(e) => setMinRefCount(e.target.value)}
                                  />
                                </div>
                              </div>
                            </Col>
                          ) : null}
                        </Row>
                      </div>
                    </>
                  ) : questionLanguage === "arabic" ? (
                    <>
                      {/* Arabic */}
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <Row className="g-3">
                          {/* Title */}
                          <Col size="12">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="product-title"
                                style={{
                                  width: "100%",
                                  textAlign: "end",
                                }}
                              >
                                لقب
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="nameAr"
                                  value={nameAr}
                                  required
                                  onChange={onAddSkillChange}
                                  ref={register({
                                    required: "This field is required",
                                  })}
                                  dir="rtl"
                                />
                                {errors.title && <span className="invalid">{errors.title.message}</span>}
                              </div>
                            </div>
                          </Col>
                          {/* instruction  */}
                          <Col size="12">
                            <div
                              className="form-group"
                              style={{
                                textAlign: "end",
                              }}
                            >
                              <label
                                className="form-label"
                                htmlFor="product-title"
                                style={{
                                  width: "100%",
                                  textAlign: "end",
                                }}
                              >
                                تعليمات
                              </label>
                              <div className="form-control-wrap">
                                <RichTextEditor
                                  initialValue={instAr || ""}
                                  getValue={(value) => {
                                    setInstAr(value);
                                  }}
                                  arValue={true}
                                />
                              </div>
                              <div></div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : null}

                  <Col size="12">
                    <div style={{ display: "flex", flexDirection: "row-reverse", gap: "15px" }}>
                      <div>
                        <Button
                          color="danger"
                          type="button"
                          onClick={(e) => {
                            onFormCancel();
                          }}
                        >
                          <span>Cancel</span>
                        </Button>
                      </div>

                      <div>
                        <Button color="primary" type="submit">
                          <span>Edit Skill</span>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </Block>
          ) : null}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SkillDetails;
