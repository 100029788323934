import { toast } from "react-toastify";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";
import { getCookie } from "../../utils/storage";
import { getPostList, getPostListById, blockUser, removePost, isPostListLoading, removeHashtag, isPostListSuccess, isPostListError } from "../Apislices/postListSlice"


export const asyncgetAllPostList = (page, limit, filterObj) => async (dispatch) => {
    // 
    try {
        dispatch(isPostListLoading());
        setAuthorizationToken(getCookie("_jwt"));
        const response = await getMethod(`/admin/post/list?createrName=${filterObj?.createrName ? filterObj?.createrName : ""}&active=${filterObj?.active !== undefined ? filterObj?.active : ""}&hashtagName=${filterObj?.hashtagName ? encodeURIComponent(filterObj?.hashtagName) : ""}&postText=${filterObj?.postText ? filterObj?.postText : ""}&page=${page}&limit=${limit}`);
        if (response?.success) {
            dispatch(isPostListSuccess());
            dispatch(
                getPostList({
                    data: response?.data,
                    total: response?.total,
                })
            );
        } else {
            dispatch(isPostListError(response?.message));
        }
    } catch (error) {
        dispatch(isPostListError(error?.message));
    }
};


export const asyncGetPostListById = (id) => async (dispatch) => {

    dispatch(isPostListLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/post/${id}`);
    if (response?.success) {
        dispatch(isPostListSuccess());
        dispatch(getPostListById(response?.data));
    } else {
        dispatch(isPostListError(response?.message));
    }
    return response

};



export const asyncUpdateStatusPost = (id, data) => async (dispatch) => {

    dispatch(isPostListLoading());
    let response = await putMethod(`admin/active/${id}/post`, data);
    if (response?.success) {
        dispatch(isPostListSuccess());
    } else {
        dispatch(isPostListError(response?.message));
        toast.error(response?.message);

    }

    return response

};

export const asyncUpdateStatusUser = (id, data) => async (dispatch) => {

    dispatch(isPostListLoading());
    let response = await putMethod(`admin/block/${id}/user`, data);
    if (response?.success) {
        dispatch(isPostListSuccess());
    } else {
        dispatch(isPostListError(response?.message));
        toast.error(response?.message);

    }

    return response

};


export const asyncInactiveAllPostByUser = (data) => async (dispatch) => {


    dispatch(isPostListLoading());
    let response = await putMethod(`admin/inactive/creator/post`, data);
    if (response?.success) {
        dispatch(isPostListSuccess());
    } else {
        dispatch(isPostListError(response?.message));
        toast.error(response?.message);

    }

    return response

};




