import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    success: false,
    error: false,
    hashtagList: [],
    hashtagId: {},
    total: 0,
    jobTypeList: []
};

export const hashtagSlice = createSlice({
    name: "hashtagSlice",
    initialState,
    reducers: {
        getJobTypeList: (state, action) => {
            state.loading = false;
            state.jobTypeList = action.payload.data;
            state.success = true;
            state.error = false;
        },
        getHashtagList: (state, action) => {
            state.loading = false;
            state.hashtagList = action.payload.data;
            state.total = action.payload.total;
            state.success = true;
            state.error = false;
        },
        getHashtagById: (state, action) => {
            state.loading = false;
            state.hashtagId = action.payload;
            state.total = action.payload.total;
            state.success = true;
            state.error = false;
        },
        isHashtagLoading: (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
            state.hashtagList = [];
        },
        addHashtag: (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },
        removeHashtag: (state) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },
        isHashtagSuccess: (state) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },
        isHashtagError: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
            state.hashtagList = [];
        },
    },
});

export default hashtagSlice.reducer;

export const { getJobTypeList, isHashtagLoading, getHashtagList, getHashtagById, addHashtag, removeHashtag, isHashtagSuccess, isHashtagError } =
    hashtagSlice.actions;
