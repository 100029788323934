import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ReactDOMServer from "react-dom/server";
import {
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon } from "../../components/Component";
import { setIsOpen, setParentId } from "../../redux/Apislices/commentListSlice";
import moment from "moment";
import clsx from "clsx";
import Swal from "sweetalert2";
import {
  asyncGetCommentListById,
  asyncUpdateChildCommentStatus,
  asyncgetAllCommentList,
} from "../../redux/Action/commentListAction";

const ChildModal = ({ currentPage, itemPerPage }) => {
  const dispatch = useDispatch();
  const { isOpen, commentListId, parentId } = useSelector((state) => state?.commentListSlice);
  const [state, setState] = useState({
    loading: true,
    success: false,
    error: false,
  });

  const toggle = () => {
    dispatch(setIsOpen(!isOpen));
    dispatch(setParentId(""));
    dispatch(asyncgetAllCommentList(currentPage, itemPerPage));
    setState({ ...state, loading: true, success: false, error: false });
  };

  const handleBlockComment = async (replyId) => {
    let data = {
      active: false,
      replyId: replyId,
    };
    let res = await dispatch(asyncUpdateChildCommentStatus(parentId, data));
    await fetchCommentListById(parentId);
    return res;
  };

  const handleUnBlockComment = async (replyId) => {
    let data = {
      active: true,
      replyId: replyId,
    };
    let res = await dispatch(asyncUpdateChildCommentStatus(parentId, data));
    await fetchCommentListById(parentId);

    return res;
  };

  const inactiveCommentAlert = (replyId) => {
    Swal.fire({
      title: "Are you sure you want to delete the comment?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const tempContainer = document.createElement("div");
        ReactDOMServer.renderToString(<Spinner type="grow" color="dark" />, tempContainer);
        const loadingSwal = Swal.fire({
          title: "Deleting...",
          html: tempContainer.innerHTML,
          allowOutsideClick: false,
          showConfirmButton: false, // Hide the "OK" button
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          let res = await handleBlockComment(replyId);
          if (res?.message) {
            Swal.fire("Deleted!", "Your comment has been deleted.", "success");
          }
        } catch (error) {
          console.error("Error deleting comment:", error);
        } finally {
          loadingSwal.close();
        }
      }
    });
  };

  const activeCommentAlert = (replyId) => {
    Swal.fire({
      title: "Are you sure you want to Recover the comment?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const tempContainer = document.createElement("div");
        ReactDOMServer.renderToString(<Spinner type="grow" color="dark" />, tempContainer);
        const loadingSwal = Swal.fire({
          title: "Recovering...",
          html: tempContainer.innerHTML,
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        try {
          let res = await handleUnBlockComment(replyId);
          if (res?.message) {
            Swal.fire("Recovered!", "Your comment has been recovered.", "success");
          }
        } catch (error) {
          // Handle error if needed
          console.error("Error recovering comment:", error);
        } finally {
          loadingSwal.close();
        }
      }
    });
  };

  const fetchCommentListById = async (parentId) => {
    let res = await dispatch(asyncGetCommentListById(parentId));

    if (res?.success) {
      setState({ ...state, loading: false, success: true, error: false });
    } else {
      setState({ ...state, loading: false, success: false, error: true });
    }
  };
  useEffect(() => {
    if (parentId) {
      fetchCommentListById(parentId);
    }

    return () => {};
  }, [parentId]);
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Child Comment List
        </ModalHeader>
        <ModalBody>
          {state?.loading ? (
            <div
              style={{
                height: "200px",
                width: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Spinner type="grow" color="dark" />
            </div>
          ) : state?.error ? (
            "something went wrong..."
          ) : state?.success ? (
            <DataTableBody>
              <DataTableHead>
                <DataTableRow size="md">
                  <span>{"Date"}</span>
                </DataTableRow>

                <DataTableRow size="sm">
                  <span>Creator Name</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools" size="sm">
                  <span>Text</span>
                </DataTableRow>

                <DataTableRow className={clsx("nk-tb-col-tools")} size="sm">
                  <span>Status</span>
                </DataTableRow>
              </DataTableHead>
              <>
                {commentListId?.replies?.length ? (
                  commentListId?.replies
                    ?.slice()
                    .sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
                    ?.map((item, index) => {
                      return (
                        <DataTableItem key={item._id}>
                          <DataTableRow size="md">
                            <span style={{ fontSize: "10px" }}>
                              {item?.createdAt ? moment(item.createdAt).utc().format("DD MMM YY h:mm A") : ""}
                            </span>
                          </DataTableRow>

                          <DataTableRow size="sm">
                            <span className="tb-sub">{item?.user?.fullName ? item?.user?.fullName : ""}</span>
                          </DataTableRow>

                          <DataTableRow size="sm">
                            <div style={{ maxWidth: "250px" }}>
                              <span className="tb-sub">{item?.text ? item?.text : ""}</span>
                            </div>
                          </DataTableRow>

                          <DataTableRow size="sm">
                            <span className={clsx("tb-sub", item?.active ? "active-color" : "inactive-color")}>
                              {" "}
                              {item?.active ? "Active " : "Inactive"}
                            </span>
                          </DataTableRow>

                          <DataTableRow className="nk-tb-col-tools">
                            <ul className="nk-tb-actions gx-1 my-n1">
                              <li className="mr-n1">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    href="#more"
                                    onClick={(ev) => ev.preventDefault()}
                                    className="dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu right>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#remove"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            if (item?.active) {
                                              inactiveCommentAlert(item._id);
                                            } else {
                                              activeCommentAlert(item._id);
                                            }
                                          }}
                                        >
                                          <Icon name={item?.active ? "unlink-alt" : "link-alt"}></Icon>
                                          <span>{item?.active ? "Delete Comment" : "Recover Comment"}</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      );
                    })
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No Child Comment List found</span>
                  </div>
                )}
              </>
            </DataTableBody>
          ) : null}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChildModal;
