import { toast } from "react-toastify";
import { postMethod, setAuthorizationToken } from "../../utils/apiServices";
import { getFileLinkList, isFileLinkListError, isFileLinkListLoading, isFileLinkListSuccess } from "../Apislices/getLinksSlice";



export const asyncUpdateStatusPost = (data) => async (dispatch) => {
    dispatch(isFileLinkListLoading());
    const formData = new FormData();
    for (let file of data) {

        formData.append('file', file);
    }

    let response = await postMethod(`admin/upload/files`, formData);

    if (response?.success) {

        dispatch(getFileLinkList({
            data: response?.data,
            total: response?.data?.length,
        }
        ));
    } else {
        dispatch(isFileLinkListError(response?.message));
        toast.error(response?.message);

    }

    return response

};


export const asyncUploadCSV = (filename) => async (dispatch) => {

    dispatch(isFileLinkListLoading());

    const formData = new FormData();
    formData.append('file', filename);



    let response = await postMethod(`admin/upload/csv/post`, formData);

    if (response?.success) {

        dispatch(isFileLinkListSuccess());
    } else {
        dispatch(isFileLinkListError(response?.message));
        toast.error(response?.message);

    }

    return response

};





