import React from "react";
import { Modal, ModalBody, Row, Spinner } from "reactstrap";
import { Block, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button } from "../../components/Component";
import OtpInput from "react-otp-input";

const OtpCsvModal = (props) => {
  const { onCsvOtpModal, onFormCancel, handleCsvOtpFlow, loading, otp, setOtp, setCsvFile } = props;

  return (
    <React.Fragment>
      <Modal isOpen={onCsvOtpModal} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <BlockHead>
            <BlockHeadContent>
              <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <BlockTitle tag="h5">
                  <p style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span>Please Enter OTP </span>
                  </p>
                </BlockTitle>
                <BlockDes>
                  <p>{onCsvOtpModal ? "Please enter 4 digit otp " : ""}</p>
                </BlockDes>
              </div>
            </BlockHeadContent>
          </BlockHead>
          {loading ? (
            <div
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <Spinner type="grow" color="dark" />
            </div>
          ) : onCsvOtpModal ? (
            <Block>
              <form
                onSubmit={(e) => {
                  handleCsvOtpFlow(e);
                }}
              >
                <Row className="g-3">
                  <div className="col-12">
                    <div className="form-control-wrap" style={{ display: "flex", justifyContent: "center" }}>
                      <OtpInput
                        value={otp}
                        onChange={(e) => setOtp(e)}
                        numInputs={4}
                        BlockHead
                        name="otp"
                        separator={<span>&nbsp;&nbsp;</span>}
                        inputStyle="otpIntputStyle"
                        containerStyle="containerStyle1"
                        shouldAutoFocus
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                      <div>
                        <Button
                          color="danger"
                          type="button"
                          onClick={(e) => {
                            setCsvFile("");
                            onFormCancel();
                          }}
                        >
                          <span>Cancel</span>
                        </Button>
                      </div>

                      <div>
                        <Button color="primary" type="submit">
                          <span>{onCsvOtpModal ? "Verify Otp" : ""}</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Row>
              </form>
            </Block>
          ) : null}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default OtpCsvModal;
