import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkItem, LinkList } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import { setCookie } from "../../../../utils/storage";

const User = () => {
  const { user } = useSelector((state) => state?.auth);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);

  const handleSignout = () => {
    setCookie("_jwt", "");
    navigate("/auth-login");
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          {user?.profilePhoto?.url ? (
            <UserAvatar className="" image={`${process.env.REACT_APP_IMG_URL}${user?.profilePhoto?.url}`} />
          ) : (
            <UserAvatar theme="lighter" className="" icon={"user-alt"}></UserAvatar>
          )}
          <div className="user-info d-none d-md-block">
            <div className="user-status">Administrator</div>
            <div className="user-name dropdown-indicator">{user?.fullName}</div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <UserAvatar theme="primary" className="" icon={"user-alt"}></UserAvatar>

            <div className="user-info">
              <span className="lead-text">{user?.fullName}</span>
              <span className="sub-text">{user?.email}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="/user-profile" type={"/user-profile"} icon="user-alt" onClick={toggle}>
              View Profile
            </LinkItem>
            <LinkItem link="/user-profile" type={"/user-profile-setting"} icon="setting-alt" onClick={toggle}>
              Account Setting
            </LinkItem>

          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a onClick={handleSignout} style={{ cursor: "pointer" }}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
