
import React, { Suspense, useEffect, useState } from "react";
import { toast } from "react-toastify";
import BasicDetails from "./components/BasicDetails";
import Candidate from "./components/Candidate";
import Interview from "./components/Interview";
import JobPreview from "./components/JobPreview";
import Sidebar from "./components/Sidebar";
import { Link } from "react-router-dom";
import Loading from "./components/Loading";
import { setAuthorizationToken } from "../../../utils/apiServices";
import { getCookie } from "../../../utils/storage";
// import { setAuthorizationToken } from "./utils/apiServices";
// import { getCookie } from "./helpers/storage";
// import { setAuthorizationToken } from "../../utils/apiServices";


function PostJobRouting(props) {
  const {
    id,
    pathsList,
    profile,
    countryList,
    editJobsIdActive,
    jobDetails,
    getUpdatedJobData,
  } = props;

  const [error, setError] = useState(false);


  useEffect(() => {
    setAuthorizationToken(getCookie("_jwt"));

    if (!pathsList.includes(id)) {
      setError(true);
    }


    return () => {

    };
  }, []);

  return (
    <>
      <div
        className="container-fluid"
        style={{ width: "100%", padding: "0px 0px", marginTop: "70px" }}
      >
        <div className="container" style={{ width: "100%", maxWidth: "100%" }}>
          <div className="row">
            <Sidebar
              path={id}
              jobDetails={jobDetails}
              editJobsIdActive={editJobsIdActive}
              pathsList={pathsList}

            />
            <>
              {error ? (
                <div
                  className="col-xl-9 col-lg-8 col-md-12 col-12 addpadd1 "
                  style={{ padding: "0px 15px" }}
                >
                  <div
                    className={"row justify-content-center"}
                    style={{
                      width: "100%",
                      margin: "0px",
                      padding: "15px",
                      background: "transprent",
                    }}
                  >
                    <div
                      className="col-md-6 col-12"
                      style={{
                        textAlign: "center",
                        position: "relative",
                        height: "auto",
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/assets/images/illustrater/404.svg"
                        alt="404 not found"
                        className="img-fluid"
                        style={{
                          width: "100%",
                          height: "auto",
                          margin: "15px 0px",
                        }}
                      />
                      <Link to="/dashboard" style={{ position: "relative" }}>
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                            width: "100%",
                            fontWeight: "600",
                            color: "#fff",
                            background: "#D56C33",
                            padding: "15px",
                            margin: "15px",
                          }}
                        >
                          Go to Dashboard
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <>


                  {id === "basic-details" ? (
                    <Suspense fallback={<Loading />}>
                      <BasicDetails
                        profile={profile}
                        getUpdatedJobData={getUpdatedJobData}
                        countryList={countryList}
                        jobDetails={jobDetails}
                        next={
                          editJobsIdActive
                            ? "candidates-requirements?job=" + editJobsIdActive
                            : "candidates-requirements"
                        }

                      />
                    </Suspense>
                  ) : id === "candidates-requirements" ? (
                    <Suspense fallback={<Loading />}>
                      <Candidate
                        getUpdatedJobData={getUpdatedJobData}
                        jobDetails={jobDetails}
                        language={
                          profile?.settings?.language?._id
                            ? profile?.settings?.language?._id
                            : ""
                        }
                        back={
                          editJobsIdActive
                            ? "basic-details?job=" + editJobsIdActive
                            : "basic-details"
                        }
                        next={
                          editJobsIdActive
                            ? "interview-information?job=" + editJobsIdActive
                            : "interview-information"
                        }

                        profile={profile}

                      />
                    </Suspense>
                  ) : id === "interview-information" ? (
                    <Interview
                      profile={profile}
                      getUpdatedJobData={getUpdatedJobData}
                      jobDetails={jobDetails}
                      back={
                        editJobsIdActive
                          ? "candidates-requirements?job=" + editJobsIdActive
                          : "candidates-requirements"
                      }
                      next={
                        editJobsIdActive
                          ? "job-preview?job=" + editJobsIdActive
                          : "job-preview"
                      }

                    />
                  ) : id === "job-preview" ? (
                    <JobPreview
                      getUpdatedJobData={getUpdatedJobData}
                      jobDetails={jobDetails}
                      back={
                        editJobsIdActive
                          ? "interview-information?job=" + editJobsIdActive
                          : "interview-information"
                      }

                      profile={profile}
                    />
                  ) : (
                    toast.warn(
                      "Oops. we can't go further before you provide the required information"
                    )
                  )}
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostJobRouting;