import React, { useEffect, useRef, useState } from "react";
import { AiFillMinusCircle, AiOutlinePlus } from "react-icons/ai";
import { BiPlus } from "react-icons/bi";
import { BsPencilFill } from "react-icons/bs";
import { MdOutlinePlaylistAddCheck } from "react-icons/md";
import { toast } from "react-toastify";



import styles from "../style/Home.module.scss";

import clsx from "clsx";


import Msg from "../../../../assets/lang/en.json"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateJobDetails } from "../middleware/putAction";
import { getCookie, getSession, setSession } from "../../../../utils/storage";
import { setJobHeading, setJobLocation, setOtherBenefitsInput, setOtherRequirmentInp } from "../../../../redux/Apislices/postJobSlice";
import { getBenifitList, getEducationList, getRequiredQualificationList }
  from "../middleware/getAction";
import { setAuthorizationToken } from "../../../../utils/apiServices";
import RichTextEditor from "../../../../components/skillComponent/TextEditor";
function Candidate(props) {
  const {
    back,
    next,
    jobDetails,
    getUpdatedJobData,
  } = props;
  const router = useNavigate();
  const dispatch = useDispatch()


  const { jobHeading, jobLocation, otherRequirmentInp, otherBenefitsInput } = useSelector((state) => ({
    jobHeading: state?.postJobSlice?.candidateState?.jobHeading,
    jobLocation: state?.postJobSlice?.candidateState?.jobLocation,
    otherRequirmentInp: state?.postJobSlice?.candidateState?.otherRequirmentInp,
    otherBenefitsInput: state?.postJobSlice?.candidateState?.otherBenefitsInput,

  }))

  const [educationList, setEducationList] = useState([]);

  const [requiredQuallification, setRequiredQualification] = useState([]);

  const [benefitList, setBenifitList] = useState([]);


  const [jobDescription, setJobDescription] = useState(null);

  const [minEducation, setMinEducation] = useState(false);
  const [jobRequirements, setJobRequirements] = useState([]);
  const [otherRequredId, setOtherRequredId] = useState({
    id: "638de7478bf8c089c9bf0029",
    status: false
  })
  const [sumReqQualification, setSumReqQualification] = useState(0)

  const [otherRequirment, setOtherRequirment] = useState([]);

  const [benfits, setBenifits] = useState([]);
  const [otherBenefits, setOtherBenefits] = useState([]);
  const [isVideoQuestions, setVideoQuestions] = useState(false);
  const [questionsList, setQuestionsList] = useState([""]);
  const [status, setStatus] = useState(jobDetails?.status);






  const handleRequredQualification = async (data) => {

    if (sumReqQualification < 6) {
      setJobRequirements([...jobRequirements, data?._id]);

    } else {
      toast.error(Msg.requredQualification)
    }

  };




  const handleSecondStep = async (e) => {
    e.preventDefault();
    let data = {
      jobDescription: jobDescription,

      jobRequirements: jobRequirements,
      benefits: benfits,
      videoRequired: isVideoQuestions,
      videoQuestionaire: questionsList,
      status: status,
    };

    if (minEducation) {
      data = {
        ...data,
        education: minEducation,
      };
    } else {
      toast.error(Msg.minimumEducationLevel)

      return false
    }



    if (jobRequirements.length === 0 && otherRequirment.length === 0) {
      toast.error(Msg.reqQualificationError)
      return false
    }

    if (otherRequirment || otherRequirmentInp) {
      data = {
        ...data,
        otherQualifications: otherRequirment || otherRequirmentInp,
      };
    }

    if (benfits?.includes("63a03f2a081a11ea46e57dd8")) {
      data = {
        ...data,
        otherJobBenefits: otherBenefits,
      };
    }



    const jobDesLength = jobDescription ? jobDescription.replace(/<\/?[^>]+(>|$)/g, "")?.length : false;

    if (!jobDesLength) {

      toast.error(`Oops. we can't go further before you provide the required information`)


      return false

    } else if (isVideoQuestions && questionsList.length < 1) {
      toast.error(`Oops. we can't go further before you provide the required information`)

    }
    else {

      let response = await updateJobDetails(jobDetails?._id, data);
      if (response.success) {
        getUpdatedJobData();
        setSession("jobTypeStatus", false)
        router("/post-a-job/" + next);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
  };


  const findSumForRequrement = (jobRequirements, otherRequirment) => {

    let sum = jobRequirements.length + otherRequirment.length;
    setSumReqQualification(sum)

    if (sum > 6) {

      toast.error(Msg.requredQualification)
      return false
    }



  }







  useEffect(() => {


    setAuthorizationToken(getCookie("_jwt"));

    if (jobDetails) {

      setMinEducation(jobDetails?.education?._id || minEducation);

      setJobRequirements(
        jobDetails?.jobRequirements?.map((item) => item?._id) || jobRequirements
      );

      setBenifits(jobDetails?.benefits?.map((item) => item?._id) || benfits);

      setVideoQuestions(jobDetails?.videoRequired || isVideoQuestions);
      setQuestionsList(jobDetails?.videoQuestionaire || questionsList);
      dispatch(setJobLocation(jobDetails?.city?.translations[0]?.cityTranslation?.name));
      dispatch(setJobHeading(
        jobDetails?.jobCategory?.translations[0]?.jobCategoryTranslation
          ?.name || jobHeading
      ));

      setOtherRequirment(jobDetails?.otherQualifications || otherRequirment);

      setOtherBenefits(jobDetails?.otherJobBenefits || otherBenefits);

    }



    getEducationList().then((response) => {
      if (response?.success) {
        setEducationList(response?.data);
      }
    });


    getRequiredQualificationList().then((response) => {
      if (response?.success) {
        setRequiredQualification(response.data);
      }
    });

    getBenifitList().then((response) => {
      if (response?.success) {
        setBenifitList(response?.data);
      }
    });

    return () => { };
  }, [jobDetails]);



  useEffect(() => {


    if (jobDetails) {

      if (getSession("jobTypeStatus") && jobDetails?.jobCategory?._id) {

      } else if (!getSession("jobTypeStatus") && jobDetails?.jobDescription) {
        setJobDescription(jobDetails?.jobDescription || jobDescription);
      }



    }

  }, [jobDetails]);

  useEffect(() => {

    findSumForRequrement(jobRequirements, otherRequirment)
  }, [jobRequirements.length, otherRequirment.length])







  return (
    <>
      <div
        className={
          "col-xl-9 col-lg-8 col-md-12 col-12 addpadd1 " + styles.candCol9
        }
      >
        <div className={styles.Content}>
          <div className={styles.candTitle}>
            {jobHeading ? <h3>{jobHeading}</h3> : null}

            {jobLocation ? <p>Location: {jobLocation}</p> : null}
          </div>
          {status === "draft" ? (
            <div className={styles.candSave} style={{ color: "#00C353" }}>
              <MdOutlinePlaylistAddCheck
                style={{ color: "#00C353", width: "25px", height: "25px" }}
              />
              &nbsp;SAVED AS DRAFT
            </div>
          ) : (
            <div
              className={styles.candSave}
              onClick={(e) => setStatus("draft")}
              style={{ cursor: "pointer" }}
            >
              <img
                src="/assets/images/icons/Product-Icons.svg"
                alt=""
                className="img-fluid"
                style={{
                  width: "auto",
                  marginRight: "12px",
                  marginTop: "-5px",
                }}
              />
              SAVE AS DRAFT
            </div>
          )}
        </div>

        <div className={styles.postMain}>
          <h3 className={styles.postTitle}>Candidate's Requirements</h3>
          <div
            className={"row " + styles.postForm}
            style={{ paddingTop: "10px" }}
          >
            <div className="col-lg-12 col-md-12 col-xl-12">
              <div className="row">
                <div>
                  {/* Job description */}
                  <div className={styles.postBlock} id={"jobDescriptionInput"}>
                    <div>
                      <div className={styles.candidateFlex}>
                        <h4 className={styles.candidateTitle}>
                          {" "}
                          Job Description *{" "}
                        </h4>

                      </div>
                      <span
                        className={styles.textareaLine}
                        style={{ marginBottom: "5px" }}
                      >
                        Please mention if you have any specific requirements
                        here, we will check the candidates for you.
                      </span>
                    </div>

                    <div className={clsx("mt-4", styles.fixText)}>


                      <RichTextEditor
                        initialValue={jobDescription || ""}
                        getValue={(value) => setJobDescription(value)}
                        arValue={false}
                      />
                    </div>
                  </div>

                  {/* Minimum education */}
                  <div className="row" style={{ paddingTop: "10px" }}>
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className={styles.postBlock}>
                        <select
                          value={minEducation}
                          onChange={(e) => setMinEducation(e.target.value)}
                          id="education"
                          name="education"
                          required
                          spellCheck="false"
                        >
                          <option value={""}></option>
                          {educationList?.length
                            ? educationList?.map((items, index) => (
                              <option value={items?._id} key={index} >
                                {
                                  items?.translations[0]?.educationTranslation
                                    ?.label
                                }
                              </option>
                            ))
                            : null}
                        </select>
                        <span className={styles.placeholder}>
                          Minimum Education  <span style={{ fontSize: "16px", marginLeft: "5px" }}>*</span>
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-6 col-12"></div>
                  </div>



                  {/* Required qualification */}
                  <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                    <div>
                      <h4 className={styles.candidateTitle}>
                        Required qualifications *
                      </h4>
                      <p className={styles.candidateDesc}>
                        Please mention all what you require from the candidates
                        to provide
                      </p>
                    </div>
                    <div
                      className={styles.ChipBox}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {requiredQuallification?.length ? (

                        requiredQuallification?.filter(
                          (e) =>
                            e?.translations[0]?.jobRequirementTranslation?.name !== "Other"
                        )
                          .sort((a, b) =>
                            a?.translations[0]?.jobRequirementTranslation?.name.localeCompare(
                              b?.translations[0]?.jobRequirementTranslation?.name
                            )
                          )
                          ?.map((items, index) => (

                            <div
                              className={clsx(styles.Chip, "px-3")}
                              onClick={(e) => {
                                if (jobRequirements?.includes(items?._id)) {
                                  let findMandIndex = jobRequirements.indexOf(items?._id);
                                  jobRequirements.splice(findMandIndex, 1);
                                  setJobRequirements([...jobRequirements]);
                                } else {
                                  handleRequredQualification(items);
                                }
                              }}
                              key={index}
                              style={{
                                cursor: "pointer",
                                margin: "10px 10px",
                                background: jobRequirements?.includes(items?._id)
                                  ? "#004E52"
                                  : "#dddddd",
                                color: jobRequirements?.includes(items?._id)
                                  ? "#fff"
                                  : "#868686",
                              }}
                            >
                              <a style={{ width: "100%" }}>
                                {items?.translations[0]?.jobRequirementTranslation?.name}
                              </a>
                              <div>
                                {jobRequirements?.includes(items?._id) ? (
                                  <AiFillMinusCircle className={styles.icon2} />
                                ) : (
                                  <BiPlus className={styles.icon} />
                                )}
                              </div>
                            </div>
                          ))


                      ) : null}
                    </div>

                    <div
                      className={styles.ChipBox}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {true ? (

                        <div
                          className={clsx(styles.Chip, "px-3")}


                          style={{
                            cursor: "pointer",
                            margin: "10px 10px",
                            background: otherRequredId?.status
                              ? "#004E52"
                              : "#dddddd",
                            color: otherRequredId?.status ? "#fff"
                              : "#868686",
                          }}
                        >
                          <a style={{ width: "100%" }}>
                            {"Other"}
                          </a>
                          <div   >
                            {otherRequredId?.status ? (
                              <AiFillMinusCircle onClick={(e) => {
                                setOtherRequredId({ ...otherRequredId, status: false })
                              }} className={styles.icon2} />
                            ) : (
                              <BiPlus onClick={(e) => {
                                setOtherRequredId({ ...otherRequredId, status: true })
                              }} className={styles.icon} />
                            )}
                          </div>
                        </div>




                      ) : null}


                      {
                        // otherRequredId?.status &&
                        otherRequirment?.length > 0
                          ? otherRequirment?.map((items) => (
                            <div
                              key={`${Math?.random()}-${items}`}
                              className={clsx(styles.Chip, "px-3")}

                              style={{
                                cursor: "pointer",
                                margin: "10px 10px",
                                background: "#004E52",
                                color: "#fff",
                              }}
                            >
                              <a style={{ width: "100%" }}>{items}</a>
                              <div>
                                <AiFillMinusCircle
                                  className={styles.icon2}
                                  onClick={() => {
                                    let a = otherRequirment?.indexOf(items);

                                    if (a > -1) {
                                      otherRequirment?.splice(a, 1);

                                      setOtherRequirment([...otherRequirment]);

                                    }
                                  }}
                                ></AiFillMinusCircle>
                              </div>
                            </div>
                          ))
                          : ""}

                      {otherRequredId?.status &&
                        otherRequirment?.length < 3 ? (
                        <div
                          className={clsx(styles.Chip, "px-3")}
                          style={{
                            backgroundColor: "#fff7f4",
                            borderColor: "#fff7f4",
                          }}
                        >
                          <ul className={styles.list}></ul>
                          <a style={{ width: "100%" }}>
                            <input
                              type="text"
                              value={otherRequirmentInp}
                              onChange={(e) =>
                                dispatch(setOtherRequirmentInp(e.target.value))
                              }
                              onKeyUp={(e) => {
                                if (e.key === "Enter" && sumReqQualification < 6) {
                                  setOtherRequirment([
                                    ...otherRequirment,
                                    otherRequirmentInp,
                                  ]);
                                  dispatch(setOtherRequirmentInp(""));
                                } else if (sumReqQualification > 6) {

                                  toast.error(Msg.requredQualification)
                                  dispatch(setOtherRequirmentInp(""));
                                }


                              }}
                              id="addQualification"
                              name="addQualification"
                              placeholder="Add Qualification"
                            />
                          </a>
                          <div>
                            <BiPlus
                              className={styles.icon}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (sumReqQualification <= 6) {
                                  setOtherRequirment([
                                    ...otherRequirment,
                                    otherRequirmentInp,
                                  ]);
                                  dispatch(setOtherRequirmentInp(""));
                                } else if (sumReqQualification > 6) {

                                  toast.error(Msg.requredQualification)
                                  dispatch(setOtherRequirmentInp(""));
                                }



                              }}
                            ></BiPlus>
                          </div>
                        </div>
                      ) : null}
                    </div>


                  </div>

                  {/* Job benefits */}
                  <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <div>
                      <h4 className={styles.candidateTitle}>Job benefits</h4>
                      <p className={styles.candidateDesc}>
                        <span>Select the job benefits would you provide for your future employee.</span><br />
                        <span>Sharing the job benefits will make your ad more attractive to potential candidates.</span>

                      </p>
                    </div>
                    <div
                      className={clsx(styles.ChipBox)}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {benefitList?.length
                        ? benefitList

                          .filter(
                            (e) =>
                              e?.translations[0]?.jobBenefitTranslation
                                ?.name !== "Other"
                          )
                          .sort((a, b) =>
                            a?.translations[0]?.jobBenefitTranslation?.name.localeCompare(
                              b?.translations[0]?.jobBenefitTranslation?.name
                            )
                          )
                          .concat(
                            benefitList.filter(
                              (e) =>
                                e?.translations[0]?.jobBenefitTranslation
                                  ?.name === "Other"
                            )
                          )
                          ?.map((items, index) => (
                            <div
                              className={clsx(styles.Chip, "px-3")}
                              onClick={(e) => {
                                if (benfits?.includes(items?._id)) {
                                  let findMandIndex = benfits.indexOf(
                                    items?._id
                                  );
                                  benfits.splice(findMandIndex, 1);
                                  setBenifits([...benfits]);
                                } else {
                                  setBenifits([...benfits, items?._id]);
                                }
                              }}
                              key={index}
                              style={{
                                cursor: "pointer",
                                margin: "10px 10px",
                                background: benfits?.includes(items?._id)
                                  ? "#004E52"
                                  : "#dddddd",
                                color: benfits?.includes(items?._id)
                                  ? "#fff"
                                  : "#868686",
                              }}
                            >
                              <a style={{ width: "100%" }}>
                                {
                                  items?.translations[0]
                                    ?.jobBenefitTranslation?.name
                                }
                              </a>
                              <div>
                                {benfits?.includes(items?._id) ? (
                                  <AiFillMinusCircle
                                    className={styles.icon2}
                                  ></AiFillMinusCircle>
                                ) : (
                                  <BiPlus className={styles.icon}></BiPlus>
                                )}
                              </div>
                            </div>
                          ))
                        : null}

                      {benfits?.includes("63a03f2a081a11ea46e57dd8") &&
                        otherBenefits?.length > 0
                        ? otherBenefits?.map((items) => (
                          <div
                            key={`${Math?.random()}-${items}`}
                            className={clsx(styles.Chip, "px-3")}
                            onClick={(e) => {
                              setOtherBenefits([]);
                            }}
                            style={{
                              cursor: "pointer",
                              margin: "10px 10px",
                              background: "#004E52",
                              color: "#fff",
                            }}
                          >
                            <a style={{ width: "100%" }}>{items}</a>
                            <div>
                              <AiFillMinusCircle
                                className={styles.icon2}
                                onClick={() => {
                                  let a = otherBenefits?.indexOf(items);
                                  if (a > -1) {
                                    otherBenefits?.splice(a, 1);

                                    setOtherBenefits([...otherBenefits]);
                                  }
                                }}
                              ></AiFillMinusCircle>
                            </div>
                          </div>
                        ))
                        : ""}

                      {benfits?.includes("63a03f2a081a11ea46e57dd8") &&
                        otherBenefits?.length < 3 ? (
                        <div
                          className={styles.Chip}
                          style={{
                            backgroundColor: "#fff7f4",
                            borderColor: "#fff7f4",
                          }}
                        >
                          <ul className={styles.list}></ul>
                          <a style={{ width: "100%" }}>
                            <input
                              type="text"
                              value={otherBenefitsInput}
                              onChange={(e) =>
                                dispatch(setOtherBenefitsInput(e.target.value))
                              }
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  setOtherBenefits([
                                    ...otherBenefits,
                                    otherBenefitsInput,
                                  ]);
                                  dispatch(setOtherBenefitsInput(""));
                                }
                              }}
                              id="addBenefits"
                              name="addBenefits"
                              placeholder="Add Benefits"
                            />
                          </a>
                          <div>
                            <BiPlus
                              className={styles.icon}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setOtherBenefits([
                                  ...otherBenefits,
                                  otherBenefitsInput,
                                ]);
                                dispatch(setOtherBenefitsInput(""));
                              }}
                            ></BiPlus>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Introduction Video */}
                  <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                    <div>
                      <h4 className={styles.candidateTitle}>
                        Video Screening Questions
                      </h4>
                      <p className={styles.candidateDesc}>
                        Would you require pre-screening video questions?
                      </p>
                    </div>
                    <div className="d-flex">
                      <div>
                        <input
                          value={
                            isVideoQuestions != "null"
                              ? isVideoQuestions
                                ? true
                                : false
                              : ""
                          }
                          checked={
                            isVideoQuestions != "null"
                              ? isVideoQuestions
                                ? true
                                : false
                              : ""
                          }
                          onChange={(e) => setVideoQuestions(true)}
                          className=""
                          type="radio"
                          name="newsletter"
                        />
                        <label
                          style={{
                            lineHeight: "normal",
                            textAlign: "left",
                            fontSize: "16px",
                            fontWeight: "550",
                            padding: "0px 15px",
                            color: "#000000",
                            marginBottom: "0px",
                          }}
                        >
                          Yes
                        </label>
                      </div>
                      <div>
                        <input
                          value={
                            isVideoQuestions != "null"
                              ? isVideoQuestions
                                ? false
                                : true
                              : ""
                          }
                          checked={
                            isVideoQuestions != "null"
                              ? isVideoQuestions
                                ? false
                                : true
                              : ""
                          }
                          onChange={(e) => setVideoQuestions(false)}
                          className=""
                          type="radio"
                          name="newsletter"
                        />
                        <label
                          style={{
                            lineHeight: "normal",
                            textAlign: "left",
                            fontSize: "16px",
                            fontWeight: "550",
                            padding: "0px 15px",
                            color: "#000000",
                            marginBottom: "0px",
                          }}
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {isVideoQuestions === true ? (
                    <div style={{ paddingTop: "20px", paddingBottom: "15px" }}>
                      <div>

                        <p className={styles.candidateDesc}>
                          You can ask up to 5 questions to the candidates. They
                          will be prompted to reply in a short video
                        </p>
                      </div>
                      <div className={styles.candInput}>
                        <ul
                          className={styles.candBlock}
                          style={{ padding: "0px" }}
                        >
                          {questionsList?.length
                            ? questionsList?.map((items, index) => (
                              <li
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  borderBottom:
                                    index === questionsList?.length - 1
                                      ? "none"
                                      : "solid #fff 2px",
                                }}
                                key={index}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <span style={{ padding: "15px" }}>
                                    {index + 1}
                                  </span>
                                </div>
                                {items?._id ? (
                                  items?.title
                                ) : (
                                  <div
                                    className={styles.postBlock}
                                    style={{ width: "100%" }}
                                  >
                                    <input
                                      type="text"
                                      value={items}
                                      onChange={(e) => {
                                        const updateQuestion = [
                                          ...questionsList,
                                        ];
                                        updateQuestion[index] =
                                          e.target.value;
                                        setQuestionsList(updateQuestion);
                                      }}
                                      id="questionstext"
                                      name="questionstext"
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                )}
                                <div
                                  style={{
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {items?._id ? (
                                    <button
                                      style={{
                                        border: "none",
                                        background: "transparent",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "relative",
                                        margin: "0px 5px",
                                      }}
                                    >
                                      <BsPencilFill
                                        className={styles.inputIcon}
                                        style={{
                                          position: "relative",
                                          bottom: "unset",
                                          float: "unset",
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      ></BsPencilFill>
                                    </button>
                                  ) : null}
                                  <button
                                    onClick={(e) => {
                                      questionsList.splice(index, 1);
                                      setQuestionsList([...questionsList]);
                                    }}
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      position: "relative",
                                      margin: "0px 5px",
                                    }}
                                  >
                                    <AiFillMinusCircle
                                      className={styles.inputIcon}
                                      style={{
                                        position: "relative",
                                        bottom: "unset",
                                        float: "unset",
                                      }}
                                    ></AiFillMinusCircle>
                                  </button>
                                </div>
                              </li>
                            ))
                            : null}
                        </ul>
                        <div className={styles.candAdd}>
                          <button
                            onClick={(e) => {
                              if (questionsList?.length < 5) {
                                setQuestionsList([...questionsList, ""]);
                              }
                            }}
                            disabled={questionsList?.length < 5 ? false : true}
                            hidden={questionsList?.length < 5 ? false : true}
                            className={styles.addBtn}
                            type="submit"
                          >
                            ADD NEW QUESTION
                            <AiOutlinePlus
                              style={{
                                marginLeft: "10px",
                                marginTop: "-2px",
                              }}
                            ></AiOutlinePlus>
                          </button>
                          <p className={styles.addDesc}>
                            You can ask up to{" "}
                            <span style={{ fontWeight: "700" }}>
                              5 questions
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* Continue Button */}
                  <div className={styles.Buttons}>
                    <div className={styles.candBtn}>
                      <button
                        onClick={(e) => {
                          router("/post-a-job/" + back);
                        }}
                        className={styles.backBtn}
                        type="submit"
                      >
                        BACK
                      </button>
                    </div>
                    <div className={styles.candBtn}>
                      <button
                        onClick={(e) => {
                          handleSecondStep(e);
                        }}
                        className={styles.btn}
                        type="button"
                      >
                        CONTINUE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default Candidate;