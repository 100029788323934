import classnames from "classnames";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, Modal, ModalBody, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  OverlineTitle,
  PreviewCard,
  Row,
  Sidebar,
  UserAvatar,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { getHrById, verifyAccount } from "../../redux/Action/hrAction";
import { currentTime, findUpper, monthNames, todaysDate } from "../../utils/Utils";
import { useSearchParams } from "react-router-dom/dist";
import { findRightPageBasedOnUrl } from "../../utils/numberToArabicNumber";

const HrId = () => {
  const navigate = useNavigate();

  const { slug } = useParams();

  const dispatch = useDispatch();
  const { loading, hrId } = useSelector((state) => state?.hr);
  const [activeIconTab, setActiveIconTab] = useState("1");

  const [sideBar, setSidebar] = useState(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (slug) {
      dispatch(getHrById(slug));
    }

    return () => {};
  }, [slug]);

  // function to toggle sidebar
  const toggle = () => {
    setSidebar(!sideBar);
  };

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  let pageNo = 0;

  if (searchParams.get("p")) {
    pageNo = searchParams.get("p");
  }

  return (
    <React.Fragment>
      <Head title="HR Details"></Head>
      {loading ? (
        <div
          style={{
            height: "200px",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spinner type="grow" color="dark" />
        </div>
      ) : hrId ? (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  HR / <strong className="text-primary small">{hrId?.fullName}</strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      Account created at:{" "}
                      <span className="text-base">{moment(hrId?.createdAt)?.format("YYYY-MM-DD")}</span>
                    </li>
                    <li>
                      Company:{" "}
                      <Link to={`/company/${hrId?.company?._id}`} className="text-primary" target="_blank">
                        {hrId?.company?.companyName}
                      </Link>{" "}
                      <span className="text-base">
                        ({hrId?.company?.companyVerified ? "Verified" : "Not verified"})
                      </span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={() => {
                    if (findRightPageBasedOnUrl("action-by-hr-hr-details")) {
                      navigate(pageNo === 0 ? -1 : `/action-by-hr?p=${pageNo}`);
                    } else {
                      navigate(pageNo === 0 ? -1 : `/hr?p=${pageNo}`);
                    }
                  }}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={() => {
                    if (findRightPageBasedOnUrl("action-by-hr-hr-details")) {
                      navigate(pageNo === 0 ? -1 : `/action-by-hr?p=${pageNo}`);
                    } else {
                      navigate(pageNo === 0 ? -1 : `/hr?p=${pageNo}`);
                    }
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <PreviewCard>
                    <Nav
                      tabs
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          margin: "0 15px",
                          display: "flex",
                        }}
                      >
                        <NavItem>
                          <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({ active: activeIconTab === "1" })}
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggleIconTab("1");
                            }}
                          >
                            <Icon name="user" /> <span>Personal</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({ active: activeIconTab === "2" })}
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggleIconTab("2");
                            }}
                          >
                            <Icon name="building-fill" /> <span>Company</span>
                          </NavLink>
                        </NavItem>
                      </div>
                    </Nav>

                    <TabContent activeTab={activeIconTab}>
                      <TabPane tabId="1">
                        <div className="card-inner">
                          <Block>
                            <BlockHead>
                              <BlockTitle tag="h5">Personal Information</BlockTitle>
                            </BlockHead>
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Name</span>
                                  <span className="profile-ud-value">{hrId?.fullName?.split(" ")[0]}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Surname</span>
                                  <span className="profile-ud-value">{hrId?.fullName?.split(" ")[1]}</span>
                                </div>
                              </div>
                              {hrId?.mobile ? (
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Mobile Number</span>
                                    <span className="profile-ud-value">{hrId?.mobile}</span>
                                  </div>
                                </div>
                              ) : null}
                              {hrId?.email ? (
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Email Address</span>
                                    <span className="profile-ud-value">{hrId?.email}</span>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </Block>

                          <Block>
                            <BlockHead className="nk-block-head-line">
                              <BlockTitle tag="h6" className="overline-title text-base">
                                Additional Information
                              </BlockTitle>
                            </BlockHead>
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Joining Date</span>
                                  <span className="profile-ud-value">
                                    {moment(hrId?.createdAt)?.utc()?.format("YYYY-MM-DD hh:mm a")}
                                  </span>
                                </div>
                              </div>
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Nationality</span>
                                  <span className="profile-ud-value">
                                    {hrId?.nationality?.translations[0]?.countryTranslation?.name}
                                  </span>
                                </div>
                              </div>
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Experience</span>
                                  <span className="profile-ud-value">{hrId?.totalExperience} year</span>
                                </div>
                              </div>
                            </div>
                          </Block>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="card-inner">
                          <Block>
                            <BlockHead>
                              <BlockTitle tag="h5">Information</BlockTitle>
                            </BlockHead>
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Company Name</span>
                                  <span className="profile-ud-value">{hrId?.company?.companyName}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Registration Number</span>
                                  <span className="profile-ud-value">{hrId?.company?.registrationNumber}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Company Domain</span>
                                  <span className="profile-ud-value">{hrId?.company?.domain}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Address</span>
                                  <span className="profile-ud-value">{hrId?.company?.address[0]?.address}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Country</span>
                                  <span className="profile-ud-value">
                                    {hrId?.company?.address[0]?.country?.translations[0]?.countryTranslation?.name}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Province</span>
                                  <span className="profile-ud-value">
                                    {hrId?.company?.address[0]?.state?.translations[0]?.stateTranslation?.name}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">City</span>
                                  <span className="profile-ud-value">
                                    {hrId?.company?.address[0]?.city?.translations[0]?.cityTranslation?.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Block>

                          <Block>
                            <BlockHead className="nk-block-head-line">
                              <BlockTitle tag="h6">Documents</BlockTitle>
                            </BlockHead>

                            <table className="table table-orders">
                              <thead className="tb-odr-head">
                                <tr className="tb-odr-item">
                                  <th className="tb-odr-amount">
                                    <span className="tb-odr-total">File Name</span>
                                  </th>
                                  <th className="tb-odr-action">&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody className="tb-odr-body">
                                {hrId?.company?.files?.map((item) => {
                                  return (
                                    <tr className="tb-odr-item" key={item._id}>
                                      <td className="tb-odr-amount">
                                        <span className="tb-odr-total">
                                          <span className="amount">{item.title}</span>
                                        </span>
                                      </td>
                                      <td className="tb-odr-action">
                                        <div className="tb-odr-btns d-none d-md-inline">
                                          <Button
                                            color="primary"
                                            className="btn-sm"
                                            onClick={() => {
                                              window?.open(`${process.env.REACT_APP_IMG_URL}${item?.url}`, "_blank");
                                            }}
                                          >
                                            View
                                          </Button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>

                            {/* <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Joining Date</span>
                                  <span className="profile-ud-value">
                                    {moment(hrId?.createdAt).format("YYYY-MM-DD hh:mm a")}
                                  </span>
                                </div>
                              </div> */}
                            {/* </div> */}
                          </Block>
                        </div>
                      </TabPane>
                      <TabPane tabId="3">
                        <p>
                          Fugiat id quis dolor culpa eiusmod anim velit excepteur proident dolor aute qui magna. Ad
                          proident laboris ullamco esse anim Lorem Lorem veniam quis Lorem irure occaecat velit nostrud
                          magna nulla. Velit et et proident Lorem do ea tempor officia dolor. Reprehenderit Lorem
                          aliquip labore est magna commodo est ea veniam consectetur.
                        </p>
                      </TabPane>
                      <TabPane tabId="4">
                        <p>
                          Eu dolore ea ullamco dolore Lorem id cupidatat excepteur reprehenderit consectetur elit id
                          dolor proident in cupidatat officia. Voluptate excepteur commodo labore nisi cillum duis
                          aliqua do. Aliqua amet qui mollit consectetur nulla mollit velit aliqua veniam nisi id do
                          Lorem deserunt amet. Culpa ullamco sit adipisicing labore officia magna elit nisi in aute
                          tempor commodo eiusmod.
                        </p>
                      </TabPane>
                    </TabContent>
                  </PreviewCard>
                </div>

                <Sidebar toggleState={sideBar}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2 mt-5 mt-xxl-0">
                      <UserAvatar className="lg" theme="primary" text={findUpper(hrId?.name)} />
                      <div className="user-info">
                        <div className="badge badge-outline-light badge-pill ucap">{hrId?.role}</div>
                        <h5>{hrId?.name}</h5>
                        <span className="sub-text">{hrId?.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner card-inner-sm">
                    <ul className="btn-toolbar justify-center gx-1">
                      <li>
                        <Button
                          href="#tool"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="shield-off"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#mail"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="mail"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#download"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="download-cloud"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#bookmark"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="bookmark"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon text-danger"
                        >
                          <Icon name="na"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                  <div className="card-inner">
                    <div className="overline-title-alt mb-2">In Account</div>
                    <div className="profile-balance">
                      <div className="profile-balance-group gx-4">
                        <div className="profile-balance-sub">
                          <div className="profile-balance-amount">
                            <div className="number">
                              2,500.00 <small className="currency currency-usd">USD</small>
                            </div>
                          </div>
                          <div className="profile-balance-subtitle">Invested Amount</div>
                        </div>
                        <div className="profile-balance-sub">
                          <span className="profile-balance-plus text-soft">
                            <Icon className="ni-plus"></Icon>
                          </span>
                          <div className="profile-balance-amount">
                            <div className="number">1,643.76</div>
                          </div>
                          <div className="profile-balance-subtitle">Profit Earned</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <Row className="text-center">
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{hrId?.tasks}</span>
                          <span className="sub-text">Total Order</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{hrId?.projects}</span>
                          <span className="sub-text">Complete</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{hrId?.performed}</span>
                          <span className="sub-text">Progress</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">Additional</h6>
                    <Row className="g-3">
                      <Col size="6">
                        <span className="sub-text">User ID:</span>
                        <span>UD003054</span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Last Login:</span>
                        <span>{hrId?.lastLogin} 01:02 PM</span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">KYC Status:</span>
                        <span
                          className={`lead-text text-${
                            hrId?.kycStatus === "success"
                              ? "success"
                              : hrId?.kycStatus === "pending"
                              ? "info"
                              : hrId?.kycStatus === "warning"
                              ? "warning"
                              : "secondary"
                          }`}
                        >
                          {/* {hrId?.kycStatus.toUpperCase()} */}
                        </span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Register At:</span>
                        <span>Nov 24, 2019</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <OverlineTitle tag="h6" className="mb-3">
                      Groups
                    </OverlineTitle>
                    <ul className="g-1">
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          investor
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          support
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          another tag
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </Sidebar>

                {sideBar && <div className="toggle-overlay" onClick={() => toggle()}></div>}
              </div>
            </Card>
          </Block>
        </Content>
      ) : null}
    </React.Fragment>
  );
};
export default HrId;
