import { getMethod, setAuthorizationToken } from "../../utils/apiServices";
import { getCookie } from "../../utils/storage";
import { getActionByHrList, isActionByHrListError, isActionByHrListLoading, isActionByHrListSuccess } from "../Apislices/actionByHrSlice";


export const asyncgetAllActionByHrList = (page, search = "") => async (dispatch) => {


    dispatch(isActionByHrListLoading());
    setAuthorizationToken(getCookie("_jwt"));

    if (search?.length) {
        const response = await getMethod(`/admin/list/actionsOfHr?search=${search}&page=${page}`);
        if (response?.success) {
            dispatch(isActionByHrListSuccess());
            dispatch(
                getActionByHrList({
                    data: response?.data,
                    total: response?.total,
                })
            );
        } else {
            dispatch(isActionByHrListError(response?.message));

        }

        return response;
    } else {
        const response = await getMethod(`/admin/list/actionsOfHr?page=${page}`);
        if (response?.success) {
            dispatch(isActionByHrListSuccess());
            dispatch(
                getActionByHrList({
                    data: response?.data,
                    total: response?.total,
                })
            );
        } else {
            dispatch(isActionByHrListError(response?.message));
        }

        return response;
    }


};










