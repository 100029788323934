import { deleteMethod, getMethod, setAuthorizationToken } from "../../../../utils/apiServices";
import { getCookie } from "../helpers/storage";


const getJobTypeList = async () => {
  // setAuthorizationToken(getCookie("_jwt"));

  let response = await getMethod("admin/job/jobType-list");
  return response;
};

const getCityListByCountry = async (id) => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await getMethod("admin/getCitybyCountry/" + id);
  return response;
};

const getStateList = async (id) => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await getMethod("admin/state-list/" + id);
  return response;
};


const getSalaryList = async () => {
  let response = await getMethod("admin/job/salary-list");
  return response;
};

const getExperinceList = async () => {
  let response = await getMethod("admin/job/experience-list");
  return response;
};


const getEducationList = async () => {
  let response = await getMethod("admin/job/education-list");
  return response;
};

const getRequiredQualificationList = async () => {
  let response = await getMethod("admin/job/jobRequirement-list");
  return response;
};


const getBenifitList = async () => {
  let response = await getMethod("admin/job/benefits-list");
  return response;
};

const getSkillList = async () => {
  let response = await getMethod("admin/job/skills-list");
  return response;
};

const getCompanyList = async (query = {}) => {
  setAuthorizationToken(getCookie("_jwt"));
  const response = await getMethod(`/admin/company/list`, query);
  return response;
};

const getCountryList = async () => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await getMethod("admin/country/list");
  return response;
};
const getCityList = async (id) => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await getMethod("admin/city-list/" + id);
  return response;
};
const getDeleteJob = async (id) => {
  let response = await deleteMethod("admin/job/" + id + "/delete");
  return response;
};

// 

const getLanguages = async () => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await getMethod("admin/getLanguage");
  return response;
};

const getProfile = async () => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await getMethod("admin/profile");
  return response;
};
const getHrInqueryList = async () => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await getMethod("/admin/inquiry/list");
  return response;
};







const getCompanyDetails = async (data) => {
  let response = await getMethod("admin/check/company", data);
  return response;
};

const getSearchDomain = async (params) => {
  let response = await getMethod("admin/comapny-domain-verified", params);
  return response;
};

const getJobCityList = async () => {
  let response = await getMethod("admin/job/city-list");
  return response;
};









const getJobsList = async (params = "", page = 1, limit = 5) => {
  let response = await getMethod(
    `admin/job/list?page=${page}&limit=${limit}`,
    params
  );
  return response;
};

const getJobsFullList = async (params, page = 1, limit = 3) => {
  let response = await getMethod(`admin/job/list`, params);
  return response;
};

const getDashboardInsights = async () => {
  let response = await getMethod("admin/dashboard/insights");
  return response;
};

const getJobDetailsfetch = async (id) => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await getMethod(`/admin/get/${id}/job`);

  return response;


};

const getApplyCandidate = async (id, page = 1, limit = 20, query) => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await getMethod(`admin/job/${id}/applied-candidates`, { page, limit, query });
  return response;
};

const getAppoinmentCandidate = async (id, page = 1, limit = 20, query) => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await getMethod(`admin/job/${id}/appointment-data`, { page, limit, query });
  return response;
};



const getJobDetailsfetchpop = async (id) => {
  let response = await getMethod("admin/job/nonepopulate/" + id);
  return response;
};



const getCloseJob = async (id) => {
  let response = await deleteMethod("admin/job/" + id + "/delete");
  return response;
};

const getSkillsByJobType = async (id) => {
  let response = await getMethod(`job-types/skills/${id}`);
  return response;
};

const getRoleByJobType = async (id) => {
  let response = await getMethod(`job-types/jobCategory/${id}`);
  return response;
};

const getCoordinates = async (lat, long) => {
  let response = await getMethod(`auth/getCoordinates?lat=${lat}&long=${long}`);
  return response;
};

const fetchCityFromCoordinates = async (city) => {
  let response = await getMethod(`auth/getCoordinatesFromCity?city=${city}`);
  return response;
};

const hrJobsList = async () => {
  let response = await getMethod(`admin/job/list`);
  return response;
};

const getSkillsById = async (id) => {
  let response = await getMethod(`admin/skill/${id}`);
  return response;
};

const getCountryCode = async () => {
  let response = await getMethod(`users/country/code`);
  return response;
};

const candidateProfile = async (id, jobId) => {
  let response = await getMethod(`admin/user/profile/${id}?job=${jobId}`);
  return response;
};

const getFiles = async (id) => {
  let response = await getMethod(`file/excelData/${id}`);
  return response;
};

const userChatList = async () => {
  let response = await getMethod(`admin/chat-list`);
  return response;
};

const userChatMessaages = async (id) => {
  let response = await getMethod(`/admin/get-message/${id}`);
  return response;
};

const hrInterviewList = async () => {
  let response = await getMethod(`admin/interview/list`);
  return response;
};

const hrExportList = async (id) => {
  let response = await getMethod(`/file/excelData/${id}`);
  return response;
};

const jobDescriptionTemplate = async (id) => {
  let response = await getMethod(`/job-category/${id}/data`);
  return response;
};

const getHrInfoFun = async () => {
  let response = await getMethod(`/admin/get/hr-info`);
  return response;
};

const getNotificationCountApi = async () => {
  let response = await getMethod(`/admin/notification/count`);
  return response;
};

const getNotificationListApi = async () => {
  let response = await getMethod(`/admin/notification-list`);
  return response;
};

const getJobConfigStatusApi = async () => {
  let response = await getMethod(`/admin/check/jobConfiguration`);
  return response;
};

const getReactivateApi = async (jobId) => {

  let response = await getMethod(`/home/reactivate/job?jobId=${jobId}`);
  return response;
};



export {
  getCompanyList,
  getReactivateApi,
  getJobConfigStatusApi,
  getJobsFullList,
  getNotificationListApi,
  getNotificationCountApi,
  getCloseJob,
  getHrInqueryList,
  getHrInfoFun,
  jobDescriptionTemplate,
  getLanguages,
  getJobDetailsfetch,
  getJobDetailsfetchpop,
  getDeleteJob,
  getExperinceList,
  getDashboardInsights,
  getJobsList,
  getProfile,
  getSalaryList,
  getBenifitList,
  getRequiredQualificationList,
  getSkillList,
  getEducationList,
  getJobCityList,
  getJobTypeList,
  getSearchDomain,
  getCountryList,
  getStateList,
  getCityList,
  getCompanyDetails,
  getSkillsByJobType,
  getRoleByJobType,
  getCoordinates,
  fetchCityFromCoordinates,
  hrJobsList,
  getCityListByCountry,
  getSkillsById,
  getCountryCode,
  candidateProfile,
  getFiles,
  userChatList,
  userChatMessaages,
  hrInterviewList,
  hrExportList,
  getApplyCandidate,
  getAppoinmentCandidate
};
