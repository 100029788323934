import React, { useEffect, useState } from "react";
// import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";

import Loading from "./PostJob/components/Loading";
import { useNavigate, useLocation } from "react-router-dom";
import { getJobDetailsfetch, getProfile } from "./PostJob/middleware/getAction";
import PostJobRouting from "./PostJob/PostJobRouting";
import { setAuthorizationToken } from "../../utils/apiServices";
import { getCookie, getSession } from "../../utils/storage";
import { useParams } from "react-router-dom";

const PostAJob = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobId = searchParams.get("job");
  const router = useNavigate();
  const params = useParams();
  const [isError, setIsError] = useState(false);

  const [path, setPath] = useState("basic-details");
  const [profileData, setProfileData] = useState(false);
  const postJobPaths = ["basic-details", "candidates-requirements", "interview-information", "job-preview"];

  const [editJobsIdActive, setEditJobsActiveId] = useState(false);

  const [jobDetails, setJobDetails] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const getUpdatedJobData = async (jobId) => {
    if (jobId) {
      setPostLoading(true);
      await getJobDetailsfetch(jobId).then((res) => {
        if (res?.success) {
          setJobDetails(res?.data);
          setPostLoading(false);
        } else {
          toast.error(res.message);
          setPostLoading(false);
          router("/jobs");
        }
      });
    }
  };

  useEffect(() => {
    if (params?.id && postJobPaths?.includes(params?.id)) {
      setPath(params?.id);
    } else {
      setIsError(true);
    }

    if (getSession("jobData")) {
      setEditJobsActiveId(getSession("jobData").jobId.toString());
    } else if (jobId) {
      setEditJobsActiveId(jobId);
    }
    if (editJobsIdActive) {
      getUpdatedJobData(editJobsIdActive);
    }
  }, [params?.id, editJobsIdActive]);

  return (
    <>
      {postLoading ? (
        <Loading />
      ) : (
        <>
          {isError ? (
            <div>Error 404</div>
          ) : (
            <>
              <PostJobRouting
                setEditJobsActiveId={setEditJobsActiveId}
                jobDetails={jobDetails}
                getUpdatedJobData={getUpdatedJobData}
                editJobsIdActive={editJobsIdActive}
                id={path}
                profile={[]}
                countryList={[]}
                pathsList={postJobPaths}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default PostAJob;
