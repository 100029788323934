import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    success: false,
    error: false,
    commentList: [],
    commentListId: {},
    total: 0,
    isOpen: false,
    parentId: "",
};

export const commentListSlice = createSlice({
    name: "commentListSlice",
    initialState,
    reducers: {

        getCommentList: (state, action) => {
            state.loading = false;
            state.commentList = action.payload.data;
            state.total = action.payload.total;
            state.success = true;
            state.error = false;
        },
        getCommentListById: (state, action) => {
            state.loading = false;
            state.commentListId = action.payload;
            state.success = true;
            state.error = false;
        },

        blockUser: (state) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },

        removeComment: (state) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },
        isCommentListLoading: (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
            state.commentList = [];
        },
        isCommentListSuccess: (state) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },
        isCommentListError: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
            state.commentList = [];
        },
        setIsOpen: (state, action) => {
            state.isOpen = action.payload
        },
        setParentId: (state, action) => {
            state.parentId = action.payload
        }
    },
});

export default commentListSlice.reducer;

export const { setParentId, setIsOpen, getCommentList, getCommentListById, blockUser, removeComment, isCommentListLoading, isCommentListSuccess, isCommentListError } =
    commentListSlice.actions;
