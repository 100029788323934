import React, { useState } from "react";
import {



  Button,
  Col,
  Icon,

  RSelect,
  Row,
} from "../Component";
import {

  Modal,
  ModalBody,

  ModalHeader,

} from "reactstrap";
import { toast } from "react-toastify";
import { addCountry, editCountryFromList, getAllCountry, getCountryById } from "../../redux/Action/countryAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";


const AddCountry = (props) => {
  const { modal, toggle, showName, idValue, currentPage, itemPerPage, editValue } = props;
  const { error, loading, success, countryId } = useSelector((state) => state?.country)

  const dispatch = useDispatch();
  const [form, setForm] = useState({
    code: "",
    countryAr: "",
    countryEn: "",
    shortSymbol: "",
  });

  const [editForm, setEditForm] = useState({
    code: editValue?.code,
    countryAr: editValue?.countryAr,
    countryEn: editValue?.countryEn,
    shortSymbol: editValue?.shortSymbol,
  });
  const [countryLanguage, setCountryLanguage] = useState("english");

  const languageOptions = [
    {
      value: "english",
      label: "English",
    },
    {
      value: "arabic",
      label: "لقب",
    },
  ];

  const handleCountryAdd = async (event) => {
    event.preventDefault();

    let { code, countryAr, countryEn, shortSymbol } = form;

    let data = {
      countryList: [
        {
          name: countryEn,
          language: "62e7f3b038629a3473e07310",
        },
        {
          name: countryAr,
          language: "62e7f4bd38629a3473e07313",
        },
      ],
      code: code,
      shortSymbol: shortSymbol.toUpperCase(),
    };

    if (code && countryAr && countryEn && shortSymbol) {
      await dispatch(addCountry(data));
      await dispatch(getAllCountry("", currentPage, itemPerPage));

      toggle();
    } else {
      toast.error("Please fill all fields in both languages");
    }
  };

  const handleCountryEdit = async (event) => {
    event.preventDefault();

    let { code, countryAr, countryEn, shortSymbol } = editForm;
    let englishId = countryId.translations[0].countryTranslation._id;
    let arLanguageId = countryId.translations[1].countryTranslation._id;
    let data = {
      englishId: englishId,
      englishName: countryEn,
      arabicId: arLanguageId,
      arabicName: countryAr,
      code: code,
      shortSymbol: shortSymbol,
    };

    if (code && countryAr && countryEn && shortSymbol && englishId && arLanguageId) {
      await dispatch(editCountryFromList(idValue, data));
      await dispatch(getAllCountry("", currentPage, itemPerPage));
      //  onFormCancel();
      toggle();
    } else {
      toast.error("Please fill all fields in both languages");
    }
  };

  const handleChange = (e) => {
    let { checked, type, name, value, files } = e.target;

    if (type === "checkbox") {
      setForm({
        ...form,
        [name]: checked,
      });
    } else if (type === "file") {
      setForm({
        ...form,
        [name]: files,
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };

  const handleEditChange = (e) => {
    let { checked, type, name, value, files } = e.target;

    if (type === "checkbox") {
      setEditForm({
        ...editForm,
        [name]: checked,
      });
    } else if (type === "file") {
      setEditForm({
        ...editForm,
        [name]: files,
      });
    } else {
      setEditForm({
        ...editForm,
        [name]: value,
      });
    }
  };

  return (
    <>
      {showName === "addCountry" ? (
        <div>
          <Modal isOpen={modal} toggle={toggle} className={``}>
            <ModalHeader toggle={toggle}> {showName === "addCountry" ? "Add Country" : "Edit Country"}</ModalHeader>
            <ModalBody>
              <form
                onSubmit={(e) => {
                  if (showName === "addCountry") {
                    handleCountryAdd(e);
                  } else if (showName === "editCountry") {
                    handleCountryEdit(e);
                  }
                }}
              >
                <Row className="g-3">
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="title">
                        Language
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="category"
                          options={languageOptions}
                          onChange={(value) => {
                            setCountryLanguage(value?.value);
                          }}
                          defaultValue={countryLanguage}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="title">
                        Country Code
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="number"
                          className="form-control"
                          name="code"
                          placeholder="971"
                          value={form.code}
                          // onChange={(e) => setCode(e.target.value)}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label" htmlFor="title">
                        Country Short Name
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          name="shortSymbol"
                          placeholder="UAE"
                          value={form.shortSymbol}
                          // onChange={(e) => setShortSymbol(e.target.value)}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </Col>
                  {countryLanguage === "english" ? (
                    <>
                      {/*  Country Name */}
                      <Col size="12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="title">
                            Country Name
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control"
                              name="countryEn"
                              placeholder="Country"
                              value={form.countryEn}
                              // onChange={(e) => setCountryEn(e.target.value)}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : countryLanguage === "arabic" ? (
                    <>
                      {/* Questions */}
                      <Col size="12">
                        <div className="form-group">
                          <div
                            style={{
                              textAlign: "end",
                            }}
                          >
                            <label className="form-label" htmlFor="title1">
                              اسم الدولة
                            </label>
                          </div>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control"
                              name="countryAr"
                              placeholder="دولة"
                              value={form.countryAr}
                              // onChange={(e) => setCountryAr(e.target.value)}
                              onChange={handleChange}
                              dir="rtl"
                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : null}

                  <Col size="12">
                    <Button color="primary" type="submit">
                      <Icon className="plus"></Icon>
                      <span>Add</span>
                    </Button>
                  </Col>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </div>
      ) : showName === "editCountry" ? (
        <div>
          <Modal isOpen={modal} toggle={toggle} className={``}>
            <ModalHeader toggle={toggle}> {showName === "addCountry" ? "Add Country" : "Edit Country"}</ModalHeader>
            <ModalBody>
              <form
                onSubmit={(e) => {
                  if (showName === "addCountry") {
                    handleCountryAdd(e);
                  } else if (showName === "editCountry") {
                    handleCountryEdit(e);
                  }
                }}
              >
                <Row className="g-3">
                  <Col size="12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="title">
                        Language
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="category"
                          options={languageOptions}
                          onChange={(value) => {
                            setCountryLanguage(value?.value);
                          }}
                          defaultValue={countryLanguage}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="title">
                        Country Code
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="number"
                          className="form-control"
                          name="code"
                          placeholder="971"
                          value={editForm.code}
                          // value = {countryId?.code ?countryId?.code:""}

                          // onChange={(e) => setCode(e.target.value)}
                          onChange={handleEditChange}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label" htmlFor="title">
                        Country Short Name
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          name="shortSymbol"
                          placeholder="UAE"
                          value={editForm.shortSymbol}
                          // value = {countryId?.shortSymbol ?countryId?.shortSymbol:""                        }
                          // onChange={(e) => setShortSymbol(e.target.value)}
                          onChange={handleEditChange}
                        />
                      </div>
                    </div>
                  </Col>
                  {countryLanguage === "english" ? (
                    <>
                      {/*  Country Name */}
                      <Col size="12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="title">
                            Country Name
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control"
                              name="countryEn"
                              placeholder="Country"
                              value={editForm.countryEn}
                              // value={countryEnValue? countryEnValue:""}
                              // onChange={(e) => setCountryEn(e.target.value)}
                              onChange={handleEditChange}
                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : countryLanguage === "arabic" ? (
                    <>
                      {/* Questions */}
                      <Col size="12">
                        <div className="form-group">
                          <div
                            style={{
                              textAlign: "end",
                            }}
                          >
                            <label className="form-label" htmlFor="title1">
                              اسم الدولة
                            </label>
                          </div>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control"
                              name="countryAr"
                              placeholder="دولة"
                              value={editForm.countryAr}
                              // value={countryArValue? countryArValue:""}

                              // onChange={(e) => setCountryAr(e.target.value)}
                              onChange={handleEditChange}
                              dir="rtl"
                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : null}

                  <Col size="12">
                    <Button color="primary" type="submit">
                      <Icon className="plus"></Icon>
                      <span>Edit</span>
                    </Button>
                  </Col>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </div>
      ) : null}
    </>
  );
};

export default AddCountry;
