import { toast } from "react-toastify";
import { deleteMethod, getMethod, postMethod, putMethod } from "../../utils/apiServices";
import {
  addquestions,
  getSingleQuestion,
  isquestionsError,
  isquestionsLoading,
  isquestionsSuccess,
  questions,
} from "../Apislices/skillQuestionSlice";

export const showQuestions = (id) => async (dispatch) => {
  try {
    dispatch(isquestionsLoading());
    let response = await getMethod(`admin/questions/${id}/list`);
    if (response?.success) {
      dispatch(isquestionsSuccess());
      dispatch(questions({ data: response?.data, total: response?.total }));
    } else {
      dispatch(isquestionsError(response?.message));
    }
  } catch (error) {
    dispatch(isquestionsError(error?.message));
  }
};

export const addQuestionsToSkill = (data) => async (dispatch) => {
  try {
    dispatch(isquestionsLoading());
    let response = await postMethod(`admin/questions/add`, data);
    if (response?.success) {
      dispatch(isquestionsSuccess());
      toast.success(response?.message);
      // dispatch(addquestions({ data: response?.data, total: response?.total }));
    } else {
      dispatch(isquestionsError(response?.message));
    }
  } catch (error) {
    dispatch(isquestionsError(error?.message));
  }
};

export const getQuestionById = (id) => async (dispatch) => {
  try {
    dispatch(isquestionsLoading());
    let response = await getMethod(`admin/question/${id}`);

    if (response?.success) {
      dispatch(isquestionsSuccess());
      dispatch(getSingleQuestion(response?.data));
    } else {
      dispatch(isquestionsError(response?.message));
    }
  } catch (error) {
    dispatch(isquestionsError(error?.message));
  }
};

export const editQuestion = (id, data) => async (dispatch) => {
  try {
    dispatch(isquestionsLoading());
    let response = await putMethod(`admin/question/${id}/edit`, data);
    if (response?.success) {
      dispatch(isquestionsSuccess());
      toast.success(response?.message);
    } else {
      dispatch(isquestionsError(response?.message));
    }
  } catch (error) {
    dispatch(isquestionsError(error?.message));
  }
};

export const deleteQuestion = (id) => async (dispatch) => {
  try {
    dispatch(isquestionsLoading());
    let response = await deleteMethod(`admin/question/${id}/remove`);

    if (response?.success) {
      dispatch(isquestionsSuccess());
      toast.success(response?.message);
    } else {
      dispatch(isquestionsError(response?.message));
    }
  } catch (error) {
    dispatch(isquestionsError(error?.message));
  }
};
