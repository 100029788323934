import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    success: false,
    error: false,
    fileLinkList: [],
    fileTotal: 0,

};

export const getLinksSlice = createSlice({
    name: "getLinksSlice",
    initialState,
    reducers: {

        getFileLinkList: (state, action) => {
            state.loading = false;
            state.fileLinkList = action.payload.data;
            state.fileTotal = action.payload.total;
            state.success = true;
            state.error = false;
        },


        resetCsvBackButton: (state) => {
            state.loading = false;
            state.success = false;
            state.error = false;

        },
        resetFileBackButton: (state) => {
            state.loading = false;
            state.success = false;
            state.error = false;
            state.fileLinkList = [];
            state.fileTotal = 0;

        },


        isFileLinkListLoading: (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
            state.fileLinkList = [];
        },
        isFileLinkListSuccess: (state) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },
        isFileLinkListError: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
            state.fileLinkList = [];
        },
    },
});

export default getLinksSlice.reducer;

export const { resetFileBackButton, resetCsvBackButton, getFileLinkList, isFileLinkListLoading, isFileLinkListSuccess, isFileLinkListError } =
    getLinksSlice.actions;
