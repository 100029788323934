import Parser from "html-react-parser";
import React, { useState } from "react";
import { BiDollar } from "react-icons/bi";
import {
  BsArrowLeft,
  BsBookmark,
  BsCheck2Square,
  BsClock,
  BsFillMoonFill,
  BsHourglassBottom,
  BsShareFill,
  BsThreeDotsVertical,
  BsToggle2Off,
  BsToggleOff,
  BsToggleOn,
} from "react-icons/bs";
import { HiOutlineAcademicCap, HiOutlineLocationMarker } from "react-icons/hi";
import { FiSettings } from "react-icons/fi";
import { ImHome, ImLocation, ImStarEmpty, ImUserTie } from "react-icons/im";
import styles from "../style/jobPreviewComponent.module.scss";
import clsx from "clsx";
import { numberToLocaleString } from "../utils/numberToArabic";

const JobPreviewComponent = ({ details }) => {
  const [showPreview, setShowPreview] = useState("english");

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          onClick={(e) => {
            e.stopPropagation();

            if (showPreview === "english") {
              setShowPreview("arabic");
            } else {
              setShowPreview("english");
            }
          }}
          style={{ border: "none", background: "transparent" }}
        >
          {showPreview === "english" ? (
            <>
              <span>English&nbsp;</span>
              <BsToggle2Off style={{ width: "25px", height: "25px", color: "#d56c33" }} />
            </>
          ) : (
            <>
              <span>Arabic&nbsp;</span>
              <BsToggleOn style={{ width: "25px", height: "25px", color: "#d56c33" }} />
            </>
          )}
        </button>
      </div>
      {showPreview === "english" ? (
        <div className={styles.jobPreview__container}>
          <div className={clsx(styles.preview__mobile, styles.preview__mobile1)}>
            <div className={styles.preview__top}>
              <div className={styles.preview__nav}>
                <div>
                  <BsArrowLeft className={styles.nav__logo} />
                </div>
                <div>
                  <BsBookmark className={styles.nav__logo} />
                  <BsShareFill className={styles.nav__logo} />
                  <BsThreeDotsVertical className={styles.nav__logo} />
                </div>
              </div>
              <div className={styles.company}>
                <div className={styles.company__logo}>
                  <img
                    src="https://images.unsplash.com/photo-1620288627223-53302f4e8c74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y29tcGFueSUyMGxvZ28lMjBkdW1teXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                    alt=""
                  />
                </div>
                <div className={styles.company__details}>
                  <span>{details?.company?.companyName}</span>
                  <span>{details?.title}</span>
                </div>
              </div>

              <div className={styles.location}>
                <div>
                  <ImLocation className={styles.logo} />
                  <span>{details?.city?.translations[0]?.cityTranslation?.name}</span>
                </div>
                <div>
                  <BiDollar className={styles.logo} />

                  <span>{`${details?.salary?.lowValue ? details?.salary?.lowValue + "/month " : ""} ${
                    details?.salary?.upperValue ? details?.salary?.upperValue + "/month" : ""
                  }`}</span>
                </div>
                {details?.openings ? (
                  <div>
                    <ImUserTie className={styles.logo} />
                    <span>{details?.openings} Openings</span>
                  </div>
                ) : null}
              </div>

              <div className={styles.data}>
                {details?.wfh ? (
                  <div>
                    <ImHome className={styles.logo} />
                    <span>Work Form Home</span>
                  </div>
                ) : (
                  ""
                )}
                {details?.nightShift ? (
                  <div>
                    <BsFillMoonFill className={styles.logo} />
                    <span>Night Shift</span>
                  </div>
                ) : (
                  ""
                )}
                {details?.partTime ? (
                  <div>
                    <BsHourglassBottom className={styles.logo} />
                    <span>Part-time</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className={styles.preview__bottom}>
              <div className={styles.job__details}>
                <div className={styles.job__heading}>Job Requirement</div>
                <div className={styles.req__details}>
                  {details?.jobRequirements?.length
                    ? details?.jobRequirements?.map((li) => (
                        <div key={`${Math.random()}-${li?._id}`}>
                          <BsCheck2Square className={styles.logo} />
                          <span>{li?.translations[0]?.jobRequirementTranslation?.name}</span>
                        </div>
                      ))
                    : ""}
                </div>
              </div>

              <div className={styles.job__details}>
                <div className={styles.job__heading}>Job Description</div>
                <div className={styles.description}>
                  <div>{Parser(details?.jobDescription || "")}</div>
                </div>

                {details?.workTimings?.startTime && details?.workTimings?.endTime ? (
                  <>
                    <hr />
                    <div className={styles.job_timing}>
                      <div className={styles.heading}>
                        <BsClock className={styles.logo} />
                        <span>Work Timings</span>
                      </div>
                      <p>
                        {`${
                          details?.workTimings?.startTime?.hours < 10
                            ? `0${details?.workTimings?.startTime?.hours}`
                            : details?.workTimings?.startTime?.hours
                        }:${
                          details?.workTimings?.startTime?.mins < 10
                            ? `0${details?.workTimings?.startTime?.mins}`
                            : details?.workTimings?.startTime?.mins
                        } ${details?.workTimings?.startTime?.zone} to ${
                          details?.workTimings?.endTime?.hours < 10
                            ? `0${details?.workTimings?.endTime?.hours}`
                            : details?.workTimings?.endTime?.hours
                        }:${
                          details?.workTimings?.endTime?.mins < 10
                            ? `0${details?.workTimings?.endTime?.mins}`
                            : details?.workTimings?.endTime?.mins
                        } ${details?.workTimings?.endTime?.zone}`}{" "}
                        | {details?.workingDays[0]} - {details?.workingDays[details?.workingDays?.length - 1]}
                      </p>
                    </div>
                    <hr />
                  </>
                ) : details?.workingDays?.length ? (
                  <>
                    <hr />
                    <div className={styles.job_timing}>
                      <div className={styles.heading}>
                        <BsClock className={styles.logo} />
                        <span>Work Timings</span>
                      </div>
                      <p>
                        {details?.workingDays[0]} - {details?.workingDays[details?.workingDays?.length - 1]}
                      </p>
                    </div>
                    <hr />
                  </>
                ) : null}

                {details?.experienceRequired ? (
                  <div className={styles.job_timing}>
                    <div className={styles.heading}>
                      <ImStarEmpty className={styles.logo} />
                      <span>Experience</span>
                    </div>
                    <p>
                      {details?.experienceRequired === "Fresher"
                        ? "Fresher can apply"
                        : `${details?.experience?.min} - ${details?.experience?.max} Years`}
                    </p>
                  </div>
                ) : (
                  ""
                )}

                {details?.skills?.length ? (
                  <div className={styles.job_timing}>
                    <div className={styles.heading}>
                      <FiSettings style={{ color: "#066569" }} className={styles.logo} />
                      <span>{details?.skills?.length === 1 ? "Skill" : "Skills"}</span>
                    </div>
                    <ul style={{ listStyle: "none", padding: "0px 0px 0px 25px" }}>
                      {details?.skills?.map((el, index) => {
                        return (
                          <li style={{ fontWeight: "600" }} key={el?._id}>
                            <span>{el?.translations[0]?.skillTranslation?.name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}

                <hr />
                {details?.education ? (
                  <div className={styles.job_timing}>
                    <div className={styles.heading}>
                      <HiOutlineAcademicCap className={styles.logo} />
                      <span>Minimum Education</span>
                    </div>
                    <p>{details?.education?.translations[0]?.educationTranslation?.label} or Above</p>
                  </div>
                ) : (
                  ""
                )}

                <hr />
                {details?.company?.address ? (
                  <div className={styles.job_timing}>
                    <div className={styles.heading}>
                      <HiOutlineLocationMarker className={styles.logo} />
                      <span>Address</span>
                    </div>
                    <p>{details?.company?.address[0]?.address}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className={styles.volume_up}></div>
          <div className={styles.volume_down}></div>
          <div className={styles.lock}></div>
        </div>
      ) : showPreview === "arabic" ? (
        <div className={styles.jobPreview__container}>
          <div className={clsx(styles.preview__mobile, styles.preview__mobile1)}>
            <div className={styles.preview__top}>
              <div className={styles.preview__nav}>
                <div>
                  <BsArrowLeft className={styles.nav__logo} />
                </div>
                <div>
                  <BsBookmark className={styles.nav__logo} />
                  <BsShareFill className={styles.nav__logo} />
                  <BsThreeDotsVertical className={styles.nav__logo} />
                </div>
              </div>
              <div className={styles.company}>
                <div className={styles.company__logo}>
                  <img
                    src="https://images.unsplash.com/photo-1620288627223-53302f4e8c74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y29tcGFueSUyMGxvZ28lMjBkdW1teXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                    alt=""
                  />
                </div>
                <div className={styles.company__details}>
                  <span>{details?.company?.companyName}</span>
                  <span>{details?.title}</span>
                </div>
              </div>

              <div className={styles.location}>
                <div>
                  <ImLocation className={styles.logo} />
                  <span>{details?.city?.translations[1]?.cityTranslation?.name}</span>
                </div>
                <div>
                  <BiDollar className={styles.logo} />
                  <span>{`${details?.salary?.lowValue ? details?.salary?.lowValue + " شهر " : ""} ${
                    details?.salary?.upperValue ? details?.salary?.upperValue + " شهر" : ""
                  }`}</span>
                </div>
                {details?.openings ? (
                  <div>
                    <ImUserTie className={styles.logo} />
                    <span> فتحات {details?.openings} </span>
                  </div>
                ) : null}
              </div>

              <div className={styles.data}>
                {details?.wfh ? (
                  <div>
                    <ImHome className={styles.logo} />
                    <span>نموذج العمل بالمنزل</span>
                  </div>
                ) : (
                  ""
                )}
                {details?.nightShift ? (
                  <div>
                    <BsFillMoonFill className={styles.logo} />
                    <span>التحول الليلي</span>
                  </div>
                ) : (
                  ""
                )}
                {details?.partTime ? (
                  <div>
                    <BsHourglassBottom className={styles.logo} />
                    <span>Part-time</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className={styles.preview__bottom}>
              <div className={styles.job__details}>
                <div className={styles.job__heading}>متطلبات العمل</div>
                <div className={styles.req__details}>
                  {details?.jobRequirements?.length
                    ? details?.jobRequirements?.map((li) => (
                        <div key={`${Math.random()}-${li?._id}`}>
                          <BsCheck2Square className={styles.logo} />
                          <span>{li?.translations[1]?.jobRequirementTranslation?.name}</span>
                        </div>
                      ))
                    : ""}
                </div>
              </div>

              <div className={styles.job__details}>
                <div className={styles.job__heading}>المسمى الوظيفي</div>
                <div className={styles.description}>{Parser(details?.jobDescription || "")}</div>

                {details?.workTimings?.startTime && details?.workTimings?.endTime ? (
                  <>
                    <hr />
                    <div className={styles.job_timing}>
                      <div className={styles.heading}>
                        <BsClock className={styles.logo} />
                        <span>مواعيد العمل</span>
                      </div>
                      {/* ٢:٠٠ صباحًا إلى ٠٦:٠٠ صباحًا | الاثنين - الجمعة */}
                      <p>
                        {`${
                          details?.workTimings?.startTime?.hours < 10
                            ? `${numberToLocaleString(0)}${numberToLocaleString(
                                details?.workTimings?.startTime?.hours
                              )}`
                            : numberToLocaleString(details?.workTimings?.startTime?.hours)
                        }:${
                          details?.workTimings?.startTime?.mins < 10
                            ? `${numberToLocaleString(0)}${numberToLocaleString(details?.workTimings?.startTime?.mins)}`
                            : numberToLocaleString(details?.workTimings?.startTime?.mins)
                        } ${details?.workTimings?.startTime?.zone} 
                        ل 
                        ${
                          details?.workTimings?.endTime?.hours < 10
                            ? `${numberToLocaleString(0)}${numberToLocaleString(details?.workTimings?.endTime?.hours)}`
                            : numberToLocaleString(details?.workTimings?.endTime?.hours)
                        }:${
                          details?.workTimings?.endTime?.mins < 10
                            ? `${numberToLocaleString(0)}${numberToLocaleString(details?.workTimings?.endTime?.mins)}`
                            : numberToLocaleString(details?.workTimings?.endTime?.mins)
                        } ${details?.workTimings?.endTime?.zone}`}{" "}
                        | {details?.workingDays[0]} - {details?.workingDays[details?.workingDays?.length - 1]}
                      </p>
                    </div>
                    <hr />
                  </>
                ) : details?.workingDays?.length ? (
                  <>
                    <hr />
                    <div className={styles.job_timing}>
                      <div className={styles.heading}>
                        <BsClock className={styles.logo} />
                        <span>مواعيد العمل</span>
                      </div>
                      <p>
                        {details?.workingDays[0]} - {details?.workingDays[details?.workingDays?.length - 1]}
                      </p>
                    </div>
                    <hr />
                  </>
                ) : null}

                {details?.experienceRequired ? (
                  <div className={styles.job_timing}>
                    <div className={styles.heading}>
                      <ImStarEmpty className={styles.logo} />
                      <span>خبرة</span>
                    </div>
                    <p>
                      {details?.experienceRequired === "Fresher"
                        ? "من غير خبرة"
                        : `${numberToLocaleString(details?.experience?.min)} - ${numberToLocaleString(
                            details?.experience?.max
                          )} سنين`}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <hr />
                {details?.skills?.length ? (
                  <div className={styles.job_timing}>
                    <div className={styles.heading}>
                      <FiSettings style={{ color: "#066569" }} className={styles.logo} />
                      <span>{details?.skills?.length === 1 ? "مهارة" : "مهارات"}</span>
                    </div>
                    <ul style={{ listStyle: "none", padding: "0px 0px 0px 25px" }}>
                      {details?.skills?.map((el, index) => {
                        return (
                          <li style={{ fontWeight: "600" }} key={el?._id}>
                            <span>{el?.translations[1]?.skillTranslation?.name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
                {details?.education ? (
                  <div className={styles.job_timing}>
                    <div className={styles.heading}>
                      <HiOutlineAcademicCap className={styles.logo} />
                      <span>الحد الأدنى من التعليم</span>
                    </div>
                    <p>{details?.education?.translations[1]?.educationTranslation?.label} أو أعلى</p>
                  </div>
                ) : (
                  ""
                )}

                <hr />
                {details?.company?.address ? (
                  <div className={styles.job_timing}>
                    <div className={styles.heading}>
                      <HiOutlineLocationMarker className={styles.logo} />
                      <span>عنوان</span>
                    </div>
                    <p>{details?.company?.address[0]?.address}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className={styles.volume_up}></div>
          <div className={styles.volume_down}></div>
          <div className={styles.lock}></div>
        </div>
      ) : null}
    </>
  );
};

export default JobPreviewComponent;
