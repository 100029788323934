/* eslint-disable */

import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { HiOutlineDuplicate } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Badge,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  RSelect,
  Row,
} from "../../components/Component";
import RichTextEditor from "../../components/skillComponent/TextEditor";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

import { toast } from "react-toastify";
import {
  addSkillsToList,
  deleteSkillsFromList,
  editSkillsFromList,
  getAllSkills,
  getSkillById,
} from "../../redux/Action/skillsAction";
import { capitalizeFirstLetter } from "../../utils/Utils";
import { slugify } from "../../utils/validation";
import { productData } from "../pre-built/products/ProductData";
import { useSearchParams } from "react-router-dom/dist";
const categoryOptionsEnglish = [
  {
    value: "reference",
    label: "Reference",
  },
  {
    value: "tech",
    label: "Tech",
  },
  {
    value: "open",
    label: "Open",
  },
];

const languageOptions = [
  {
    value: "english",
    label: "English",
  },
  {
    value: "arabic",
    label: "لقب",
  },
];

const SkillList = () => {
  const [questionLanguage, setQuestionLanguage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { error, loading, success, skills, skill, total } = useSelector((state) => state?.skills);
  const [searchParams] = useSearchParams()

  const {
    loading: questionLoading,
    success: questionSuccess,
    error: questionError,
    questions,
    total: questionTotal,
  } = useSelector((state) => state?.skillQuestions);

  const [data, setData] = useState(productData);
  const [sm, updateSm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    img: null,
    sku: "",
    price: 0,
    stock: 0,
    category: [],
    fav: false,
    check: false,
  });
  const [type, setType] = useState("");
  const [srcQuery, setSrcQuery] = useState("");
  const [view, setView] = useState({
    edit: false,
    add: false,
    details: false,
    questions: false,
    addQuestions: false,
  });

  const [skillsData, setSkillsData] = useState({
    nameEn: "",
    nameAr: "",
    skillType: "",
  });
  const [passPercentage, setPassPercentage] = useState("");
  const [minRefCount, setMinRefCount] = useState("");
  const [instEn, setInstEn] = useState(
    "You can take the test only once every xx months If you leave the test before the end, you will not be able to resume.  The questions are selected randomly. You and other users can have different questions for the same skill test. Once you submit an answer, you can't come back to amend a previous answer. Read the questions and all answers before selecting your answer In some questions, you are required only one option. In others, you are asked to select all correct options. Read the question very well."
  );
  const [instAr, setInstAr] = useState(
    "يمكنك إجراء الاختبار مرة واحدة فقط كل 3 شهرًا إذا سبت الاختبار قبل النهاية ، فلن تتمكن من الرجوع اليه و اكماله. الأسئلة تتغير مع كل اختبار.ما فيش اثنين عندهم نفس الاسئلة بالظبط . اقرأ الأسئلة وكل الاقتراحات قبل اختيار الإجابة  الرجوع الى الوراء غير متاح لتعديل الإجابة على سؤال سابق. -في بعض الأسئلة ، مطلوب منك خيار واحد فقط. في أسئلةأخرى ، يُطلب منك تحديد جميع الخيارات الصحيحة. اقرأ السؤال جيدًا."
  );
  const [idValue, setId] = useState("");
  const { nameEn, nameAr, skillType } = skillsData;
  const [noOfQuestion, setNoOfQuestion] = useState(5);
  const [retestDuration, setRetestDuration] = useState("");
  const [timePerQuestion, setTimePerQuestion] = useState(1);

  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(searchParams.get("p") ? parseInt(searchParams.get("p")) : 1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = productData.filter((item) => {
        return item.sku.toLowerCase().includes(onSearchText.toLowerCase());
      });
      setData([...filteredObject]);
    } else {
      setData([...productData]);
    }
  }, [onSearchText]);

  // OnChange function to get the input data
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // category change
  const onAddSkillChange = (e) => {
    const { name, value } = e.target;
    setSkillsData({
      ...skillsData,
      [name]: value,
    });
  };

  const onCategoryChange = (value) => {
    setSkillsData({ ...skillsData, skillType: value?.value });
  };

  const handleAddSkill = async (e) => {
    e.preventDefault();

    let data = {
      skillList: [
        {
          name: nameEn,
          language: "62e7f3b038629a3473e07310",
          instructions: [instEn],
        },
        {
          name: nameAr,
          language: "62e7f4bd38629a3473e07313",
          instructions: [instAr],
        },
      ],
      slug: slugify(nameEn),
      skillType,
    };
    if (skillType === "tech") {
      data = { ...data, passPercent: +passPercentage };
      data = { ...data, noOfquestionsToDisplay: +noOfQuestion };
      data = { ...data, retestDuration: +retestDuration };
      data = { ...data, timePerQuestion: +timePerQuestion };
    } else if (skillType === "reference") {
      data = { ...data, minRefCount: +minRefCount };
    }

    if (
      skillType === "tech" &&
      nameEn &&
      nameAr &&
      passPercentage &&
      noOfQuestion &&
      retestDuration &&
      timePerQuestion
    ) {
      await dispatch(addSkillsToList(data));
      await dispatch(getAllSkills(type, currentPage, itemPerPage));

      onFormCancel();
      setSkillsData({ ...skillsData, nameEn: "", nameAr: "", skillType: "" });
      setPassPercentage("");
      setNoOfQuestion(5);
      setRetestDuration("");
      setTimePerQuestion(1);
    } else if (skillType === "reference" && minRefCount && nameEn && nameAr && noOfQuestion) {
      await dispatch(addSkillsToList(data));
      await dispatch(getAllSkills(type, currentPage, itemPerPage));

      onFormCancel();
      setSkillsData({ ...skillsData, nameEn: "", nameAr: "", skillType: "" });
      setNoOfQuestion(5);
      setMinRefCount("");
    } else {
      toast.error("Please fill all fields in both languages");
    }
  };

  const handleEditSkill = async (e) => {
    e.preventDefault();

    let englishId = skill.translations[0].skillTranslation._id;
    let arLanguageId = skill.translations[1].skillTranslation._id;

    let data = {
      englishId: englishId,
      arabicId: arLanguageId,
      englishName: nameEn,
      englishInstructions: instEn,
      skillType,
      arabicName: nameAr,
      arabicInstructions: instAr,
      slug: slugify(nameEn),
    };

    if (skillType === "tech") {
      data = { ...data, passPercent: +passPercentage };
      data = { ...data, noOfquestionsToDisplay: +noOfQuestion };
      data = { ...data, retestDuration: +retestDuration };
      data = { ...data, timePerQuestion: +timePerQuestion };
    } else if (skillType === "reference") {
      data = { ...data, minRefCount: +minRefCount };
    }

    if (
      skillType === "tech" &&
      nameEn &&
      nameAr &&
      passPercentage &&
      noOfQuestion &&
      retestDuration &&
      timePerQuestion
    ) {
      dispatch(editSkillsFromList(idValue, data));

      dispatch(getAllSkills(type, currentPage, itemPerPage));
      onFormCancel();
      setSkillsData({ ...skillsData, nameEn: "", nameAr: "", skillType: "" });
      setPassPercentage("");
      setNoOfQuestion(5);
      setRetestDuration("");
      setTimePerQuestion(1);
    } else if (skillType === "reference" && minRefCount && nameEn && nameAr && noOfQuestion) {
      dispatch(editSkillsFromList(idValue, data));

      dispatch(getAllSkills(type, currentPage, itemPerPage));
      onFormCancel();

      setSkillsData({ ...skillsData, nameEn: "", nameAr: "", skillType: "" });
      setNoOfQuestion(5);
      setMinRefCount("");
    } else {
      toast.error("Please fill all fields in both languages");
    }
  };

  // function to delete a skill
  const handleDeleteSkill = async (id) => {
    await dispatch(deleteSkillsFromList(id));
    await dispatch(getAllSkills(type, currentPage, itemPerPage));
  };

  // Delete Alert
  const deleteAlert = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteSkill(id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setView({ edit: false, add: false, details: false, questions: false, addQuestions: false });
  };

  // toggle function to view product details
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
      details: type === "details" ? true : false,
      questions: type === "questions" ? true : false,
      addQuestions: type === "addQuestions" ? true : false,
    });
  };

  // handles ondrop function of dropzone
  const handleDropChange = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  useEffect(() => {
    dispatch(getAllSkills(type, currentPage, itemPerPage));
    return () => { };
  }, [dispatch, currentPage, itemPerPage, type]);

  useEffect(() => {
    if (idValue) {
      dispatch(getSkillById(idValue));
      dispatch(getAllSkills(type, currentPage, itemPerPage));
    }
    return () => { };
  }, [idValue, dispatch]);

  const { errors, register, handleSubmit } = useForm();

  const editIntialValue = (id, skillObj) => {
    setSkillsData({
      ...skillsData,
      nameEn: skillObj?.translations[0]?.skillTranslation?.name,
      nameAr: skillObj?.translations[1]?.skillTranslation?.name,
      skillType: skillObj?.skillType,
    });
    setPassPercentage(skillObj?.passPercent);
    setNoOfQuestion(skillObj?.noOfquestionsToDisplay);
    setRetestDuration(skillObj?.retestDuration);
    setTimePerQuestion(skillObj?.timePerQuestion);
    setMinRefCount(skillObj?.minRefCount);
  };

  const duplicateSkillFun = async (id, skillObj) => {
    let nameEn = `${skillObj?.translations[0]?.skillTranslation?.name}`
      ? `${skillObj?.translations[0]?.skillTranslation?.name}`
      : "copy1";
    let nameAr = `${skillObj?.translations[1]?.skillTranslation?.name}`
      ? `${skillObj?.translations[1]?.skillTranslation?.name}`
      : "copy1";
    let passPercentage = +skillObj?.passPercent ? +skillObj?.passPercent : 30;
    let noOfQuestion = +skillObj?.noOfquestionsToDisplay ? +skillObj?.noOfquestionsToDisplay : 5;
    let retestDuration = +skillObj?.retestDuration ? +skillObj?.retestDuration : 5;
    let timePerQuestion = +skillObj?.timePerQuestion ? +skillObj?.timePerQuestion : 5;
    let minRefCount = +skillObj?.minRefCount ? +skillObj?.minRefCount : 2;

    let data = {
      skillList: [
        {
          name: `${nameEn}-copy`,
          language: "62e7f3b038629a3473e07310",
          instructions: [`${nameEn}-copy`],
        },
        {
          name: `ينسخ-${nameAr}`,
          language: "62e7f4bd38629a3473e07313",
          instructions: [`ينسخ-${nameAr}`],
        },
      ],
      slug: slugify(`${nameEn}-copy`),
      skillType: skillObj?.skillType,
    };
    if (skillObj?.skillType === "tech") {
      data = { ...data, passPercent: +passPercentage };
      data = { ...data, noOfquestionsToDisplay: +noOfQuestion };
      data = { ...data, retestDuration: +retestDuration };
      data = { ...data, timePerQuestion: +timePerQuestion };
    } else if (skillObj?.skillType === "reference") {
      data = { ...data, minRefCount: +minRefCount };
    }

    if (
      skillObj?.skillType === "tech" &&
      nameEn &&
      nameAr &&
      passPercentage &&
      noOfQuestion &&
      retestDuration &&
      timePerQuestion
    ) {
      await dispatch(addSkillsToList(data));
      await dispatch(getAllSkills(type, currentPage, itemPerPage));
    } else if (skillObj?.skillType === "reference" && minRefCount && nameEn && nameAr && noOfQuestion) {
      await dispatch(addSkillsToList(data));
      await dispatch(getAllSkills(type, currentPage, itemPerPage));
    }
  };
  return (
    <React.Fragment>
      <Head title="Skill List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Skills</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  href="#more"
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateSm(!sm);
                  }}
                >
                  <Icon name="more-v"></Icon>
                </a>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="transparent"
                          className="dropdown-toggle dropdown-indicator btn btn-outline-light btn-white"
                        >
                          {capitalizeFirstLetter(type) || "Type"}
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setType("");
                                }}
                              >
                                <span>All</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setType("reference");
                                }}
                              >
                                <span>Reference</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setType("tech");
                                }}
                              >
                                <span>Tech</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setType("open");
                                }}
                              >
                                <span>Open</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button
                        className="toggle btn-icon d-md-none"
                        color="primary"
                        onClick={() => {
                          toggle("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                      </Button>
                      <Button
                        className="toggle d-none d-md-inline-flex"
                        color="primary"
                        onClick={() => {
                          toggle("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Skill</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered">
            <div className="card-inner-group">
              <div
                className="card-inner p-0"
                style={{
                  width: "100%",
                }}
              >
                {loading ? (
                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    {/* <TriLoader /> */}
                    <Spinner type="grow" color="dark" />
                  </div>
                ) : error ? (
                  "something went wrong..."
                ) : success ? (
                  <DataTableBody>
                    <DataTableHead>
                      <DataTableRow className="nk-tb-col-check">{/* <span>#</span> */}</DataTableRow>
                      <DataTableRow size="sm">
                        <span>Name</span>
                      </DataTableRow>

                      <DataTableRow size="md">
                        <span>Type</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        {/* <Icon name="star-round" className="tb-asterisk"></Icon> */}
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                    </DataTableHead>
                    <>
                      {skills.length > 0 ? (
                        skills.map((item, index) => {
                          return (
                            <DataTableItem key={item._id}>
                              <DataTableRow className="nk-tb-col-check"></DataTableRow>
                              <DataTableRow size="sm">
                                <Link to={`/skill/${item?._id}?p=${currentPage}`} className="tb-product">
                                  <span className="title">{item?.translations[0]?.skillTranslation?.name}</span>
                                </Link>
                              </DataTableRow>

                              <DataTableRow size="md">
                                <span className="tb-sub">{item?.skillType}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                {item?.skillType === "tech" ? (
                                  <span className={"tb-sub"}>Questions</span>
                                ) : item?.skillType === "reference" ? (
                                  <span className={"tb-sub"} onClick={(e) => e.preventDefault()}>
                                    minimum Reference : {item?.minRefCount}
                                  </span>
                                ) : null}
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools">
                                <ul className="nk-tb-actions gx-1 my-n1">
                                  <li className="mr-n1">
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        tag="a"
                                        href="#more"
                                        onClick={(ev) => ev.preventDefault()}
                                        className="dropdown-toggle btn btn-icon btn-trigger"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownToggle>
                                      <DropdownMenu right>
                                        <ul className="link-list-opt no-bdr">
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#remove"
                                              onClick={(ev) => {
                                                ev.preventDefault();
                                                // handleDeleteSkill(item._id);
                                                setId(item._id);

                                                editIntialValue(item._id, item);
                                                toggle("edit", item._id);
                                              }}
                                            >
                                              <Icon name="edit"></Icon>
                                              <span>Edit Skill</span>
                                            </DropdownItem>
                                          </li>
                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#remove"
                                              onClick={(ev) => {
                                                ev.preventDefault();

                                                duplicateSkillFun(item?._id, item);
                                              }}
                                              style={{ display: "flex", columnGap: "14px" }}
                                            >
                                              {/* <Icon name="trash"></Icon> */}
                                              <HiOutlineDuplicate style={{ width: "18px", height: "18px" }} />
                                              <span>Duplicate Skill</span>
                                            </DropdownItem>
                                          </li>

                                          <li>
                                            <DropdownItem
                                              tag="a"
                                              href="#remove"
                                              onClick={(ev) => {
                                                ev.preventDefault();

                                                deleteAlert(item._id);
                                              }}
                                            >
                                              <Icon name="trash"></Icon>
                                              <span>Remove Skill</span>
                                            </DropdownItem>
                                          </li>
                                        </ul>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </li>
                                </ul>
                              </DataTableRow>
                            </DataTableItem>
                          );
                        })
                      ) : (
                        <div className="text-center">
                          <span className="text-silent">No Skills found</span>
                        </div>
                      )}
                    </>
                  </DataTableBody>
                ) : null}
                <div className="card-inner">
                  {skills.length > 0 ? (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={total}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={total}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Block>

        {view.add && <div className="toggle-overlay" onClick={toggle}></div>}

        {/* Add Skills2  */}

        <Modal
          isOpen={view.add || view?.edit}
          toggle={() => onFormCancel()}
          className="modal-dialog-centered"
          size="lg"
        >
          <ModalBody>
            <BlockHead>
              <BlockHeadContent>
                <BlockTitle tag="h5">
                  <p style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <span>{view?.add ? "Add" : view?.edit ? "Edit" : "Add"} Skill</span>
                    <RxCross2
                      onClick={(e) => {
                        e.stopPropagation();
                        onFormCancel();
                      }}
                      style={{ width: "20px", height: "20px", color: "gray" }}
                    />
                  </p>
                </BlockTitle>
                <BlockDes>
                  <p>
                    {view?.add
                      ? "Add information of skill."
                      : view?.edit
                        ? "Edit information of skill."
                        : "Add information of skill."}
                  </p>
                </BlockDes>
              </BlockHeadContent>
            </BlockHead>
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Spinner type="grow" color="dark" />
              </div>
            ) : success ? (
              <Block>
                <form
                  onSubmit={(e) => {
                    if (view?.add) {
                      handleAddSkill(e);
                    } else {
                      handleEditSkill(e);
                    }
                  }}
                >
                  <Row className="g-3">
                    <Col size="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="title">
                          Language
                        </label>
                        <div className="form-control-wrap">
                          <RSelect
                            name="category"
                            options={languageOptions}
                            onChange={(value) => {
                              setQuestionLanguage(value?.value);
                            }}
                            defaultValue={questionLanguage}
                          />
                        </div>
                      </div>
                    </Col>

                    {questionLanguage === "english" ? (
                      <>
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <Row className="g-3">
                            {/* Title */}
                            <Col size="12">
                              <div className="form-group">
                                <label className="form-label" htmlFor="product-title">
                                  Title *
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="nameEn"
                                    value={nameEn}
                                    onChange={onAddSkillChange}
                                    required
                                    ref={register({
                                      required: "This field is required",
                                    })}
                                    placeholder="title"
                                  />
                                  {errors.title && <span className="invalid">{errors.title.message}</span>}
                                </div>
                              </div>
                            </Col>
                            {/* Instruction */}
                            <Col size="12">
                              <div className="form-group">
                                <label className="form-label" htmlFor="product-title">
                                  Instructions
                                </label>
                                <div className="form-control-wrap">
                                  <RichTextEditor
                                    initialValue={instEn || ""}
                                    getValue={(value) => {
                                      setInstEn(value);
                                    }}
                                    arValue={false}
                                  />
                                </div>
                              </div>
                            </Col>

                            {/*No of question  */}
                            <Col size="12">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="product-title"
                                  style={{
                                    width: "100%",
                                    textAlign: "start",
                                  }}
                                >
                                  No Of Question
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="instAr"
                                    value={noOfQuestion}
                                    onChange={(e) => setNoOfQuestion(e.target.value)}
                                    dir="ltr"
                                    placeholder="Enter number of question in number"
                                  />
                                </div>
                              </div>
                            </Col>

                            {/* Type */}
                            <Col size="12">
                              <div className="form-group">
                                <label className="form-label" htmlFor="category">
                                  Type *
                                </label>
                                <div className="form-control-wrap">
                                  <RSelect
                                    name="category"
                                    options={categoryOptionsEnglish}
                                    onChange={onCategoryChange}
                                    //ref={register({ required: "This is required" })}
                                    defaultValue={skillType}
                                  />
                                  {errors.category && <span className="invalid">{errors.category.message}</span>}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <div style={{ marginTop: "1rem" }}>
                          <Row className={"g-3"}>
                            {skillType === "tech" ? (
                              <>
                                <Col size={"6"}>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="product-title">
                                      Pass Percentage
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="passPercentage"
                                        value={passPercentage}
                                        onChange={(e) => setPassPercentage(e.target.value)}
                                        placeholder="Enter pass percentage in number"
                                      />
                                    </div>
                                  </div>
                                </Col>

                                <Col size={"6"}>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="product-title">
                                      Retest Duration
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="retestDuration"
                                        value={retestDuration}
                                        required
                                        onChange={(e) => setRetestDuration(e.target.value)}
                                        placeholder="Enter retest duration in months"
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col size={"6"}>
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="product-title">
                                      Time Per Question
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="timePerQuestion"
                                        value={timePerQuestion}
                                        required
                                        onChange={(e) => setTimePerQuestion(e.target.value)}
                                        placeholder="Enter time per question in minutes"
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </>
                            ) : skillType === "reference" ? (
                              <Col size={"12"}>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="product-title">
                                    Minimum Reference Count
                                  </label>
                                  <div className="form-control-wrap">
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="minRefCount"
                                      value={minRefCount}
                                      onChange={(e) => setMinRefCount(e.target.value)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                      </>
                    ) : questionLanguage === "arabic" ? (
                      <>
                        {/* Arabic */}
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <Row className="g-3">
                            {/* Title */}
                            <Col size="12">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="product-title"
                                  style={{
                                    width: "100%",
                                    textAlign: "end",
                                  }}
                                >
                                  لقب
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="nameAr"
                                    value={nameAr}
                                    required
                                    onChange={onAddSkillChange}
                                    ref={register({
                                      required: "This field is required",
                                    })}
                                    dir="rtl"
                                  />
                                  {errors.title && <span className="invalid">{errors.title.message}</span>}
                                </div>
                              </div>
                            </Col>
                            {/* instruction  */}
                            <Col size="12">
                              <div
                                className="form-group"
                                style={{
                                  textAlign: "end",
                                }}
                              >
                                <label
                                  className="form-label"
                                  htmlFor="product-title"
                                  style={{
                                    width: "100%",
                                    textAlign: "end",
                                  }}
                                >
                                  تعليمات
                                </label>
                                <div className="form-control-wrap">
                                  <RichTextEditor
                                    initialValue={instAr || ""}
                                    getValue={(value) => {
                                      setInstAr(value);
                                    }}
                                    arValue={true}
                                  />
                                </div>
                                <div></div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : null}

                    <Col size="12">
                      <div style={{ display: "flex", flexDirection: "row-reverse", gap: "15px" }}>
                        <div>
                          <Button
                            color="danger"
                            type="button"
                            onClick={(e) => {
                              onFormCancel();
                            }}
                          >
                            <span>Cancel</span>
                          </Button>
                        </div>

                        <div>
                          <Button color="primary" type="submit">
                            <span>{view?.add ? "Add Skill" : view?.edit ? "Edit Skill" : "Add Skill"}</span>
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </form>
              </Block>
            ) : null}
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};

export default SkillList;
