
export const s3BaseUrl = process.env.REACT_APP_IMG_URL
const hrImagesPath = process.env.REACT_APP_IMG_URL + 'web-hr/images/'

export const images = {
  logoGreenEn: hrImagesPath + 'skatch-green-with-tag-line-en.svg',
  logoGreenAr: hrImagesPath + 'skatch-logo-ar-green.svg',
  navbarEnvelop: hrImagesPath + 'navbar-envelop.svg',
  micRed: hrImagesPath + 'mic-red.svg',
  playCircleWhite: s3BaseUrl + 'webassets/home/play-circle-white.svg'

}