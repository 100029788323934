import Axios from "axios";
import React from "react";

const API_URL = process.env.REACT_APP_API_URL;
//APP_URL for api front url defined
const AxiosInstance = Axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {},
});
//here axios instance create

const setAuthorizationToken = (token) => {
  // console.log("token", token)
  AxiosInstance.defaults.headers.common.Authorization = token ? `Bearer ${token}` : "";
  // if (isCredintialActive) {
  //     AxiosInstance.defaults.withCredentials = token ? true : false;
  // } else {
  //     AxiosInstance.defaults.headers.common.Authorization = token ? `Bearer ${token}` : "";
  // }
};

//set token for authorization

async function postMethod(endpoint, data) {
  return new Promise((resolve) => {
    var config = {
      method: "post",
      url: endpoint,
      data: data,
    };
    AxiosInstance(config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        if (error?.response?.status === 401) {
          resolve({
            success: false,
            status: error?.response?.status,
            message: "failed!",
          });
        }
        if (error?.response?.data) {
          resolve(
            error?.response?.data
              ? error?.response?.data
              : {
                success: false,
                status: error?.response?.status,
                message: "failed!",
              }
          );
        } else {
          resolve({
            success: false,
            status: error?.response?.status,
            message: "failed!",
          });
        }
      }
    );
  });
}

//postmethod create function

async function getMethod(path, params = {}) {
  return new Promise((resolve) => {
    var config = {
      method: "get",
      url: path,
      params: params,
    };
    AxiosInstance(config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        if (error?.response?.status === 401) {
          resolve({
            success: false,
            status: error?.response?.status,
            message: "failed!",
          });
        }
        if (!error.response || error.code === "ECONNABORTED") {
          resolve({
            success: false,
            status: error?.response?.status,
            message: "error!",
          });
        } else {
          resolve(
            error?.response?.data
              ? error?.response?.data
              : {
                success: false,
                status: error?.response?.status,
                message: "failed!",
              }
          );
        }
      }
    );
  });
}
//getmethod create function

async function putMethod(endpoint, data) {
  return new Promise((resolve) => {
    var config = {
      credentials: "include",
      method: "put",
      url: endpoint,
      data: data,
    };
    AxiosInstance(config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        if (error?.response?.status === 401) {
          resolve({
            success: false,
            status: error?.response?.status,
            message: "failed!",
          });
        }
        if (!error.response || error.code === "ECONNABORTED") {
          resolve({
            success: false,
            status: error?.response?.status,
            message: "error!",
          });
        } else {
          resolve(
            error?.response?.data
              ? error?.response?.data
              : {
                success: false,
                status: error?.response?.status,
                message: "failed!",
              }
          );
        }
      }
    );
  });
}

async function deleteMethod(endpoint, data) {
  return new Promise((resolve) => {
    var config = {
      method: "delete",
      url: endpoint,
    };
    AxiosInstance(config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        if (error?.response?.status === 401) {
          resolve({
            success: false,
            status: error?.response?.status,
            message: "failed!",
          });
        }
        resolve(error.response.data);
      }
    );
  });
}
//putmethod create function
export { postMethod, setAuthorizationToken, getMethod, putMethod, API_URL, deleteMethod };
