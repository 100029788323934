import { putMethod, setAuthorizationToken } from "../utils/apiServices";
import { getCookie } from "../helpers/storage";

const profileUpdate = async (data) => {
  let response = await putMethod("admin/update/profile", data);
  return response;
};

const updateJobStatus = async (id, data) => {

  let response = await putMethod("admin/job/" + id + "/status", data);
  return response;
};

const updateJobapplication = async (id, user, data) => {
  let response = await putMethod(
    "admin/job/" + id + "/" + user + "/allowMessages",
    data
  );
  return response;
};

const updateCompanyData = async (id, data) => {
  let response = await putMethod(`admin/company/${id}/update`, data);
  return response;
};

const updateJobDetails = async (id, data) => {
  const _data = { ...data }
  if (_data.experienceRequired == "Fresher") {
    _data.experience = null
  }
  setAuthorizationToken(getCookie("_jwt"));
  let response = await putMethod(`/admin/job/${id}/edit`, _data);
  return response;
};

const updateCompanyFiles = async (id, data) => {
  let response = await putMethod(`/admin/removeCompanyFile/${id}`, data);
  return response;
};

const updateJobApplicationStatusApi = async (id, data) => {

  let response = await putMethod(
    `/jobApplication/update/status/${id}/actions`,
    data
  );
  return response;
};

const updateJobConfigApi = async (data) => {

  let response = await putMethod(`/admin/save/jobConfiguration`, data);
  return response;
};


const savePasswordApi = async (data, token) => {

  let response = await putMethod(`/auth/save/password`, data, { headers: { 'Authorization': 'Bearer ' + token } });
  return response;
};

const resduleInterviewApi = async (id, data) => {


  let response = await putMethod(`/admin/interview/${id}/reschedule`, data);
  return response;
};

const saveProfilePicApi = async (data) => {


  let response = await putMethod(`/admin/profilePic/upload`, data);
  return response;
};



export {
  saveProfilePicApi,
  resduleInterviewApi,
  savePasswordApi,
  updateJobConfigApi,
  profileUpdate,
  updateJobStatus,
  updateJobapplication,
  updateCompanyData,
  updateJobDetails,
  updateCompanyFiles,
  updateJobApplicationStatusApi,
};
