import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import auth from "./Apislices/authSlice";
// import loadAndError from "./Apislices/loadingAndErrorSlice";
import company from "./Apislices/companySlice";
import country from "./Apislices/countrySlice";
import hr from "./Apislices/hrSlice";
import job from "./Apislices/jobSlice";
import skillQuestions from "./Apislices/skillQuestionSlice";
import skills from "./Apislices/skillSlice";
import user from "./Apislices/userSlice";
import hashtagSlice from "./Apislices/hashtagSlice";
import postListSlice from "./Apislices/postListSlice";
import commentListSlice from "./Apislices/commentListSlice";
import getLinksSlice from "./Apislices/getLinksSlice";
import postJobSlice from "./Apislices/postJobSlice";
import actionByHrSlice from "./Apislices/actionByHrSlice";


const reducers = combineReducers({
  auth,
  country,
  skills,
  skillQuestions,
  user,
  hr,
  job,
  company,
  hashtagSlice,
  postListSlice,
  commentListSlice,
  getLinksSlice,
  postJobSlice,
  actionByHrSlice
});

const store = configureStore({
  reducer: reducers,
});

export default store;
