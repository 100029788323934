import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  questions: [],
  question: null,
  total: 0,
};

export const questionSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    isquestionsLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    questions: (state, action) => {
      state.loading = false;
      state.questions = action.payload.data;
      state.total = action.payload.total;
      state.success = true;
      state.error = false;
    },
    getSingleQuestion: (state, action) => {
      state.loading = false;
      state.question = action.payload;
      state.success = true;
      state.error = false;
    },
    addquestions: (state, action) => {
      state.loading = false;
      state.questions = [...state.questions, action.payload.data];
      state.total = action.payload.total;
      state.success = true;
      state.error = false;
    },
    isquestionsSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isquestionsError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.questions = [];
    },
  },
});

export default questionSlice.reducer;

export const { questions, getSingleQuestion, addquestions, isquestionsLoading, isquestionsSuccess, isquestionsError } =
  questionSlice.actions;
