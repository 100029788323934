import React, { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import styles from './audio-player.module.scss'
import IconPlay from '../icon/IconPlay'
import IconPause from '../icon/IconPause'
import { images } from '../../utils/images'
import { prependZero } from '../../utils/date-time.util'


const AudioPlayer = ({ url }) => {


  const [playing, setPlaying] = useState(false)
  const audioRef = useRef()
  const audioTimerRef = useRef()
  const [percent, setPercent] = useState(0)
  const [duration, setDuration] = useState({ mints: "00", secs: "00" })
  const [timer, setTimer] = useState({ mints: "00", secs: "00" })

  useEffect(() => {
    audioRef.current.onpause = (event) => {
      setPlaying(false)
      clearTimeout(audioTimerRef.current);
      const { currentTime, duration } = event.target
      if (currentTime == duration) {
        setPercent(100)
        setTimeout(() => {
          setPercent(0)
          setTimer(getTime(0))
        }, 500)
      }
    }
    audioRef.current.onplaying = (_event) => {
      var duration = _event.target.duration;
      advance(duration, audioRef.current);
    }

    var advance = function (duration, element) {
      let increment = 10 / duration
      setPercent(Math.min(increment * element.currentTime * 10, 100))
      setTimer(getTime(element.currentTime * 1000))
      startTimer(duration, element);
    }

    var startTimer = function (duration, element) {
      if (percent < 100) {
        audioTimerRef.current = setTimeout(function () {
          advance(duration, element)
        }, 100);
      } else {
        setPercent(0)
      }
    }

  }, [])

  const getTime = (miliseconds) => {
    let totalSecs = parseInt((miliseconds / 1000).toString())
    let mints = prependZero(parseInt((totalSecs / 60).toString()))
    let secs = prependZero(totalSecs % 60)
    return { mints, secs }
  }

  const onPlay = () => {
    if (playing) {
      audioRef.current.pause()
      setPlaying(false)
    } else {
      audioRef.current.play()
      setPlaying(true)
    }
  }

  const onLoadedData = (e) => {
    const { duration } = e.target
    console.log({ duration });
    setDuration(getTime(duration * 1000))
  }

  return (
    <>
      <audio className='d-none' ref={audioRef} src={process.env.REACT_APP_IMG_URL + url} controls onLoadedData={onLoadedData} />
      <div className={clsx('px-3 pt-3 pb-2', styles.cont)}>
        <div className='d-flex'>
          <div className={styles.audioProgress}>
            <div className={clsx("progress", styles.progress)}>
              <div className={clsx("progress-bar", styles.progressBar)} role="progressbar" data-aria-valuenow={percent}
                data-aria-valuemin="0" data-aria-valuemax="100" style={{ width: percent + '%' }}>
                <span className="sr-only">{percent}% Complete</span>
              </div>
            </div>
          </div>
          <button className={clsx(styles.audioPlayBtn)} onClick={onPlay}>
            {playing ? <IconPause className={'me-1'} /> : <IconPlay className='me-1' />}
            &thinsp;
            {playing ? 'Pause' : 'Play'}
          </button>
        </div>
        <div className={clsx('text-left', styles.duration)}>{timer.mints}:{timer.secs}<span>/{duration.mints}:{duration.secs}</span></div>
      </div>
    </>
  )
}

export default AudioPlayer