import classnames from "classnames";
import clsx from "clsx";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  asyncGetPostListById,
  asyncInactiveAllPostByUser,
  asyncUpdateStatusPost,
  asyncUpdateStatusUser,
} from "../../redux/Action/postListAction";
import styles from "./style/post.module.scss";

const PostById = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, postListId } = useSelector((state) => state?.postListSlice);
  const [activeIconTab, setActiveIconTab] = useState("1");
  const [isOpenInactivePostDropDown, setIsOpenInactivePostDropDown] = useState(false);

  let pageNo = 0;

  if (searchParams.get("p")) {
    pageNo = searchParams.get("p")
  }

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const handleBlockPost = async (id) => {
    let data = {
      status: false,
    };
    let res = await dispatch(asyncUpdateStatusPost(id, data));

    if (res?.success) {
      await dispatch(asyncGetPostListById(id));
    } else {
      await dispatch(asyncGetPostListById(id));
    }
  };

  const handleUnBlockPost = async (id) => {
    let data = {
      status: true,
    };
    let res = await dispatch(asyncUpdateStatusPost(id, data));

    if (res?.success) {
      await dispatch(asyncGetPostListById(id));
    } else {
      await dispatch(asyncGetPostListById(id));
    }
  };

  const handleBlockUser = async (id) => {
    let data = {
      status: true,
    };
    let res = await dispatch(asyncUpdateStatusUser(id, data));

    if (res?.success) {
      await dispatch(asyncGetPostListById(postListId?._id));
    } else {
      await dispatch(asyncGetPostListById(postListId?._id));
    }
  };

  const handleUnBlockUser = async (id) => {
    let data = {
      status: false,
    };
    let res = await dispatch(asyncUpdateStatusUser(id, data));

    if (res?.success) {
      await dispatch(asyncGetPostListById(postListId?._id));
    } else {
      await dispatch(asyncGetPostListById(postListId?._id));
    }
  };

  const inActiveAllPostByUser = async (id) => {
    let data = {
      userId: id,
    };
    let res = await dispatch(asyncInactiveAllPostByUser(data));

    if (res?.success) {
      await dispatch(asyncGetPostListById(postListId?._id));
    } else {
      await dispatch(asyncGetPostListById(postListId?._id));
    }
  };

  const inactivePostAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete the post?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleBlockPost(id);
        Swal.fire("Deleted!", "Your post has been deleted", "success");
      }
    });
  };

  const activePostAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to Recover the post?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUnBlockPost(id);
        Swal.fire("Recovered!", "Your post has been recovered", "success");
      }
    });
  };

  const blockAlertUser = (id) => {
    Swal.fire({
      title: "Are you sure you want to block the user?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleBlockUser(id);
        Swal.fire("Blocked!", "Your user has been blocked.", "success");
      }
    });
  };

  const unBlockAlertUser = (id) => {
    Swal.fire({
      title: "Are you sure you want to unblock the user?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUnBlockUser(id);
        Swal.fire("Unblocked!", "Your user has been unblocked.", "success");
      }
    });
  };

  const inActiveAllPostByUserAlert = (id) => {
    Swal.fire({
      title: "Are you sure you want to deactivate all posts of this user?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        inActiveAllPostByUser(id);
        Swal.fire("Deactivated!", "Your posts have been set to inactive successfully.", "success");
      }
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(asyncGetPostListById(id));
    }

    return () => { };
  }, [id]);

  return (
    <React.Fragment>
      <Head title="Post Detail"></Head>
      {loading ? (
        <div
          style={{
            height: "200px",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spinner type="grow" color="dark" />
        </div>
      ) : postListId ? (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <Breadcrumb className="breadcrumb-arrow">
                  <BreadcrumbItem>
                    <Link to={"/feed"}>Feed</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={"/moderate-world"}>Moderate World</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <Link to={"/post-list"}>Post List </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a>Post Details</a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button color="light" outline className="bg-white d-none d-sm-inline-flex" onClick={() => navigate(pageNo === 0 ? -1 : `/post-list?p=${pageNo}`)}>
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(pageNo === 0 ? -1 : `/post-list?p=${pageNo}`);
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
          {postListId ? (
            <Block>
              <Card className="card-bordered">
                <div className="card-aside-wrap" id="user-detail-block">
                  <div className="card-content">
                    <PreviewCard>
                      <Nav
                        tabs
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            margin: "0 15px",
                            display: "flex",
                          }}
                        >
                          <NavItem>
                            <NavLink
                              tag="a"
                              href="#tab"
                              className={classnames({ active: activeIconTab === "1" })}
                              onClick={(ev) => {
                                ev.preventDefault();
                                toggleIconTab("1");
                              }}
                            >
                              <Icon name="file-docs"></Icon>
                              <span>Details</span>
                            </NavLink>
                          </NavItem>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            margin: "0 15px",
                          }}
                        >
                          <NavItem>
                            {postListId ? (
                              <NavLink tag="div" href="#tab">
                                <Button
                                  className="toggle btn-icon d-md-none"
                                  color={postListId?.active ? "danger" : "success"}
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    if (postListId?.active) {
                                      inactivePostAlert(postListId?._id);
                                    } else {
                                      activePostAlert(postListId?._id);
                                    }
                                  }}
                                >
                                  <Icon name={postListId?.active ? "unlink-alt" : "link-alt"}></Icon>
                                </Button>
                                <Button
                                  className="toggle d-none d-md-inline-flex"
                                  color={postListId?.active ? "danger" : "success"}
                                  outline
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    if (postListId?.active) {
                                      inactivePostAlert(postListId?._id);
                                    } else {
                                      activePostAlert(postListId?._id);
                                    }
                                  }}
                                >
                                  <Icon name={postListId?.active ? "unlink-alt" : "link-alt"}></Icon>
                                  <span>{postListId?.active ? "Delete Post " : "Recover Post"} </span>
                                </Button>
                              </NavLink>
                            ) : null}
                          </NavItem>

                          <NavItem>
                            {true ? (
                              <NavLink tag="div" href="#tab">
                                <Button
                                  className="toggle btn-icon d-md-none"
                                  color={!postListId?.user?.blockedByAdmin ? "danger" : "success"}
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    if (!postListId?.user?.blockedByAdmin) {
                                      blockAlertUser(postListId?.user?._id);
                                    } else {
                                      unBlockAlertUser(postListId?.user?._id);
                                    }
                                  }}
                                >
                                  <Icon
                                    name={!postListId?.user?.blockedByAdmin ? "user-cross" : "user-add-fill"}
                                  ></Icon>{" "}
                                </Button>
                                <Button
                                  className="toggle d-none d-md-inline-flex"
                                  color={!postListId?.user?.blockedByAdmin ? "danger" : "success"}
                                  outline
                                  onClick={() => {
                                    if (!postListId?.user?.blockedByAdmin) {
                                      blockAlertUser(postListId?.user?._id);
                                    } else {
                                      unBlockAlertUser(postListId?.user?._id);
                                    }
                                  }}
                                >
                                  <Icon
                                    name={!postListId?.user?.blockedByAdmin ? "user-cross" : "user-add-fill"}
                                  ></Icon>
                                  <span>{!postListId?.user?.blockedByAdmin ? "Block User " : "Unblock User"}</span>{" "}
                                </Button>
                              </NavLink>
                            ) : null}
                          </NavItem>

                          <Dropdown
                            isOpen={isOpenInactivePostDropDown}
                            toggle={() => {
                              setIsOpenInactivePostDropDown(!isOpenInactivePostDropDown);
                            }}
                            className="d-flex align-items-center"
                          >
                            <DropdownToggle className={clsx("dropdown-toggle btn btn-light")}>
                              <Icon className=" d-block d-md-none" name="more-h"></Icon>
                              <span className="d-none d-md-block  ">Dropdown</span>
                            </DropdownToggle>
                            <DropdownMenu>
                              <ul className="link-list-opt">
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#links"
                                    style={{ color: postListId?.active ? "#526484" : "#dddddd" }}
                                    onClick={(ev) => {
                                      ev.preventDefault();

                                      if (postListId?.active) {
                                        inActiveAllPostByUserAlert(postListId?.user?._id);
                                      }
                                    }}
                                  >
                                    <Icon name={"na"}></Icon>
                                    <span>Inactive All Post</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </Nav>

                      <TabContent activeTab={activeIconTab}>
                        <TabPane tabId="1">
                          <div className="card-inner">
                            <Block>
                              <BlockHead>
                                <BlockTitle tag="h5">Post Details</BlockTitle>
                              </BlockHead>
                              <div className="profile-ud-list">
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Post Id</span>
                                    <span className="profile-ud-value">{postListId?._id ? postListId?._id : ""}</span>
                                  </div>
                                </div>
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">{"Date & Time"}</span>
                                    <span className="profile-ud-value">
                                      {postListId?.createdAt
                                        ? moment(postListId.createdAt).utc().format("DD MMM YY h:mm A")
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Creator Name</span>
                                    <span className="profile-ud-value">
                                      {postListId?.user?.fullName ? postListId?.user?.fullName : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Last Name</span>
                                    <span className="profile-ud-value">
                                      {postListId?.user?.lastName ? postListId?.user?.lastName : ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Post Type</span>
                                    <span className="profile-ud-value">
                                      {postListId?.postType ? postListId?.postType : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Hashtag English</span>
                                    <span className="profile-ud-value">
                                      {postListId?.hashtag?.translations[0]?.hashtagTranslation?.name
                                        ? postListId?.hashtag?.translations[0]?.hashtagTranslation?.name
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Hashtag Arabic</span>
                                    <span className="profile-ud-value">
                                      {postListId?.hashtag?.translations[1]?.hashtagTranslation?.name
                                        ? postListId?.hashtag?.translations[1]?.hashtagTranslation?.name
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Post Status</span>
                                    <span
                                      className={clsx(
                                        "profile-ud-value",
                                        postListId?.active ? "active-color" : "inactive-color"
                                      )}
                                    >
                                      {postListId?.active ? "Active" : "InActive"}
                                    </span>
                                  </div>
                                </div>
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">User Status</span>
                                    <span
                                      className={clsx(
                                        "profile-ud-value",
                                        !postListId?.user?.blockedByAdmin ? "active-color" : "inactive-color"
                                      )}
                                    >
                                      {postListId?.user?.blockedByAdmin ? "Block" : "Not Blocked"}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="" style={{ width: "100%" }}>
                                <div className="d-flex flex-column ">
                                  <span className="profile-ud-label">Text</span>
                                  <span className="profile-ud-value">{postListId?.post ? postListId?.post : ""}</span>
                                </div>
                              </div>
                            </Block>
                            {postListId?.postType ? (
                              <Block>
                                <BlockHead className="nk-block-head-line">
                                  <BlockTitle tag="h6" className="overline-title text-base">
                                    Image / Video / PDF /Audio File
                                  </BlockTitle>
                                </BlockHead>
                                <div className="bq-note">
                                  <div className="bq-note-item">
                                    <div className="row m-0">
                                      {postListId?.postType === "image"
                                        ? postListId.media?.map((el, index) => {
                                          return (
                                            <div className="col-lg-4 col-sm-6 col-12 p-0" key={index}>
                                              <div className="p-2">
                                                <img
                                                  src={`${process.env.REACT_APP_IMG_URL}${el}`}
                                                  // src={el}
                                                  alt="Post Images"
                                                />
                                              </div>
                                            </div>
                                          );
                                        })
                                        : postListId?.postType === "audio"
                                          ? postListId?.media?.map((el, index) => {
                                            return (
                                              <div className="col-lg-4 col-sm-6 col-12 p-0" key={index}>
                                                <div className="p-2">
                                                  <video controls className="w-100">
                                                    <source
                                                      src={`${process.env.REACT_APP_IMG_URL}${el}`}
                                                      type="video/mp4"
                                                    />
                                                  </video>
                                                </div>
                                              </div>
                                            );
                                          })
                                          : postListId?.postType === "video"
                                            ? postListId.media?.map((el, index) => {
                                              return (
                                                <div className="col-lg-4 col-sm-6 col-12 p-0" key={index}>
                                                  <div className="p-2">
                                                    <video controls className="w-100">
                                                      <source
                                                        src={`${process.env.REACT_APP_IMG_URL}${el}`}
                                                        type="video/mp4"
                                                      />
                                                    </video>
                                                  </div>
                                                </div>
                                              );
                                            })
                                            : postListId?.postType === "file"
                                              ? postListId?.media?.map((el, index) => {
                                                return (
                                                  <div className="col-lg-4 col-sm-6 col-12 p-0" key={index}>
                                                    <div className="p-2">
                                                      <a
                                                        style={{ textDecoration: "underline" }}
                                                        href={`${process.env.REACT_APP_IMG_URL}${el}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        View File
                                                      </a>
                                                    </div>
                                                  </div>
                                                );
                                              })
                                              : null}
                                    </div>
                                  </div>
                                </div>
                              </Block>
                            ) : null}
                          </div>
                        </TabPane>
                      </TabContent>
                    </PreviewCard>
                  </div>
                </div>
              </Card>
            </Block>
          ) : null}
        </Content>
      ) : null}
    </React.Fragment>
  );
};

export default PostById;
