import clsx from 'clsx'
import React from 'react'

const IconPlay = (props) => {
  const { className, ...rest } = props

  return (
    <svg className={clsx('svgIcon', className)} xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" {...rest}>
      <path id="Polygon_6" data-name="Polygon 6" d="M5.768,3A2,2,0,0,1,9.232,3l4.037,7a2,2,0,0,1-1.732,3H3.463A2,2,0,0,1,1.73,10Z" transform="translate(13) rotate(90)" fill="currentColor" />
    </svg>

  )
}

export default IconPlay