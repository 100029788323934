import classnames from "classnames";
import clsx from "clsx";
import parse from "html-react-parser";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";

import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import RichTextEditor from "../../components/skillComponent/TextEditor";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { editJob, editJobStatus, editTitleAndDescriptionApi, getJobById } from "../../redux/Action/jobAction";
import styles from "./style/jobById.module.scss";
import { getCookie, setSession } from "../../utils/storage";
import { useSearchParams } from "react-router-dom/dist";
import { findRightPageBasedOnUrl } from "../../utils/numberToArabicNumber";
import { filePresignedUrl } from "./PostJob/middleware/postAction";
import { uploadData } from "../../utils/awsServices";
import { setAuthorizationToken } from "../../utils/apiServices";
import { updateJobDetails } from "./PostJob/middleware/putAction";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import { s3BaseUrl } from "../../utils/images";

const JobById = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, jobId } = useSelector((state) => state?.job);
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [activeIconTab, setActiveIconTab] = useState("1");
  const [showEdit, setShowEdit] = useState(false);
  const [jdMultimediaType, setJdMultimediaType] = useState('none')
  const [jdMultimediaFile, setJdMultimediaFile] = useState(null)
  const [jdThumbnailFile, setJdThumbnailFile] = useState(null)
  const [editingJdFile, setEditingJdFile] = useState(false)
  const [uploading, setUploading] = useState(false)

  //set status font color based on status
  const setFontColorBaseOnStatus = (status) => {
    if (status === "live" || status === "active") {
      return styles.greenColor;
    } else if (status === "draft" || status === "underReview" || status === "inactive") {
      return styles.yellowColor;
    } else if (status === "rejected" || status === "block" || status === "closed") {
      return styles.redColor;
    } else {
      return "";
    }
  };
  const [searchParams] = useSearchParams();
  let pageNo = 0;

  if (searchParams.get("p")) {
    pageNo = searchParams.get("p");
  }

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  const changeJobStatusAlert = (id, data, jobId) => {
    let alertTitle = ""; // Initialize an empty string for the alert title

    switch (data?.status) {
      case "live":
        alertTitle = "Are you sure you want to make this job live?";
        break;
      case "inactive":
        alertTitle = "Are you sure you want to pause this job?";
        break;
      default:
        alertTitle = "Are you sure you want to close this job?";
        break;
    }

    Swal.fire({
      title: alertTitle,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          editJobStatus(
            jobId,
            data,
            (res) => {
              Swal.fire("Success!", res?.message, "success");
              dispatch(getJobById(jobId));
            },
            (res) => {
              Swal.fire("Error!", res?.message, "error");
              dispatch(getJobById(jobId));
            }
          )
        );
      }
    });
  };

  const EditTitleAndDescription = (id, data) => {
    Swal.fire({
      title: "Are you sure you want to edit?",
      // text: "Do ",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, Edit it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          editTitleAndDescriptionApi(
            jobId?._id,
            data,
            (res) => {
              Swal.fire("Success!", res?.message, "success");
              dispatch(getJobById(id));
              onFormCancel();
            },
            (res) => {
              Swal.fire("Error!", res?.message, "error");
              dispatch(getJobById(id));
              onFormCancel();
            }
          )
        );
      }
    });
  };

  const onFormCancel = () => {
    setShowEdit(false);
  };

  const handleEditData = async (e) => {
    e.preventDefault();

    EditTitleAndDescription(id, { title: editTitle, jobDescription: editDescription });
  };

  useEffect(() => {
    if (id) {
      dispatch(getJobById(id));
    }

    return () => { };
  }, [id]);

  useEffect(() => {
    if (jobId) {
      setEditTitle(jobId?.title);
      setEditDescription(jobId.jobDescription);

      if (jobId.multimediaJobDescription) {
        setJdMultimediaType(jobId.multimediaJobDescription.type)
      } else {
        setJdMultimediaType('none')
      }
    }

    return () => { };
  }, [jobId]);

  const onChangeJDType = (event) => {
    setJdMultimediaType(event.target.value)
    setJdMultimediaFile(null)
  }

  const onChangeJDFile = useCallback((event) => {
    const { files, name } = event.target
    const [file] = files
    console.log(file)
    if (file) {
      if (name == 'file') {
        if (file.type.startsWith(jdMultimediaType + '/')) {
          setJdMultimediaFile(file)
        } else {
          alert('Invalid file format.')
        }
      } else {
        if (['image/jpeg', 'image/png', 'image/webp', 'image/png'].includes(file.type)) {
          setJdThumbnailFile(file)
        } else {
          alert('Invalid file format.')
        }
      }
    }
  }, [jdMultimediaType])

  const onUploadJdFile = async () => {
    if (jdMultimediaFile || jdThumbnailFile) {
      try {
        setUploading(true)
        const path = await onUploadFile(jdMultimediaFile)
        const thumbnailPath = await onUploadFile(jdThumbnailFile)

        const payload = { multimediaJobDescription: { type: jdMultimediaType, source: path } }
        if (jdMultimediaType == 'video' && thumbnailPath) {
          payload.multimediaJobDescription.thumbnail = thumbnailPath
        }
        const res = await updateJobDetails(jobId._id, payload)

        setJdMultimediaFile(null)
        setJdThumbnailFile(null)

        dispatch(getJobById(jobId.jobId))
        setUploading(false)
        setEditingJdFile(false)
        toast.loading("Changes saved successfully", { type: "success", isLoading: false, autoClose: 3000, closeButton: true });
      } catch (e) {
        setUploading(false)
        toast.loading("Failed to save changes", { type: "error", isLoading: false, autoClose: 3000, closeButton: true });
      }
    } else if (jdMultimediaType == 'none') {
      try {
        setUploading(true)
        const res = await updateJobDetails(jobId._id, { multimediaJobDescription: null })
        dispatch(getJobById(jobId.jobId))
        setUploading(false)
        setEditingJdFile(false)
        toast.loading("Changes saved successfully", { type: "success", isLoading: false, autoClose: 3000, closeButton: true });
      } catch (e) {
        setUploading(false)
        toast.loading("Failed to save changes", { type: "error", isLoading: false, autoClose: 3000, closeButton: true });
      }
    }
  }

  const onUploadFile = async (file) => {
    if (file) {
      if (file.constructor.name == 'Object') {
        return file.path
      } else {
        let fileGenerate = await filePresignedUrl({
          mimeType: file.type,
          name: file.name.replace(/\.[^/.]+$/, ""),
        });
        if (fileGenerate?.success && fileGenerate?.data) {
          let preUrl = fileGenerate?.data;

          const formData = new Blob([file], { type: file?.type });

          let fileUploadAws = await uploadData(preUrl, formData, file?.type);
          if (fileUploadAws?.success || fileUploadAws.status === 200) {
            const fetchFile = fileGenerate?.data?.split("?");
            const path = fetchFile[0].substring(fetchFile[0]?.lastIndexOf(".com") + 5);
            return path
          } else {
            throw Error('File upload error!')
          }
        } else {
          throw Error('presigned url error!')
        }
      }
    }
  }

  const onEditingJdFile = () => {
    if (jobId.multimediaJobDescription?.source) {
      setJdMultimediaFile({ name: jobId.multimediaJobDescription.source.split("/").pop(), path: jobId.multimediaJobDescription.source })
    }
    if (jobId.multimediaJobDescription?.thumbnail) {
      setJdThumbnailFile(({ name: jobId.multimediaJobDescription.thumbnail.split("/").pop(), path: jobId.multimediaJobDescription.thumbnail }))
    }
    setEditingJdFile(true)
  }

  const disabledSave = useMemo(() => {
    if (uploading) return true;
    else if (jdMultimediaType == 'video' && !(jdMultimediaFile && jdThumbnailFile)) return true;
    else if (jdMultimediaType == 'audio' && !jdMultimediaFile) return true;
    else return false;
  }, [uploading, jdMultimediaType, jdMultimediaFile, jdThumbnailFile])

  return (
    <React.Fragment>
      <Head title="Job Detail"></Head>
      {loading ? (
        <div
          style={{
            height: "200px",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spinner type="grow" color="dark" />
        </div>
      ) : jobId ? (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Job / <strong className="text-primary small">{jobId?.title}</strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      Job created at: <span className="text-base">{moment(jobId?.createdAt).format("YYYY-MM-DD")}</span>
                    </li>
                    <li>
                      Company:{" "}
                      <Link to={`/company/${jobId?.company?._id}`} className="text-primary" target="_blank">
                        {jobId?.company?.companyName}
                      </Link>{" "}
                      <span className="text-base">
                        ({jobId?.company?.companyVerified ? "Verified" : "Not verified"})
                      </span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={() => {
                    if (findRightPageBasedOnUrl("action-by-hr-job-details")) {
                      navigate(pageNo === 0 ? -1 : `/action-by-hr?p=${pageNo}`);
                    } else {
                      navigate(pageNo === 0 ? -1 : `/jobs?p=${pageNo}`);
                    }
                  }}
                >
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={() => {
                    if (findRightPageBasedOnUrl("action-by-hr-job-details")) {
                      navigate(pageNo === 0 ? -1 : `/action-by-hr?p=${pageNo}`);
                    } else {
                      navigate(pageNo === 0 ? -1 : `/jobs?p=${pageNo}`);
                    }
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <PreviewCard>
                    <Nav
                      tabs
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          margin: "0 15px",
                          display: "flex",
                        }}
                      >
                        <NavItem>
                          <NavLink
                            tag="a"
                            href="#tab"
                            className={classnames({ active: activeIconTab === "1" })}
                            onClick={(ev) => {
                              ev.preventDefault();
                              toggleIconTab("1");
                            }}
                          >
                            <Icon name="file-docs"></Icon>
                            <span>Details</span>
                          </NavLink>
                        </NavItem>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          margin: "0 15px",
                        }}
                      >
                        <NavItem style={{ paddingRight: "15px" }}>
                          <NavLink tag="div" href="#tab">
                            <Button
                              className="toggle btn-icon d-md-none"
                              color="warning"
                              onClick={() => {
                                setShowEdit(true);
                              }}
                            >
                              <Icon name="edit"></Icon>
                            </Button>
                            <Button
                              className="toggle d-none d-md-inline-flex"
                              color="warning"
                              outline
                              onClick={() => {
                                setShowEdit(true);
                              }}
                            >
                              <Icon name="edit"></Icon>
                              <span>Edit</span>
                            </Button>
                          </NavLink>
                        </NavItem>

                        {jobId?.company?.companyVerified && <NavItem>
                          <NavLink tag="div" href="#tab">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                href="#more"
                                onClick={(ev) => ev.preventDefault()}
                                className={clsx("dropdown-toggle btn ", styles.changeStatusDrop)}
                              >
                                <span className="d-md-block d-none">Change Job Status</span>
                                <Icon name="chevron-down" className={"d-md-block d-none"}></Icon>
                                <Icon name="more-h" className={"d-md-none d-block"}></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li className={clsx(jobId?.status === "live" ? "active" : "")}>
                                    <DropdownItem
                                      tag="a"
                                      href="#remove"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        if (jobId?.status !== "live") {
                                          changeJobStatusAlert(jobId?._id, { status: "live" }, jobId?.jobId);
                                        }
                                      }}
                                      className={clsx(
                                        styles.greenColor,
                                        jobId?.status === "live" ? styles.disableDropdown : ""
                                      )}
                                    >
                                      <span>Live </span>
                                    </DropdownItem>
                                  </li>

                                  <li className={clsx(jobId?.status === "inactive" ? "active" : "")}>
                                    <DropdownItem
                                      tag="a"
                                      href="#remove"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        if (jobId?.status !== "inactive") {
                                          changeJobStatusAlert(jobId?._id, { status: "inactive" }, jobId?.jobId);
                                        }
                                      }}
                                      className={clsx(
                                        styles.yellowColor,
                                        jobId?.status === "inactive" ? styles.disableDropdown : ""
                                      )}
                                    >
                                      <span>Pause </span>
                                    </DropdownItem>
                                  </li>

                                  <li className={clsx(jobId?.status === "closed" ? "active" : "")}>
                                    <DropdownItem
                                      tag="a"
                                      href="#remove"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        if (jobId?.status !== "closed") {
                                          changeJobStatusAlert(jobId?._id, { status: "closed" }, jobId?.jobId);
                                        }
                                      }}
                                      className={clsx(
                                        styles.redColor,
                                        jobId?.status === "closed" ? styles.disableDropdown : ""
                                      )}
                                    >
                                      <span>Close </span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </NavLink>
                        </NavItem>}

                        <NavItem style={{ paddingRight: "15px" }}>
                          <NavLink tag="div" href="#tab">
                            <Button
                              className="toggle btn-icon d-md-none"
                              color="info"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSession("jobData", { jobId: jobId?.jobId });

                                navigate(`/post-a-job/basic-details?job=${jobId?.jobId}`);
                              }}
                            >
                              <Icon name="edit"></Icon>
                            </Button>
                            <Button
                              className="toggle d-none d-md-inline-flex"
                              color="info"
                              outline
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSession("jobData", { jobId: jobId?.jobId });

                                navigate(`/post-a-job/basic-details?job=${jobId?.jobId}`);
                              }}
                            >
                              <Icon name="edit"></Icon>
                              <span>Edit Job</span>
                            </Button>
                          </NavLink>
                        </NavItem>
                      </div>
                    </Nav>

                    <TabContent activeTab={activeIconTab}>
                      <TabPane tabId="1">
                        <div className="card-inner">
                          <Block>
                            <BlockHead className={clsx(styles.jobDetailsInlineBlock)}>
                              <BlockTitle tag="h5">Job Details</BlockTitle>
                            </BlockHead>
                            {jobId.conflict_affected_allowed && <div style={{ color: "#FF7D3D" }} className="w-100 d-flex justify-content-start align-items-center">
                              This job is &nbsp; <b> open for conflict affected candidates</b>
                            </div>}
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Title</span>
                                  <span className="profile-ud-value">{jobId?.title}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Company</span>
                                  <span className="profile-ud-value">{jobId?.company?.companyName}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Job type</span>
                                  <span className="profile-ud-value">
                                    {jobId?.jobType?.translations?.[0]?.jobTypeTranslation?.name}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Job Category</span>
                                  <span className="profile-ud-value">
                                    {jobId?.jobCategory?.translations?.[0]?.jobCategoryTranslation?.name}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Status</span>
                                  <span className={clsx("profile-ud-value", setFontColorBaseOnStatus(jobId?.status))}>
                                    {jobId?.status === "inactive" ? "paused" : jobId?.status}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Block>

                          <Block>
                            <BlockHead className="nk-block-head-line">
                              <BlockTitle tag="h6" className="overline-title text-base">
                                Additional Details
                              </BlockTitle>
                            </BlockHead>
                            <div className="profile-ud-list">
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Interviewer</span>
                                  <span className="profile-ud-value">
                                    {jobId?.Interviewer === "Myself" ? jobId?.user?.fullName : jobId?.RecruiterName}
                                  </span>
                                </div>
                              </div>
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Openings</span>
                                  <span className="profile-ud-value">{jobId?.openings ?? "Not specified"}</span>
                                </div>
                              </div>
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">City</span>
                                  <span className="profile-ud-value">
                                    {jobId?.city?.translations?.[0]?.cityTranslation?.name} year
                                  </span>
                                </div>
                              </div>
                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Country</span>
                                  <span className="profile-ud-value">
                                    {jobId?.jobCountry?.translations?.[0]?.countryTranslation?.name}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Skills</span>
                                  <span className="profile-ud-value">
                                    {jobId?.skills?.length
                                      ? jobId?.skills?.map(
                                        (li, i) =>
                                          `${li?.translations?.[0]?.skillTranslation?.name}${i < jobId?.skills?.length ? ", " : ""
                                          }`
                                      )
                                      : "Not specified"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Salary</span>
                                  <span className="profile-ud-value">
                                    {jobId?.salary
                                      ? `${jobId?.salary?.lowValue ? jobId?.salary?.lowValue : ""}${`${jobId?.salary?.upperValue ? -jobId?.salary?.upperValue : ""
                                      }`}`
                                      : "Not specified"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Type</span>
                                  <span className="profile-ud-value">
                                    {jobId?.partTime
                                      ? `Part Time ${jobId?.wfh ? `(Work from home)` : ""}`
                                      : `Full Time ${jobId?.wfh ? `(Work from home)` : ""}`}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Night Shift</span>
                                  <span className="profile-ud-value">{jobId?.nightShift ? `Yes` : "No"}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Gender</span>
                                  <span className="profile-ud-value">
                                    {jobId?.gender ? `${jobId?.gender}` : "Not specified"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Experience</span>
                                  <span className="profile-ud-value">
                                    {jobId?.experienceRequired === "Fresher"
                                      ? `${jobId?.experienceRequired}`
                                      : jobId?.experience?.min && jobId?.experience?.max
                                        ? `${jobId?.experienceRequired} ${jobId?.experience?.min}-${jobId?.experience?.max} years`
                                        : "NA"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Working Days</span>
                                  <span className="profile-ud-value">{jobId?.workingDays?.join(", ")}</span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Working Time</span>
                                  <span className="profile-ud-value">
                                    {jobId?.workTimings?.startTime && jobId?.workTimings?.startTime
                                      ? `${jobId?.workTimings?.startTime?.hours < 10
                                        ? `0${jobId?.workTimings?.startTime?.hours}`
                                        : jobId?.workTimings?.startTime?.hours
                                      }:${jobId?.workTimings?.startTime?.mins < 10
                                        ? `0${jobId?.workTimings?.startTime?.mins}`
                                        : jobId?.workTimings?.startTime?.mins
                                      } ${jobId?.workTimings?.startTime?.zone} - ${jobId?.workTimings?.endTime?.hours < 10
                                        ? `0${jobId?.workTimings?.endTime?.hours}`
                                        : jobId?.workTimings?.endTime?.hours
                                      }:${jobId?.workTimings?.endTime?.mins < 10
                                        ? `0${jobId?.workTimings?.endTime?.mins}`
                                        : jobId?.workTimings?.endTime?.mins
                                      } ${jobId?.workTimings?.endTime?.zone}`
                                      : "Not specified"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Education</span>
                                  <span className="profile-ud-value">
                                    {jobId?.education
                                      ? `${jobId?.education?.translations?.[0]?.educationTranslation?.label}`
                                      : "Not specified"}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Requirement</span>
                                  <span className="profile-ud-value">
                                    {jobId?.jobRequirements?.length
                                      ? jobId?.jobRequirements?.map(
                                        (li, i) =>
                                          `${li?.translations?.[0]?.jobRequirementTranslation?.name}${i < jobId?.jobRequirements?.length - 1 ? ", " : ""
                                          }`
                                      )
                                      : `Not specified`}
                                  </span>
                                </div>
                              </div>

                              <div className="profile-ud-item">
                                <div className="profile-ud wider">
                                  <span className="profile-ud-label">Benefits</span>
                                  <span className="profile-ud-value">
                                    {jobId?.benefits?.length
                                      ? jobId?.benefits?.map(
                                        (li, i) =>
                                          `${li?.translations?.[0]?.jobBenefitTranslation?.name}${i < jobId?.benefits?.length - 1 ? ", " : ""
                                          }`
                                      )
                                      : `Not specified`}
                                  </span>
                                </div>
                              </div>

                              {jobId?.incentives ? (
                                <div className="profile-ud-item">
                                  <div className="profile-ud wider">
                                    <span className="profile-ud-label">Incentives</span>
                                    <span className="profile-ud-value">Yes</span>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </Block>
                          <Block>
                            <BlockHead className="nk-block-head-line">
                              <BlockTitle tag="h6" className="overline-title text-base">
                                Description
                              </BlockTitle>
                            </BlockHead>
                            <div className="bq-note">
                              <div className="bq-note-item">
                                <div className="bq-note-text">{parse(jobId?.jobDescription ?? "")}</div>
                              </div>
                            </div>
                          </Block>
                          <Block>
                            <BlockHead className="nk-block-head-line">
                              <BlockTitle tag="h6" className="overline-title text-base">
                                Audio / Video Description
                              </BlockTitle>
                            </BlockHead>
                            <div className="d-flex justify-between mb-5">
                              <fieldset disabled={jdMultimediaFile || jdThumbnailFile || (jobId.multimediaJobDescription && !editingJdFile)}>
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="jdMmNone"
                                    name="multimediaJobDescription"
                                    checked={jdMultimediaType == 'none'}
                                    value="none"
                                    className="custom-control-input form-control"
                                    onChange={onChangeJDType}
                                  />
                                  <label className="custom-control-label mr-4" htmlFor="jdMmNone">
                                    None
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="jdMmAudio"
                                    name="multimediaJobDescription"
                                    checked={jdMultimediaType == 'audio'}
                                    value="audio"
                                    className="custom-control-input form-control"
                                    onChange={onChangeJDType}
                                  />
                                  <label className="custom-control-label mr-4" htmlFor="jdMmAudio">
                                    Audio
                                  </label>
                                </div>
                                <div className="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    id="jdMmVideo"
                                    name="multimediaJobDescription"
                                    checked={jdMultimediaType == 'video'}
                                    value="video"
                                    className="custom-control-input form-control"
                                    onChange={onChangeJDType}
                                  />
                                  <label className="custom-control-label mr-4" htmlFor="jdMmVideo">
                                    Video
                                  </label>
                                </div>
                              </fieldset>
                              {(!!jdMultimediaFile || editingJdFile) && <button className={clsx("btn btn-blue rounded-pill px-5")} onClick={onUploadJdFile} disabled={disabledSave}>{uploading ? 'Saving...' : 'Save Changes'}</button>}
                              {!!jobId.multimediaJobDescription && !editingJdFile && <button className={clsx("btn btn-blue rounded-pill px-5")} onClick={onEditingJdFile}>Edit</button>}
                            </div>
                            <div>
                              {jdMultimediaType != 'none' && (
                                <div className="d-inline-block text-center">
                                  <div>
                                    {((!jdMultimediaFile && !jobId.multimediaJobDescription) || (editingJdFile && !jdMultimediaFile)) && (
                                      <div className="d-inline-block mr-5">
                                        <label className={styles.inputFile}>
                                          <input
                                            type='file'
                                            name="file"
                                            accept={jdMultimediaType == 'audio' ? 'audio/*' : 'video/*'}
                                            onChange={onChangeJDFile} />
                                          Upload file
                                        </label>
                                        <p className="fs-12px">{jdMultimediaType == 'audio' ? 'MP3' : 'MP4'} format accepted</p>
                                      </div>
                                    )}
                                    {!!jdMultimediaFile && (
                                      <div className="d-inline-block mr-5">
                                        <span>{jdMultimediaFile.name}</span>
                                        <span onClick={() => setJdMultimediaFile(null)} className={clsx('pointer', styles.removeFile)}>
                                          <Icon className="d-inline-block fs-16px font-weight-bold ml-2" style={{ color: '#fe7d3d' }} name="cross" />
                                        </span>
                                      </div>
                                    )}

                                    {(jdMultimediaType == 'video' && ((!jdThumbnailFile && !jobId.multimediaJobDescription?.thumbnail) || (editingJdFile && !jdThumbnailFile))) && (
                                      <div className="d-inline-block">
                                        <label className={styles.inputFile}>
                                          <input
                                            type='file'
                                            name="thumbnail"
                                            accept={'image/jpeg,image/webp,image/png'}
                                            onChange={onChangeJDFile} />
                                          Upload Thumbnail
                                        </label>
                                        <p className="fs-12px">WEBP, JPG, and PNG format accepted</p>
                                      </div>
                                    )}
                                    {!!jdThumbnailFile && (
                                      <div className="d-inline-block">
                                        <span>{jdThumbnailFile.name}</span>
                                        <span onClick={() => setJdThumbnailFile(null)} className={clsx('pointer', styles.removeFile)}>
                                          <Icon className="d-inline-block fs-16px font-weight-bold ml-2" style={{ color: '#fe7d3d' }} name="cross" />
                                        </span>
                                      </div>
                                    )}
                                  </div>

                                  {jobId.multimediaJobDescription?.type == 'audio' && !editingJdFile && (
                                    <AudioPlayer
                                      url={jobId.multimediaJobDescription.source}
                                    />
                                  )}
                                  {jobId.multimediaJobDescription?.type == 'video' && !editingJdFile && (
                                    <video
                                      className={styles.video}
                                      controls
                                      src={s3BaseUrl + jobId.multimediaJobDescription.source}
                                      poster={s3BaseUrl + jobId.multimediaJobDescription.thumbnail}></video>
                                  )}
                                </div>
                              )}
                            </div>


                          </Block>
                        </div>
                      </TabPane>
                    </TabContent>
                  </PreviewCard>
                </div>
              </div>
            </Card>
          </Block>

          {/* Jobs Edit title and description*/}
          <Modal isOpen={showEdit} toggle={() => onFormCancel()} className="modal-dialog-centered" size="lg">
            <ModalBody>
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h5">Edit</BlockTitle>
                </BlockHeadContent>
              </BlockHead>

              <Block>
                <form
                  onSubmit={(e) => {
                    handleEditData(e);
                  }}
                >
                  <div className="g-3 row">
                    {/* Title */}
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="title">
                          Title
                        </label>
                        <div
                          className="input-group"
                          style={{
                            margin: "0 0 15px 0",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder={`Title`}
                            value={editTitle}
                            onChange={(e) => {
                              setEditTitle(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Description */}
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="title">
                          Description
                        </label>
                        <div
                          className={
                            jobId?.jobDescription !== ""
                              ? clsx(`form-control-wrap`, styles.editorDiv)
                              : clsx(`form-control-wrap`)
                          }
                        >
                          <RichTextEditor
                            initialValue={editDescription || ""}
                            getValue={(value) => setEditDescription(value)}
                            arValue={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <Button color="primary" type="submit">
                        <Icon className="plus"></Icon>
                        <span>Edit</span>
                      </Button>
                    </div>
                  </div>
                </form>
              </Block>
            </ModalBody>
          </Modal>
        </Content>
      ) : null}
    </React.Fragment>
  );
};

export default JobById;
