import React from "react";
import styles from "../style/loading.module.scss"

import { clsx } from "clsx";


const Loading = () => {
    return (
        <div
            className={clsx(
                `position-fixed w-100 h-100 text-center ${styles.loading}`
            )}
            style={{
                background: "#0007",
                color: "white",
                top: 0,
                left: 0,
                zIndex: 10000000000099,
            }}
        >
            <svg width={"250"} height="250" viewBox="0 0 40 50">
                <polygon
                    stroke="#fff"
                    strokeWidth={"1"}
                    fill="none"
                    points="20,1 40,40 1,40"
                />
            </svg>
            <span style={{ fontSize: "22px" }}>
                Loading...
            </span>
        </div>
    );
};

export default Loading;
