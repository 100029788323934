

let DEFAULT_LANGUAGE = "ar";
const TO_LOCALE_STRING = { en: "en-US", ar: "ar-EG" };

export const numberToLocaleString = (num, userLanguage = DEFAULT_LANGUAGE) => {

    return num?.toLocaleString(TO_LOCALE_STRING[userLanguage], { useGrouping: false })

}





export const convertNumber = (num, toArabic) => {
    if (toArabic === "ar") {
        const digitsMap = { '0': '٠', '1': '١', '2': '٢', '3': '٣', '4': '٤', '5': '٥', '6': '٦', '7': '٧', '8': '٨', '9': '٩' };
        const numStr = num.toString();
        const convertedNumStr = numStr.split('').map(digit => digitsMap[digit] || digit).join('');
        return convertedNumStr;
    } else {
        const formattedNum = num.toLocaleString();
        return formattedNum;
    }

}


