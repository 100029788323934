import { toast } from "react-toastify";
import { deleteMethod, getMethod, postMethod, putMethod, setAuthorizationToken } from "../../utils/apiServices";
import { getCookie } from "../../utils/storage";

import { isCountryLoading, countryReducer, countryById, addCountryReducer, removeCountry, isCountrySuccess, isCountryError } from "../Apislices/countrySlice"



export const getAllCountry = (type = "", page, limit) => async (dispatch) => {
  try {
    dispatch(isCountryLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/country/list?type=${type}&page=${page}&limit=${limit}`);

    if (response?.success) {
      dispatch(isCountrySuccess());
      dispatch(
        countryReducer({
          data: response?.data,
          total: response?.total,
        })
      );
    } else {
      dispatch(isCountryError(response?.message));
    }
  } catch (error) {
    dispatch(isCountryError(error?.message));
  }
};

export const getCountryById = (id) => async (dispatch) => {
  try {
    dispatch(isCountryLoading());
    setAuthorizationToken(getCookie("_jwt"));
    const response = await getMethod(`/admin/country/${id}`);
    if (response?.success) {
      dispatch(isCountrySuccess());
      dispatch(countryById(response?.data));
    } else {
      dispatch(isCountryError(response?.message));
      toast.error("This country is alerady available in the list ");
      dispatch(getAllCountry("", 1, 20));
    }
  } catch (error) {
    dispatch(isCountryError(error?.message));
  }
};


export const addCountry = (data) => async (dispatch) => {
  try {
    let response = await postMethod(`/admin/country/add`, data);

    if (response?.success) {
      dispatch(isCountrySuccess());
      toast.success(response?.message);
    } else {
      dispatch(isCountryError(response?.message));
      toast.error("This country is alerady available in the list ");
      dispatch(getAllCountry("", 1, 20));
    }
  } catch (error) {
    dispatch(isCountryError(error?.message));
    toast.error("This country is alerady available in the list ");

  }
};


export const deleteCountryFromList = (id) => async (dispatch) => {
  try {
    let response = await deleteMethod(`/admin/remove/${id}/country`);
    if (response?.success) {

      dispatch(isCountrySuccess());
      toast.success(response?.message);
    } else {
      dispatch(isCountryError(response?.message));
    }
  } catch (error) {
    dispatch(isCountryError(error?.message));
  }
};



export const editCountryFromList = (id, data) => async (dispatch) => {
  try {
    let response = await putMethod(`/admin/country/${id}/edit`, data);
    if (response?.success) {
      dispatch(isCountrySuccess());
      toast.success(response?.message);
    } else {
      dispatch(isCountryError(response?.message));
      toast.error("This country is alerady available in the list ");
      dispatch(getAllCountry("", 1, 20));
    }
  } catch (error) {
    dispatch(isCountryError(error?.message));
  }
};



