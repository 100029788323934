import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  skills: [],
  skill: null,
  total: 0,
};

export const skillSlice = createSlice({
  name: "skills",
  initialState,
  reducers: {
    isSkillsLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.skills = [];
    },
    skills: (state, action) => {
      state.loading = false;
      state.skills = action.payload.data;
      state.total = action.payload.total;
      state.success = true;
      state.error = false;
    },
    skillById: (state, action) => {
      state.loading = false;
      state.skill = action.payload;
      // state.total = action.payload.total;
      state.success = true;
      state.error = false;
    },
    addSkills: (state, action) => {
      state.loading = false;
      // state.skills = [...state.skills, action.payload.data];
      // state.total = action.payload.total;
      state.success = true;
      state.error = false;
    },
    removeSkill: (state) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isSkillsSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    },
    isSkillsError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.skills = [];
    },
  },
});

export default skillSlice.reducer;

export const { skills, skillById, addSkills, removeSkill, isSkillsLoading, isSkillsSuccess, isSkillsError } =
  skillSlice.actions;
