import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { Icon } from "../../components/Component";
import { svgSelect } from "../../components/FileUploadModal/Data";
import { bytesToMegaBytes } from "../../utils/Utils";
import {
  filePresignedUrl,
  getCompanyById,
  updateDataInAwsApi,
  uploadCompanyDocumentApi,
} from "../../redux/Action/companyAction";
import { toast } from "react-toastify";
import { uploadData } from "../../utils/awsServices";
import { useDispatch } from "react-redux";

const DocumentUploadModal = ({ toggle, companyId }) => {
  //   const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  // handles ondrop function of dropzone
  const handleDropChange = async (files) => {
    if (files) {
      let fileGenerate = await filePresignedUrl({
        mimeType: files[0].type,
        name: files[0].name.replace(/\.[^/.]+$/, ""),
      });
      if (fileGenerate?.success && fileGenerate?.data) {
        let preUrl = fileGenerate?.data;

        const formData = new Blob([files[0]], { type: files[0]?.type });

        const toastId = toast.loading("Please wait...");

        let fileUploadAws = await uploadData(preUrl, formData, files[0]?.type);
        if (fileUploadAws?.success || fileUploadAws.status === 200) {
          const fetchFile = fileGenerate?.data?.split("?");
          const path = fetchFile[0].substring(fetchFile[0]?.lastIndexOf(".com") + 5);

          let res = await dispatch(
            uploadCompanyDocumentApi({ title: files[0].name, url: path, alt: files[0].name || "Company Document" })
          );

          if (res?.success) {
            //   let data = [...filesId, res?.data?._id];
            let data = {
              file: res?.data?._id,
              companyId: companyId?._id,
            };

            await dispatch(updateDataInAwsApi(data));
            await dispatch(getCompanyById(companyId?._id));
            toggle();
            document.getElementById("document").scrollIntoView({ behavior: "smooth" });

            toast.update(toastId, {
              render: "Uploaded",
              type: "success",
              isLoading: false,
              autoClose: 3000,
              closeButton: true,
              closeOnClick: true,
            });
          }
        } else {
          toast.update(toastId, {
            render: fileUploadAws.message,
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeButton: true,
            closeOnClick: true,
          });
        }
      } else {
        toast.error(fileGenerate?.message);
      }
    } else {
      toast.error("Please select document!");
    }
  };

  const removeFromList = (name) => {
    // let defaultFiles = files;
    // defaultFiles = defaultFiles.filter((item) => item.name !== name);
    // setFiles([...defaultFiles]);
  };

  return (
    <React.Fragment>
      <a
        href="#close"
        onClick={(ev) => {
          ev.preventDefault();
          toggle();
        }}
        className="close"
      >
        <Icon name="cross-sm"></Icon>
      </a>
      <div className="modal-body modal-body-md">
        <div className="nk-upload-form">
          <h5 className="title mb-3">Upload File</h5>
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleDropChange(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} className="dropzone upload-zone small bg-lighter my-2 dz-clickable">
                  <input {...getInputProps()} />
                  <div className="dz-message">
                    <span className="dz-message-text">
                      <span>Drag and drop</span> file here or <span>browse</span>
                    </span>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </div>

        <div className="nk-modal-action justify-end">
          <ul className="btn-toolbar g-4 align-center">
            <li>
              <a
                href="#toggle"
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle();
                }}
                className="link link-primary"
              >
                Cancel
              </a>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocumentUploadModal;
