// import { Jodit } from "jodit-react";
import JoditEditor from "jodit-react";
import React, { useMemo, useRef, useState } from "react";

const RichTextEditor = ({ initialValue, getValue, arValue }) => {
  const editor = useRef(null);
  const [configData, setConfigData] = useState(arValue ? arValue : false);




  const config = useMemo(() => {
    return {
      extraButtons: [

        {
          name: configData ? "English" : "Arabic",
          tooltip: "type in english language",
          exec: () => {
            setConfigData(!configData);
          },
        },
      ],
      readonly: false,
      language: configData ? "ar" : "en",
      direction: configData ? "rtl" : "ltr",
    };
  }, [configData]);

  return (
    <div
      style={{
        textAlign: "start",
      }}
    >
      <JoditEditor
        ref={editor}
        value={initialValue}
        config={config}
        tabIndex={1}

        onChange={(newContent) => {
          getValue(newContent);

        }}

      />
    </div>
  );
};

export default RichTextEditor;
