import { postMethod, setAuthorizationToken } from "../utils/apiServices";
import { getCookie } from "../helpers/storage";

const sendOtp = async (data) => {
  let response = await postMethod("admin/sendOtp", data);
  return response;
};
const otpVerify = async (data) => {
  let response = await postMethod("admin/otp/verify", data);
  return response;
};
const filePresignedUrl = async (data) => {
  let response = await postMethod("admin/file/presingend", data);
  return response;
};
const addSkilles = async (data) => {
  let response = await postMethod("admin/job/skills/add", data);
  return response;
};
const addJob = async (data) => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await postMethod("admin/job/add", data);
  return response;
};

const addRequiredQualification = async (data) => {
  let response = await postMethod("users/addRequirement", data);
  return response;
};

const addSkillByJobType = async (id, data) => {
  let response = await postMethod(`/job-types/addSkills/${id}`, data);
  return response;
};

const addFile = async (data) => {
  let response = await postMethod(`/admin/addFile`, data);
  return response;
};

const anotherRecOtpSend = async (data) => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await postMethod(`/admin/hr/send/whatsAppOtp`, data);
  return response;
};

const anotherRecOtpVerify = async (data) => {
  setAuthorizationToken(getCookie("_jwt"));
  let response = await postMethod(`/admin/hr/verify/whatsAppOtp`, data);
  return response;
};

const profileOtpSend = async (data) => {
  let response = await postMethod(`/admin/send/profileOtp`, data);
  return response;
};

const profileOtpVerify = async (data) => {
  let response = await postMethod(`/admin/verify/profileOtp`, data);
  return response;
};




const sendMessage = async (id, data) => {
  let response = await postMethod(`/admin/send-message/${id}`, data);
  return response;
};

const candidateInterview = async (id, data) => {
  let response = await postMethod(
    `admin/interview/candidate/${id}/schedule`,
    data
  );
  return response;
};
const helpSupportApi = async (data) => {
  let response = await postMethod(`/admin/inquiry/support`, data);
  return response;
};

const sendNotificationApi = async (data) => {
  let response = await postMethod(`/admin/inquiry/support`, data);
  return response;
};

const submitForVerificationApi = async (id) => {
  let response = await postMethod(`/admin/company/${id}/verified`);
  return response;
};

const loginPasswordApi = async (data) => {
  let response = await postMethod(`/auth/login`, data);
  return response
}
export {
  loginPasswordApi,
  submitForVerificationApi,
  sendNotificationApi,
  sendOtp,
  otpVerify,
  filePresignedUrl,
  addSkilles,
  addJob,
  addRequiredQualification,
  addSkillByJobType,
  addFile,
  anotherRecOtpSend,
  anotherRecOtpVerify,
  profileOtpSend,
  profileOtpVerify,
  sendMessage,
  candidateInterview,
  helpSupportApi,
};
