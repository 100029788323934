import { useState } from "react";

import React, { useEffect } from "react";
import { Button } from "react-bootstrap";

const CountdownTimer = ({
  minSecs,
  handleSubmit,
  label,
  resetValue,
  setResetValue,
}) => {
  const { minutes = 0, seconds = 60 } = minSecs;

  const [[mins, secs], setTime] = useState([minutes, seconds]);
  const [trigger, setTrigger] = useState(false);
  const tick = () => {
    if (mins === 0 && secs === 0) {
      reset();
      setTrigger(true);
      if (resetValue === false) {
        setResetValue(true);
      }
    } else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };

  const reset = () => {
    setTime([parseInt(minutes), parseInt(seconds)]);
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      tick();
    }, 1000);
    return () => clearInterval(timerId);
  }, [tick]);

  return (
    <div
      style={{
        fontSize: "16px",
        // marginLeft: "10px",
        alignSelf: "center",
        display: "flex",
      }}
    >
      {trigger ? (
        <Button
          style={{
            border: "none",
            outline: "none",
            background: "none",
            color: "#d56c33",
            fontSize: "14px",
            cursor: "pointer",
            fontWeight: "600",
            padding: "0px",
          }}
          variant="link"
          type="button"
          onClick={() => {
            setTrigger(false);
            reset();
            handleSubmit();
            if (resetValue) {
              setResetValue(false);
            }
          }}
        >
          {label || "Resend OTP"}
        </Button>
      ) : (
        <p style={{ color: "#004E52", fontSize: "14", fontWeight: "500" }}>
          {minutes && seconds
            ? `
            ${mins.toString().padStart(2, "0")}:${secs
                .toString()
                .padStart(2, "0")}`
            : minutes
            ? `
              ${mins.toString().padStart(2, "0")}:${secs
                .toString()
                .padStart(2, "0")} min`
            : `
                ${secs.toString().padStart(2, "0")} secs`}
        </p>
      )}
    </div>
  );
};

export default CountdownTimer;
