import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: false,
  error: false,
  hr: null,
  total: 0,
  hrId: null,
};

export const hrSlice = createSlice({
  name: "hr",
  initialState,
  reducers: {
    isHrLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    },
    isHrSuccess: (state, action) => {
      state.loading = false;
      state.hr = action.payload.data;
      state.total = action.payload.total;
      state.success = true;
      state.error = false;
    },
    isHrByIdSuccess: (state, action) => {
      state.loading = false;
      state.hrId = action.payload;
      state.success = true;
      state.error = false;
    },
    isHrError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export default hrSlice.reducer;

export const { isHrByIdSuccess, isHrError, isHrLoading, isHrSuccess } = hrSlice.actions;
