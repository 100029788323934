import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../style/Home.module.scss"

function Sidebar(props) {
  const { path, editJobsIdActive, jobDetails } = props;
  const router = useNavigate();

  const pathsallow = [
    {
      id: 0,
      path: "basic-details",
      title: "Basic Details",
      term: jobDetails,
    },
    {
      id: 1,
      path: "candidates-requirements",
      title: "Candidate's Requirements",
      term: jobDetails && jobDetails?.jobCategory,
    },
    {
      id: 2,
      path: "interview-information",
      title: "Interview Information",
      term: jobDetails && jobDetails?.jobDescription,
    },
    {
      id: 3,
      path: "job-preview",
      title: "Job Preview",
      term: jobDetails && jobDetails?.Interviewer,
    },
  ];

  const findIndex = (array, paths) => {

    let flag = array.find(({ path }) => path === paths);

    return flag;
  };
  return (
    <div
      className="col-xl-3 col-lg-4 col-md-4 col-12 tabsHidenow"
      style={{
        position: "relative",
        top: "0px",
        padding: "15px",
        background: "#fff",
      }}
    >
      <div
        style={{
          background: "#fff",
          position: "sticky",
          width: "inherit",
          margin: "0px",
          left: "0px",
          zIndex: 1,
        }}
      >
        <div className={styles.postHeapathsallowding}>
          <h3 style={{ textAlign: "center" }}>POST A JOB</h3>
        </div>
        <div>
          <ul className={styles.postTabs}>
            {pathsallow?.length
              ? pathsallow?.map((items, index) => (


                <li key={index}>
                  {items?.term ? (
                    <Link
                      to={
                        editJobsIdActive
                          ? "/post-a-job/" +
                          items?.path +
                          "?job=" +
                          editJobsIdActive
                          : "/post-a-job/" + items?.path
                      }
                      className={"d-flex " + styles.tabTitle}
                    >
                      <span
                        className={`${findIndex(pathsallow, path)?.id >= index
                          ? "d-flex alignAnchor active"
                          : "d-flex alignAnchor"
                          }`}
                        style={{
                          margin: "0px",
                          padding: "0px",
                          cursor: "pointer",
                        }}
                      >
                        <h5 className={styles.count}>{items?.id + 1}</h5>
                        <h5 className={"textanch " + styles.tabTitle}>
                          {items?.title}
                        </h5>
                      </span>
                    </Link>
                  ) : (
                    <span className={"d-flex " + styles.tabTitle}>
                      <span
                        className={`${findIndex(pathsallow, path)?.id >= index
                          ? "d-flex alignAnchor active"
                          : "d-flex alignAnchor"
                          }`}
                        style={{
                          margin: "0px",
                          padding: "0px",

                        }}
                      >
                        <h5 className={styles.count}>{items?.id + 1}</h5>
                        <h5 className={"textanch " + styles.tabTitle}>
                          {items?.title}
                        </h5>
                      </span>
                    </span>
                  )}
                </li>

              ))
              : null}


          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;