/* eslint-disable */
//



import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, redirect, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./app.scss";
import CountryDetails from "./pages/Country/CountryDetails";
import Homepage from "./pages/Homepage";
import SkillDetails from "./pages/Skills/SkillDetails";
import SkillList from "./pages/Skills/SkillList";
import Error404Classic from "./pages/error/404-classic";

import PrivateRoute from "./route/PrivateRoute";
import CountryList from "./pages/Country/CountryList";
import Hr from "./pages/Hr/Hr";
import HrId from "./pages/Hr/HrId";
import User from "./pages/User/User";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Success from "./pages/auth/Success";
import VerifyOtp from "./pages/auth/VerifyOtp";
import Company from "./pages/company/Company";
import CompanyById from "./pages/company/CompanyById";
import JobById from "./pages/jobs/JobById";
import Jobs from "./pages/jobs/Jobs";
import InvoicePrint from "./pages/pre-built/invoice/InvoicePrint";
import UserProfileLayout from "./pages/pre-built/user-manage/UserProfileLayout";
import Pages from "./route/Index";
import { getCookie, setCookie } from "./utils/storage";
import UserId from "./pages/User/UserId";
import Feed from "./pages/Feed/Feed";
import AddPosts from "./pages/Feed/AddPosts";
import GetLinks from "./pages/Feed/GetLinks";
import Hashtag from "./pages/Hashtag/Hashtag";
import ModrateWorld from "./pages/ModrateWorld/ModrateWorld";
import PostList from "./pages/PostList/PostList";
import PostById from "./pages/PostList/PostById";
import CommentList from "./pages/CommentList/CommentList";
import CommentListById from "./pages/CommentList/CommentListById";
import UploadCsv from "./pages/Feed/UploadCsv";
import PostAJob from "./pages/jobs/PostAJob";
import ActionByHr from "./pages/ActionByHr/ActionByHr";
import { setAuthorizationToken } from "./utils/apiServices";
import { setAuthorizationToken as setAuthorizationToken2 } from "./pages/jobs/PostJob/utils/apiServices";




const App = () => {
  const { user, success, error } = useSelector((state) => state);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user && error) {
      setCookie("_jwt", "");
      navigate("/auth-login");
    }

    return () => { };
  }, [user, error]);

  useEffect(() => {
    setAuthorizationToken(getCookie("_jwt"));
    setAuthorizationToken2(getCookie("_jwt"));
  }, [])



  return (
    <>
      <Routes>
        <Route path="/auth-login" element={<Login />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />

        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Homepage />} />
          <Route path="/user-profile" element={<UserProfileLayout />} />
          <Route path="/skills" element={<SkillList />} />
          <Route path="/skill/:id" element={<SkillDetails />} />

          <Route path="/country" element={<CountryList />} />
          <Route path="/country/:id" element={<CountryDetails />} />

          <Route path="/user" element={<User />} />
          <Route path="/user/:slug" element={<UserId />} />
          <Route path="/hr" element={<Hr />} />
          <Route path="/hr/:slug" element={<HrId />} />

          <Route path="/jobs" element={<Jobs />} />
          <Route path="/job/:id" element={<JobById />} />
          <Route path="/post-a-job/:id" element={<PostAJob />} />

          <Route path="/company" element={<Company />} />
          <Route path="/company/:id" element={<CompanyById />} />
          <Route path="/feed" element={<Feed />} />
          <Route path="/add-posts" element={<AddPosts />} />
          <Route path="/get-links" element={<GetLinks />} />
          <Route path="/hashtags" element={<Hashtag />} />
          <Route path="/moderate-world" element={<ModrateWorld />} />
          <Route path="/post-list" element={<PostList />} />
          <Route path="/post-list/:id" element={<PostById />} />
          <Route path="/comment-list" element={<CommentList />} />
          <Route path="/comment-list/:id" element={<CommentListById />} />
          <Route path="/upload-xls" element={<UploadCsv />} />
          <Route path="/action-by-hr" element={<ActionByHr />} />
          <Route path="/action-by-hr-company-details/:id" element={<CompanyById />} />
          <Route path="/action-by-hr-hr-details/:slug" element={<HrId />} />
          <Route path="/action-by-hr-job-details/:id" element={<JobById />} />










          <Route path="*" element={<Error404Classic />} />
        </Route>
      </Routes>

      <ToastContainer
        position="bottom-center"
        toastStyle={{ padding: "2px 5px" }}
        bodyStyle={{
          fontWeight: "600",
          fontSize: "16px",
        }}
        closeButton={false}
        autoClose={3000}
        pauseOnHover={false}

        closeOnClick
        pauseOnFocusLoss={false}
        hideProgressBar={true}
      />
    </>
  );
};
export default App;
