import { toast } from "react-toastify";
import { setAuthorizationToken as setAuthorizationToken2 } from "../../pages/jobs/PostJob/utils/apiServices";
import { getMethod, postMethod, setAuthorizationToken } from "../../utils/apiServices";
import { fetchCookie, getCookie, removeSession, setCookie, setSession } from "../../utils/storage";
import { getUser, isError, isLoading, isSuccess, login, otpSend } from "../Apislices/authSlice";

export const sendOtp = (loginData) => async (dispatch) => {
  dispatch(isLoading());


  let res = await postMethod(`/admin/send-otp`, loginData);
  if (res?.success) {
    setSession("_init", {
      label: loginData.check,
      countryCode: loginData.countryCode,
      key: res.data,
      isNewUser: res.isNewUser,
      provider: res.ProviderValue,
      profileComplete: res.profileComplete,
    });
    dispatch(otpSend(true));
    toast.success(res?.message);

    // navigate("/verify-otp");
  } else {
    dispatch(isError());
    toast.error(res?.message);
  }

  return res

};

export const verifyOtp = (otpData) => async (dispatch) => {


  dispatch(isLoading());
  let res = await postMethod(`/admin/otp-verify`, otpData);

  if (res?.success) {
    setCookie("_jwt", res?.data);
    setAuthorizationToken(res?.data);
    setAuthorizationToken2(res?.data);
    dispatch(login());
    removeSession("_init");
    dispatch(login(res?.data));
    dispatch(isSuccess());
    toast.success(res?.message);
  } else {
    dispatch(isError(res?.message));
    toast.error(res?.message);
  }

  return res

};

export const getUserProfile = () => async (dispatch) => {
  try {
    dispatch(isLoading());
    setAuthorizationToken(getCookie("_jwt"));
    let res = await getMethod(`/admin/profile`, {});

    if (res?.success) {
      dispatch(getUser(res?.data));
      dispatch(isSuccess(true));
    } else {
      dispatch(isError(res?.message));
    }
  } catch (error) {
    dispatch(isError(error?.message));
  }
};

// export const logout = () => async (dispatch) => {
//   try {
//     let res = await getMethod(`/admin/profile`);
//     if (res?.success) {
//       //
//     }
//   } catch (error) {}
// };
