
import moment from 'moment';
let DEFAULT_LANGUAGE = "en";
const TO_LOCALE_STRING = { en: "en-US", ar: "ar-EG" };

export const numberToLocaleString = (num, userLanguage = DEFAULT_LANGUAGE, leadingZero = false) => {
    let num1 = num?.toLocaleString(TO_LOCALE_STRING[userLanguage], { useGrouping: false })
    if (leadingZero && num >= 0 && num < 10) {
        return numberToLocaleString(0, userLanguage) + num1
    }
    return num1

}


export const showDateColorBasedOnDate = (jobValidity) => {
    console.log("jobValidity", jobValidity)
    const jobValidityDate = moment(jobValidity, 'YYYY-MM-DDTHH:mm:ss');
    if (!jobValidityDate.isValid()) {
        console.error('Invalid date format for jobValidity:', jobValidity);
    }
    const currentDate = moment().tz("Africa/Cairo").startOf('day');

    // Check if the jobValidity date is before today
    if (jobValidityDate.isBefore(currentDate, 'day')) {
        // Job validity date has passed
        return "pastDate"; // Apply the appropriate CSS class for past dates
    } else {
        // Job validity date is in the future
        return "futureDate"; // Apply the appropriate CSS class for future dates
    }


}

//find right page list

export const findRightPageBasedOnUrl = (path) => {
    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    // Split the pathname by slashes
    const parts = pathname.split("/");

    // Find the index of the part you're interested in

    if (parts.includes(path)) {
        return true
    } else {
        return false
    }

}